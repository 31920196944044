import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/shared/ui/material.module';
import { DidomiCookieConsentComponent } from './didomi-cookie-consent/didomi-cookie-consent.component';
import { SmartDidomiCookieConsentService } from './services/didomi-cookie-consent.service';

@NgModule({
  declarations: [DidomiCookieConsentComponent],
  imports: [
    CommonModule,
    MaterialModule,
  ],
  providers: [
    SmartDidomiCookieConsentService,
  ],
  exports: [
    DidomiCookieConsentComponent,
  ],
})
export class DidomiModule {
}
