import {
  ActionReducerMap,
  MetaReducer,
} from '@ngrx/store';
import { AuthenticationActionTypes } from './shared/core/authentication/authentication.actions';

export interface State {}

export const reducers: ActionReducerMap<State> = { };

export function clearState(reducer: any) {
  return (state, action) => {
    return reducer(action.type === AuthenticationActionTypes.AuthenticationDisconnect ? undefined : state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [clearState];
