import { BreadcrumbActions, BreadcrumbActionsTypes } from './breadcrumb.actions';
import { BreadcrumbItem } from '../breadcrumb';

export interface BreadcrumbState {
  items: BreadcrumbItem[];
}

export const initialState: BreadcrumbState = {
  items: [],
};

export function reducer(state = initialState, action: BreadcrumbActions): BreadcrumbState {
  switch (action.type) {

    case BreadcrumbActionsTypes.SetBreadcrumb:
      return {
        ...state,
        items: action.payload,
      };

    case BreadcrumbActionsTypes.AddBreadcrumb:
      return {
        ...state,
        items: [...state.items, ...action.payload],
      };

    case BreadcrumbActionsTypes.RemoveBreadcrumb:
      return {
        ...state,
        items: state.items.filter((breadcrumbItem) => {
          return !action.payload.find(i => i.label === breadcrumbItem.label && i.path === breadcrumbItem.path);
        }),
      };

    default:
      return state;
  }
}
