import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetPathPipe } from './asset-path.pipe';
import { SeasonByDatePipe } from './season-by-date.pipe';
import { LowerUnderscorePipe } from './lower-underscore.pipe';
import { CustomCurrencyPipe } from './custom-currency.pipe';
import { CustomNumberPipe } from '@shared/ui/pipes/custom-number.pipe';
import { ContractReferencePipe } from '@shared/ui/pipes/contract-reference.pipe';
import { ContainBraceTokenPipe } from './contain-brace-token';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    AssetPathPipe,
    SeasonByDatePipe,
    LowerUnderscorePipe,
    CustomCurrencyPipe,
    CustomNumberPipe,
    ContractReferencePipe,
    ContainBraceTokenPipe,
  ],
  exports: [
    AssetPathPipe,
    SeasonByDatePipe,
    LowerUnderscorePipe,
    CustomCurrencyPipe,
    CustomNumberPipe,
    ContractReferencePipe,
    ContainBraceTokenPipe,
  ],
})
export class PipesModule {
}
