<mgr-popin codename="unsupportedBrowserPopin" size="sm">
  <div class="popin-header no-border text-center">
    <h2 class="popin-title">{{ 'global-browser_version_popin-title' | translate }}</h2>
  </div>
  <div class="popin-content text-center">
    <p>{{'global-browser_version_popin-text'| translate}}</p>
  </div>
  <div class="popin-footer text-center">
    <button mat-raised-button type="button" mgrClosePopin="unsupportedBrowserPopin"
      color="primary">{{'global-browser_version_popin-close'| translate}}</button>
  </div>
</mgr-popin>
<router-outlet></router-outlet>