import { DidomiCookieConsentViewModel } from '../model/didomi-cookie-consent.view-model';

export const SiteContent: DidomiCookieConsentViewModel = {
  app: {
    apiKey: '',
    privacyPolicyURL: '',
  },
  didomiVendorSdkIds: [
    'googleana',
    'recaptcha',
  ],
  gaVendorId: '',
  gtmKey: '',
  tagManager: {
    provider: 'gtm',
  },
  theme: {
    buttons: {
      regularButtons: {
        backgroundColor: '#050b7f',
        borderRadius: '30px',
        color: 'white',
      },
      highlightButtons: {
        backgroundColor: '#050b7f',
        borderRadius: '30px',
        color: 'white',
      },
    },
  },
};
