import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { reducer } from './state/popin.reducer';
import { TranslateModule } from '@ngx-translate/core';
import { PopinComponent } from './popin.component';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../directives/directives.module';
import { OpenPopinDirective } from './directive/open-popin.directive';
import { ClosePopinDirective } from './directive/close-popin.directive';
import { PopinService } from '@shared/ui/popin/shared/popin.service';

@NgModule({
  declarations: [
    PopinComponent,
    OpenPopinDirective,
    ClosePopinDirective,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    DirectivesModule,
    StoreModule.forFeature('popin', reducer),
  ],
  exports: [
    PopinComponent,
    OpenPopinDirective,
    ClosePopinDirective,
  ],
  providers: [
    PopinService,
  ],
})
export class PopinModule {
}
