import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessService } from './access.service';
import { RoleGuardService } from './role-guard.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    AccessService,
    RoleGuardService,
  ],
  declarations: [],
})
export class AccessModule { }
