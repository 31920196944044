import { Action } from '@ngrx/store';

export enum NavigationActionTypes {
  ToggleNotifications = '[Navigation] Toggle Notifications Block',
  ToggleUserNavigation = '[Navigation] Toggle User Navigation',
  ToggleFullNavigation = '[Navigation] Toggle Full Navigation',
  ToggleLanguages = '[Navigation] Toggle Language',
}

export class ToggleNotifications implements Action {
  readonly type = NavigationActionTypes.ToggleNotifications;

  constructor(public payload: boolean) {
  }
}

export class ToggleUserNavigation implements Action {
  readonly type = NavigationActionTypes.ToggleUserNavigation;

  constructor(public payload: boolean) {
  }
}

export class ToggleFullNavigation implements Action {
  readonly type = NavigationActionTypes.ToggleFullNavigation;

  constructor(public payload: boolean) {
  }
}

export class ToggleLanguages implements Action {
  readonly type = NavigationActionTypes.ToggleLanguages;

  constructor(public payload: boolean) {
  }
}

export type NavigationActions = ToggleNotifications | ToggleUserNavigation | ToggleFullNavigation | ToggleLanguages;
