<div class="user-nav" *ngIf="user">

  <mgr-password-expiration-popin [daysBeforePasswordExpires]="daysBeforePasswordExpires"></mgr-password-expiration-popin>

  <i (click)="notifyToggleEvent(!showUserNavigation)" class="icon-user user-nav-name" id="user-nav-button"></i>

  <ul (clickOut)="notifyToggleEvent(false)" *ngIf="showUserNavigation" [@Fade] [exclude]="'#user-nav-button'"
    class="user-nav-container" mgrClickOutside>

    <li class="user-nav-item" (click)="notifyToggleEvent(false); openEditMyProfilePanel()">
      <i class="user-nav-icon icon-user"></i>
      <span class="user-nav-link">{{ 'global-top_header-my_profile'| translate }}</span>
    </li>
    <li class="user-nav-item">
      <a class="user-nav-link" href="javascript:Didomi.preferences.show('preferences')">
        <i class="user-nav-icon icon-compliance"></i>{{'global-top_header-manage_cookies_preferences' | translate }}
      </a>
    </li>

    <!--    <li class="user-nav-item" *restrictTo="[USER_ROLES.Administrator, USER_ROLES.Commercial, USER_ROLES.FleetManager]"-->
    <!--      (click)="notifyToggleEvent(false); openMyPreferencesPanel()">-->
    <!--      <i class="user-nav-icon icon-settings"></i>-->
    <!--      <span class="user-nav-link">{{ 'global-top_header-my_preferences'| translate }}</span>-->
    <!--    </li>-->

    <li class="user-nav-item" *ngIf="hasDriverRole">
      <a class="user-nav-link" href="{{driverUrl}}" target="_blank">
        <i class="user-nav-icon icon-switch"></i>{{ 'global-top_header-continue_as_driver'| translate }}
      </a>
    </li>

    <li class="user-nav-item" mgrOpenPopin="logoutPopin" (click)="notifyToggleEvent(false)">
      <i class="user-nav-icon icon-power"></i>
      <span class="user-nav-link">{{ 'global-top_header-logout'| translate }}</span>
    </li>

  </ul>
</div>