import { PasswordExpiration, UserRoles } from './user.const';
import { Company } from '@shared/models/company.model';
import { UserRole } from '@shared/models/user-role.model';
import { UserProfile } from '@shared/models/user-profile.model';

export class UserHelper {
  public static getUserRole(userProfile: UserProfile, role: UserRoles, countryCode: string): UserRole {
    if (userProfile && userProfile.userRoles && userProfile.userRoles.length > 0) {
      const userRoleToReturn = userProfile.userRoles.find(userRole =>
        userRole.role.toLowerCase() === role.toLowerCase() && userRole.countryCode.toLowerCase() === countryCode.toLowerCase());
      if (userRoleToReturn) {
        return userRoleToReturn;
      }
    }

    return null;
  }

  public static getUserCompany(userProfile: UserProfile, countryCode: string): Company {
    if (userProfile && userProfile.userRoles && userProfile.userRoles.length > 0) {
      const userRoleToReturn = userProfile.userRoles.filter(userRole => userRole.countryCode.toLowerCase() === countryCode.toLowerCase());
      if (userRoleToReturn && userRoleToReturn.length > 0) {
        return userRoleToReturn[0].company;
      }
    }

    return null;
  }
  public static setPasswordExpirationState(
    users: { passwordExpirationDate: Date, passwordExpirationState}[],
    delay: number): { passwordExpirationDate: Date, passwordExpirationState}[] {
    const today = new Date();
    if (!users) {
      return;
    }
    users.map((u) => {
      if (!u.passwordExpirationDate) {
        u.passwordExpirationState = null;
        return;
      }
      const expirationDate = new Date(u.passwordExpirationDate);
      if (Math.ceil((expirationDate.getTime() - today.getTime()) / (1000 * 3600 * 24)) <= delay) {
        u.passwordExpirationState = PasswordExpiration.WillExpireSoon;
      }
      if (expirationDate <= today) {
        u.passwordExpirationState = PasswordExpiration.Expired;
      }
    });
    return users;
  }

}
