import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { StorageConst } from './storage.const';

@Injectable()
export class StorageAuthService {

  constructor(private storage: StorageService) {
  }

  public setAuth(tokens: any): void {
    const storageKey = StorageConst.auth.keys.rememberAuth;
    const storageType = StorageService.STORES.LOCAL;

    this.storage.set(storageKey, tokens, storageType);
  }

  get auth() {
    return this.rememberAuth;
  }

  removeAuth() {
    this.storage.remove(StorageConst.auth.keys.rememberAuth, StorageService.STORES.LOCAL);
  }

  get rememberAuth() {
    return this.storage.get(StorageConst.auth.keys.rememberAuth, StorageService.STORES.LOCAL);
  }

  get accessToken(): string {
    return this.auth[StorageConst.auth.keys.accessToken] || false;
  }

  get refreshToken(): string | boolean {
    return this.auth[StorageConst.auth.keys.refreshToken] || false;
  }

}
