import { AppCultures } from './app-cultures';
import { CurrencyCodes, CurrencySymbols } from './app-currencies';
import { AppCountries } from './app-countries';
import { StorageConst } from '@app/shared/core/storage/storage.const';

export const CONFIGURATION = {
  AppCountries,
  AppCultures,
  CurrencyCodes,
  CurrencySymbols,
  DefaultAppCulture: 'en-us',
  getCountry: (country: string = window.sessionStorage.getItem(StorageConst.selectedCountry)) => {
    return AppCountries[country.toUpperCase()];
  },
};
