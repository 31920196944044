<div class="country-access-error">
  <h1 class="country-access-error-title">
    {{ 'global-country_access_error_page-title' | translate }}
  </h1>

  <div>
    <a (click)="goToLogin()" mat-raised-button color="accent" class="country-access-error-home-button">{{ 'global-country_access_error_page-button' | translate }}</a>
  </div>
</div>

<iframe id="logout_ifm" width="1" height="1" src=""></iframe>
