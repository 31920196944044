import { ModuleWithProviders, NgModule } from '@angular/core';
import { TransferHttpModule } from './core/transfer-http';
import { StorageModule } from './core/storage/storage.module';
import { AuthenticationModule } from './core/authentication/authentication.module';
import { StorageService } from './core/storage/storage.service';
import { StorageAuthService } from './core/storage/storage.auth.service';
import { WindowRef } from './core/helpers/window.ref';
import { DocumentRef } from './core/helpers/document.ref';
import { AccessModule } from './core/access/access.module';
import { ConfigurationModule } from './core/configuration/configuration.module';
import { DirectivesModule } from './ui/directives/directives.module';
import { FeatureModule } from './core/feature/feature.module';
import { FeatureService } from './core/feature/feature.service';
import { NotFoundComponent } from './ui/not-found/not-found.component';
import { PopinModule } from './ui/popin/popin.module';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconModule } from './ui/svg-icon/svg-icon.module';
import { MgrFormsModule } from './ui/forms/forms.module';
import { PipesModule } from './ui/pipes/pipes.module';
import { SvgInlineModule } from './ui/svg-inline/svg-inline.module';
import { CommonModule } from '@angular/common';
import { BreadcrumbModule } from './ui/breadcrumb/breadcrumb.module';
import { PanelModule } from './ui/panel/panel.module';
import { NotificationPreviewModule } from './ui/notification-preview/notification-preview.module';
import { AldSnackbarModule, AldSnackbarService, AldMatButtonLoadingModule } from '@ald/ui';
import { ShellModule } from '@shell/shell.module';
import { RouterModule } from '@angular/router';
import { GenericNotificationPanelComponent } from './ui/notification/generic-notification-panel/generic-notification-panel.component';
import { LoadingModule } from '@shared/ui/loading/loading.module';
import { MaterialModule } from '@app/shared/ui/material.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ContractsService } from '@my-fleet/shared/services/contracts.service';
import { NotificationDisplayComponent } from '@shared/ui/notification-preview/notification-display/notification-display.component';
import { DocumentsService } from '@documents/shared/services/documents.service';
import { UserManagementService } from '@user-management/shared/services/user-management.service';
import { NotificationsService } from '@notifications/shared/services/notifications.service';
import { MessageComponent } from './ui/message/message.component';
import { InlineEditComponent } from './ui/inline-edit/inline-edit.component';
import { UserErrorPageComponent } from './ui/user-error-page/user-error-page.component';
import { CountryAccessErrorPageComponent } from './ui/country-access-error-page/country-access-error-page.component';
import { MapService } from './core/map/map.service';
import { AppConfigService } from 'country-configuration/app-config.service';
import { HostPageService } from 'country-configuration/host-page.service';

@NgModule({
  declarations: [
    NotFoundComponent,
    UserErrorPageComponent,
    CountryAccessErrorPageComponent,
    GenericNotificationPanelComponent,
    MessageComponent,
    InlineEditComponent,
  ],
  imports: [
    CommonModule,
    ShellModule,
    DirectivesModule,
    PopinModule,
    PanelModule,
    SvgIconModule,
    MgrFormsModule,
    PipesModule,
    SvgInlineModule,
    BreadcrumbModule,
    RouterModule,
    TranslateModule,
    NotificationPreviewModule,
    LoadingModule,
    MaterialModule,
    NgxMaterialTimepickerModule,
    AldMatButtonLoadingModule,
    AldSnackbarModule,
  ],
  exports: [
    CommonModule,
    TransferHttpModule,
    StorageModule,
    AuthenticationModule,
    AccessModule,
    ConfigurationModule,
    DirectivesModule,
    ShellModule,
    FeatureModule,
    PopinModule,
    PanelModule,
    TranslateModule,
    SvgIconModule,
    MgrFormsModule,
    PipesModule,
    SvgInlineModule,
    BreadcrumbModule,
    NotificationPreviewModule,
    MessageComponent,
    AldSnackbarModule,
  ],
  providers: [
    StorageService,
    StorageAuthService,
    DocumentRef,
    StorageAuthService,
    FeatureService,
    WindowRef,
    DocumentRef,
    AldSnackbarService,
    ContractsService,
    DocumentsService,
    UserManagementService,
    NotificationsService,
    MapService,
    HostPageService,
    AppConfigService,
  ],
  entryComponents: [
    NotificationDisplayComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return { ngModule: SharedModule };
  }
}
