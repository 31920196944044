import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

export function validateCustomUrl(control: AbstractControl): ValidationErrors | null {
  /* tslint:disable:max-line-length */
  const urlRegex = /http(s)?:\/\/.?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/;
  return (!control.value || urlRegex.test(control.value)) ? null : { url: true };
}

@Directive({
  selector: '[customUrl]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CustomUrlValidatorDirective, multi: true }],
})
export class CustomUrlValidatorDirective implements Validator {
  validate: ValidatorFn = validateCustomUrl;
}
