import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DocumentsService } from '@documents/shared/services/documents.service';
import { AldSnackbarService } from '@ald/ui';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mgr-notification-display',
  templateUrl: './notification-display.component.html',
  styleUrls: ['./notification-display.component.scss'],
})
export class NotificationDisplayComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<NotificationDisplayComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private documentsService: DocumentsService,
              private readonly translateService: TranslateService,
              private snackBarService: AldSnackbarService) { }

  ngOnInit() {
  }

  getDocument(documentId) {
    this.documentsService.getDownloadUrl(documentId).subscribe((documentUrl: string) => {
      if (documentUrl) {
        window.open(documentUrl, '_blank');
      } else {
        this.snackBarService.error(this.translateService.instant('global-generic_terms-snackbar_generic_error'));
      }
    });
  }

  onClose(isValidate: boolean): void {
    this.dialogRef.close(isValidate);
  }

}
