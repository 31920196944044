<mat-form-field class="form-fullwidth multiselect">
    <mat-select disableRipple
                disableOptionCentering
                placeholder="{{ label  }}"
                [required]="hasValidator('required')"
                [formControl]="control"
               
                [multiple]="true">
      <!-- Selected options chips display-->
      <mat-select-trigger class="multiselect-chips">
        <mat-chip-list>
          <mat-basic-chip disableRipple *ngFor="let selected of control.value" [selectable]="false" [removable]="true" (removed)="removeOption(selected)">
            {{ selected.label }}
            <i class="icon-x" matChipRemove matRipple *ngIf="!control.disabled"></i>
          </mat-basic-chip>
        </mat-chip-list>
      </mat-select-trigger>
      <!-- Search-->
      <mat-option>
        <ngx-mat-select-search placeholderLabel="{{ 'global-generic_terms-search' | translate }}" [noEntriesFoundLabel]="null" [formControl]="searchOptionField">
            <i style="display:none;" class="icon-x" ngxMatSelectSearchClear></i>
            <button  class="mat-select-search-custom-header-content" (click)="applyFilter(searchOptionField.value)">{{ 'global-generic_terms-search' | translate }}</button>
          </ngx-mat-select-search>
      </mat-option>
  
     <!-- No results message -->
     <div class="multiselect-no-entries-found" *ngIf="searchOptionField.value?.length > 1 && isNoResultsState && !isOptionsLoading">
      {{ 'global-generic_terms-no_results' | translate }}
    </div>
  
      <!-- Loading indicator -->
      <mat-option class="multiselect-loading" *ngIf="isOptionsLoading">
        <mat-spinner [diameter]="20" [strokeWidth]="2" class="multiselect-loading-spinner"></mat-spinner>
      </mat-option>
  
      <!-- All options -->
      <ng-container *ngIf="options && !isOptionsLoading" [@Fade]>
        <mat-option class="multiselect-option"
                    *ngFor="let option of options" [value]="option"
                    [class.child]="option.parentId"
                    [class.grandchild]="isGrandChild(option)"
                    [class.hidden]="isOptionSelected(option)">
          {{ option.label }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <span *ngIf="maxItems" class="multiselect-max-items"> {{ control.value?.length + ' / ' + maxItems}}</span>
  <span *ngIf="!control.disabled && clearOption && control.value?.length > 0" (click)="clearSelection()" class="multiselect-clear-selection-old">{{ 'global-generic_terms-clear_selection' | translate }}</span>
  
  