import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.state';
import * as fromNotifications from './notifications.reducer';

// Extends the app state to include the navigation feature.
export interface State extends fromRoot.State {
  notifications: fromNotifications.NotificationsState;
}

// Selector functions
export const getNotificationsFeatureState = createFeatureSelector<fromNotifications.NotificationsState>('notifications');

export const getNotifications = createSelector(
  getNotificationsFeatureState,
  state => state.notifications,
);

export const getManagerAlerts = createSelector(
  getNotificationsFeatureState,
  state => state.managerAlerts,
);

export const getManagerAlertsLoading = createSelector(
  getNotificationsFeatureState,
  state => state.managerAlertsLoading,
);

export const getUnreadNotificationsNumber = createSelector(
  getNotificationsFeatureState,
  state => state.managerAlerts.filter((managerAlert => !managerAlert.read)).length,
);

export const getNotificationsLoading = createSelector(
  getNotificationsFeatureState,
  state => state.loading,
);

export const getSelectedNotification = createSelector(
  getNotificationsFeatureState,
  (state) => {
    if (!state.notifications) {
      return [];
    }
    return state.notifications.filter((notification) => {
      return notification.id === state.selectedNotificationId;
    });
  },
);
