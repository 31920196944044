import * as tslib_1 from "tslib";
import { UserActionTypes } from './user.actions';
export var initialState = {
    loading: false,
    isLoaded: false,
    user: null,
    selectedUserRole: null,
    workingScope: [],
    language: null,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var loading;
    switch (action.type) {
        case UserActionTypes.GetUser:
            loading = true;
            return tslib_1.__assign({}, state, { loading: loading });
        case UserActionTypes.GetUserSuccess:
            loading = false;
            var user = action.payload;
            return tslib_1.__assign({}, state, { loading: loading, user: user, isLoaded: true });
        case UserActionTypes.GetUserFail:
            loading = false;
            return tslib_1.__assign({}, state, { loading: loading, isLoaded: true });
        case UserActionTypes.UpdateWorkingScope:
            loading = true;
            return tslib_1.__assign({}, state, { loading: loading });
        case UserActionTypes.UpdateWorkingScopeSuccess:
            var newUser = Object.assign({}, state.user);
            newUser.userRoles.find(function (r) { return r.id === state.selectedUserRole.id; }).workingScopes = action.payload;
            var newRole = state.selectedUserRole;
            newRole.workingScopes = action.payload;
            loading = false;
            return tslib_1.__assign({}, state, { loading: loading, user: newUser, selectedUserRole: newRole });
        case UserActionTypes.UpdateWorkingScopeFail:
            loading = false;
            return tslib_1.__assign({}, state, { loading: loading });
        case UserActionTypes.SwitchRole:
            return tslib_1.__assign({}, state, { selectedUserRole: action.payload });
        case UserActionTypes.UpdateMyUserProfile:
            loading = true;
            return tslib_1.__assign({}, state, { loading: loading });
        case UserActionTypes.UpdateMyUserProfileSuccess:
            loading = false;
            return tslib_1.__assign({}, state, { loading: loading, user: user });
        case UserActionTypes.UpdateMyUserProfileFail:
            loading = false;
            return tslib_1.__assign({}, state, { loading: loading });
        case UserActionTypes.ClearUserStore:
            loading = false;
            return tslib_1.__assign({}, initialState, { loading: loading });
        case UserActionTypes.UpdateMyPreferences:
            loading = true;
            return tslib_1.__assign({}, state, { loading: loading });
        case UserActionTypes.UpdateSelectedCulture:
            return tslib_1.__assign({}, state, { language: action.payload });
        case UserActionTypes.UpdateMyPreferencesSuccess:
            loading = false;
            var selectedUserRole = state.selectedUserRole;
            selectedUserRole.culture = action.payload.culture;
            selectedUserRole.notificationByEmail = action.payload.notificationByEmail;
            selectedUserRole.notificationByPush = action.payload.notificationByPush;
            return tslib_1.__assign({}, state, { loading: loading });
        case UserActionTypes.UpdateMyPreferencesFail:
            loading = false;
            return tslib_1.__assign({}, state, { loading: loading });
        default:
            return state;
    }
}
