import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

export function validateNumeric(control: AbstractControl): ValidationErrors | null {
  return (!control.value || /^[0-9]+$/.test(control.value)) ? null : { numeric: true };
}

@Directive({
  selector: '[numeric]',
  providers: [{ provide: NG_VALIDATORS, useExisting: NumericValidatorDirective, multi: true }],
})

export class NumericValidatorDirective implements Validator {
  validate: ValidatorFn = validateNumeric;
}
