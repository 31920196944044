/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navigation-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./navigation-item.component";
var styles_NavigationItemComponent = [i0.styles];
var RenderType_NavigationItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavigationItemComponent, data: {} });
export { RenderType_NavigationItemComponent as RenderType_NavigationItemComponent };
function View_NavigationItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "subnav-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
export function View_NavigationItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "left-nav-item-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "subnav-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "ul", [["class", "subnav"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationItemComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.title; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "left-nav-item-icon icon-", _co.icon, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 2, 0, currVal_1); }); }
export function View_NavigationItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mgr-navigation-item", [], null, null, null, View_NavigationItemComponent_0, RenderType_NavigationItemComponent)), i1.ɵdid(1, 49152, null, 0, i3.NavigationItemComponent, [], null, null)], null, null); }
var NavigationItemComponentNgFactory = i1.ɵccf("mgr-navigation-item", i3.NavigationItemComponent, View_NavigationItemComponent_Host_0, { title: "title", icon: "icon" }, {}, ["*"]);
export { NavigationItemComponentNgFactory as NavigationItemComponentNgFactory };
