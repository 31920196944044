import * as tslib_1 from "tslib";
import { TranslationService } from './../../../core/translation/translation.service';
import { UserService } from '../user.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { GetUser, GetUserSuccess, SwitchRole, UpdateMyPreferencesSuccess, UpdateMyUserProfileSuccess, UpdateWorkingScopeSuccess, UserActionTypes, UpdateLastLoginDateSuccess, UpdateLastLoginDateFail, } from './user.actions';
import { Observable, of } from 'rxjs';
import { UserManagementService } from '@user-management/shared/services/user-management.service';
import { StorageService } from '@shared/core/storage/storage.service';
import { StorageConst } from '@shared/core/storage/storage.const';
import { AldSnackbarService } from '@ald/ui';
import { PanelService } from '@shared/ui/panel/shared/panel.service';
import { UserManagementConst } from '@user-management/shared/user-management.const';
import { UserUtilsService } from '@user-management/shared/services/user-utils.service';
import { Router } from '@angular/router';
import { APP_ROUTES } from '@app/app.routes';
import { GetContractList } from '@my-fleet/shared/state/contract-state/contract.actions';
import { GetDocuments } from '@documents/shared/state/documents.actions';
import { GetOrderList } from '@my-fleet/shared/state/order-state/order.actions';
import { GetMyaldAccessUsers } from '@user-management/myald-access/shared/state/myald-access.actions';
import { GetUserList } from '@user-management/user-list/shared/state/user-list.actions';
import { GtmService } from '@shared/core/gtm/gtm.service';
import { GTM_EVENTS } from '@shared/core/gtm/gtm-events.const';
import { GtmUserRoleProperty } from '@shared/core/gtm/gtm-properties.model';
import { UpdateKpis } from '@app/dashboard/shared/state/dashboard-kpi.actions';
import { TranslateService } from '@ngx-translate/core';
var UserEffects = /** @class */ (function () {
    function UserEffects(userService, actions$, storageService, panelService, userManagementService, translationService, userUtilsService, snackBarService, gtmService, router, translateService) {
        var _this = this;
        this.userService = userService;
        this.actions$ = actions$;
        this.storageService = storageService;
        this.panelService = panelService;
        this.userManagementService = userManagementService;
        this.translationService = translationService;
        this.userUtilsService = userUtilsService;
        this.snackBarService = snackBarService;
        this.gtmService = gtmService;
        this.router = router;
        this.translateService = translateService;
        this.getUser$ = this.actions$.pipe(ofType(UserActionTypes.GetUser), mergeMap(function () {
            return _this.userService.getUser().pipe(switchMap(function (user) {
                if (user && user.userRoles) {
                    var selectedRole = _this.userService.getUserDefaultRole(user);
                    return [new GetUserSuccess(user), new SwitchRole(selectedRole)];
                }
            }), catchError(function (error) {
                var redirectRoute = error.status === 401 ? [APP_ROUTES.COUNTRY_ACCESS_ERROR] : [APP_ROUTES.USER_ERROR];
                _this.router.navigate(redirectRoute);
                return of({ type: UserActionTypes.GetUserFail });
            }));
        }));
        this.switchRole$ = this.actions$.pipe(ofType(UserActionTypes.SwitchRole), map(function (action) { return action.payload; }), tap(function (userRole) {
            _this.storageService.set(StorageConst.selectedRoleId, userRole.id);
            _this.storageService.set(StorageConst.selectedRoleLabel, userRole.role);
            _this.storageService.set(StorageConst.selectedCountry, userRole.countryCode);
        }));
        this.updateWorkingScope$ = this.actions$.pipe(ofType(UserActionTypes.UpdateWorkingScope), mergeMap(function (action) {
            _this.snackBarService.loading(_this.translateService.instant('global-working_scope_panel-snackbar_loading'));
            return _this.userUtilsService.updateSelectedWorkingScopes(action.payload.filter(function (ws) { return ws.isSelected; }).map(function (ws) { return ws.companyId; })).pipe(tap(function () {
                _this.snackBarService.success(_this.translateService.instant('global-working_scope_panel-snackbar_success'));
                _this.panelService.close(UserManagementConst.windowCodename.switchWorkingScopePanel);
            }), switchMap(function () {
                _this.gtmService.sendEvent(GTM_EVENTS.switchWorkingScope, action.payload.length.toString(), GtmUserRoleProperty.role, true);
                return [new UpdateWorkingScopeSuccess(action.payload)].concat(_this.getCurrentRouteRefreshAction());
            }), catchError(function () {
                _this.snackBarService.error(_this.translateService.instant('global-working_scope_panel-snackbar_error'));
                return of({ type: UserActionTypes.UpdateWorkingScopeFail });
            }));
        }));
        this.updateMyUserProfile$ = this.actions$.pipe(ofType(UserActionTypes.UpdateMyUserProfile), mergeMap(function (action) {
            _this.snackBarService.loading(_this.translateService.instant('global-edit_my_profile_panel-snackbar_loading'));
            return _this.userManagementService.updateMyUserProfile(action.payload).pipe(switchMap(function () {
                _this.snackBarService.success(_this.translateService.instant('global-edit_my_profile_panel-snackbar_success'));
                _this.gtmService.sendEvent(GTM_EVENTS.editUserProfile, GtmUserRoleProperty.role, GtmUserRoleProperty.countryCode, true);
                return [new UpdateMyUserProfileSuccess(), new GetUser()];
            }), catchError(function () {
                _this.snackBarService.error(_this.translateService.instant('global-generic_terms-snackbar_generic_error'));
                return of({ type: UserActionTypes.UpdateMyUserProfileFail });
            }));
        }));
        this.updateMyPreferences$ = this.actions$.pipe(ofType(UserActionTypes.UpdateMyPreferences), mergeMap(function (action) {
            if (action.showToaster) {
                _this.snackBarService.loading(_this.translateService.instant('global-edit_preferences_panel-snackbar_loading'));
            }
            return _this.userManagementService.updateMyUserPreferences(action.payload).pipe(tap(function () {
                if (action.showToaster) {
                    _this.snackBarService.success(_this.translateService.instant('global-edit_preferences_panel-snackbar_success'));
                }
                _this.panelService.close(UserManagementConst.windowCodename.editPreferencesPanel);
            }), switchMap(function () {
                if (action.payload.culture) {
                    _this.translationService.language = action.payload.culture;
                }
                return [new UpdateMyPreferencesSuccess(action.payload)];
            }), catchError(function () {
                if (action.showToaster) {
                    _this.snackBarService.error(_this.translateService.instant('global-edit_preferences_panel-snackbar_error'));
                }
                return of({ type: UserActionTypes.UpdateMyPreferencesFail });
            }));
        }));
        this.updateLastLoginDate$ = this.actions$.pipe(ofType(UserActionTypes.UpdateLastLoginDate), mergeMap(function () {
            return _this.userService.updateLastLoginDate().pipe(switchMap(function () { return of(new UpdateLastLoginDateSuccess()); }), catchError(function () { return of(new UpdateLastLoginDateFail()); }));
        }));
        this.updateLastLoginDateFail$ = this.actions$.pipe(ofType(UserActionTypes.UpdateLastLoginDateFail), tap(function () {
            _this.snackBarService.error(_this.translateService.instant('global-generic_terms-snackbar_generic_error'));
        }));
    }
    UserEffects.prototype.getCurrentRouteRefreshAction = function () {
        var _this = this;
        var _a;
        var actionsList = (_a = {},
            _a[APP_ROUTES.DOCUMENTS] = new GetDocuments(),
            _a[APP_ROUTES.CONTRACT_LIST] = new GetContractList(),
            _a[APP_ROUTES.ORDER_LIST] = new GetOrderList(),
            _a[APP_ROUTES.GIVE_ACCESS] = new GetMyaldAccessUsers(),
            _a[APP_ROUTES.USER_LIST] = new GetUserList(),
            _a[APP_ROUTES.HOME] = new UpdateKpis(),
            _a);
        var routes = Object.keys(actionsList);
        var routeToReload = routes.find(function (t) { return _this.router.url.includes(t); });
        return routeToReload ? [actionsList[routeToReload]] : [];
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UserEffects.prototype, "getUser$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Observable)
    ], UserEffects.prototype, "switchRole$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UserEffects.prototype, "updateWorkingScope$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UserEffects.prototype, "updateMyUserProfile$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UserEffects.prototype, "updateMyPreferences$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UserEffects.prototype, "updateLastLoginDate$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Observable)
    ], UserEffects.prototype, "updateLastLoginDateFail$", void 0);
    return UserEffects;
}());
export { UserEffects };
