import { Component, OnInit } from '@angular/core';
import { WindowRef } from '@app/shared/core/helpers/window.ref';
import { SiteContent } from '../site-details/didomi-cookie-consent.site-element';
import { DidomiConsent, DidomiCookieConsentViewModel } from '../model/didomi-cookie-consent.view-model';
import { SmartDidomiCookieConsentService } from '../services/didomi-cookie-consent.service';
import { Store } from '@ngrx/store';
import { StartState } from '@app/start/state/start.reducer';
import { UpdateRecaptchaAuth } from '@app/start/state/start.actions';
@Component({
  selector: 'mgr-smart-didomi-cookie-consent',
  templateUrl: './didomi-cookie-consent.component.html',
  styleUrls: ['./didomi-cookie-consent.component.scss'],
})
export class DidomiCookieConsentComponent implements OnInit {
  didomiConfigDetails: DidomiCookieConsentViewModel;
  gaVendorId: string;
  capchaVendorId: string;

  constructor(
    private window: WindowRef,
    private startStore: Store<StartState>,
    private didomiCookiesService: SmartDidomiCookieConsentService) {
  }

  ngOnInit() {
    this.didomiCookiesService.loadConfig(SiteContent)
      .subscribe((didomiConfigDetails) => {
        this.didomiConfigDetails = didomiConfigDetails;
        const win = this.window.nativeWindow as any;
        win.didomiConfig = this.didomiConfigDetails;
        this.removeGoogleCookies();
        this.loadDidomiScript();
        this.initialiseDidomiEventListener();
      });
  }

  private loadDidomiScript(): void {
    const node = document.createElement('script');
    node.src = './assets/didomi.js';
    node.type = 'text/javascript';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  // Commenting google analytics temporarily
  private loadGoogleScript(): void {
    // const node = document.createElement('script');
    // node.src = './assets/google-analytics.js';
    // node.type = 'text/javascript';
    // node.async = true;
    // document.getElementsByTagName('head')[0].appendChild(node);
  }

  private removeGoogleCookies(): void {
    const domain = this.didomiCookiesService.getDomain(window.location.hostname);
    const allCookies = this.didomiCookiesService.getAllCookie();
    allCookies.forEach((cookie: string, index) => {
      if (allCookies.hasOwnProperty(index) && (cookie.startsWith('_ga') || cookie.startsWith('_gi'))) {
        this.didomiCookiesService.deleteCookies(cookie, domain);
      }
    });
  }

  private getVendorsIds(configDetails) {
    const didomiVendorSdkIds = this.didomiConfigDetails.didomiVendorSdkIds;
    configDetails.app.vendors.custom.forEach((vendor) => {
      if (vendor.id.includes(didomiVendorSdkIds[0])) {
        this.gaVendorId = vendor.id;
      }
      if (vendor.id.includes(didomiVendorSdkIds[1])) {
        this.capchaVendorId = vendor.id;
      }
    });
  }

  private onVendorStateReceivedFromDidomi(didomiState) {
    if (didomiState.didomiVendorsConsentDenied.includes(this.gaVendorId)) {
      this.removeGoogleCookies();
    }
    if (didomiState.didomiVendorsConsent.includes(this.gaVendorId)) {
      this.loadGoogleScript();
    }
    if (didomiState.didomiVendorsConsentDenied.includes(this.capchaVendorId)) {
      this.startStore.dispatch(new UpdateRecaptchaAuth('disagree'));
    }
    if (didomiState.didomiVendorsConsent.includes(this.capchaVendorId)) {
      this.startStore.dispatch(new UpdateRecaptchaAuth('agree'));
    }
    if (didomiState.didomiVendorsConsentUnknown.includes(this.capchaVendorId)) {
      this.startStore.dispatch(new UpdateRecaptchaAuth('unknown'));
    }
  }

  private onVendorsConsentStatusChanged(configDetails, context) {
    const recaptchaDeniedConsents = this.getChangedConsent(configDetails, context, this.capchaVendorId);
    if (recaptchaDeniedConsents && recaptchaDeniedConsents.length > 0) {
      this.startStore.dispatch(new UpdateRecaptchaAuth('disagree'));
    } else {
      this.startStore.dispatch(new UpdateRecaptchaAuth('agree'));
    }
    const gaDeniedConsents = this.getChangedConsent(configDetails, context, this.gaVendorId);
    if (gaDeniedConsents && gaDeniedConsents.length > 0) {
      this.removeGoogleCookies();
    }
    if (gaDeniedConsents && gaDeniedConsents.length === 0) {
      this.loadGoogleScript();
    }
  }

  private initialiseDidomiEventListener(): void {
    const win = this.window.nativeWindow as any;
    win.didomiConfig = this.didomiConfigDetails;

    // tslint:disable-next-line: no-this-assignment
    const self = this;
    this.window.nativeWindow.didomiOnReady = win.didomiOnReady || [];
    // @ts-ignore
    this.window.nativeWindow.didomiOnReady.push(function (Didomi) {
      const configDetails = Didomi.getConfig();
      self.getVendorsIds(configDetails);
      window.addEventListener(
        'message',

        // tslint:disable-next-line: ter-prefer-arrow-callback
        (event) => {
          const didomiState = event.target['didomiState'];
          self.onVendorStateReceivedFromDidomi(didomiState);
        },
        false,
      );

      // @ts-ignore
      window.didomiEventListeners = window.didomiEventListeners || [];

      // @ts-ignore
      window.didomiEventListeners.push({
        event: 'consent.changed',
        listener(context: DidomiConsent) {
          self.onVendorsConsentStatusChanged(configDetails, context);
        },
      });
    });
  }

  private getChangedConsent(configDetails, context, vendorId): any {
    if (vendorId) {
      const changedConsent = configDetails.app.vendors.custom.find(vendor =>
        vendor.id.includes(vendorId)).purposeIds;

      const requiredPurpose = context.consentToken.consents.filter(consent =>
        changedConsent.some(purpose => purpose === consent.purpose));

      const deniedPurpose = requiredPurpose.filter(consent => consent.vendors.some(
        vendor => typeof (vendor.id) === 'string' && vendor.id.includes(vendorId) && !vendor.status,
      ),
      );
      return deniedPurpose;
    }
  }
}
