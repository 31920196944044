import { AuthenticationActions, AuthenticationActionTypes } from './authentication.actions';

export interface AuthenticationState {
  status: AuthStatus;
}

export enum AuthStatus {
  IN = 'IN',
  OUT = 'OUT',
}

export const initialState: AuthenticationState = {
  status: AuthStatus.OUT,
};

export function reducer(state = initialState, action: AuthenticationActions): AuthenticationState {
  let status: AuthStatus;
  switch (action.type) {

    case AuthenticationActionTypes.AuthenticationAuthenticate:
      status = AuthStatus.IN;
      return { ...state, status };

    case AuthenticationActionTypes.AuthenticationDisconnect:
      status = AuthStatus.OUT;
      return { ...state, status };

    default:
      return state;
  }
}
