import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TransferHttpOptions } from './transfer-http-options';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../error-handler/error.service';
import { HttpConst } from './transfer-http.const';
import { StorageService } from '../storage/storage.service';
import { StorageConst } from '../storage/storage.const';

@Injectable()
export class TransferHttpService {

  constructor(
    private httpClient: HttpClient,
    private errorHandlerService: ErrorService,
    private storageService: StorageService,
  ) {
  }

  public get<T>(url: string, isAnonymousCall: Boolean = false): Observable<T> {
    return this.sendRequest(() => {
      return this.httpClient.get<T>(url, this.getOptions(isAnonymousCall));
    });
  }

  public getString(url: string): Observable<string> {
    let headers = new HttpHeaders();
    const countryCode = this.storageService.get(StorageConst.selectedCountry);
    const userRole = this.storageService.get(StorageConst.selectedRoleLabel);
    if (countryCode && countryCode !== 'false') {
      headers = headers.append(HttpConst.headers.CountryCode, countryCode);
    }
    if (userRole && userRole !== 'false') {
      headers = headers.append(HttpConst.headers.UserRole, userRole);
    }
    return this.sendRequest(() => {
      return this.httpClient.get(url, {
        headers,
        responseType: 'text',
      });
    });
  }

  public getBlob(url: string): Observable<Blob> {
    let headers = new HttpHeaders();
    const countryCode = this.storageService.get(StorageConst.selectedCountry);
    const userRole = this.storageService.get(StorageConst.selectedRoleLabel);
    if (countryCode && countryCode !== 'false') {
      headers = headers.append(HttpConst.headers.CountryCode, countryCode);
    }
    if (userRole && userRole !== 'false') {
      headers = headers.append(HttpConst.headers.UserRole, userRole);
    }
    return this.sendRequest(() => {
      return this.httpClient.get(url, {
        headers,
        responseType: 'blob',
      });
    });
  }

  public post<T>(url: string, body: any, isAnonymousCall: Boolean = false): Observable<T> {
    return this.sendRequest(() => {
      return this.httpClient.post<T>(url, body, this.getOptions(isAnonymousCall));
    });
  }

  public postFile<T>(url: string, body: any): Observable<T> {
    return this.sendRequest(() => {
      return this.httpClient.post<T>(url, body, this.getOptions(false, false));
    });
  }

  public putFile<T>(url: string, body: any): Observable<T> {
    return this.sendRequest(() => {
      return this.httpClient.put<T>(url, body, this.getOptions(false, false));
    });
  }

  public put<T>(url: string, body: any, isAnonymousCall: Boolean = false): Observable<T> {
    return this.sendRequest(() => {
      return this.httpClient.put<T>(url, body, this.getOptions(isAnonymousCall));
    });
  }

  public delete<T>(url: string, isAnonymousCall: Boolean = false): Observable<T> {
    return this.sendRequest(() => {
      return this.httpClient.delete<T>(url, this.getOptions(isAnonymousCall));
    });
  }

  public patch<T>(url: string, body: any, isAnonymousCall: Boolean = false): Observable<T> {
    return this.sendRequest(() => {
      return this.httpClient.patch<T>(url, body, this.getOptions(isAnonymousCall));
    });
  }

  public head<T>(url: string, isAnonymousCall: Boolean = false): Observable<T> {
    return this.sendRequest(() => {
      return this.httpClient.head<T>(url, this.getOptions(isAnonymousCall));
    });
  }

  public options<T>(url: string, isAnonymousCall: Boolean = false): Observable<T> {
    return this.sendRequest<T>(() => {
      return this.httpClient.options<T>(url, this.getOptions(isAnonymousCall));
    });
  }

  private sendRequest<T>(callback: () => Observable<T>): Observable<T> {
    return callback().pipe(
      catchError((error) => {
        if (error.status === 500) {
          this.errorHandlerService.handleError();
        }
        return throwError(error);
      },
      ));
  }

  private getOptions(isAnonymousCall: Boolean, useJsonContentType = true): TransferHttpOptions {
    let options: TransferHttpOptions;
    let headers = new HttpHeaders();

    if (useJsonContentType) {
      headers = headers.append(HttpConst.headers.ContentType, 'application/json');
    }

    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if (isIEOrEdge) {
      headers = headers.append('Cache-Control', 'no-cache');
      headers = headers.append('Pragma', 'no-cache');
      headers = headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    }

    if (isAnonymousCall) {
      options = {
        headers,
        withCredentials: false,
      };
    } else {
      const countryCode = this.storageService.get(StorageConst.selectedCountry);
      const userRole = this.storageService.get(StorageConst.selectedRoleLabel);
      if (countryCode && countryCode !== 'false') {
        headers = headers.append(HttpConst.headers.CountryCode, countryCode);
      }
      if (userRole && userRole !== 'false') {
        headers = headers.append(HttpConst.headers.UserRole, userRole);
      }

      options = { headers };
    }

    return options;
  }
}
