import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '@shared/core/authentication/authentication.service';
import { WindowRef } from '@shared/core/helpers/window.ref';
import { StorageService } from '@shared/core/storage/storage.service';
import { GtmService } from '@shared/core/gtm/gtm.service';
import { GTM_EVENTS } from '@shared/core/gtm/gtm-events.const';
import { GtmUserRoleProperty } from '@shared/core/gtm/gtm-properties.model';
import { APP_ROUTES } from '@app/app.routes';
import { Router } from '@angular/router';

@Component({
  selector: 'mgr-logout-popin',
  templateUrl: './logout-popin.component.html',
  styleUrls: ['./logout-popin.component.scss'],
})
export class LogoutPopinComponent {

  constructor(private authService: AuthenticationService,
              private windowRef: WindowRef,
              private gtmService: GtmService,
              private storageService: StorageService,
              private router: Router,
              ) { }

  public logout() {
    this.windowRef.nativeWindow.addEventListener(
      'message',
      this.adfsEventCallBack,
      false);
    const logoutIframe = document.getElementById('logout_ifm') as HTMLIFrameElement;
    logoutIframe.src = environment.adfs.logoutUrl;
  }

  private adfsEventCallBack = (e: any) => {
    const { data: eventData } = e;
    if (eventData.waitingForLogin) {
      this.windowRef.nativeWindow.removeEventListener('message', this.adfsEventCallBack);
      this.authService.clearAuthentication();
      this.gtmService.sendEvent(GTM_EVENTS.logout, GtmUserRoleProperty.role, GtmUserRoleProperty.countryCode, true);
      this.storageService.clearStorage();
      this.router.navigate([`/${APP_ROUTES.START}/${APP_ROUTES.LOGIN}`]);
    }
  }

}
