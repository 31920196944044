<mgr-popin class="logout-popin" codename="logoutPopin" size="sm">
    <div class="popin-header text-center no-border">
      <h2 class="popin-title">{{'global-logout_popin-title'| translate}}</h2>
    </div>
    <div class="popin-content text-center">
      <p>{{'global-logout_popin-text'| translate}}</p>
    </div>
  <div class="popin-footer text-center">
    <button mat-button type="button" mgrClosePopin="logoutPopin">{{'global-logout_popin-cancel'| translate}}</button>
    <button mat-raised-button type="button" mgrClosePopin="logoutPopin" (click)="logout()" color="primary">{{'global-logout_popin-confirm'| translate}}</button>
  </div>
</mgr-popin>
<iframe id="logout_ifm" width="1" height="1" src=""></iframe>
