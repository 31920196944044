import { Injectable } from '@angular/core';
import { NotificationsService } from '../services/notifications.service';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, map, tap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { NotificationsActionTypes } from './notifications.actions';
import * as actions from '../../../notifications/shared/state/notifications.actions';
import { PanelService } from '@shared/ui/panel/shared/panel.service';
import { AldSnackbarService } from '@ald/ui';
import { NotificationsConst } from '../notifications.const';
import { APP_ROUTES } from '@app/app.routes';
import { Router } from '@angular/router';
import { GtmService } from '@shared/core/gtm/gtm.service';
import { GTM_EVENTS } from '@shared/core/gtm/gtm-events.const';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NotificationsEffects {
  constructor(
    private notificationService: NotificationsService,
    private actions$: Actions,
    private panelService: PanelService,
    private gtmService: GtmService,
    private snackBarService: AldSnackbarService,
    private readonly translateService: TranslateService,
    private router: Router,
  ) { }

  @Effect()
  getNotifications$: Observable<Action> = this.actions$.pipe(
    ofType(NotificationsActionTypes.GetNotifications),
    mergeMap(action =>
      this.notificationService.getNotifications().pipe(
        map(data => ({ type: NotificationsActionTypes.GetNotificationsSuccess, payload: data || [] })),
        catchError(() => of({ type: NotificationsActionTypes.GetNotificationsFail })),
      ),
    ),
  );

  @Effect()
  getManagerAlerts$: Observable<Action> = this.actions$.pipe(
    ofType(NotificationsActionTypes.GetManagerAlerts),
    mergeMap(action =>
      this.notificationService.getAlerts().pipe(
        map(data => ({ type: NotificationsActionTypes.GetManagerAlertsSuccess, payload: data || [] })),
        catchError(() => of({ type: NotificationsActionTypes.GetManagerAlertsFail })),
      ),
    ),
  );

  @Effect()
  createNotification$: Observable<Action> = this.actions$.pipe(
    ofType(NotificationsActionTypes.CreateNotification),
    mergeMap((action: actions.CreateNotification) => {
      this.snackBarService.loading(this.translateService.instant('notifications-create_panel-snackbar_loading'));
      return this.notificationService.createNotification('notification', action.payload).pipe(
        tap(() => {
          this.snackBarService.success(this.translateService.instant('notifications-create_panel-snackbar_success'));
          this.panelService.close(NotificationsConst.windowCodename.createNotificationPanel);
          this.router.navigate([`/${APP_ROUTES.NOTIFICATIONS}`]);
        }),
        switchMap(() => {
          this.gtmService.sendEvent(GTM_EVENTS.createNotification, '1');
          return [new actions.CreateNotificationSuccess(), new actions.GetNotifications()];
        }),
        catchError(() => {
          this.snackBarService.error(this.translateService.instant('notifications-create_panel-snackbar_fail'));
          return of(new actions.CreateNotificationFail());
        }),
      );
    }),
  );
}
