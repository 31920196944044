import { Action } from '@ngrx/store';
import { Document } from '../models/document.model';

export enum DocumentsActionTypes {
  GetDocuments = '[Documents] Get',
  GetSharedDocuments = '[SharedDocuments] Get',
  GetDocumentsSuccess = '[Documents] Get Success',
  GetSharedDocumentsSuccess = '[SharedDocuments] Get Success',
  GetDocumentsFail = '[Documents] Get Fail',
  GetSharedDocumentsFail = '[SharedDocuments] Get Fail',
  DeleteDocuments = '[Documents] Delete From List',
  UploadDocument = '[Documents] Upload',
  UploadDocumentSuccess = '[Documents] Upload Success',
  UploadDocumentFail = '[Documents] Upload Fail',
  SelectDocumentToEdit = '[Documents] Select Document',
  EditDocument = '[Documents] Edit',
  EditDocumentSuccess = '[Documents] Edit Success',
  EditDocumentFail = '[Documents] Edit Fail',
  SelectDocumentsToNotify = '[Documents] Selected Document list',
}

export class GetDocuments implements Action {
  readonly type = DocumentsActionTypes.GetDocuments;
}

export class GetSharedDocuments implements Action {
  readonly type = DocumentsActionTypes.GetSharedDocuments;
}

export class GetDocumentsSuccess implements Action {
  readonly type = DocumentsActionTypes.GetDocumentsSuccess;

  constructor(public payload: Document[]) { }
}

export class GetSharedDocumentsSuccess implements Action {
  readonly type = DocumentsActionTypes.GetSharedDocumentsSuccess;

  constructor(public payload: Document[]) { }
}

export class GetDocumentsFail implements Action {
  readonly type = DocumentsActionTypes.GetDocumentsFail;
}

export class GetSharedDocumentsFail implements Action {
  readonly type = DocumentsActionTypes.GetSharedDocumentsFail;
}

export class DeleteDocuments implements Action {
  readonly type = DocumentsActionTypes.DeleteDocuments;

  constructor(public payload: Document[]) { }
}

export class UploadDocument implements Action {
  readonly type = DocumentsActionTypes.UploadDocument;

  constructor(public user: any, public payload: any) { }
}

export class UploadDocumentSuccess implements Action {
  readonly type = DocumentsActionTypes.UploadDocumentSuccess;

  constructor() { }
}

export class UploadDocumentFail implements Action {
  readonly type = DocumentsActionTypes.UploadDocumentFail;
}

export class SelectDocumentToEdit implements Action {
  readonly type = DocumentsActionTypes.SelectDocumentToEdit;

  constructor(public payload: string) { }
}

export class EditDocument implements Action {
  readonly type = DocumentsActionTypes.EditDocument;

  constructor(public user: any, public payload: any) { }
}

export class EditDocumentSuccess implements Action {
  readonly type = DocumentsActionTypes.EditDocumentSuccess;

  constructor(public payload: any) { }
}

export class EditDocumentFail implements Action {
  readonly type = DocumentsActionTypes.EditDocumentFail;
}

export class SelectDocumentsToNotify implements Action {
  readonly type = DocumentsActionTypes.SelectDocumentsToNotify;

  constructor(public payload: string[]) { }
}

export type DocumentsActions = GetDocuments
| GetSharedDocuments
| GetDocumentsSuccess
| GetSharedDocumentsSuccess
| GetDocumentsFail
| GetSharedDocumentsFail
| UploadDocument
| UploadDocumentSuccess
| UploadDocumentFail
| SelectDocumentToEdit
| EditDocument
| EditDocumentSuccess
| EditDocumentFail
| DeleteDocuments
| SelectDocumentsToNotify;
