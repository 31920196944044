import { environment } from '../../../environments/environment';
import { DocumentUploadDateFilter } from './filters/document-upload-date.filter';
import { DocumentRoleFilter } from './filters/document-role.filter';
import { APP_ROUTES } from '@app/app.routes';
import { SharedDocumentSharedDateFilter } from './filters/shared-document-shared-date.filter';

export const DocumentsConst = {
  api: {
    baseUrl: `${environment.apigatewayUrl}/apigateway/`,
    modules: {
      documents: {
        url: 'documentmanagement/api/',
        endpoints: {
          GetDocuments: 'Documents/',
          CreateDocument: 'Documents/',
          DeleteDocuments: 'Documents/Delete/',
          DownloadDocuments: 'Documents/',
          GetCategories: 'Category/',
        },
      },
    },
  },

  windowCodename: {
    uploadDocumentPanel: 'uploadDocumentPanel',
    editDocumentPanel: 'editDocumentPanel',
    deleteDocumentPopin: 'deleteDocumentPopin',
    documentSendNotificationPanel: 'genericNotificationPanel',
    switchWorkingScopePanel: 'switchWorkingScopePanel',
  },

  filters: {
    documentList: [
      DocumentUploadDateFilter,
      DocumentRoleFilter,
    ],
    sharedDocumentList: [
      SharedDocumentSharedDateFilter,
    ],
  },

  breadcrumbs: {
    documentsList:  {
      path: `/${APP_ROUTES.DOCUMENTS}/${APP_ROUTES.DOCUMENT_LIST}`,
      label: 'global-breadcrumbs-documents_label',
    },
    sharedDocumentsList:  {
      path: `/${APP_ROUTES.DOCUMENTS}/${APP_ROUTES.DOCUMENT_LIST_SHARED_BY_DRIVER}`,
      label: 'global-breadcrumbs-shared_documents_label',
    },
    documentUpload:  {
      path: '',
      label: 'global-breadcrumbs-upload_document_label',
    },
    documentEdit:  {
      path: '',
      label: 'global-breadcrumbs-edit_document_label',
    },
  },

  displayedColumns: {
    documentList: [
      'select',
      'title',
      'creationDate',
      'category.name',
      'companies',
      'licensePlate',
      'roles',
      'users',
    ],
    sharedDocumentList: [
      'select',
      'title',
      'createdBy',
      'creationDate',
      'companies',
      'comment',
    ],
  },
  maximumFileSize: 15728640,
};

export enum FileUploadStatus {
  Success = 'success',
  Fail = 'fail',
}
