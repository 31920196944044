import * as tslib_1 from "tslib";
import { PopinActionTypes } from './popin.actions';
export var initialState = {
    showPopins: [],
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case PopinActionTypes.OpenPopin:
            return tslib_1.__assign({}, state, { showPopins: state.showPopins.concat([action.payload]) });
        case PopinActionTypes.ClosePopin:
            return tslib_1.__assign({}, state, { showPopins: state.showPopins.filter(function (popin) {
                    return popin !== action.payload;
                }) });
        default:
            return state;
    }
}
