import { Filter } from '@shared/ui/table/table-filters/shared/filter.interface';
import { DashboardConst } from '@dashboard/shared/dashboard.const';

export function contractMileageDeviationFilterFunction(option: Filter, row: any): boolean | null {
  return option.value.some(opt => row.mileageDeviationCategory && row.mileageDeviationCategory.includes(opt));
}
export const ContractMileageDeviationFilter = {
  name: 'contractMileageDeviation',
  label: 'global-filters-contract_mileage_deviation',
  options: [
    {
      label: 'global-filters-mileage_deviation_over50',
      value: DashboardConst.kpiCategories.mileageDeviationCategory.over50,
    },
    {
      label: 'global-filters-mileage_deviation_over30',
      value: DashboardConst.kpiCategories.mileageDeviationCategory.over30,
    },
    {
      label: 'global-filters-mileage_deviation_over10',
      value: DashboardConst.kpiCategories.mileageDeviationCategory.over10,
    },
    {
      label: 'global-filters-mileage_deviation_under50',
      value: DashboardConst.kpiCategories.mileageDeviationCategory.under50,
    },
    {
      label: 'global-filters-mileage_deviation_under30',
      value: DashboardConst.kpiCategories.mileageDeviationCategory.under30,
    },
    {
      label: 'global-filters-mileage_deviation_under10',
      value: DashboardConst.kpiCategories.mileageDeviationCategory.under10,
    },
  ],
  predicateFn: contractMileageDeviationFilterFunction,
};
