import { Component, Input } from '@angular/core';
import { PanelService } from '@app/shared/ui/panel/shared/panel.service';

@Component({
  selector: 'mgr-password-expiration-popin',
  templateUrl: './password-expiration-popin.component.html',
})
export class PasswordExpirationPopinComponent {

  @Input()
  public daysBeforePasswordExpires: string;

  constructor(
    private panelService: PanelService,
  ) { }

  public goToChangePassword(): void {
    this.panelService.open('changeUserPassword');
  }
}
