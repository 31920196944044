import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TransferHttpService } from '@shared/core/transfer-http';
import { DocumentsConst } from '../documents.const';
import { Document } from '../models/document.model';
import { DocumentManagementResponse } from '../models/document-management-response.model';
import { DocumentCategory } from '../models/document-category.model';
import { catchError, map } from 'rxjs/operators';
import { AldSnackbarService } from '@ald/ui';
import { GtmService } from '@app/shared/core/gtm/gtm.service';
import { GTM_EVENTS } from '@app/shared/core/gtm/gtm-events.const';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DocumentsService {

  constructor(
    private httpClient: TransferHttpService,
    private gtmService: GtmService,
    private snackBarService: AldSnackbarService,
    private readonly translateService: TranslateService,
    ) {
  }

  public getDocuments(): Observable<Document[]> {
    const documentsModule = DocumentsConst.api.modules.documents;
    const url = DocumentsConst.api.baseUrl + documentsModule.url + documentsModule.endpoints.GetDocuments;
    return this.httpClient.get(url);
  }

  public deleteDocuments(documents: Document[]): Observable<DocumentManagementResponse[]> {
    if (!documents || documents.length === 0) {
      return of([]);
    }
    const documentsModule = DocumentsConst.api.modules.documents;
    const url = DocumentsConst.api.baseUrl + documentsModule.url + documentsModule.endpoints.DeleteDocuments;
    const body: string[] = documents.map((doc) => {
      return doc.id;
    });
    return this.httpClient.post(url, body);
  }

  public getDownloadUrl(documentId: string) {
    const documentsModule = DocumentsConst.api.modules.documents;
    const url = `${DocumentsConst.api.baseUrl + documentsModule.url + documentsModule.endpoints.DownloadDocuments}/${documentId}`;
    return this.httpClient.get(url).pipe(
      map((response_url: string) => {
        if (response_url) {
          this.gtmService.sendEvent(GTM_EVENTS.downloadDocument, '1');
          this.snackBarService.success(this.translateService.instant('documents-document_list_page-snackbar_download_started'));
          window.open(response_url, '_self');
        } else {
          this.snackBarService.info(this.translateService.instant('global-user_notifications_panel-unavailable_download_file'));
        }
        return response_url;
      }),
      catchError((error) => {
        this.snackBarService.error(this.translateService.instant('global-generic_terms-snackbar_generic_error'));
        return of(error);
      },
      ),
    );
  }

  public getAvailableCategories(): Observable<DocumentCategory[]> {
    const documentsModule = DocumentsConst.api.modules.documents;
    const url = DocumentsConst.api.baseUrl + documentsModule.url + documentsModule.endpoints.GetCategories;
    return this.httpClient.get(url);
  }

  public uploadDocument(documentFormData: FormData) {
    const documentsModule = DocumentsConst.api.modules.documents;
    const url = DocumentsConst.api.baseUrl + documentsModule.url + documentsModule.endpoints.CreateDocument;
    return this.httpClient.postFile(url, documentFormData);
  }

  public editDocument(document: Document) {
    const documentsModule = DocumentsConst.api.modules.documents;
    const url = DocumentsConst.api.baseUrl + documentsModule.url + documentsModule.endpoints.CreateDocument;
    return this.httpClient.put(url, document);
  }
}
