import { AccessConst } from '@shared/core/access/access.const';
import { StorageConst } from '@shared/core/storage/storage.const';
import { StorageAuthService } from '@shared/core/storage/storage.auth.service';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { TransferHttpService } from '@shared/core/transfer-http';
import { UserConst } from './user.const';
import { StorageService } from '@shared/core/storage/storage.service';
import { ChangePasswordRequest } from '@shell/top-navigation/edit-my-profile/change-password/change-password-request';
import { UserRole } from '@shared/models/user-role.model';
import { UserProfile } from '@shared/models/user-profile.model';
import * as jwt_decode from 'jwt-decode';
import { map, catchError } from 'rxjs/operators';
import { ValidatePasswordRequest } from './model/validate-password-request';
import { AuthenticationService } from '../../../../app/shared/core/authentication/authentication.service';

@Injectable()
export class UserService {
  private keyPrefix = 'http://www.aldautomotive.fr/claims/';
  public isCheckingPasswordUsername: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private httpClient: TransferHttpService,
    private storageAuthService: StorageAuthService,
    private storageService: StorageService,
    private authService: AuthenticationService,
    ) {
  }

  public getUser(): Observable<UserProfile> {
    const usersModule = UserConst.api.modules.users;
    const url = UserConst.api.baseUrl + usersModule.url + usersModule.endpoints.getUser;
    return this.httpClient.get(url);
  }

  public hasRole(userRoles: string[], roleToCheck: string) {
    return userRoles
      .map(role => role.toLowerCase().trim())
      .includes(roleToCheck.toLowerCase().trim());
  }

  public getUserCountryFromJwt() {
    return this.getUserInfoFromJwt('country');
  }

  public getUserDefaultRole(user: UserProfile): UserRole {
    const currentCountry = this.storageService.get(StorageConst.selectedCountry);
    const currentCountryDefaultRole = user.userRoles.find(
      userRole =>
      (currentCountry && userRole.countryCode.toLowerCase() === currentCountry.toLowerCase()) &&
        (AccessConst.acceptedRoles.map(acceptedRole => acceptedRole.toString().toLowerCase()).indexOf(userRole.role.toLowerCase()) > 0),
    );

    return user.userRoles.find(r => r.id === this.storageService.get(StorageConst.selectedRoleId)) ||
      currentCountryDefaultRole ||
      (user.userRoles.length > 0 ? user.userRoles[0] : null);
  }

  private getUserInfoFromJwt(key: string, prefix = true): any {
    let userAccessToken = null;
    if (this.storageAuthService.auth) {
      userAccessToken = jwt_decode(this.storageAuthService.auth[StorageConst.auth.keys.accessToken]);
    }
    return userAccessToken[(prefix ? this.keyPrefix : '') + key];
  }

  public changePassword(request: ChangePasswordRequest) {
    const usersModule = UserConst.api.modules.users;
    const url = UserConst.api.baseUrl + usersModule.url + usersModule.endpoints.changePassword;
    return this.httpClient.post(url, request);
  }

  public updateLastLoginDate(): Observable<any> {
    const usersModule = UserConst.api.modules.users;
    const url = UserConst.api.baseUrl + usersModule.url + usersModule.endpoints.updateLastLoginDate;
    return this.httpClient.put(url, null);
  }

  public isPasswordExpired(email: string): Observable<boolean> {
    const usersModule = UserConst.api.modules.users;
    const url = new URL(UserConst.api.baseUrl + usersModule.url + usersModule.endpoints.isPasswordExpired);
    url.searchParams.append('email', email);
    return this.httpClient.getString(url.href).pipe(
      map(status => status ? JSON.parse(status) : false),
      catchError(() => of(false)),
    );
  }

  public isAuthenticateUser(email: string, password: string): Observable<any> {
    const usersModule = UserConst.api.modules.users;
    const url = UserConst.api.baseUrl + usersModule.url + usersModule.endpoints.authenticateUser;
    const request = {
      email,
      password,
    };
    return this.httpClient.post(url, request);
  }

  public validatePassword(password: string, token: string): Observable<boolean> {
    const usersModule = UserConst.api.modules.users;
    const url = UserConst.api.baseUrl + usersModule.url + usersModule.endpoints.validatePassword;
    const request: ValidatePasswordRequest = {
      password,
      resetPasswordToken: token,
    };
    this.authService.clearAuthentication();
    return this.httpClient.post(url, request, true).pipe(
      map(status => status ? JSON.parse(status.toString()) : false),
    );
  }

  public validatePasswordAuthenticated(password: string): Observable<boolean> {
    const usersModule = UserConst.api.modules.users;
    const url = UserConst.api.baseUrl + usersModule.url + usersModule.endpoints.validatePasswordAuthenticated;
    return this.httpClient.post(url, `"${password}"`);
  }

  public getExternalAppToken(): Observable<string> {
    const userModule = UserConst.api.modules.users;
    const url = `${UserConst.api.baseUrl + userModule.url + userModule.endpoints.GetExternalJwtToken}`;
    return this.httpClient.getString(url);
  }
}
