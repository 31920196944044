import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

export function validateLicensePlate(control: AbstractControl): ValidationErrors | null {
  return /^[a-zA-Z0-9-]+$/i.test(control.value) ? null : { licensePlate: true };
}

@Directive({
  selector: '[licensePlate]',
  providers: [{ provide: NG_VALIDATORS, useExisting: LicenseValidatorDirective, multi: true }],
})
export class LicenseValidatorDirective implements Validator {
  validate: ValidatorFn = validateLicensePlate;
}
