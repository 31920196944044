import * as tslib_1 from "tslib";
import { NotificationsActionTypes } from './notifications.actions';
export var initialState = {
    loading: false,
    notifications: [],
    selectedNotificationId: null,
    managerAlerts: [],
    managerAlertsLoading: false,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case NotificationsActionTypes.GetNotifications:
            return tslib_1.__assign({}, state, { loading: true });
        case NotificationsActionTypes.GetNotificationsSuccess:
            return tslib_1.__assign({}, state, { loading: false, notifications: action.payload });
        case NotificationsActionTypes.GetNotificationsFail:
            return tslib_1.__assign({}, state, { loading: false, notifications: [] });
        case NotificationsActionTypes.DeleteNotifications:
            return tslib_1.__assign({}, state, { notifications: state.notifications.filter(function (notifcation) {
                    return !action.payload.includes(notifcation);
                }) });
        case NotificationsActionTypes.CreateNotification:
            return tslib_1.__assign({}, state, { loading: true });
        case NotificationsActionTypes.CreateNotificationSuccess:
            return tslib_1.__assign({}, state, { loading: false });
        case NotificationsActionTypes.CreateNotificationFail:
            return tslib_1.__assign({}, state, { loading: false });
        case NotificationsActionTypes.SelectNotificationToEdit:
            return tslib_1.__assign({}, state, { loading: false, selectedNotificationId: action.payload });
        case NotificationsActionTypes.UpdateNotification:
            var updatedNotification_1 = action.payload;
            var indexToUpdate = state.notifications.findIndex(function (notification) { return notification.id === updatedNotification_1.id; });
            state.notifications[indexToUpdate] = updatedNotification_1;
            return tslib_1.__assign({}, state, { notifications: state.notifications.slice() });
        case NotificationsActionTypes.RemoveNotificationFromSelection:
            return tslib_1.__assign({}, state, { selectedNotificationId: null });
        case NotificationsActionTypes.GetManagerAlerts:
            return tslib_1.__assign({}, state, { managerAlertsLoading: true });
        case NotificationsActionTypes.GetManagerAlertsSuccess:
            return tslib_1.__assign({}, state, { managerAlertsLoading: false, managerAlerts: action.payload });
        case NotificationsActionTypes.GetManagerAlertsFail:
            return tslib_1.__assign({}, state, { managerAlertsLoading: false, managerAlerts: [] });
        default:
            return state;
    }
}
