/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./password-validation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./password-validation.component";
import * as i9 from "../../../entity/user/user.service";
var styles_PasswordValidationComponent = [i0.styles];
var RenderType_PasswordValidationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PasswordValidationComponent, data: {} });
export { RenderType_PasswordValidationComponent as RenderType_PasswordValidationComponent };
function View_PasswordValidationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "password-validation-spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(2, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"], strokeWidth: [1, "strokeWidth"] }, null)], function (_ck, _v) { var currVal_3 = 15; var currVal_4 = 2; _ck(_v, 2, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_PasswordValidationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "password-validation-item"]], [[2, "pending", null], [2, "invalid", null], [2, "valid", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 1), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.passwordControl.value; var currVal_1 = (_co.passwordControl.getError(_v.context.$implicit) && _co.passwordControl.value); var currVal_2 = (!_co.passwordControl.getError(_v.context.$implicit) && _co.passwordControl.value); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), ("global-password_validation-rule_" + _v.context.$implicit))))); _ck(_v, 1, 0, currVal_3); }); }
export function View_PasswordValidationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.LowerCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["class", "password-validation-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 7, "ul", [["class", "password-validation-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "li", [["class", "password-validation-item"]], [[2, "pending", null], [2, "valid", null], [2, "invalid", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PasswordValidationComponent_1)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PasswordValidationComponent_2)), i1.ɵdid(11, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.isCheckingPasswordUsername; _ck(_v, 7, 0, currVal_4); var currVal_6 = _co.PASSWORD_RULES_REGEX; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("global-password_validation-rule_description")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isPasswordUsernamePending(); var currVal_2 = _co.isPasswordUsernameValid(); var currVal_3 = _co.isPasswordUsernameInvalid(); _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); var currVal_5 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("global-password_validation-rule_notcontainusername")); _ck(_v, 8, 0, currVal_5); }); }
export function View_PasswordValidationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mgr-password-validation", [], null, null, null, View_PasswordValidationComponent_0, RenderType_PasswordValidationComponent)), i1.ɵdid(1, 49152, null, 0, i8.PasswordValidationComponent, [i9.UserService], null, null)], null, null); }
var PasswordValidationComponentNgFactory = i1.ɵccf("mgr-password-validation", i8.PasswordValidationComponent, View_PasswordValidationComponent_Host_0, { passwordControl: "passwordControl" }, {}, []);
export { PasswordValidationComponentNgFactory as PasswordValidationComponentNgFactory };
