<div class="panel" id="panel-{{codename}}">
  <!--panel overlay to the first one only -->
  <div class="panel-overlay" *ngIf="showPanel" [class._transparent]="!isFirstPanel" (click)="closePanel()" [@Fade]></div>
  <!--panel content-->
  <div class="panel-container" *ngIf="showPanel" [@SlideLeft]>
    <div class="panel-header" (click)="notifyClickBackLink($event)">
    <i class="panel-header-back icon-arrow-left"></i>
      <p class="panel-header-title" *ngIf="title">{{ title | translate}}</p>
    </div>
    <div class="panel-content">
      <ng-container *ngTemplateOutlet="contentRef"></ng-container>
    </div>
  </div>
</div>

