import * as tslib_1 from "tslib";
import { PanelActionTypes } from './panel.actions';
export var initialState = {
    showPanels: [],
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case PanelActionTypes.OpenPanel:
            return tslib_1.__assign({}, state, { showPanels: state.showPanels.concat([action.payload]) });
        case PanelActionTypes.ClosePanel:
            return tslib_1.__assign({}, state, { showPanels: state.showPanels.filter(function (panel) {
                    return panel !== action.payload;
                }) });
        case PanelActionTypes.ClearPanels:
            return tslib_1.__assign({}, state, { showPanels: [] });
        default:
            return state;
    }
}
