import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserState } from '../../../entity/user/state/user.reducer';
import { select, Store } from '@ngrx/store';
import { getUserSelectedRole } from '../../../entity/user/state';
import { filter, takeWhile } from 'rxjs/operators';
import { UserRoles } from '@app/shared/entity/user/user.const';

@Directive({
  selector: '[restrictTo]',
})
export class RestrictToDirective implements OnInit, OnDestroy {

  @Input() restrictTo: UserRoles[];

  private directiveActive = true;
  private isVisible = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private userStore: Store<UserState>,
  ) {}

  ngOnInit(): void {
    this.userStore.pipe(
      select(getUserSelectedRole),
      filter(role => !!role),
      takeWhile(() => this.directiveActive),
    ).subscribe((selectedUserRole) => {

      if (!selectedUserRole) {
        this.viewContainerRef.clear();
        return;
      }

      if (this.restrictTo.map(r => r.toLowerCase()).includes(selectedUserRole.role.toLowerCase())) {
        if (!this.isVisible) {
          this.isVisible = true;
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      } else {
        this.isVisible = false;
        this.viewContainerRef.clear();
      }
    });
  }

  ngOnDestroy(): void {
    this.directiveActive = false;
  }
}
