import { passwordUserNameValidator } from '@app/shared/ui/forms/validators/password/password-username.validator.ts';
import { UserService } from '@app/shared/entity/user/user.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ChangePasswordRequest } from '../change-password-request';
import { MgrValidators } from '@shared/ui/forms/validators/custom.validator';
import { sameAsValidator } from '@app/shared/ui/forms/validators/same-as.validator';

@Component({
  selector: 'mgr-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
})
export class ChangePasswordFormComponent implements OnInit {
  @Input() isLoading: boolean;
  @Output() submitFormSuccess: EventEmitter<ChangePasswordRequest> = new EventEmitter<ChangePasswordRequest>();

  public form: FormGroup;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      currentPassword: new FormControl('', [MgrValidators.required]),
      password: new FormControl('', [MgrValidators.required, MgrValidators.securePassword], [passwordUserNameValidator(this.userService)]),
      confirmPassword: new FormControl(''),
    });
    this.form.get('confirmPassword').setValidators([MgrValidators.required, sameAsValidator(this.form.get('password'))]);
  }

  public resetConfirmationField(): void {
    this.form.controls.confirmPassword.setValue('');
    this.form.controls.confirmPassword.updateValueAndValidity();
  }

  public submitForm(): void {
    if (this.form.valid) {
      const request: ChangePasswordRequest = {
        currentPassword: this.form.get('currentPassword').value,
        newPassword: this.form.get('password').value,
      };
      this.submitFormSuccess.emit(request);
    }
  }
}
