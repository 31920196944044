import { UserRoles } from '../../entity/user/user.const';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { WindowRef } from '../helpers/window.ref';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { UserState } from '../../entity/user/state/user.reducer';
import { UserActionTypes } from '../../entity/user/state/user.actions';
import { Observable, of } from 'rxjs';
import { ConfigurationService } from '../configuration/configuration.service';
import { ConfigurationState } from '../configuration/configuration.reducer';
import { ConfigurationActionTypes } from '../configuration/configuration.actions';
import { FeatureState } from '../feature/feature.reducer';
import { FeatureActionTypes } from '../feature/feature.actions';
import { UserService } from '@app/shared/entity/user/user.service';
import { AccessConst } from './access.const';
import { getUserFeatureState } from '../../entity/user/state';
import { StorageAuthService } from '../storage/storage.auth.service';
import { AuthenticationState, AuthStatus } from '../authentication/authentication.reducer';
import { getAuthStatus } from '../authentication';
import { StorageService } from '../storage/storage.service';

@Injectable()
export class AccessService implements CanActivate {

  constructor(
    private windowRef: WindowRef,
    private userStore: Store<UserState>,
    private userService: UserService,
    private authStorage: StorageAuthService,
    private configurationStore: Store<ConfigurationState>,
    private configurationService: ConfigurationService,
    private authenticationStore: Store<AuthenticationState>,
    private featureStore: Store<FeatureState>,
    private storageService: StorageService) {
    this.authenticationStore.pipe(
      select(getAuthStatus),
      filter(status => status === AuthStatus.IN),
    ).subscribe(() => {
      this.userStore.dispatch({ type: UserActionTypes.GetUser });
      this.configurationStore.dispatch({ type: ConfigurationActionTypes.GetConfiguration });
      this.featureStore.dispatch({ type: FeatureActionTypes.GetFeature });
    });
  }

  canActivate(): Observable<boolean> {
    return this.userStore.pipe(select(getUserFeatureState), filter(state => !state.loading && state.isLoaded), map((state) => {
      const user = state.user;
      if (user) {
        return this.hasAccess(user.userRoles.map(userRole => userRole.role));
      }
      return false;
    }));
  }

  private hasAccess(userRoles): boolean {
    if (this.hasAccessToManagerSite(userRoles)) {
      return true;
    }

    if (this.userService.hasRole(userRoles, UserRoles.Driver) || this.userService.hasRole(userRoles, UserRoles.Prospect)) {
      this.redirectToDriver();
      return false;
    }

    this.redirectToSharepoint();
    return false;
  }

  public hasAccessToManagerSite(userRoles: string[]) {
    return AccessConst.acceptedRoles.some((role) => {
      return this.userService.hasRole(userRoles, role);
    });
  }

  private redirectToDriver() {
    this.configurationService.getItemFromConfiguration('DriverWebsiteUrl')
      .subscribe((item) => {
        this.storageService.clearStorage();
        this.authStorage.removeAuth();
        this.windowRef.nativeWindow.location = item.value;
      });
  }

  private redirectToSharepoint() {
    this.configurationService.getItemFromConfiguration('DefaultSharepointUrl')
      .subscribe((item) => {
        this.windowRef.nativeWindow.location = item.value;
      });
  }
}
