import { ValidatorFn, Validators } from '@angular/forms';
import { validateLicensePlate } from './license-plate.validator';
import { validateSecurePassword } from './password/password.validator';
import { validateConfirmedPassword } from './confirmed-password.validator';
import { validateNotEmpty } from './not-empty.validator';
import { validateNumeric } from './numeric.validator';
import { validateCustomEmail } from './custom-email.validator';
import { validateCustomUrl } from './custom-url.validator';
import { validatePhoneNumeric } from './phone-number.validator';

export class MgrValidators extends Validators {
  static licensePlate: ValidatorFn = validateLicensePlate;
  static securePassword: ValidatorFn = validateSecurePassword;
  static confirmedPassword: ValidatorFn = validateConfirmedPassword;
  static notEmpty: ValidatorFn = validateNotEmpty;
  static numeric: ValidatorFn = validateNumeric;
  static phoneNumber: ValidatorFn = validatePhoneNumeric;
  static customEmail: ValidatorFn = validateCustomEmail;
  static customUrl: ValidatorFn = validateCustomUrl;
}
