import { Action } from '@ngrx/store';
import { Notification } from '../models/notification.model';
import { DocumentsActionTypes } from '@documents/shared/state/documents.actions';

export enum NotificationsActionTypes {
  GetNotifications = '[Notifications] Get',
  GetNotificationsSuccess = '[Notifications] Get Success',
  GetNotificationsFail = '[Notifications] Get Fail',
  DeleteNotifications = '[Notifications] Delete',
  CreateNotification = '[Notifications] Create',
  CreateNotificationSuccess = '[Notifications] Create Success',
  CreateNotificationFail = '[Notifications] Create Fail',
  SelectNotificationToEdit = '[Notifications] Select Notification',
  UpdateNotification = '[Notifications] Update Notification',
  RemoveNotificationFromSelection = '[Notifications] Clear Notification Selection',
  GetManagerAlerts = '[Notifications] Get manager alerts',
  GetManagerAlertsSuccess = '[Notifications] Get manager alerts success',
  GetManagerAlertsFail = '[Notifications] Get manager alerts fail',
}

export class GetNotifications implements Action {
  readonly type = NotificationsActionTypes.GetNotifications;
}

export class GetNotificationsSuccess implements Action {
  readonly type = NotificationsActionTypes.GetNotificationsSuccess;

  constructor(public payload: Notification[]) {
  }
}

export class GetNotificationsFail implements Action {
  readonly type = NotificationsActionTypes.GetNotificationsFail;
}

export class DeleteNotifications implements Action {
  readonly type = NotificationsActionTypes.DeleteNotifications;

  constructor(public payload: Notification[]) {
  }
}

export class CreateNotification implements Action {
  readonly type = NotificationsActionTypes.CreateNotification;

  constructor(public payload: any) {
  }
}

export class CreateNotificationSuccess implements Action {
  readonly type = NotificationsActionTypes.CreateNotificationSuccess;

  constructor() {
  }
}

export class CreateNotificationFail implements Action {
  readonly type = NotificationsActionTypes.CreateNotificationFail;
}

export class SelectNotificationToEdit implements Action {
  readonly type = NotificationsActionTypes.SelectNotificationToEdit;

  constructor(public payload: string) { }
}

export class UpdateNotification implements Action {
  readonly type = NotificationsActionTypes.UpdateNotification;

  constructor(public payload: Notification) { }
}

export class RemoveNotificationFromSelection implements Action {
  readonly type = NotificationsActionTypes.RemoveNotificationFromSelection;
}

export class GetManagerAlerts implements Action {
  readonly type = NotificationsActionTypes.GetManagerAlerts;
}

export class GetManagerAlertsSuccess implements Action {
  readonly type = NotificationsActionTypes.GetManagerAlertsSuccess;

  constructor(public payload: Notification[]) {
  }
}

export class GetManagerAlertsFail implements Action {
  readonly type = NotificationsActionTypes.GetManagerAlertsFail;
}

export type NotificationsActions = GetNotifications
  | GetNotificationsSuccess
  | GetNotificationsFail
  | DeleteNotifications
  | CreateNotification
  | CreateNotificationSuccess
  | CreateNotificationFail
  | SelectNotificationToEdit
  | UpdateNotification
  | RemoveNotificationFromSelection
  | GetManagerAlerts
  | GetManagerAlertsSuccess
  | GetManagerAlertsFail;
