import * as tslib_1 from "tslib";
import { BreadcrumbActionsTypes } from './breadcrumb.actions';
export var initialState = {
    items: [],
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case BreadcrumbActionsTypes.SetBreadcrumb:
            return tslib_1.__assign({}, state, { items: action.payload });
        case BreadcrumbActionsTypes.AddBreadcrumb:
            return tslib_1.__assign({}, state, { items: state.items.concat(action.payload) });
        case BreadcrumbActionsTypes.RemoveBreadcrumb:
            return tslib_1.__assign({}, state, { items: state.items.filter(function (breadcrumbItem) {
                    return !action.payload.find(function (i) { return i.label === breadcrumbItem.label && i.path === breadcrumbItem.path; });
                }) });
        default:
            return state;
    }
}
