import { StorageService } from '../storage.service';
import { PosStorageConst } from './pos-storage.const';
var PosStorageService = /** @class */ (function () {
    function PosStorageService(storage) {
        this.storage = storage;
    }
    Object.defineProperty(PosStorageService.prototype, "addresses", {
        /**
         * gets addresses
         */
        get: function () {
            return this.storage.get(PosStorageConst.storageElements.addresses.key, StorageService.STORES.LOCAL, []);
        },
        /**
         * sets addresses
         */
        set: function (addresses) {
            this.storage.set(PosStorageConst.storageElements.addresses.key, addresses);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * add an adress in the storage (keeping only x(= _historyLength) adresses)
     */
    PosStorageService.prototype.addAddress = function (address) {
        if (address.place_id) {
            var addresses = this.addresses;
            var existingAddress = addresses.filter(function (storesAddress) { return (storesAddress.place_id === address.place_id); });
            if (existingAddress.length === 0) {
                addresses.push(address);
                if (addresses.length > PosStorageConst.storageElements.addresses.conf.historyLength) {
                    addresses = addresses.slice(-PosStorageConst.storageElements.addresses.conf.historyLength);
                }
                this.addresses = addresses;
            }
        }
        return this.addresses;
    };
    return PosStorageService;
}());
export { PosStorageService };
