import { Filter } from '@shared/ui/table/table-filters/shared/filter.interface';
import { DateFilterValueType } from '../enums/date-filter-value-type-enum';

export function sharedDocumentSharedDateFilterFunction(option: Filter, row: any): boolean | null {
  const today = new Date();
  const rowDate = new Date(row.creationDate);
  if (option.value.includes(DateFilterValueType.Today)) {
    return rowDate.toDateString() === today.toDateString();
  }
  if (option.value.includes(DateFilterValueType.Yesterday)) {
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    return rowDate.toDateString() === yesterday.toDateString();
  }
  if (option.value.includes(DateFilterValueType.ThisMonth)) {
    return (rowDate.getMonth() === today.getMonth() && rowDate.getFullYear() === today.getFullYear());
  }
  if (option.value.includes(DateFilterValueType.PastMonth)) {
    const pastMonth = new Date(today);
    pastMonth.setMonth(pastMonth.getMonth() - 1);
    return (rowDate.getMonth() === pastMonth.getMonth() && rowDate.getFullYear() === pastMonth.getFullYear());
  }
  if (option.value.includes(DateFilterValueType.PastThreeMonths)) {
    const pastMonth1 = new Date(today);
    pastMonth1.setMonth(pastMonth1.getMonth() - 1);
    const pastMonth2 = new Date(today);
    pastMonth2.setMonth(pastMonth2.getMonth() - 2);
    const pastMonth3 = new Date(today);
    pastMonth3.setMonth(pastMonth3.getMonth() - 3);
    return (rowDate.getMonth() === pastMonth1.getMonth() && rowDate.getFullYear() === pastMonth1.getFullYear())
      || (rowDate.getMonth() === pastMonth2.getMonth() && rowDate.getFullYear() === pastMonth2.getFullYear())
      || (rowDate.getMonth() === pastMonth3.getMonth() && rowDate.getFullYear() === pastMonth3.getFullYear());
  }
}

export const SharedDocumentSharedDateFilter = {
  name: 'sharedDocumentSharedDate',
  label: 'global-filters-shareddocumentuploaddate',
  options: [
    {
      label: 'global-filters-today',
      value: 'today',
    },
    {
      label: 'global-filters-yesterday',
      value: 'yesterday',
    },
    {
      label: 'global-filters-thismonth',
      value: 'thisMonth',
    },
    {
      label: 'global-filters-pastmonth',
      value: 'pastMonth',
    },
    {
      label: 'global-filters-pastthreemonths',
      value: 'pastThreeMonths',
    },
  ],
  predicateFn: sharedDocumentSharedDateFilterFunction,
};
