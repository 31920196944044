import { AuthenticationState } from './shared/core/authentication/authentication.reducer';
import { FeatureState } from './shared/core/feature/feature.reducer';
import { UserState } from './shared/entity/user/state/user.reducer';
import { ConfigurationState } from './shared/core/configuration/configuration.reducer';
import { AppLoaderService } from '@shared/ui/app-loader/app-loader.service';
import { AuthStatus } from '@shared/core/authentication/authentication.reducer';
import { Store, select } from '@ngrx/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { GtmService } from '@shared/core/gtm/gtm.service';
import { StorageAuthService } from '@shared/core/storage/storage.auth.service';
import { getAuthStatus } from './shared/core/authentication';
import { getConfigurationLoaded } from './shared/core/configuration';
import { getFeaturesLoaded } from './shared/core/feature';
import { getUserLoaded, getUserLoading } from './shared/entity/user/state';
import { TranslationService } from './shared/core/translation/translation.service';
import { filter, tap } from 'rxjs/operators';
import { PopinService } from '@shared/ui/popin/shared/popin.service';
import { StorageService } from './shared/core/storage/storage.service';
import { StorageConst } from './shared/core/storage/storage.const';

@Component({
  selector: 'mgr-root',
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit, OnDestroy {

  private routerEvents: Subscription;

  constructor(
    private gtmService: GtmService,
    private router: Router,
    private store: Store<ConfigurationState | UserState | FeatureState | AuthenticationState>,
    private appLoaderService: AppLoaderService,
    private translationService: TranslationService,
    private authStorageService: StorageAuthService,
    private popinService: PopinService,
    private storage: StorageService,
  ) {
  }

  ngOnInit(): void {
    this.setUpGtm();
    this.setUpTranslations();
    this.setUpLoader();
    this.checkBrowser();
  }

  private checkBrowser(): void {
    this.routerEvents = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd
        && /Trident\/|MSIE/.test(window.navigator.userAgent)
        && this.storage.get(StorageConst.selectedCountry)) {
        this.popinService.open('unsupportedBrowserPopin');
      }
    });
  }

  private setUpGtm(): void {
    this.routerEvents = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.authStorageService.auth) {
          this.gtmService.sendPageView(event.url);
        }
      }
    });
  }

  private setUpTranslations(): void {
    this.store.pipe(
      select(getAuthStatus),
      tap(() => {
        this.translationService.setTranslations();
      }),
    ).subscribe();
  }

  private setUpLoader(): void {
    // Hide loader (anonymous part) when translation are loaded
    combineLatest([
      this.store.pipe(select(getAuthStatus)),
      this.translationService.isTranslationLoaded$,
    ]).pipe(
      filter(([authenticationStatus, isTranslationLoaded]) => {
        return authenticationStatus === AuthStatus.OUT && !!isTranslationLoaded;
      }),
      tap(() => this.appLoaderService.hideAppLoader()),
    ).subscribe();
    // Hide loader (auth part) when config/features/user/translations elements are loaded
    combineLatest([
      this.store.pipe(select(getAuthStatus)),
      this.store.pipe(select(getConfigurationLoaded)),
      this.store.pipe(select(getFeaturesLoaded)),
      this.store.pipe(select(getUserLoaded)),
      this.translationService.isTranslationLoaded$,
    ]).pipe(
      filter(([authenticationStatus, isConfigLoaded, isFeaturesLoaded, isUserLoaded, isTranslationLoaded]) => {
        return authenticationStatus === AuthStatus.IN &&
          !!isConfigLoaded &&
          !!isFeaturesLoaded &&
          !!isUserLoaded &&
          !!isTranslationLoaded;
      }),
      tap(() => this.appLoaderService.hideAppLoader()),
    ).subscribe();
    // Show loader when translation or user is loading
    combineLatest([
      this.store.pipe(select(getUserLoading)),
      this.translationService.isTranslationLoading$,
      this.store.pipe(select(getUserLoaded)),
    ]).pipe(
      filter(([isUserLoading, isTranslationLoading, isUserLoaded]) => {
        return (isUserLoading || isTranslationLoading) && !isUserLoaded;
      }),
      tap(() => this.appLoaderService.showAppLoader()),
    ).subscribe();
  }

  ngOnDestroy(): void {
    if (this.routerEvents) {
      this.routerEvents.unsubscribe();
    }
  }
}
