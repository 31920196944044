/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logout-popin.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/ui/popin/popin.component.ngfactory";
import * as i3 from "../../shared/ui/popin/popin.component";
import * as i4 from "@ngrx/store";
import * as i5 from "../../shared/ui/popin/shared/popin.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "../../shared/ui/popin/directive/close-popin.directive";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "./logout-popin.component";
import * as i14 from "../../shared/core/authentication/authentication.service";
import * as i15 from "../../shared/core/helpers/window.ref";
import * as i16 from "../../shared/core/gtm/gtm.service";
import * as i17 from "../../shared/core/storage/storage.service";
import * as i18 from "@angular/router";
var styles_LogoutPopinComponent = [i0.styles];
var RenderType_LogoutPopinComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoutPopinComponent, data: {} });
export { RenderType_LogoutPopinComponent as RenderType_LogoutPopinComponent };
export function View_LogoutPopinComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "mgr-popin", [["class", "logout-popin"], ["codename", "logoutPopin"], ["size", "sm"]], null, null, null, i2.View_PopinComponent_0, i2.RenderType_PopinComponent)), i1.ɵdid(1, 245760, null, 0, i3.PopinComponent, [i4.Store, i5.PopinService], { codename: [0, "codename"], size: [1, "size"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "div", [["class", "popin-header text-center no-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h2", [["class", "popin-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, 0, 3, "div", [["class", "popin-content text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, 0, 10, "div", [["class", "popin-footer text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "button", [["mat-button", ""], ["mgrClosePopin", "logoutPopin"], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(12, 81920, null, 0, i8.ClosePopinDirective, [i4.Store, i5.PopinService], { mgrClosePopin: [0, "mgrClosePopin"] }, null), i1.ɵdid(13, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(14, 0, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 4, "button", [["color", "primary"], ["mat-raised-button", ""], ["mgrClosePopin", "logoutPopin"], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.logout() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(17, 81920, null, 0, i8.ClosePopinDirective, [i4.Store, i5.PopinService], { mgrClosePopin: [0, "mgrClosePopin"] }, null), i1.ɵdid(18, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(19, 0, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(21, 0, null, null, 0, "iframe", [["height", "1"], ["id", "logout_ifm"], ["src", ""], ["width", "1"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "logoutPopin"; var currVal_1 = "sm"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_6 = "logoutPopin"; _ck(_v, 12, 0, currVal_6); var currVal_10 = "logoutPopin"; _ck(_v, 17, 0, currVal_10); var currVal_11 = "primary"; _ck(_v, 18, 0, currVal_11); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("global-logout_popin-title")); _ck(_v, 4, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("global-logout_popin-text")); _ck(_v, 8, 0, currVal_3); var currVal_4 = (i1.ɵnov(_v, 13).disabled || null); var currVal_5 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_4, currVal_5); var currVal_7 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("global-logout_popin-cancel")); _ck(_v, 14, 0, currVal_7); var currVal_8 = (i1.ɵnov(_v, 18).disabled || null); var currVal_9 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); _ck(_v, 16, 0, currVal_8, currVal_9); var currVal_12 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform("global-logout_popin-confirm")); _ck(_v, 19, 0, currVal_12); }); }
export function View_LogoutPopinComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mgr-logout-popin", [], null, null, null, View_LogoutPopinComponent_0, RenderType_LogoutPopinComponent)), i1.ɵdid(1, 49152, null, 0, i13.LogoutPopinComponent, [i14.AuthenticationService, i15.WindowRef, i16.GtmService, i17.StorageService, i18.Router], null, null)], null, null); }
var LogoutPopinComponentNgFactory = i1.ɵccf("mgr-logout-popin", i13.LogoutPopinComponent, View_LogoutPopinComponent_Host_0, {}, {}, []);
export { LogoutPopinComponentNgFactory as LogoutPopinComponentNgFactory };
