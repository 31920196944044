import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ConfigurationActionTypes } from './configuration.actions';
import { Observable, of } from 'rxjs';

@Injectable()
export class ConfigurationEffects {
  @Effect()
  getConfiguration$: Observable<Action> = this.actions$.pipe(
    ofType(ConfigurationActionTypes.GetConfiguration),
    mergeMap(() =>
      this.userService.getConfiguration().pipe(
        map(data => ({ type: ConfigurationActionTypes.GetConfigurationSuccess, payload: data })),
        catchError(() => of({ type: ConfigurationActionTypes.GetConfigurationFail })),
      ),
    ),
  );

  constructor(private userService: ConfigurationService, private actions$: Actions) { }
}
