<p class="password-validation-description">{{ 'global-password_validation-rule_description' | translate }}</p>
<ul class="password-validation-list">
  <li
    class="password-validation-item"
    [class.pending]="isPasswordUsernamePending()"
    [class.valid]="isPasswordUsernameValid()"
    [class.invalid]="isPasswordUsernameInvalid()">
    <div class="password-validation-spinner" *ngIf="isCheckingPasswordUsername">
      <mat-spinner [diameter]="15" [strokeWidth]="2"></mat-spinner>
    </div>
    {{ 'global-password_validation-rule_notcontainusername' | translate }}
  <li
    class="password-validation-item"
    *ngFor="let rule of PASSWORD_RULES_REGEX"
    [class.pending]="!passwordControl.value"
    [class.invalid]="passwordControl.getError(rule) && passwordControl.value"
    [class.valid]="!passwordControl.getError(rule) && passwordControl.value">
      {{ 'global-password_validation-rule_' + rule | lowercase | translate }}
  </li>
</ul>
