import { UserService } from '@app/shared/entity/user/user.service';
import { FormControl } from '@angular/forms';
import { PasswordRulesRegex } from '../validators/password/password.validator';
var PasswordValidationComponent = /** @class */ (function () {
    function PasswordValidationComponent(userService) {
        this.userService = userService;
        this.PASSWORD_RULES_REGEX = Object.keys(PasswordRulesRegex);
    }
    Object.defineProperty(PasswordValidationComponent.prototype, "isCheckingPasswordUsername", {
        get: function () {
            return this.userService.isCheckingPasswordUsername.getValue();
        },
        enumerable: true,
        configurable: true
    });
    PasswordValidationComponent.prototype.isPasswordUsernamePending = function () {
        var _this = this;
        return this.passwordControl.errors &&
            Object.keys(this.passwordControl.errors).some(function (key) { return _this.PASSWORD_RULES_REGEX.includes(key); });
    };
    PasswordValidationComponent.prototype.isPasswordUsernameValid = function () {
        return !this.passwordControl.getError('notContainUserName') &&
            this.passwordControl.value &&
            !this.isPasswordUsernamePending() &&
            !this.isCheckingPasswordUsername;
    };
    PasswordValidationComponent.prototype.isPasswordUsernameInvalid = function () {
        return this.passwordControl.getError('notContainUserName') &&
            this.passwordControl.value &&
            !this.isPasswordUsernamePending() &&
            !this.isCheckingPasswordUsername;
    };
    return PasswordValidationComponent;
}());
export { PasswordValidationComponent };
