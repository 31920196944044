import { APP_ROUTES } from '@app/app.routes';

enum PageSection {
  'Homepage' = 'Homepage',
  'UsersManagement' = 'Users management',
  'MyFleet' = 'My fleet',
  'Documents' = 'Documents',
  'MyReports' = 'My reports',
  'Notifications' = 'Notifications',
  'Administration' = 'Administration',
  'Others' = 'Others',
  'ErrorPage' = 'Error page',
}

const routeMapper = {
  [APP_ROUTES.HOME] : PageSection.Homepage,
  [APP_ROUTES.USER_MANAGEMENT] : PageSection.UsersManagement,
  [APP_ROUTES.MY_FLEET] : PageSection.MyFleet,
  [APP_ROUTES.DOCUMENTS] : PageSection.Documents,
  [APP_ROUTES.REPORTS] : PageSection.MyReports,
  [APP_ROUTES.NOTIFICATIONS] : PageSection.Notifications,
  [APP_ROUTES.ADMINISTRATION] : PageSection.Administration,
  [APP_ROUTES.NOT_FOUND] : PageSection.ErrorPage,
};

export const getPageSectionByUrl = (url: string): PageSection  => {
  for (const route in routeMapper) {
    if (route && Object.prototype.hasOwnProperty.call(routeMapper, route)) {
      if (url.includes(route)) {
        return routeMapper[route];
      }
    }
  }
  return PageSection.Others;
};
