import { environment } from '../../../environments/environment';

export const DashboardConst = {
  breadcrumb: {
    label: 'global-breadcrumbs-home_label',
    src: '/home',
    disabled: false,
  },
  kpiCategories: {
    requestCategory: {
      approvedRequest: 'ApprovedRequest',
      pendingRequest: 'PendingRequest',
      rejectedRequest: 'RejectedRequest',
    },
    mileageInformationCategories: {
      mileageMissing: 'MileageMissing',
      contractMileageReached: 'ContractMileageReached',
      mileageNotUpdated: 'MileageNotUpdated',
    },
    mileageDeviationCategory: {
      noDeviation: 'NoDeviation',
      under10: 'Under10',
      under30: 'Under30',
      under50: 'Under50',
      under: 'Under',
      over10: 'Over10',
      over30: 'Over30',
      over50: 'Over50',
      over: 'Over',
    },
    contractStatus: {
      undefined: 'Undefined',
      vehicleConfigure: 'VehicleConfigure',
      delivery: 'Delivery',
      vehicleOnRoad: 'VehicleOnRoad',
      contractTerminating: 'ContractTerminating',
      contractTerminated: 'ContractTerminated',
      contractOnOrder: 'ContractOnOrder',
    },
    deliveryCategory: {
      thisMonth: 'ThisMonth',
      nextMonth: 'NextMonth',
    },
    salesforceCategory: {
      processedTickets: 'ProcessedTickets',
      pendingTickets: 'PendingTickets',
      rejectedTicket: 'RejectedTicket',
    },
  },
  api: {
    baseUrl: `${environment.apigatewayUrl}/apigateway/`,
    modules: {
      user: {
        url: 'usermanagement/api/',
        endpoints: {
          GetRequestAccessKpi: 'Kpi/RequestAccessKpi',
        },
      },
      contract: {
        url: 'contract/api/',
        endpoints: {
          GetMileageInformation: 'Kpi/GetMileageInformation',
          GetMileageDeviations: 'Kpi/GetMileageDeviations',
          GetContractInformation: 'Kpi/GetContractStatusInformation',
          GetDelivery: 'Kpi/GetOrdersDeliveryDate',
          GetSalesforce: 'Kpi/GetSalesforce',
        },
      },
    },
  },
};
