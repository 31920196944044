import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../app.state';
import * as fromNavigation from './navigation.reducer';
import * as navigationActions from './navigation.actions';

// Extends the app state to include the navigation feature.
export interface State extends fromRoot.State {
  navigation: fromNavigation.NavigationState;
}

// Selector functions
export const getNavigationFeatureState = createFeatureSelector<fromNavigation.NavigationState>('navigation');

export const getShowNotifications = createSelector(
  getNavigationFeatureState,
  state => state.showNotifications,
);

export const getShowUserNavigation = createSelector(
  getNavigationFeatureState,
  state => state.showUserNavigation,
);

export const getShowFullNavigation = createSelector(
  getNavigationFeatureState,
  state => state.showFullNavigation,
);

export const getShowLanguages = createSelector(
  getNavigationFeatureState,
  state => state.showLanguages,
);

export const reducer = fromNavigation.reducer;
export const actions = navigationActions;
