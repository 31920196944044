<div class="top-nav" id="top-nav">

  <div class="top-nav-brand">
    <a routerLink="/{{APP_ROUTES.HOME}}">
      <span class="brand-logo-container">
        <img src="{{'images/icons/'+ countryLogo | assetPath}}" alt="">
      </span>
      <span class="top-nav-brand-title" *ngIf="countryName !== nffleetCounty">
        | {{'global-generic_terms-application_title'| translate}} {{'global-generic_terms-application_subtitle'|
        translate}}
      </span>
      <span class="top-nav-brand-title" *ngIf="countryName == nffleetCounty">
        | {{'global-generic_terms-application_subtitle'|
        translate}}
      </span>
    </a>
  </div>

  <div class="top-nav-buttons">

    <!-- country name -->
    <div *ngIf="country" class="top-nav-action">
      <span class="country-name">{{country.countryName}}</span>
    </div>

    <!-- ## Languages -->
    <div class="top-nav-action">
      <span (click)="toggleLanguage()" class="top-nav-action-language" *ngIf="currentLanguage as currentCulture"
        id="languages">{{ 'global-generic_terms-culture_' + (currentCulture | lowerUnderscore) | translate }}</span>
      <ul (clickOut)="toggleLanguage()" *ngIf="showLanguages && availableCultures" [@Fade] [exclude]="'#languages'"
        class="top-nav-action-container top-nav-action-container-no-scroll" mgrClickOutside>
        <li *ngFor="let culture of availableCultures" (click)="switchLanguage(culture)" class="top-nav-action-item">
          <p>
            <img [src]="'/images/flags/' + culture[3] + culture[4] + '.svg' | AldCdnPath" width="18" alt="" />
            <span class="top-nav-action-name">{{ 'global-generic_terms-culture_' + (culture | lowerUnderscore) |
              translate }}</span>
          </p>
        </li>
      </ul>
    </div>

    <!-- ## Notifications -->
    <div class="top-nav-action" *restrictTo="[USER_ROLES.FleetManager]">
      <span *ngIf="!(notificationsLoading$ | async)" (click)="openNotificationPanel()"
        [matBadge]="(unreadNotifications$ | async)" class="user-nav-name">
        <i class="user-nav-icon icon-alert"></i>
      </span>
      <mgr-loading class="user-nav-name user-nav-loading" *ngIf="(notificationsLoading$ | async)" theme="light">
      </mgr-loading>
    </div>

    <!-- ## Working Scope -->
    <div *restrictTo="[USER_ROLES.Administrator, USER_ROLES.FleetManager, USER_ROLES.Commercial]"
      class="top-nav-action">
      <span (click)="openSwitchWorkingScopePanel()" class="user-nav-name"
        [matBadge]="selectedWorkingScopesSize.toString()">
        <i class="user-nav-icon icon-briefcase"></i>
      </span>
    </div>

    <!-- ## User -->
    <div class="top-nav-action">
      <mgr-user-navigation (toggle)="toggleUserNavigation($event)"></mgr-user-navigation>
    </div>

  </div>
</div>

<mgr-logout-popin></mgr-logout-popin>

<mgr-edit-my-user-profile></mgr-edit-my-user-profile>

<mgr-user-notifications-panel *restrictTo="[USER_ROLES.FleetManager]" [notifications]="notifications$ | async">
</mgr-user-notifications-panel>

<mgr-panel *restrictTo="[USER_ROLES.Administrator, USER_ROLES.FleetManager, USER_ROLES.Commercial]"
  codename="switchWorkingScopePanel" title="{{'global-top_header-switch_working_scope'| translate}}">
  <ng-template>
    <mgr-working-scope-navigation></mgr-working-scope-navigation>
  </ng-template>
</mgr-panel>

<!--<mgr-panel *restrictTo="[USER_ROLES.Administrator, USER_ROLES.FleetManager, USER_ROLES.Commercial]"-->
<!--  codename="editPreferencesPanel" title="{{'global-top_header-edit_preferences'| translate}}">-->
<!--  <ng-template>-->
<!--    <mgr-edit-preferences-panel></mgr-edit-preferences-panel>-->
<!--  </ng-template>-->
<!--</mgr-panel>-->