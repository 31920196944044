import { AldSnackbarService } from '@ald/ui';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorService {

  constructor(
    private snackBarService: AldSnackbarService,
    private readonly translateService: TranslateService,
  ) {
  }

  public handleError(): void {
    this.snackBarService.error(this.translateService.instant('global-generic_terms-snackbar_generic_error'));
  }
}
