import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as panelState from '../state';
import * as actions from '../state/panel.actions';

@Injectable()
export class PanelService {

  constructor(
    private readonly store: Store<panelState.State>,
  ) {
  }

  public open(codename: string): void {
    this.store.dispatch(new actions.OpenPanel(codename.replace(/'/g, '')));
  }

  public close(codename: string): void {
    this.store.dispatch(new actions.ClosePanel(codename.replace(/'/g, '')));
  }

  public clear(): void {
    this.store.dispatch(new actions.ClearPanels());
  }

}
