import { StorageService } from './storage.service';
import { StorageConst } from './storage.const';
var StorageAuthService = /** @class */ (function () {
    function StorageAuthService(storage) {
        this.storage = storage;
    }
    StorageAuthService.prototype.setAuth = function (tokens) {
        var storageKey = StorageConst.auth.keys.rememberAuth;
        var storageType = StorageService.STORES.LOCAL;
        this.storage.set(storageKey, tokens, storageType);
    };
    Object.defineProperty(StorageAuthService.prototype, "auth", {
        get: function () {
            return this.rememberAuth;
        },
        enumerable: true,
        configurable: true
    });
    StorageAuthService.prototype.removeAuth = function () {
        this.storage.remove(StorageConst.auth.keys.rememberAuth, StorageService.STORES.LOCAL);
    };
    Object.defineProperty(StorageAuthService.prototype, "rememberAuth", {
        get: function () {
            return this.storage.get(StorageConst.auth.keys.rememberAuth, StorageService.STORES.LOCAL);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageAuthService.prototype, "accessToken", {
        get: function () {
            return this.auth[StorageConst.auth.keys.accessToken] || false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StorageAuthService.prototype, "refreshToken", {
        get: function () {
            return this.auth[StorageConst.auth.keys.refreshToken] || false;
        },
        enumerable: true,
        configurable: true
    });
    return StorageAuthService;
}());
export { StorageAuthService };
