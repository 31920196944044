import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NotificationDisplayComponent } from './notification-display/notification-display.component';
import { MatButtonModule } from '@angular/material';

@NgModule({
  declarations: [
    NotificationDisplayComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
  ],
  exports: [
    NotificationDisplayComponent,
  ],
})
export class NotificationPreviewModule {
}
