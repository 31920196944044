import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as popinState from './state';
import { takeWhile } from 'rxjs/operators';
import { fadeAnimation } from '../animations/fade.animation';
import { isPopinSizeType, PopinSize, PopinSizeType } from '@shared/ui/popin/shared/popin-size.type';
import { PopinService } from '@shared/ui/popin/shared/popin.service';

@Component({
  selector: 'mgr-popin',
  templateUrl: './popin.component.html',
  styleUrls: ['./popin.component.scss'],
  animations: [fadeAnimation],
})
export class PopinComponent implements OnInit, OnDestroy {
  @Input() codename: string;
  @Input() size: PopinSizeType;

  public showPopin: boolean;
  private componentActive = true;

  constructor(private store: Store<popinState.State>,
              private popinService: PopinService) {
  }

  ngOnInit(): void {
    this.checkValidSize();
    this.store.pipe(select(popinState.getPopinFeatureState),
                    takeWhile(() => this.componentActive),
    ).subscribe((state) => {
      this.showPopin = (state.showPopins.includes(this.codename));
    },
    );
  }

  private checkValidSize(): void {
    if (this.size && !isPopinSizeType(this.size)) {
      throw new Error(`Size ${this.size} is not in the authorized range: ${PopinSize.toString()}`);
    }
  }

  public closePopin(): void {
    if (this.showPopin) {
      this.popinService.close(this.codename);
    }
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }
}
