import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { StartConst } from '../../../start/shared/start.const';
import { StorageAuthService } from '../storage/storage.auth.service';
import { environment } from '../../../../environments/environment';
import { ConfigurationConst } from '../configuration/configuration.const';
import { UserConst } from '@app/shared/entity/user/user.const';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private storage: StorageAuthService,
              ) {
  }

  private readonly anonymousRoutes: string[] =  [
    environment.adfs.baseUrl,
    environment.translationLoadPath,
    StartConst.api.modules.start.endpoints.requestAccess,
    StartConst.api.modules.start.endpoints.checkToken,
    StartConst.api.modules.start.endpoints.forgotPassword,
    StartConst.api.modules.start.endpoints.resetPassword,
    UserConst.api.modules.users.endpoints.isPasswordExpired,
    `${ConfigurationConst.api.baseUrl}/getconfiguration/`,
  ];

  private static stringToRegex(value: string) {
    return new RegExp(value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isAnonymousRoute = this.anonymousRoutes.some((anonymousRoute) => {
      return JwtInterceptor.stringToRegex(anonymousRoute).test(request.url);
    });
    let httpRequest = request;
    if (!isAnonymousRoute && this.storage.accessToken) {
      httpRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.storage.accessToken}`,
        },
      });
    }
    return next.handle(httpRequest);
  }

}
