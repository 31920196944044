import { environment } from '../../../../environments/environment';

export const UserConst = {
  api: {
    baseUrl: `${environment.apigatewayUrl}/apigateway/`,
    modules: {
      users: {
        url: 'UserManagement/api/',
        endpoints: {
          getUser: 'User/UserProfile',
          getUserProfile: 'UserManagement/UserProfile',
          changePassword: 'User/ChangePassword',
          getCarPolicies: 'Company/GetCompanyCarPolicies',
          updateUser: 'User/UserProfile',
          getPartners: 'Partner/GetAvailablePartners',
          updatePreferences: 'User/UpdatePreferences',
          updateLastLoginDate: 'User/LoginDate',
          GetExternalJwtToken: 'User/ExternalAppJwt',
          isPasswordExpired: 'User/IsPasswordExpired',
          validatePassword: 'User/ValidatePassword',
          validatePasswordAuthenticated: 'User/AuthenticatedValidatePassword',
          authenticateUser: 'User/Authenticate',
        },
      },
    },
  },
};

export enum UserRoles {
  Driver = 'Driver',
  FleetManager = 'FleetManager',
  Prospect = 'Prospect',
  Commercial = 'Commercial',
  Administrator = 'Administrator',
  Supplier = 'Supplier',
}

export const USER_WITHOUT_ACCOUNT_ID = '00000000-0000-0000-0000-000000000000';

export enum PasswordExpiration {
  WillExpireSoon = 'WillExpireSoon',
  Expired = 'Expired',
}
