import { environment } from '../../../../environments/environment';
import { APP_ROUTES } from '@app/app.routes';

export const FeatureConst = {
  api: {
    availableFeatures: `${environment.apigatewayUrl}/apigateway/Configuration/api/Feature/`,
  },
  features: {
    aldInsurance: 'spec_isInsuranceFieldVisible',
    aldQuote: 'spec_isAldQuoteActivated',
    aldQuoteV2: 'spec_isAldQuoteV2Activated',
    carSelector: 'spec_isCarSelectorActivated',
    carSelectorActivatedWithToken: 'spec_isCarSelectorActivatedWithToken',
    carSelectorActivatedWithoutToken: 'spec_isCarSelectorActivatedWithoutToken',
    aldQuoteWithToken: 'spec_isALDQuoteWithTokenActivated',
    aldQuoteWithoutToken: 'spec_isALDQuoteWithoutTokenActivated',
    aldNetReportsWithoutToken: 'spec_isALDNetReportWithoutTokenActivated',
    aldNetReportsWithToken: 'spec_isALDNetReportWithTokenActivated',
    smartCare: 'spec_isSmartCareActivated',
    fuelCard: 'spec_isFuelCardActivated',
    assignPlateToDriver: 'spec_isAssignPlateToDriverActivated',
    domainWhitelisting: 'spec_isDomainWhitelistingActivated',
    manageSupplier: 'spec_isSupplierActivated',
    createDriver: 'spec_isCreateDriverActivated',
    hideRentAndFleetManagement: 'spec_isHideRentAndFleetManagement',
    salesforce: 'spec_salesForceManagerActivated',
    maintenanceHistory: 'spec_isMaintenanceHistoryActivated',
    assistanceForDriver: 'spec_isAssistanceForDriverActivated',
    translationManagement: 'spec_isTranslationManagementActivated',
    sharedDocuments: 'spec_isSharedDocumentsActivated',
    trafficFinesV2: 'spec_isTrafficFinesV2Activated',
    trafficFinesV1: 'spec_isTrafficFinesActivated',
    exportUsers: 'spec_isExportUsersActivated',
    ifrsReports: 'spec_isIfrsReportsActivated',
    reliefService: 'spec_isReliefServiceShown',
    excessMileageCharge: 'spec_isContractDetailsExcessMileageShown',
    excessMileageChargeInCents: 'spec_isExcessChargeInCents',
    mileageDeviation: 'spec_isContractDetailsDeviationShown',
    canEditUserProfileActivated: 'spec_isEditUserProfilesActivated',
    orderListQuotationNumber: 'spec_isOrderListQuotationNumberActivated',
    canEditOwnProfileActivated: 'spec_isEditOwnProfileActivated',
    canEditOwnNameActivated: 'spec_isEditOwnNameActivated',
    featureManagement: 'spec_isFeatureManagerActivated',
    canEditUserNamesActivated: 'spec_isEditUserNamesActivated',
    crepCodeMaintenanceHistory: 'spec_showCrepCodeMaintenanceHistory',
    displayQuotationNumber: 'spec_isQuotationNumberActivated',
    displayPorscheNordLink: 'spec_isPorscheNordLinkActivated',
    displayEuroMasterLink: 'spec_isEuroMasterLinkActivated',
    supplierWithToken: 'spec_isSupplierWithTokenActivated',
    displayChatBotLink: 'spec_isChatBotLinkActivated',
  },
  routes: {
    [APP_ROUTES.WHITELIST_DOMAINS]: 'spec_isDomainWhitelistingActivated',
    [APP_ROUTES.SUPPLIER_LIST]: 'spec_isSupplierActivated',
    [APP_ROUTES.SUPPLIER_LIST]: 'spec_isSupplierWithTokenActivated',
    [APP_ROUTES.SALESFORCE_TICKETS]: 'spec_salesForceActivated',
    [APP_ROUTES.MAINTENANCE_HISTORY]: 'spec_isMaintenanceHistoryActivated',
    [APP_ROUTES.CONTRACT_DRIVER_ASSISTANCE]: 'spec_isAssistanceForDriverActivated',
    [APP_ROUTES.TRANSLATION_LANDING_PAGE]: 'spec_isTranslationManagementActivated',
    [APP_ROUTES.DOCUMENT_LIST_SHARED_BY_DRIVER]: 'spec_isSharedDocumentsActivated',
    [APP_ROUTES.CONTRACT_FUEL_CARDS]: 'spec_isFuelCardActivated',
    [APP_ROUTES.FUEL_CARD_HISTORY]: 'spec_isFuelCardActivated',
    [APP_ROUTES.IFRS_REPORTS]: 'spec_isIfrsReportsActivated',
    [APP_ROUTES.FEATURES_LANDING_PAGE]: 'spec_isFeatureManagerActivated',
    [APP_ROUTES.CONTRACT_ONLINE_BOOKING]: 'spec_isEuroMasterLinkActivated',
  },
};
