import { Filter } from '@shared/ui/table/table-filters/shared/filter.interface';

export function userStatusFilterFunction(option: Filter, row: any): boolean | null {
  if (row.user.name) {
    return true;
  }
  return false;
}

export const UserStatusFilter = {
  name: 'userStatus',
  label: 'global-filters-user_status',
  options: [
    {
      label: 'global-filters-exclude_missing_driver',
      value: null,
    },
  ],
  predicateFn: userStatusFilterFunction,
};
