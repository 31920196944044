import { NgModule } from '@angular/core';
import { LicenseValidatorDirective } from './license-plate.validator';
import { ConfirmedPasswordValidatorDirective } from './confirmed-password.validator';
import { NotEmptyValidatorDirective } from './not-empty.validator';
import { NumericValidatorDirective } from './numeric.validator';
import { CustomEmailValidatorDirective } from './custom-email.validator';
import { CustomUrlValidatorDirective } from './custom-url.validator';
import { SecurePasswordDirective } from './password/password.validator';
import { PhoneNumberValidatorDirective } from './phone-number.validator';

@NgModule({
  imports: [
  ],
  declarations: [
    LicenseValidatorDirective,
    ConfirmedPasswordValidatorDirective,
    NotEmptyValidatorDirective,
    NumericValidatorDirective,
    CustomEmailValidatorDirective,
    CustomUrlValidatorDirective,
    SecurePasswordDirective,
    PhoneNumberValidatorDirective,
  ],
  exports: [
    LicenseValidatorDirective,
    ConfirmedPasswordValidatorDirective,
    NotEmptyValidatorDirective,
    NumericValidatorDirective,
    CustomEmailValidatorDirective,
    CustomUrlValidatorDirective,
    SecurePasswordDirective,
    PhoneNumberValidatorDirective,
  ],
})
export class ManagerValidatorsModule {
}
