import { EventEmitter, Injectable } from '@angular/core';
import { SupportStorage } from './storage.model';
import { WindowRef } from '../helpers/window.ref';
import { StorageConst } from '@shared/core/storage/storage.const';

enum STORES {
  SESSION,
  LOCAL,
}

@Injectable()
export class StorageService {

  public static STORES = STORES;
  private supportStorage: SupportStorage;

  $localeChanges = new EventEmitter<any>();

  constructor(private window: WindowRef) {
    this.supportStorage = new SupportStorage();
  }

  private getStore(storage): Storage {
    let store;

    if (storage === StorageService.STORES.SESSION && this.isAvailableStorage(this.window.nativeWindow.sessionStorage)) {
      store = this.window.nativeWindow.sessionStorage;
    } else if (storage === StorageService.STORES.LOCAL && this.isAvailableStorage(this.window.nativeWindow.localStorage)) {
      store = this.window.nativeWindow.localStorage;
    } else {
      store = this.supportStorage;
    }

    return store;
  }

  private isAvailableStorage(storage: Storage): boolean {
    const test = 'test';
    try {
      storage.setItem(test, test);
      storage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }

  set(key: string, value: any, store = StorageService.STORES.SESSION): boolean {
    const storage = this.getStore(store);

    if (typeof value === 'object') {
      storage.setItem(key, this.stringify(value));
    } else {
      storage.setItem(key, value);
    }
    this.$localeChanges.emit({ key, value });
    return true;
  }

  remove(key: string, store = StorageService.STORES.SESSION): boolean {
    const storage = this.getStore(store);
    storage.removeItem(key);
    return true;
  }

  get(key: string, store = StorageService.STORES.SESSION, defaultValue: any = false, nestedKey?): any {
    const storage = this.getStore(store);

    if (storage.getItem(key)) {
      try {
        let datas = JSON.parse(storage.getItem(key));
        if (nestedKey) {
          datas = datas.hasOwnProperty(nestedKey) ? datas[nestedKey] : defaultValue;
        }
        return datas;
      } catch (e) {
        return storage.getItem(key);
      }
    }

    return defaultValue;
  }

  private stringify(value) {
    return JSON.stringify(value);
  }

  public clearStorage() {
    const lang = this.get(StorageConst.translation.keys.lang);
    const country = this.get(StorageConst.selectedCountry);

    this.window.nativeWindow.sessionStorage.clear();
    this.set(StorageConst.translation.keys.lang, lang);
    this.set(StorageConst.selectedCountry, country);
    this.window.nativeWindow.localStorage.clear();
  }

}
