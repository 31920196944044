/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./change-password-form/change-password-form.component.ngfactory";
import * as i2 from "./change-password-form/change-password-form.component";
import * as i3 from "../../../../shared/entity/user/user.service";
import * as i4 from "@angular/common";
import * as i5 from "../../../../shared/ui/panel/panel.component.ngfactory";
import * as i6 from "../../../../shared/ui/panel/panel.component";
import * as i7 from "@ngrx/store";
import * as i8 from "../../../../shared/ui/panel/shared/panel.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./change-password.component";
import * as i11 from "@ald/ui";
import * as i12 from "../../../../shared/core/gtm/gtm.service";
var styles_ChangePasswordComponent = [];
var RenderType_ChangePasswordComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChangePasswordComponent, data: {} });
export { RenderType_ChangePasswordComponent as RenderType_ChangePasswordComponent };
function View_ChangePasswordComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "mgr-change-password-form", [], null, [[null, "submitFormSuccess"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitFormSuccess" === en)) {
        var pd_0 = (_co.changeUserPassword($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ChangePasswordFormComponent_0, i1.RenderType_ChangePasswordFormComponent)), i0.ɵdid(1, 114688, null, 0, i2.ChangePasswordFormComponent, [i3.UserService], { isLoading: [0, "isLoading"] }, { submitFormSuccess: "submitFormSuccess" }), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.isLoading)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ChangePasswordComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "mgr-panel", [["codename", "changeUserPassword"]], null, null, null, i5.View_PanelComponent_0, i5.RenderType_PanelComponent)), i0.ɵdid(1, 245760, null, 1, i6.PanelComponent, [i7.Store, i8.PanelService, i0.ChangeDetectorRef], { codename: [0, "codename"], title: [1, "title"] }, null), i0.ɵqud(335544320, 1, { contentRef: 0 }), i0.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [[1, 2]], null, 0, null, View_ChangePasswordComponent_1))], function (_ck, _v) { var currVal_0 = "changeUserPassword"; var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform("global-change_my_password_panel-title")); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ChangePasswordComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mgr-change-password", [], null, null, null, View_ChangePasswordComponent_0, RenderType_ChangePasswordComponent)), i0.ɵdid(1, 49152, null, 0, i10.ChangePasswordComponent, [i3.UserService, i11.AldSnackbarService, i12.GtmService, i8.PanelService, i9.TranslateService], null, null)], null, null); }
var ChangePasswordComponentNgFactory = i0.ɵccf("mgr-change-password", i10.ChangePasswordComponent, View_ChangePasswordComponent_Host_0, {}, {}, []);
export { ChangePasswordComponentNgFactory as ChangePasswordComponentNgFactory };
