import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { UserState } from '@shared/entity/user/state/user.reducer';
import { UpdateWorkingScope } from '@shared/entity/user/state/user.actions';
import { MgrValidators } from '@shared/ui/forms/validators/custom.validator';
import { getUserSelectedRole } from '@shared/entity/user/state';
import { map, takeWhile, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CompanyLight } from '@shared/models/company-light.model';
import { InputChoice } from '@shared/models/input-choice.model';
import { UserUtilsService } from '@user-management/shared/services/user-utils.service';
import { UserWorkingScope } from '@shared/models/user-role.model';
import { UserRoles } from '@shared/entity/user/user.const';
import { maxItemsValidator } from '@app/shared/ui/validators/max-items.validator';

@Component({
  selector: 'mgr-working-scope-navigation',
  templateUrl: './edit-working-scope.component.html',
  styleUrls: ['./edit-working-scope.component.scss'],
})
export class EditWorkingScopeComponent implements OnInit, OnDestroy {
  private componentActive = true;
  public companyName: string;
  public isLoadingScope = true;
  public form: FormGroup;
  public searchCompaniesCallback: (query: string) => Observable<InputChoice[]>;
  public readonly USER_ROLES = UserRoles;
  public availableWorkingScopes: InputChoice[];
  private userRole;

  constructor(
    private userStore: Store<UserState>,
    private userUtilsService: UserUtilsService) {
  }

  ngOnInit(): void {
    this.userStore.pipe(select(getUserSelectedRole))
      .pipe(
        takeWhile(() => this.componentActive),
        filter(role => !!role),
      ).subscribe((userRole) => {
        this.userRole = userRole;
        this.buildForm();
        if (userRole.role === this.USER_ROLES.FleetManager) {
          const selectedCompaniesIDs = userRole.workingScopes.filter(ws => ws.isSelected).map(ws => ws.companyId);
          this.availableWorkingScopes = CompanyLight.toInputChoiceTree(UserWorkingScope.toCompanyLight(userRole.workingScopes));
          this.form.get('workingScope').setValue(this.availableWorkingScopes.filter(ws => selectedCompaniesIDs.includes(ws.value)));
        } else {
          const selectedCompanies = userRole.workingScopes.filter(ws => ws.isSelected);
          this.form.get('workingScope').setValue(UserWorkingScope.toInputChoice(selectedCompanies));
          this.searchCompaniesCallback = query => this.userUtilsService.searchCompanies(query).pipe(
            map(w => CompanyLight.toInputChoiceTree(w)),
          );
        }
        this.isLoadingScope = false;
      });
  }

  private buildForm(): void {
    this.form = new FormGroup({
      workingScope: new FormControl('', [MgrValidators.required, maxItemsValidator(50)]),
    });
  }

  public updateWorkingScope(): void {
    if (this.form.valid) {
      let selectedWorkingScopes: UserWorkingScope[];
      if (this.userRole.role === this.USER_ROLES.FleetManager) {
        selectedWorkingScopes = this.availableWorkingScopes.map((aws) => {
          return {
            companyId: aws.value,
            companyName: aws.name,
            companyReference: aws.reference,
            parentId: aws.parentId,
            isSelected: this.form.get('workingScope').value.map(sws => sws.value).includes(aws.value),
          };
        });
      } else {
        selectedWorkingScopes = this.form.get('workingScope').value.map((ws) => {
          return {
            companyId: ws.value,
            companyName: ws.name,
            companyReference: ws.reference,
            parentId: ws.parentId,
            isSelected: true,
          };
        });
      }
      this.userStore.dispatch(new UpdateWorkingScope(selectedWorkingScopes));
    }
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }
}
