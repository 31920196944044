import * as tslib_1 from "tslib";
import { Validators } from '@angular/forms';
import { validateLicensePlate } from './license-plate.validator';
import { validateSecurePassword } from './password/password.validator';
import { validateConfirmedPassword } from './confirmed-password.validator';
import { validateNotEmpty } from './not-empty.validator';
import { validateNumeric } from './numeric.validator';
import { validateCustomEmail } from './custom-email.validator';
import { validateCustomUrl } from './custom-url.validator';
import { validatePhoneNumeric } from './phone-number.validator';
var MgrValidators = /** @class */ (function (_super) {
    tslib_1.__extends(MgrValidators, _super);
    function MgrValidators() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MgrValidators.licensePlate = validateLicensePlate;
    MgrValidators.securePassword = validateSecurePassword;
    MgrValidators.confirmedPassword = validateConfirmedPassword;
    MgrValidators.notEmpty = validateNotEmpty;
    MgrValidators.numeric = validateNumeric;
    MgrValidators.phoneNumber = validatePhoneNumeric;
    MgrValidators.customEmail = validateCustomEmail;
    MgrValidators.customUrl = validateCustomUrl;
    return MgrValidators;
}(Validators));
export { MgrValidators };
