import { UserRoles } from '@shared/entity/user/user.const';
import { UserManagementConst } from '@user-management/shared/user-management.const';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as navigationState from '../../state';
import * as userState from '@shared/entity/user/state';
import { select, Store } from '@ngrx/store';
import { fadeAnimation } from '@shared/ui/animations/fade.animation';
import { State } from '../../../app.state';
import { combineLatest } from 'rxjs';
import { ConfigurationService } from '@shared/core/configuration/configuration.service';
import { PanelService } from '@shared/ui/panel/shared/panel.service';
import { UserProfile } from '@shared/models/user-profile.model';
import { filter } from 'rxjs/operators';
import { PopinService } from '@app/shared/ui/popin/shared/popin.service';
import { StartConst } from '@app/start/shared/start.const';
import { StorageService } from '@app/shared/core/storage/storage.service';

@Component({
  selector: 'mgr-user-navigation',
  templateUrl: './user-navigation.component.html',
  styleUrls: ['./user-navigation.component.scss'],
  animations: [fadeAnimation],
})

export class UserNavigationComponent implements OnInit {
  public USER_ROLES = UserRoles;
  public showUserNavigation = false;
  public user: UserProfile;
  public driverRoleCountry: string;
  public hasDriverRole = false;
  public driverUrl: string;
  public daysBeforePasswordExpires: Number;

  @Output() toggle = new EventEmitter<boolean>();

  constructor(
    private store: Store<State>,
    private panelService: PanelService,
    private configurationService: ConfigurationService,
    private popinService: PopinService,
    private storage: StorageService) {
  }

  ngOnInit(): void {
    combineLatest(
      this.store.pipe(select(navigationState.getShowUserNavigation)),
      this.store.pipe(select(userState.getUser)),
    ).subscribe((result) => {
      this.showUserNavigation = result[0];
      this.user = result[1];

      if (this.user) {

        const daysBeforePasswordExpires = this.user.passwordExpirationDate ?
          Math.ceil((new Date(this.user.passwordExpirationDate).getTime() - new Date().getTime()) / (1000 * 3600 * 24)) : null;
        if (daysBeforePasswordExpires && daysBeforePasswordExpires <= 15) {
          this.daysBeforePasswordExpires = daysBeforePasswordExpires;
          const lastShowDate = new Date(this.storage.get('expirationPasswordPopinShown')) || null;
          // tslint:disable-next-line: max-line-length
          const showExpirationPopin = !lastShowDate || Math.floor((new Date().getTime() - lastShowDate.getTime()) / (1000 * 3600 * 24)) >= 1;
          if (showExpirationPopin) {
            this.popinService.open(StartConst.windowCodename.passwordExpirationPopin);
            this.storage.set('expirationPasswordPopinShown', new Date());
          }
        }

        this.driverRoleCountry = this.user.userRoles
          .filter(userRole => userRole.role.toLowerCase() === UserRoles.Driver.toLowerCase())
          .map(userRole => userRole.countryCode)[0];

        this.hasDriverRole = this.user.userRoles
          .some(userRole => userRole.role.toLowerCase() === UserRoles.Driver.toLowerCase() && !userRole.disabled);
      }
      this.configurationService.getItemFromConfiguration('DriverWebsiteUrl')
        .pipe(
          filter(configuration => configuration && !!configuration.value),
        )
        .subscribe((configuration) => {
          this.driverUrl = `${configuration.value}/feed?activedriver=true `;
        });
    });
  }

  public notifyToggleEvent(status: boolean): void {
    this.toggle.emit(status);
  }

  public openEditMyProfilePanel(): void {
    this.panelService.open(UserManagementConst.windowCodename.editMyProfilePanel);
  }

  public openMyPreferencesPanel(): void {
    this.panelService.open(UserManagementConst.windowCodename.editPreferencesPanel);
  }
}
