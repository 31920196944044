import { Injectable } from '@angular/core';
import { DocumentRef } from '@shared/core/helpers/document.ref';
import { timer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppLoaderService {

  private readonly LOADED_CLASS_NAME: string = 'loaded';
  private readonly LOADING_MINIMUM_TIME: number = 1000;

  constructor(private documentRef: DocumentRef) {
  }

  public hideAppLoader(): void {
    if (!document.body.classList.contains(this.LOADED_CLASS_NAME)) {
      const loadingTimer = timer(this.LOADING_MINIMUM_TIME);
      let isProcessed = false;
      loadingTimer.pipe(
        takeWhile(() => !isProcessed),
      ).subscribe(() => {
        this.documentRef.nativeDocument.body.classList.add(this.LOADED_CLASS_NAME);
        isProcessed = true;
      });
    }
  }

  public showAppLoader(): void {
    if (document.body.classList.contains(this.LOADED_CLASS_NAME)) {
      this.documentRef.nativeDocument.body.classList.remove(this.LOADED_CLASS_NAME);
    }
  }
}
