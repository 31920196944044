import { Component } from '@angular/core';
import { ChangePasswordRequest } from './change-password-request';
import { UserService } from '@shared/entity/user/user.service';
import { AldSnackbarService } from '@ald/ui';
import { finalize } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { PanelService } from '@shared/ui/panel/shared/panel.service';
import { GtmService } from '@shared/core/gtm/gtm.service';
import { GTM_EVENTS } from '@shared/core/gtm/gtm-events.const';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mgr-change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent {

  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private userService: UserService,
              private snackbarService: AldSnackbarService,
              private gtmService: GtmService,
              private panelService: PanelService,
              private readonly translateService: TranslateService,
  ) { }

  public changeUserPassword(request: ChangePasswordRequest) {
    this.isLoading.next(true);
    this.userService.changePassword(request).pipe(
      finalize(() => {
        this.isLoading.next(false);
      }),
    ).subscribe(
      () => {
        this.panelService.close('changeUserPassword');
        this.snackbarService.success(this.translateService.instant('global-change_my_password_panel-snackbar_success'));
        this.gtmService.sendEvent(GTM_EVENTS.changePassword);
      },
      (error) => {
        if (error.status === 400) {
          if (error.error === 'invalid_password') {
            this.snackbarService.error(this.translateService.instant('global-change_my_password_panel-snackbar_invalidpassword_error'));
          } else {
            this.snackbarService.error(this.translateService.instant('global-change_my_password_panel-snackbar_error'));
          }
        }
      });
  }

}
