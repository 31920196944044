import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { InputChoice } from '@shared/models/input-choice.model';

@Component({
  selector: 'mgr-multiselect',
  templateUrl: './multiselect.component.html',
})
export class MultiselectComponent {

  @Input() control: FormControl;
  @Input() label: string;
  @Input() clearOption = false;
  @Input() optionTooltip: string;
  @Input() selectAll: boolean;
  @Input() search: boolean;
  @Input() selectAllLabel: string;
  @Input() clearSelectionLabel: string;
  @Input()
  set options(options: InputChoice[]) {
    this._options = options;
    this.filteredOptions = options;
  }

  @Output() valueChange: EventEmitter<void> = new EventEmitter();

  private _options: InputChoice[];
  public searchOption: FormControl = new FormControl();
  public filteredOptions: InputChoice[];

  constructor() { }

  public removeOption(optionToRemove): void {
    const index = this.control.value.findIndex(o => o === optionToRemove);
    if (index !== -1) {
      this.control.value.splice(index, 1);
      this.control.setValue(this.control.value);
    }
  }

  public isGrandChild(option: InputChoice) {
    const father = this._options.find(o => o.value === option.parentId);
    return option.parentId && father && father.parentId;
  }
  public toggleSelection(isAllSelected: boolean): void {
    this.control.setValue(isAllSelected ? this.filteredOptions : []);
  }
  public hasValidator(validator: string): boolean {
    if (!(this.control.validator instanceof Function)) {
      return false;
    }
    if (!this.control.validator('' as unknown as AbstractControl)) {
      return false;
    }

    return !!this.control.validator('' as unknown as AbstractControl).hasOwnProperty(validator);
  }

  public clearSelection(): void {
    this.control.setValue([]);
  }
}
