<mgr-popin codename="passwordExpirationPopin" size="sm">
  <div class="popin-header text-center no-border">
    <h2 class="popin-title">{{'global-password_expiration_popin-title'| translate}}</h2>
  </div>
  <div class="popin-content text-center">
    <p>{{'global-password_expiration_popin-text' | translate:{dataLength: daysBeforePasswordExpires} }}</p>
  </div>
  <div class="popin-footer text-center">
    <button mat-button type="button"
      mgrClosePopin="passwordExpirationPopin">{{'global-password_expiration_popin-close'| translate}}</button>
    <button mat-raised-button type="button" mgrClosePopin="passwordExpirationPopin" (click)="goToChangePassword()"
      color="primary">{{'global-password_expiration_popin-change_now'| translate}}</button>
  </div>
</mgr-popin>