<div class="edit-working-scope-loading align-center" *ngIf="isLoadingScope">
  <mgr-loading theme="main"></mgr-loading>
</div>

<form [formGroup]="form" (submit)="updateWorkingScope()" *ngIf="form && !isLoadingScope">
  <ng-container *restrictTo="[USER_ROLES.Administrator, USER_ROLES.Commercial]">
    <mgr-apply-filter-with-button class="edit-working-scope-multiselect"
    [label]="'global-working_scope_panel-working_scope_label' | translate"
    [optionsSearchCallback]="searchCompaniesCallback"
     [clearOption]="true" 
     [maxItems]="50" 
     [control]="form.controls.workingScope">
    </mgr-apply-filter-with-button>
    <mat-error class="form-error" *ngIf="form.get('workingScope').hasError('required')">
      {{ 'global-generic_terms-error_message_required' | translate }}</mat-error>
    <mat-error class="form-error" *ngIf="form.get('workingScope').hasError('invalidMaxItems')">
      {{ 'global-generic_terms-error_message_invalid_max_items' | translate }}</mat-error>
  </ng-container>

  <ng-container *restrictTo="[USER_ROLES.FleetManager]">
    <mgr-multiselect class="edit-working-scope-multiselect" *ngIf="availableWorkingScopes"
      [label]="'global-working_scope_panel-working_scope_label'" [options]="availableWorkingScopes"
      [control]="form.controls.workingScope">
    </mgr-multiselect>
    <mat-error class="form-error" *ngIf="form.get('workingScope').hasError('required')">
      {{ 'global-generic_terms-error_message_required' | translate }}</mat-error>
  </ng-container>

  <div class="panel-footer align-center">
    <button mat-button class="edit-working-scope-button" type="button" mgrClosePanel="switchWorkingScopePanel">{{ 'global-generic_terms-form_cancel' | translate }}</button>
    <button mat-raised-button class="edit-working-scope-button" type="submit" [disabled]="!form.valid" color="primary">{{'global-generic_terms-form_submit'| translate}}</button>
  </div>
</form>
