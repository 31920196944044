import { StorageService } from '@shared/core/storage/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { StorageConst } from '../storage/storage.const';

export class ManagerTranslateLoader implements TranslateLoader {
  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private translationLoadPath?: string,
  ) { }

  getTranslation(lang: string): Observable<any> {
    if (/onlogin/.test(window.location.href)) {
      return of(null);
    }
    const countryCode = this.storageService.get(StorageConst.selectedCountry);

    const contentHeader = new HttpHeaders({
      'Country-Code': countryCode,
    });

    // TO DO : countryCode will be removed from URL soon. We will use the countryCode set in header
    const apiAddress = `${this.translationLoadPath}${countryCode}/${lang}`;
    return this.httpClient.get(apiAddress, { headers: contentHeader, withCredentials: false });
  }
}
