import { Injectable } from '@angular/core';
import { FeatureService } from './feature.service';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FeatureActionTypes, GetFeatureFail, GetFeatureSuccess } from './feature.actions';
import { Observable, of } from 'rxjs';

@Injectable()
export class FeatureEffects {
  constructor(private featureService: FeatureService,
              private actions$: Actions) {
  }

  @Effect()
  getFeature$: Observable<Action> = this.actions$.pipe(
    ofType(FeatureActionTypes.GetFeature),
    switchMap(() =>
      this.featureService.get()
        .pipe(
          map(data => new GetFeatureSuccess(data)),
          catchError(() => of(new GetFeatureFail())),
        ),
    ),
  );
}
