import { Directive } from '@angular/core';
import { FormGroup, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

export function validateConfirmedPassword(formGroup: FormGroup): ValidationErrors | null {
  const password = formGroup.controls.password;
  const confirmedPassword = formGroup.controls.confirmPassword;

  if (password && confirmedPassword) {
    if (password.value !== confirmedPassword.value) {
      return { confirmedPassword: true };
    }
  }
  return null;
}

@Directive({
  selector: '[confirmedPassword]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ConfirmedPasswordValidatorDirective, multi: true }],
})
export class ConfirmedPasswordValidatorDirective implements Validator {
  validate: ValidatorFn = validateConfirmedPassword;
}
