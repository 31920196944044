import { Action } from '@ngrx/store';
import { ConfigurationModel } from './configuration.model';

export enum ConfigurationActionTypes {
  GetConfiguration = '[Configuration] Get',
  GetConfigurationSuccess = '[Configuration] Get success',
  GetConfigurationFail = '[Configuration] Get fail',
}

export class GetConfiguration implements Action {
  readonly type = ConfigurationActionTypes.GetConfiguration;
}

export class GetConfigurationSuccess implements Action {
  readonly type = ConfigurationActionTypes.GetConfigurationSuccess;

  constructor(public payload: ConfigurationModel[]) { }
}

export class GetConfigurationFail implements Action {
  readonly type = ConfigurationActionTypes.GetConfigurationFail;
}

export type ConfigurationActions = GetConfiguration | GetConfigurationSuccess | GetConfigurationFail;
