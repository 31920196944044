function getDocument() {
    // return the global native browser document object
    return document;
}
var DocumentRef = /** @class */ (function () {
    function DocumentRef() {
    }
    Object.defineProperty(DocumentRef.prototype, "nativeDocument", {
        get: function () {
            return getDocument();
        },
        enumerable: true,
        configurable: true
    });
    return DocumentRef;
}());
export { DocumentRef };
