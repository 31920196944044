import { Injectable } from '@angular/core';
import { FeatureConst } from './feature.consts';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TransferHttpService } from '../transfer-http';
import { filter, map } from 'rxjs/operators';
import { FeatureState } from './feature.reducer';
import { select, Store } from '@ngrx/store';
import { FeatureActionTypes } from './feature.actions';

@Injectable()
export class FeatureService implements CanActivate {

  constructor(private httpClient: TransferHttpService,
              private featureStore: Store<FeatureState>) {
  }

  public updateFeaturesList() {
    this.featureStore.dispatch({ type: FeatureActionTypes.GetFeature });
  }

  public isFeatureActivated(featureName: string): Observable<boolean> {
    return this.featureStore.pipe(
      select(state => state['feature']),
      filter(feature => feature.loading === false),
      filter(featuresList => !!featuresList['items']),
      map(featuresList => featureName.startsWith('!') ?
        !featuresList['items'].includes(featureName.substring(1)) :
        featuresList['items'].includes(featureName),
      ));
  }

  public isFeatureExists(featureSpecName): boolean {
    let isFeatureExists = false;
    Object.keys(FeatureConst.features).forEach((featureName) => {
      if (FeatureConst.features[featureName] === featureSpecName) {
        isFeatureExists = true;
        return;
      }
    });
    return isFeatureExists;
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isFeatureActivated(FeatureConst.routes[route.url[0].path]);
  }

  public get(): Observable<string[]> {
    return this.httpClient.get(FeatureConst.api.availableFeatures)
      .pipe(
        map(res => res as string[]),
      );
  }
}
