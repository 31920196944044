import { StorageService } from '@app/shared/core/storage/storage.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { StorageConst } from '@shared/core/storage/storage.const';
import * as i0 from "@angular/core";
import * as i1 from "../../../core/storage/storage.service";
var SmartDidomiCookieConsentService = /** @class */ (function () {
    function SmartDidomiCookieConsentService(storageService) {
        this.storageService = storageService;
    }
    SmartDidomiCookieConsentService.prototype.loadConfig = function (configDetails) {
        var _this = this;
        return new Observable(function (observer) {
            _this.countryCode = _this.storageService.get(StorageConst.selectedCountry);
            _this.culture = _this.storageService.get(StorageConst.translation.keys.lang);
            configDetails.gtmKey = "" + environment.gtmKey;
            configDetails.app.apiKey = "" + environment.didomiApiKey;
            configDetails.app.privacyPolicyURL = environment.publicDocumentsBaseUrl + "/" + _this.countryCode + "/cookies-charter-" + _this.culture + ".pdf";
            observer.next(configDetails);
            observer.complete();
        });
    };
    SmartDidomiCookieConsentService.prototype.getDomain = function (hostname) {
        return hostname === 'localhost' ? hostname :
            "." + hostname.split('.')[hostname.split('.').length - 2] +
                ("." + hostname.split('.')[hostname.split('.').length - 1]);
    };
    SmartDidomiCookieConsentService.prototype.deleteCookies = function (name, domain) {
        var d = new Date();
        d.setTime(d.getTime() + (-1 * 24 * 60 * 60 * 1000));
        var cName = name + "= ";
        var expires = "expires=" + d.toUTCString();
        var cPath = "path=/";
        var cDomain = "domain=" + domain;
        document.cookie = cName + ";" + cDomain + ";" + expires + ";" + cPath;
    };
    SmartDidomiCookieConsentService.prototype.getAllCookie = function () {
        var cookieArray = [];
        document.cookie.split('; ').forEach(function (cookie) {
            cookieArray.push(cookie.split('=')[0]);
        });
        return cookieArray;
    };
    SmartDidomiCookieConsentService.ngInjectableDef = i0.defineInjectable({ factory: function SmartDidomiCookieConsentService_Factory() { return new SmartDidomiCookieConsentService(i0.inject(i1.StorageService)); }, token: SmartDidomiCookieConsentService, providedIn: "root" });
    return SmartDidomiCookieConsentService;
}());
export { SmartDidomiCookieConsentService };
