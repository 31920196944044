import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { InputChoice } from '@shared/models/input-choice.model';
import { Observable, of } from 'rxjs';
import { fadeAnimation } from '@shared/ui/animations/fade.animation';

@Component({
  selector: 'mgr-apply-filter-with-button',
  templateUrl: './apply-filter-with-button.html',
  styleUrls: ['./apply-filter-with-button.css'],
  animations: [fadeAnimation],
})
export class ApplyFilterwithButtonComponent implements OnInit {
  @Input() control: FormControl;
  @Input() label: string;
  @Input() clearOption = false;
  @Input() optionsSearchCallback: (query: string) => Observable<any>;
  @Input() maxItems = null;
  public isOptionsLoading: boolean;
  public isNoResultsState = false;
  public searchOptionField: FormControl = new FormControl();
  @Input() searchCompany: InputChoice[];
  public _options: InputChoice[];
  set options(options: InputChoice[]) {
    this._options = options;
  }
  get options(): InputChoice[] {
    return this._options;
  }

  constructor(private changeDetector: ChangeDetectorRef) {}
  ngOnInit() {
    if (this.control.value) {
      this.options = this.control.value;
    }
  }

  public applyFilter(searchQuery) {
    if (searchQuery && searchQuery.length > 1 && searchQuery !== '') {
      this.isOptionsLoading = true;
      this.optionsSearchCallback(searchQuery).subscribe((newOptions) => {
        this.isOptionsLoading = false;
        this.isNoResultsState = !(newOptions && newOptions.length);
        // We must concat with the selected values ​​otherwise they disappear in the select trigger
        if (newOptions.length > 0) {
          this.options = this.control.value ? [...newOptions, ...this.control.value] : newOptions;
          this.changeDetector.detectChanges();
        }
      });
    } else {
      this.options = this.control.value;
      this.isOptionsLoading = false;
      this.isNoResultsState = false;
      of([]);
    }
  }

  public removeOption(optionToRemove): void {
    const index = this.control.value.findIndex(o => o === optionToRemove);
    if (index !== -1) {
      this.control.value.splice(index, 1);
      this.control.setValue(this.control.value);
      this.options = this.control.value;
    }
  }

  public hasValidator(validator: string): boolean {
    if (!(this.control.validator instanceof Function)) {
      return false;
    }
    if (!this.control.validator('' as unknown as AbstractControl)) {
      return false;
    }

    return !!this.control
      .validator('' as unknown as AbstractControl)
      .hasOwnProperty(validator);
  }

  public isOptionSelected(option: InputChoice): boolean {
    return (
      this.control.value &&
      this.control.value.find(o => option && o.value === option.value)
    );
  }

  public isGrandChild(option: InputChoice) {
    const father = this.options.find(o => o.value === option.parentId);
    return option.parentId && father && father.parentId;
  }

  public clearSelection(): void {
    this.control.setValue([]);
    this.options = null;
  }
}
