import * as tslib_1 from "tslib";
import { AuthenticationActionTypes } from './authentication.actions';
export var AuthStatus;
(function (AuthStatus) {
    AuthStatus["IN"] = "IN";
    AuthStatus["OUT"] = "OUT";
})(AuthStatus || (AuthStatus = {}));
export var initialState = {
    status: AuthStatus.OUT,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var status;
    switch (action.type) {
        case AuthenticationActionTypes.AuthenticationAuthenticate:
            status = AuthStatus.IN;
            return tslib_1.__assign({}, state, { status: status });
        case AuthenticationActionTypes.AuthenticationDisconnect:
            status = AuthStatus.OUT;
            return tslib_1.__assign({}, state, { status: status });
        default:
            return state;
    }
}
