import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TransferHttpService } from '../transfer-http';
import { ConfigurationModel } from './configuration.model';
import { ConfigurationConst } from './configuration.const';
import { StorageService } from '../storage/storage.service';
import { ConfigurationState } from './configuration.reducer';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/internal/operators';

@Injectable()
export class ConfigurationService {

  constructor(
    private transferHttpService: TransferHttpService,
    private storage: StorageService,
    private configurationStore: Store<ConfigurationState>) {
  }

  public getConfiguration(): Observable<ConfigurationModel[]> {
    const url = ConfigurationConst.api.baseUrl;
    return this.transferHttpService.get<ConfigurationModel[]>(`${url}/${this.storage.get('country_selected')}`)
      .pipe(
        map(confItems => confItems as ConfigurationModel[]));
  }

  public getItemFromConfiguration(itemKey: string): Observable<ConfigurationModel> {
    return this.configurationStore.pipe(
      select(state => state['configuration']),
      filter(configuration => configuration['loading'] === false),
      map((configuration) => {
        if (configuration['items']) {
          return configuration['items'].filter(item => item.key === itemKey)[0];
        }
      }),
    );
  }
}
