import { DocumentRef } from '@shared/core/helpers/document.ref';
import { timer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../core/helpers/document.ref";
var AppLoaderService = /** @class */ (function () {
    function AppLoaderService(documentRef) {
        this.documentRef = documentRef;
        this.LOADED_CLASS_NAME = 'loaded';
        this.LOADING_MINIMUM_TIME = 1000;
    }
    AppLoaderService.prototype.hideAppLoader = function () {
        var _this = this;
        if (!document.body.classList.contains(this.LOADED_CLASS_NAME)) {
            var loadingTimer = timer(this.LOADING_MINIMUM_TIME);
            var isProcessed_1 = false;
            loadingTimer.pipe(takeWhile(function () { return !isProcessed_1; })).subscribe(function () {
                _this.documentRef.nativeDocument.body.classList.add(_this.LOADED_CLASS_NAME);
                isProcessed_1 = true;
            });
        }
    };
    AppLoaderService.prototype.showAppLoader = function () {
        if (document.body.classList.contains(this.LOADED_CLASS_NAME)) {
            this.documentRef.nativeDocument.body.classList.remove(this.LOADED_CLASS_NAME);
        }
    };
    AppLoaderService.ngInjectableDef = i0.defineInjectable({ factory: function AppLoaderService_Factory() { return new AppLoaderService(i0.inject(i1.DocumentRef)); }, token: AppLoaderService, providedIn: "root" });
    return AppLoaderService;
}());
export { AppLoaderService };
