import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTES } from '@app/app.routes';
import { StorageService } from '@app/shared/core/storage/storage.service';
import { WindowRef } from '@shared/core/helpers/window.ref';
import { AuthenticationService } from '@app/shared/core/authentication/authentication.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'mgr-country-access-error-page',
  templateUrl: './country-access-error-page.component.html',
  styleUrls: ['./country-access-error-page.component.scss'],
})
export class CountryAccessErrorPageComponent {

  constructor(
    private authService: AuthenticationService,
    private windowRef: WindowRef,
    private storageService: StorageService,
    private router: Router) {
  }

  public goToLogin() {
    this.windowRef.nativeWindow.addEventListener(
      'message',
      this.adfsEventCallBack,
      false);
    const logoutIframe = document.getElementById('logout_ifm') as HTMLIFrameElement;
    logoutIframe.src = environment.adfs.logoutUrl;
  }

  private adfsEventCallBack = (e: any) => {
    const { data: eventData } = e;
    if (eventData.waitingForLogin) {
      this.windowRef.nativeWindow.removeEventListener('message', this.adfsEventCallBack);
      this.authService.clearAuthentication();
      this.storageService.clearStorage();
      this.router.navigate([`/${APP_ROUTES.START}/${APP_ROUTES.LOGIN}`]);
    }
  }
}
