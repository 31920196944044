import { EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
var MultiselectComponent = /** @class */ (function () {
    function MultiselectComponent() {
        this.clearOption = false;
        this.valueChange = new EventEmitter();
        this.searchOption = new FormControl();
    }
    Object.defineProperty(MultiselectComponent.prototype, "options", {
        set: function (options) {
            this._options = options;
            this.filteredOptions = options;
        },
        enumerable: true,
        configurable: true
    });
    MultiselectComponent.prototype.removeOption = function (optionToRemove) {
        var index = this.control.value.findIndex(function (o) { return o === optionToRemove; });
        if (index !== -1) {
            this.control.value.splice(index, 1);
            this.control.setValue(this.control.value);
        }
    };
    MultiselectComponent.prototype.isGrandChild = function (option) {
        var father = this._options.find(function (o) { return o.value === option.parentId; });
        return option.parentId && father && father.parentId;
    };
    MultiselectComponent.prototype.toggleSelection = function (isAllSelected) {
        this.control.setValue(isAllSelected ? this.filteredOptions : []);
    };
    MultiselectComponent.prototype.hasValidator = function (validator) {
        if (!(this.control.validator instanceof Function)) {
            return false;
        }
        if (!this.control.validator('')) {
            return false;
        }
        return !!this.control.validator('').hasOwnProperty(validator);
    };
    MultiselectComponent.prototype.clearSelection = function () {
        this.control.setValue([]);
    };
    return MultiselectComponent;
}());
export { MultiselectComponent };
