import { ConfigurationModel } from './configuration.model';
import { ConfigurationActions, ConfigurationActionTypes } from './configuration.actions';

export interface ConfigurationState {
  loading: boolean;
  isLoaded: boolean;
  items: ConfigurationModel[];
}

export const initialState: ConfigurationState = {
  loading: false,
  isLoaded: false,
  items: null,
};

export function reducer(state = initialState, action: ConfigurationActions): ConfigurationState {
  let loading: boolean;
  let items: ConfigurationModel[];

  switch (action.type) {
    case ConfigurationActionTypes.GetConfiguration:
      loading = true;
      return { ...state, loading };

    case ConfigurationActionTypes.GetConfigurationSuccess:
      loading = false;
      items = action.payload;
      return { ...state, loading, items, isLoaded: true };

    case ConfigurationActionTypes.GetConfigurationFail:
      loading = false;
      return { ...state, loading, isLoaded: true };

    default:
      return state;
  }
}
