import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../../../app.state';
import * as fromUser from './user.reducer';
import * as userActions from './user.actions';

// Extends the app state to include the navigation feature.
export interface State extends fromRoot.State {
  users: fromUser.UserState;
}

// Selector functions
export const getUserFeatureState = createFeatureSelector<fromUser.UserState>('users');

export const getUser = createSelector(
  getUserFeatureState,
  state => state.user,
);

export const getUserLoading = createSelector(
  getUserFeatureState,
  state => state.loading,
);

export const getUserLoaded = createSelector(
  getUserFeatureState,
  state => state.isLoaded,
);

export const getUserSelectedRole = createSelector(
  getUserFeatureState,
  state => state.selectedUserRole,
);

export const getUserSelectedCountry = createSelector(
  getUserSelectedRole,
  (state) => {
    return (state && state.countryCode) ? state.countryCode : null;
  },
);

export const getUserSelectedCulture = createSelector(
  getUserSelectedRole,
  (state) => {
    return (state && state.culture) ? state.culture : null;
  },
);

export const getUserSelectedWorkingScope = createSelector(
  getUserFeatureState,
  (state) => {
    if (state.selectedUserRole) {
      return state.selectedUserRole.workingScopes.filter(ws => ws.isSelected);
    }
  },
);

export const getUserSelectedRoleLabel = createSelector(
  getUserFeatureState,
  state => state.selectedUserRole.role,
);

export const getUserSelectedLanguage = createSelector(
  getUserFeatureState,
  (state) => {
    return state.language;
  },
);

export const getUserRoleCountries = createSelector(
  getUserFeatureState,
  (state) => {
    state.user.userRoles.map((role) => {
      return role.countryCode;
    });
  },
);

export const reducer = fromUser.reducer;
export const actions = userActions;
