import { Injectable } from '@angular/core';
import { UserState } from '../../entity/user/state/user.reducer';
import { select, Store } from '@ngrx/store';
import { filter, take, takeWhile } from 'rxjs/operators';
import { getUserFeatureState } from '../../entity/user/state';
import { GtmRef } from '@shared/core/gtm/gtm.ref';
import { combineLatest, Observable } from 'rxjs';
import { getPageSectionByUrl } from '@shared/core/gtm/gtm.helper';
import { AuthenticationState, AuthStatus } from '@shared/core/authentication/authentication.reducer';
import { UserRoles } from '@shared/entity/user/user.const';
import { GtmEventProperties, GtmPageViewProperties, GtmUserRoleProperty } from '@shared/core/gtm/gtm-properties.model';
import { getAuthFeatureState } from '@shared/core/authentication';
import { StorageService } from '@shared/core/storage/storage.service';
import { StorageConst } from '@shared/core/storage/storage.const';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GtmService {

  private userData$: Observable<[UserState, AuthenticationState] | [any]>;

  constructor(private store: Store<UserState | AuthenticationState>,
              private storageService: StorageService,
              private gtm: GtmRef,
  ) {
    this.userData$ =  combineLatest([
      this.store.pipe(select(getUserFeatureState)),
      this.store.pipe(select(getAuthFeatureState)),
    ]).pipe(
      filter(([userState, authState]) => {
        return userState &&
            authState.status === AuthStatus.IN &&
            userState.isLoaded &&
            userState.user != null &&
            userState.selectedUserRole != null;
      }),
      take(1),
    );
  }

  public sendPageView(url: string): void {
    let isPageViewFired = false;
    this.userData$.pipe(takeWhile(() => !isPageViewFired)).subscribe(([userState]) => {
      const currentCulture = this.storageService.get(StorageConst.translation.keys.lang) || userState.selectedUserRole.culture;
      const gtmProperties: GtmPageViewProperties = {
        event: 'dataLayerLoad',
        country: environment.production ? userState.selectedUserRole.countryCode : 'TEST',
        pageLanguage: currentCulture,
        userRole: userState.selectedUserRole.role as UserRoles,
        userCompany: userState.selectedUserRole.company ? userState.selectedUserRole.company.name : null,
        plateforme: 'manager',
        pageSection: getPageSectionByUrl(url),
        userId: userState.user.userId,
      };
      this.gtm.nativeDatalayer.push(gtmProperties);
      isPageViewFired = true;
    });
  }

  public sendEvent(event: GtmEventProperties,
                   label?: string | GtmUserRoleProperty,
                   details?: string | GtmUserRoleProperty,
                   isFromUserRole?: boolean): void {
    let isEventFired = false;
    this.userData$.pipe(takeWhile(() => !isEventFired)).subscribe(([userState]) => {
      // Set eventLabel with existing user role data or take hardcoded label or take default
      if (Object.prototype.hasOwnProperty.call(event, 'eventLabel')) {
        event.eventLabel = label in GtmUserRoleProperty && isFromUserRole ?
          userState.selectedUserRole[label] :
          label || event.eventLabel;
      }
      // Set eventDetail with existing user role data or take hardcoded details or take default
      if (Object.prototype.hasOwnProperty.call(event, 'eventDetails')) {
        event.eventDetails = details in GtmUserRoleProperty && isFromUserRole ?
          userState.selectedUserRole[details] :
          details || event.eventDetails;
      }
      this.checkEventRequiredProperty(event);
      this.gtm.nativeDatalayer.push(event);
      isEventFired = true;
    });
  }

  public sendAnonymousEvent(event: GtmEventProperties, label?: string, details?: string): void {
    event.eventDetails = details ? details : event['eventDetails'];
    event.eventLabel = label ? label : event['eventLabel'];
    this.checkEventRequiredProperty(event);
    this.gtm.nativeDatalayer.push(event);
  }

  private checkEventRequiredProperty(event: GtmEventProperties): void {
    for (const property in event) {
      if (property && Object.prototype.hasOwnProperty.call(event, property) && /<.*>/.test(event[property])) {
        console.error('GTM - Missing property in event : ', event.event, property, event[property]);
        return;
      }
    }
  }
}
