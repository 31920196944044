/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/ui/popin/popin.component.ngfactory";
import * as i2 from "../../shared/ui/popin/popin.component";
import * as i3 from "@ngrx/store";
import * as i4 from "../../shared/ui/popin/shared/popin.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "../../shared/ui/popin/directive/close-popin.directive";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "./password-expiration-popin.component";
import * as i13 from "../../shared/ui/panel/shared/panel.service";
var styles_PasswordExpirationPopinComponent = [];
var RenderType_PasswordExpirationPopinComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PasswordExpirationPopinComponent, data: {} });
export { RenderType_PasswordExpirationPopinComponent as RenderType_PasswordExpirationPopinComponent };
export function View_PasswordExpirationPopinComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 21, "mgr-popin", [["codename", "passwordExpirationPopin"], ["size", "sm"]], null, null, null, i1.View_PopinComponent_0, i1.RenderType_PopinComponent)), i0.ɵdid(1, 245760, null, 0, i2.PopinComponent, [i3.Store, i4.PopinService], { codename: [0, "codename"], size: [1, "size"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 3, "div", [["class", "popin-header text-center no-border"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "h2", [["class", "popin-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(6, 0, null, 0, 4, "div", [["class", "popin-content text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵpod(9, { dataLength: 0 }), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(11, 0, null, 0, 10, "div", [["class", "popin-footer text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 4, "button", [["mat-button", ""], ["mgrClosePopin", "passwordExpirationPopin"], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 13).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i0.ɵdid(13, 81920, null, 0, i7.ClosePopinDirective, [i3.Store, i4.PopinService], { mgrClosePopin: [0, "mgrClosePopin"] }, null), i0.ɵdid(14, 180224, null, 0, i8.MatButton, [i0.ElementRef, i9.Platform, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(15, 0, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(17, 0, null, null, 4, "button", [["color", "primary"], ["mat-raised-button", ""], ["mgrClosePopin", "passwordExpirationPopin"], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 18).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.goToChangePassword() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i0.ɵdid(18, 81920, null, 0, i7.ClosePopinDirective, [i3.Store, i4.PopinService], { mgrClosePopin: [0, "mgrClosePopin"] }, null), i0.ɵdid(19, 180224, null, 0, i8.MatButton, [i0.ElementRef, i9.Platform, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵted(20, 0, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "passwordExpirationPopin"; var currVal_1 = "sm"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_6 = "passwordExpirationPopin"; _ck(_v, 13, 0, currVal_6); var currVal_10 = "passwordExpirationPopin"; _ck(_v, 18, 0, currVal_10); var currVal_11 = "primary"; _ck(_v, 19, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("global-password_expiration_popin-title")); _ck(_v, 4, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 10).transform("global-password_expiration_popin-text", _ck(_v, 9, 0, _co.daysBeforePasswordExpires))); _ck(_v, 8, 0, currVal_3); var currVal_4 = (i0.ɵnov(_v, 14).disabled || null); var currVal_5 = (i0.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_4, currVal_5); var currVal_7 = i0.ɵunv(_v, 15, 0, i0.ɵnov(_v, 16).transform("global-password_expiration_popin-close")); _ck(_v, 15, 0, currVal_7); var currVal_8 = (i0.ɵnov(_v, 19).disabled || null); var currVal_9 = (i0.ɵnov(_v, 19)._animationMode === "NoopAnimations"); _ck(_v, 17, 0, currVal_8, currVal_9); var currVal_12 = i0.ɵunv(_v, 20, 0, i0.ɵnov(_v, 21).transform("global-password_expiration_popin-change_now")); _ck(_v, 20, 0, currVal_12); }); }
export function View_PasswordExpirationPopinComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mgr-password-expiration-popin", [], null, null, null, View_PasswordExpirationPopinComponent_0, RenderType_PasswordExpirationPopinComponent)), i0.ɵdid(1, 49152, null, 0, i12.PasswordExpirationPopinComponent, [i13.PanelService], null, null)], null, null); }
var PasswordExpirationPopinComponentNgFactory = i0.ɵccf("mgr-password-expiration-popin", i12.PasswordExpirationPopinComponent, View_PasswordExpirationPopinComponent_Host_0, { daysBeforePasswordExpires: "daysBeforePasswordExpires" }, {}, []);
export { PasswordExpirationPopinComponentNgFactory as PasswordExpirationPopinComponentNgFactory };
