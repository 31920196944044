import { Injectable } from '@angular/core';
import { TransferHttpService } from '@shared/core/transfer-http';
import { Observable } from 'rxjs';
import { ContractsConst } from '../consts/contracts.const';
import { ContractLight } from '../models/contract-light.model';
import { ContractInformation } from '../models/contract-information.model';
import { MileageLight } from '../models/mileage-light.model';
import { MaintenanceHistory } from '../models/maintenance-history.model';
import { Vehicle } from '../models/vehicle.model';
import { Company } from '@shared/models/company.model';
import { UserProfile } from '@shared/models/user-profile.model';
import { OrdersConst } from '@my-fleet/shared/consts/orders.const';
import { ContractStatusContext } from '@my-fleet/shared/models/contract-status-context';
import { ContractAskPermission, ContractOrderDamageRepair, ContractOrderMaintenance } from '../models/contract-assistance.model';
import { ContractFuelCard } from '../../shared/models/contract-fuel-card.model';

@Injectable()
export class ContractsService {

  constructor(private transferHttpService: TransferHttpService) {
  }

  public getContractsList(): Observable<ContractLight[]> {
    const url = `${ContractsConst.api.baseUrl +
      ContractsConst.api.modules.contract.url +
      ContractsConst.api.modules.contract.endpoints.getContractList}`;
    return this.transferHttpService.get<ContractLight[]>(url);
  }

  public getDriverInformationByContext(id: string, context: ContractStatusContext): Observable<UserProfile> {
    const apiConst = context === ContractStatusContext.contract ? ContractsConst : OrdersConst;
    const endPoint = apiConst.api.modules.user.endpoints.getDriver;
    const url = `${apiConst.api.baseUrl + apiConst.api.modules.user.url + endPoint}/${id}`;
    return this.transferHttpService.get<UserProfile>(url);
  }

  public getContractDetails(contractId: string): Observable<ContractInformation> {
    const url = `${ContractsConst.api.baseUrl +
      ContractsConst.api.modules.contract.url +
      ContractsConst.api.modules.contract.endpoints.getContractDetail}/${contractId}`;
    return this.transferHttpService.get<ContractInformation>(url);
  }

  public getMileageHistory(contractId: string): Observable<MileageLight[]> {
    const url = `${ContractsConst.api.baseUrl +
      ContractsConst.api.modules.contract.url +
      ContractsConst.api.modules.contract.endpoints.getMileageHistory}/${contractId}`;
    return this.transferHttpService.get<MileageLight[]>(url);
  }

  public getCompanyByContext(id: string, context: ContractStatusContext): Observable<Company> {
    const apiConst = context === ContractStatusContext.contract ? ContractsConst : OrdersConst;
    const endPoint = apiConst.api.modules[context].endpoints.getCompany;
    const url = `${apiConst.api.baseUrl + apiConst.api.modules[context].url + endPoint}/${id}`;
    return this.transferHttpService.get<Company>(url);
  }

  public updateMileage(contractId: string, body: { mileage: number, date: string }): Observable<any> {
    const url = `${ContractsConst.api.baseUrl +
      ContractsConst.api.modules.contract.url +
      ContractsConst.api.modules.contract.endpoints.updateMileage}/${contractId}`;
    return this.transferHttpService.put<any>(url, body);
  }

  public getMaintenanceHistory(contractId: string): Observable<MaintenanceHistory[]> {
    const url = `${ContractsConst.api.baseUrl +
      ContractsConst.api.modules.contract.url +
      ContractsConst.api.modules.contract.endpoints.getMaintenanceHistory}/${contractId}`;
    return this.transferHttpService.get<MaintenanceHistory[]>(url);
  }

  public downloadMaintenanceHistory(contractId: string): Observable<Blob> {
    const url = `${ContractsConst.api.baseUrl +
      ContractsConst.api.modules.contract.url +
      ContractsConst.api.modules.contract.endpoints.downloadMaintenanceHistory}/${contractId}/en-US`;
    return this.transferHttpService.getBlob(url);
  }

  public getVehicleInformation(contractId: string): Observable<Vehicle> {
    const url = `${ContractsConst.api.baseUrl +
      ContractsConst.api.modules.contract.url +
      ContractsConst.api.modules.contract.endpoints.getVehicleInformation}/${contractId}`;
    return this.transferHttpService.get<Vehicle>(url);
  }

  public sendNotificationToContracts(notificationRequestFormdata: FormData) {
    const url = `${ContractsConst.api.baseUrl
      + ContractsConst.api.modules.notifications.url
      + ContractsConst.api.modules.notifications.endpoints.sendNotification}`;
    return this.transferHttpService.postFile(url, notificationRequestFormdata);
  }

  public sendAskPermissionForm(askPermission: ContractAskPermission) {
    const contractsModule = ContractsConst.api.modules.assistance;
    const url = ContractsConst.api.baseUrl + contractsModule.url + contractsModule.endpoints.sendAskPermissionForm;
    return this.transferHttpService.post(url, askPermission);
  }

  public sendOrderDamageRepairRequest(damageRepair: ContractOrderDamageRepair) {
    const contractsModule = ContractsConst.api.modules.assistance;
    const url = ContractsConst.api.baseUrl + contractsModule.url + contractsModule.endpoints.sendOrderDamageRepairRequest;
    return this.transferHttpService.post(url, damageRepair);
  }

  public sendOrderMaintenanceRequest(orderMaintenance: ContractOrderMaintenance) {
    const contractsModule = ContractsConst.api.modules.assistance;
    const url = ContractsConst.api.baseUrl + contractsModule.url + contractsModule.endpoints.sendOrderMaintenanceRequest;
    return this.transferHttpService.post(url, orderMaintenance);
  }

  public getFuelCardList(contractId: string): Observable<ContractFuelCard[]> {
    const url = `${ContractsConst.api.baseUrl +
      ContractsConst.api.modules.contract.url +
      ContractsConst.api.modules.contract.endpoints.getFuelCardHistory}/${contractId}`;
    return this.transferHttpService.get<ContractFuelCard[]>(url);
  }

  public exportFuelReport(contractId: string): Observable<Blob> {
    const url = `${ContractsConst.api.baseUrl +
      ContractsConst.api.modules.contract.url +
      ContractsConst.api.modules.contract.endpoints.exportFuelReport}/${contractId}`;
    return this.transferHttpService.getBlob(url);
  }

  public removeColumnFromContractsListTable(column) {
    if (ContractsConst.displayedColumns.contractList.indexOf(column) > -1) {
      ContractsConst.displayedColumns.contractList.splice(ContractsConst.displayedColumns.contractList.indexOf(column), 1);
    }
  }

  public exportContract(): Observable<Blob> {
    const url = `${ContractsConst.api.baseUrl +
      ContractsConst.api.modules.contract.url
      + ContractsConst.api.modules.contract.endpoints.exportContract}`;
    return this.transferHttpService.getBlob(url);
  }

  public addColumnToContractsListTable(column) {
    if (ContractsConst.displayedColumns.contractList.indexOf(column) === -1) {
      ContractsConst.displayedColumns.contractList.splice(2, 0, column);
    }
  }
}
