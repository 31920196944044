import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { StorageService } from '../storage/storage.service';
import { StorageConst } from '../storage/storage.const';
import { DateAdapter } from '@angular/material';
import { CONFIGURATION } from 'country-configuration/country-configuration';
import { UpdateMyPreferences } from '@app/shared/entity/user/state/user.actions';
import { EditUserPreferencesRequest } from '@app/user-management/user-list/shared/models/user-edit-preferences.model';
import { UserState } from '@app/shared/entity/user/state/user.reducer';
import { take } from 'rxjs/operators';
import { getUserSelectedCulture } from '@app/shared/entity/user/state';
import { Title } from '@angular/platform-browser';
import { PosState } from '@app/administration/shared/state/pos-state/pos.reducer';
import { GetPosSuccess } from '@app/administration/shared/state/pos-state/pos.actions';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {

  public isTranslationLoading$ = new BehaviorSubject(true);
  public isTranslationLoaded$ = new BehaviorSubject(false);

  constructor(
    private userStore: Store<UserState | PosState>,
    public translateService: TranslateService,
    private dateAdapter: DateAdapter<any>,
    private storage: StorageService,
    private titleService: Title) {
  }

  get language(): string {
    return this.translateService.currentLang;
  }

  set language(lang: string) {
    const newLang = lang || CONFIGURATION.DefaultAppCulture;
    this.dateAdapter.setLocale(newLang.split('-')[0]);
    this.storage.set(StorageConst.translation.keys.lang, newLang);
    this.translateService.use(newLang).subscribe(() => {
      this.isTranslationLoading$.next(false);
      this.isTranslationLoaded$.next(true);
    });
  }

  public setTranslations(): void {
    if (this.storage.get(StorageConst.selectedCountry)) {
      this.isTranslationLoading$.next(true);
      this.titleService.setTitle(CONFIGURATION.AppCountries[this.storage.get(StorageConst.selectedCountry).toUpperCase()].seo.title);

      const storedLanguage = this.storage.get(StorageConst.translation.keys.lang);
      const isTranslationsExists = !!this.translateService.getLangs().length;
      const countryCultures = CONFIGURATION.getCountry().cultures;
      const isStoredLanguageAvailable = !!countryCultures.find(l => l === storedLanguage);
      const currentCulture = isStoredLanguageAvailable ?
        this.storage.get(StorageConst.translation.keys.lang) :
        CONFIGURATION.getCountry().defaultCulture;

      this.userStore.pipe(select(getUserSelectedCulture), take(1))
        .subscribe((selectedUserCulture) => {
          if (selectedUserCulture && currentCulture && (selectedUserCulture !== currentCulture)) {
            const preferences: EditUserPreferencesRequest = { culture: currentCulture };
            this.userStore.dispatch(new UpdateMyPreferences(preferences, false));
          }
        });
      this.isTranslationLoaded$
      .subscribe((isLoaded) => {
        if (isLoaded) {
          this.userStore.dispatch(new GetPosSuccess({ data: [],
            currentLanguage: this.language, responseFromAPI: false }));
        }
      });
      // Clear already set languages
      countryCultures.forEach((culture) => {
        this.translateService.resetLang(culture);
      });
      this.translateService.langs = [];
      this.translateService.addLangs(countryCultures);
      // Reload the current culture Login / logout
      if (isTranslationsExists) {
        this.translateService.getTranslation(currentCulture).subscribe((translations) => {
          this.translateService.setTranslation(currentCulture, translations, true);
        });
      }
      // Set a new language
      this.language = currentCulture;
    }
  }

  public translate(key: string | string[], value?: string): Observable<string | any> {
    return this.translateService.get(key, { value });
  }
}
