import { Component, Input } from '@angular/core';

@Component({
  selector: 'mgr-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
})
export class NavigationItemComponent {
  @Input() title?: string;
  @Input() icon: string;

  constructor() {
  }
}
