import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { StorageService } from '../storage/storage.service';
import { StorageConst } from '../storage/storage.const';
import { DateAdapter } from '@angular/material';
import { CONFIGURATION } from 'country-configuration/country-configuration';
import { UpdateMyPreferences } from '@app/shared/entity/user/state/user.actions';
import { take } from 'rxjs/operators';
import { getUserSelectedCulture } from '@app/shared/entity/user/state';
import { Title } from '@angular/platform-browser';
import { GetPosSuccess } from '@app/administration/shared/state/pos-state/pos.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/material/core";
import * as i4 from "../storage/storage.service";
import * as i5 from "@angular/platform-browser";
var TranslationService = /** @class */ (function () {
    function TranslationService(userStore, translateService, dateAdapter, storage, titleService) {
        this.userStore = userStore;
        this.translateService = translateService;
        this.dateAdapter = dateAdapter;
        this.storage = storage;
        this.titleService = titleService;
        this.isTranslationLoading$ = new BehaviorSubject(true);
        this.isTranslationLoaded$ = new BehaviorSubject(false);
    }
    Object.defineProperty(TranslationService.prototype, "language", {
        get: function () {
            return this.translateService.currentLang;
        },
        set: function (lang) {
            var _this = this;
            var newLang = lang || CONFIGURATION.DefaultAppCulture;
            this.dateAdapter.setLocale(newLang.split('-')[0]);
            this.storage.set(StorageConst.translation.keys.lang, newLang);
            this.translateService.use(newLang).subscribe(function () {
                _this.isTranslationLoading$.next(false);
                _this.isTranslationLoaded$.next(true);
            });
        },
        enumerable: true,
        configurable: true
    });
    TranslationService.prototype.setTranslations = function () {
        var _this = this;
        if (this.storage.get(StorageConst.selectedCountry)) {
            this.isTranslationLoading$.next(true);
            this.titleService.setTitle(CONFIGURATION.AppCountries[this.storage.get(StorageConst.selectedCountry).toUpperCase()].seo.title);
            var storedLanguage_1 = this.storage.get(StorageConst.translation.keys.lang);
            var isTranslationsExists = !!this.translateService.getLangs().length;
            var countryCultures = CONFIGURATION.getCountry().cultures;
            var isStoredLanguageAvailable = !!countryCultures.find(function (l) { return l === storedLanguage_1; });
            var currentCulture_1 = isStoredLanguageAvailable ?
                this.storage.get(StorageConst.translation.keys.lang) :
                CONFIGURATION.getCountry().defaultCulture;
            this.userStore.pipe(select(getUserSelectedCulture), take(1))
                .subscribe(function (selectedUserCulture) {
                if (selectedUserCulture && currentCulture_1 && (selectedUserCulture !== currentCulture_1)) {
                    var preferences = { culture: currentCulture_1 };
                    _this.userStore.dispatch(new UpdateMyPreferences(preferences, false));
                }
            });
            this.isTranslationLoaded$
                .subscribe(function (isLoaded) {
                if (isLoaded) {
                    _this.userStore.dispatch(new GetPosSuccess({ data: [],
                        currentLanguage: _this.language, responseFromAPI: false }));
                }
            });
            // Clear already set languages
            countryCultures.forEach(function (culture) {
                _this.translateService.resetLang(culture);
            });
            this.translateService.langs = [];
            this.translateService.addLangs(countryCultures);
            // Reload the current culture Login / logout
            if (isTranslationsExists) {
                this.translateService.getTranslation(currentCulture_1).subscribe(function (translations) {
                    _this.translateService.setTranslation(currentCulture_1, translations, true);
                });
            }
            // Set a new language
            this.language = currentCulture_1;
        }
    };
    TranslationService.prototype.translate = function (key, value) {
        return this.translateService.get(key, { value: value });
    };
    TranslationService.ngInjectableDef = i0.defineInjectable({ factory: function TranslationService_Factory() { return new TranslationService(i0.inject(i1.Store), i0.inject(i2.TranslateService), i0.inject(i3.DateAdapter), i0.inject(i4.StorageService), i0.inject(i5.Title)); }, token: TranslationService, providedIn: "root" });
    return TranslationService;
}());
export { TranslationService };
