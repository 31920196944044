import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading.component';
import { LoadingPlaceholderComponent } from './loading-placeholder/loading-placeholder.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    LoadingComponent,
    LoadingPlaceholderComponent,
  ],
  exports: [
    LoadingComponent,
    LoadingPlaceholderComponent,
  ],
})
export class LoadingModule {
}
