import { finalize, switchMap, catchError } from 'rxjs/operators';
import { UserService } from '@app/shared/entity/user/user.service';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';

export function passwordUserNameValidator(userService: UserService, token: string = null): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors> | null => {
    userService.isCheckingPasswordUsername.next(true);
    const validatePasswordRequest = token ?
    userService.validatePassword(control.value, token) :
    userService.validatePasswordAuthenticated(control.value);

    return validatePasswordRequest.pipe(
      switchMap((isPasswordValidated) => {
        return isPasswordValidated ? of(null) : of({ notContainUserName: true });
      }),
      catchError(() => of({ notContainUserName: true })),
      finalize(() => userService.isCheckingPasswordUsername.next(false)),
    );
  };
}
