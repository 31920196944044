import { UserManagementConst } from '@user-management/shared/user-management.const';
import { MgrValidators } from '@shared/ui/forms/validators/custom.validator';
import { StorageConst } from '@shared/core/storage/storage.const';
import { catchError, filter, takeWhile, tap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, throwError } from 'rxjs';
import { OnDestroy, OnInit } from '@angular/core';
import { StorageService } from '@shared/core/storage/storage.service';
import { UserHelper } from '@shared/entity/user/user.helper';
import { PanelService } from '@shared/ui/panel/shared/panel.service';
import { UserRoles } from '@shared/entity/user/user.const';
import { getUserFeatureState } from '@shared/entity/user/state';
import { UpdateMyUserProfile } from '@shared/entity/user/state/user.actions';
import { FeatureService } from '@shared/core/feature/feature.service';
import { FeatureConst } from '@shared/core/feature/feature.consts';
var EditMyProfilePanelComponent = /** @class */ (function () {
    function EditMyProfilePanelComponent(userStore, storage, featureService, panelService) {
        this.userStore = userStore;
        this.storage = storage;
        this.featureService = featureService;
        this.panelService = panelService;
        this.currentCountry = null;
        this.USER_ROLES = UserRoles;
        this.componentActive = true;
    }
    EditMyProfilePanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        // TODO OD update this part of code to use the stored SelectedUserRole
        this.currentCountry = this.storage.get(StorageConst.selectedCountry).toLowerCase();
        this.initForm();
        this.userStore.pipe(select(getUserFeatureState), filter(function (state) { return state.user !== null; })).subscribe(function (state) {
            var user = state.user;
            if (user) {
                _this.userProfile = user;
                _this.fillForm();
            }
        });
    };
    EditMyProfilePanelComponent.prototype.initForm = function () {
        var _this = this;
        this.form = new FormGroup({
            name: new FormControl('', [MgrValidators.required]),
            email: new FormControl('', [MgrValidators.required, MgrValidators.customEmail]),
            companyName: new FormControl('', MgrValidators.required),
            companyLocation: new FormControl('', MgrValidators.required),
            address: new FormControl('', []),
            postalcode: new FormControl('', []),
            city: new FormControl('', []),
            phone: new FormControl('', []),
            companyPhone: new FormControl('', []),
        });
        if (this.form) {
            this.form.reset();
            combineLatest([
                this.featureService.isFeatureActivated(FeatureConst.features.canEditOwnNameActivated),
                this.featureService.isFeatureActivated(FeatureConst.features.canEditOwnProfileActivated),
            ]).pipe(takeWhile(function () { return _this.componentActive; }), catchError(function (error) {
                return throwError(error);
            }), tap(function (_a) {
                var canEditOwnName = _a[0], canEditOwnProfile = _a[1];
                _this.canEditOwnName = canEditOwnName;
                _this.canEditOwnProfile = canEditOwnProfile;
                _this.form.get('email').disable();
                if (!_this.canEditOwnName) {
                    _this.form.get('name').disable();
                }
                if (!_this.canEditOwnProfile) {
                    _this.form.get('name').disable();
                    _this.form.get('companyName').disable();
                    _this.form.get('companyLocation').disable();
                    _this.form.get('address').disable();
                    _this.form.get('postalcode').disable();
                    _this.form.get('city').disable();
                    _this.form.get('phone').disable();
                    _this.form.get('companyPhone').disable();
                }
                _this.userProfile = null;
            })).subscribe();
        }
    };
    EditMyProfilePanelComponent.prototype.fillForm = function () {
        if (this.userProfile) {
            this.form.get('name').setValue(this.userProfile.name);
            this.form.get('email').setValue(this.userProfile.email);
            var userCompany = UserHelper.getUserCompany(this.userProfile, this.currentCountry);
            if (userCompany) {
                this.form.get('companyName').setValue(userCompany.name);
                this.form.get('companyLocation').setValue(userCompany.address);
                this.form.get('companyPhone').setValue(userCompany.phone);
            }
            if (this.userProfile.address) {
                this.form.get('address').setValue(this.userProfile.address);
            }
            if (this.userProfile.postalCode) {
                this.form.get('postalcode').setValue(this.userProfile.postalCode);
            }
            if (this.userProfile.city) {
                this.form.get('city').setValue(this.userProfile.city);
            }
            if (this.userProfile.phone) {
                this.form.get('phone').setValue(this.userProfile.phone);
            }
        }
    };
    EditMyProfilePanelComponent.prototype.cancelEdit = function () {
        this.panelService.close(UserManagementConst.windowCodename.editMyProfilePanel);
    };
    EditMyProfilePanelComponent.prototype.updateUserProfile = function () {
        if (this.form.valid) {
            var userRequest = {
                name: this.form.get('name').value,
                address: this.form.get('address').value,
                postalCode: this.form.get('postalcode').value,
                city: this.form.get('city').value,
                phone: this.form.get('phone').value,
            };
            this.userStore.dispatch(new UpdateMyUserProfile(userRequest));
            this.panelService.close(UserManagementConst.windowCodename.editMyProfilePanel);
        }
    };
    EditMyProfilePanelComponent.prototype.ngOnDestroy = function () {
        this.componentActive = false;
    };
    return EditMyProfilePanelComponent;
}());
export { EditMyProfilePanelComponent };
