import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
var mapScript = document.createElement('script');
mapScript.src = "https://maps.googleapis.com/maps/api/js?key=" + environment.gmapApiKey + "&libraries=places";
document.body.appendChild(mapScript);
var gtmNoScript = function (gtmID) {
    return "<iframe src='https://www.googletagmanager.com/ns.html?id=" + gtmID + "'\n          height='0'\n          width=0'\n          style='display:none;visibility:hidden'>\n  </iframe>";
};
var ɵ0 = gtmNoScript;
var noscript = document.createElement('noscript');
noscript.innerHTML = gtmNoScript(environment.gtmKey);
document.body.appendChild(noscript);
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch(function (err) { return console.error(err); });
export { ɵ0 };
