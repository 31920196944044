import { PanelActions, PanelActionTypes } from './panel.actions';

export interface PanelState {
  showPanels: string[];
}

export const initialState: PanelState = {
  showPanels: [],
};

export function reducer(state = initialState, action: PanelActions): PanelState {
  switch (action.type) {

    case PanelActionTypes.OpenPanel:
      return {
        ...state,
        showPanels: [...state.showPanels, action.payload],
      };

    case PanelActionTypes.ClosePanel:
      return {
        ...state,
        showPanels: state.showPanels.filter((panel) => {
          return panel !== action.payload;
        }),
      };

    case PanelActionTypes.ClearPanels:
      return {
        ...state,
        showPanels: [],
      };

    default:
      return state;
  }
}
