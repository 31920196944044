import * as tslib_1 from "tslib";
import { ConfigurationService } from './configuration.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ConfigurationActionTypes } from './configuration.actions';
import { Observable, of } from 'rxjs';
var ConfigurationEffects = /** @class */ (function () {
    function ConfigurationEffects(userService, actions$) {
        var _this = this;
        this.userService = userService;
        this.actions$ = actions$;
        this.getConfiguration$ = this.actions$.pipe(ofType(ConfigurationActionTypes.GetConfiguration), mergeMap(function () {
            return _this.userService.getConfiguration().pipe(map(function (data) { return ({ type: ConfigurationActionTypes.GetConfigurationSuccess, payload: data }); }), catchError(function () { return of({ type: ConfigurationActionTypes.GetConfigurationFail }); }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ConfigurationEffects.prototype, "getConfiguration$", void 0);
    return ConfigurationEffects;
}());
export { ConfigurationEffects };
