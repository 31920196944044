export enum CurrencyCodes {
  INR = 'INR',
  RON = 'RON',
  TRY = 'TRY',
  EUR = 'EUR',
  BGN = 'BGN',
  RSD = 'RSD',
  CHF = 'CHF',
  PLN = 'PLN', // Poland
  UAH = 'UAH', // Ukraine
  HUF = 'HUF', // Hungary
  DZD = 'DZD', // Algeria
  MAD = 'MAD', // Morocco
  HRK = 'HRK', // Croatia
  MYR = 'MYR', // Malaysia
}

export enum CurrencySymbols {
  INR = '₹',
  RON = 'RON',
  TRY = '₺',
  EUR = '€',
  BGN = 'лв',
  RSD = 'дин',
  CHF = 'CHF',
  UAH = '₴',
  HUF = 'HUF',
  DZD = 'DZD',
  PLN = 'zł',
  MAD = 'MAD',
  HRK = 'kn',
  MYR = 'RM',
}

export enum CurrencyPosition {
  BEFORE,
  AFTER,
}
