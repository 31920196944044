/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-navigation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../shared/ui/directives/click-outside/click-outside.directive";
import * as i4 from "../../../shared/core/helpers/document.ref";
import * as i5 from "@angular/common";
import * as i6 from "../../../shared/ui/popin/directive/open-popin.directive";
import * as i7 from "@ngrx/store";
import * as i8 from "../../../shared/ui/popin/shared/popin.service";
import * as i9 from "../../../start/password-expiration-popin/password-expiration-popin.component.ngfactory";
import * as i10 from "../../../start/password-expiration-popin/password-expiration-popin.component";
import * as i11 from "../../../shared/ui/panel/shared/panel.service";
import * as i12 from "./user-navigation.component";
import * as i13 from "../../../shared/core/configuration/configuration.service";
import * as i14 from "../../../shared/core/storage/storage.service";
var styles_UserNavigationComponent = [i0.styles];
var RenderType_UserNavigationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserNavigationComponent, data: { "animation": [{ type: 7, name: "Fade", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 0, name: "*", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: { type: 4, styles: null, timings: "500ms ease-out" }, options: null }, { type: 1, expr: ":leave", animation: { type: 4, styles: null, timings: "300ms ease-in" }, options: null }], options: {} }] } });
export { RenderType_UserNavigationComponent as RenderType_UserNavigationComponent };
function View_UserNavigationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "user-nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "user-nav-link"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "user-nav-icon icon-switch"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.driverUrl, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("global-top_header-continue_as_driver")); _ck(_v, 3, 0, currVal_1); }); }
function View_UserNavigationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "ul", [["class", "user-nav-container"], ["mgrClickOutside", ""]], [[24, "@Fade", 0]], [[null, "clickOut"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClickDocument($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("clickOut" === en)) {
        var pd_1 = (_co.notifyToggleEvent(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 81920, null, 0, i3.ClickOutsideDirective, [i1.ElementRef, i4.DocumentRef], { exclude: [0, "exclude"] }, { clickOut: "clickOut" }), (_l()(), i1.ɵeld(2, 0, null, null, 4, "li", [["class", "user-nav-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.notifyToggleEvent(false);
        var pd_0 = (_co.openEditMyProfilePanel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "user-nav-icon icon-user"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "user-nav-link"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 4, "li", [["class", "user-nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "a", [["class", "user-nav-link"], ["href", "javascript:Didomi.preferences.show('preferences')"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "user-nav-icon icon-compliance"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserNavigationComponent_3)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 5, "li", [["class", "user-nav-item"], ["mgrOpenPopin", "logoutPopin"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.notifyToggleEvent(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 81920, null, 0, i6.OpenPopinDirective, [i7.Store, i8.PopinService], { mgrOpenPopin: [0, "mgrOpenPopin"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 0, "i", [["class", "user-nav-icon icon-power"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "span", [["class", "user-nav-link"]], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = "#user-nav-button"; _ck(_v, 1, 0, currVal_1); var currVal_4 = _co.hasDriverRole; _ck(_v, 13, 0, currVal_4); var currVal_5 = "logoutPopin"; _ck(_v, 15, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("global-top_header-my_profile")); _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("global-top_header-manage_cookies_preferences")); _ck(_v, 10, 0, currVal_3); var currVal_6 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("global-top_header-logout")); _ck(_v, 18, 0, currVal_6); }); }
function View_UserNavigationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "user-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mgr-password-expiration-popin", [], null, null, null, i9.View_PasswordExpirationPopinComponent_0, i9.RenderType_PasswordExpirationPopinComponent)), i1.ɵdid(2, 49152, null, 0, i10.PasswordExpirationPopinComponent, [i11.PanelService], { daysBeforePasswordExpires: [0, "daysBeforePasswordExpires"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "icon-user user-nav-name"], ["id", "user-nav-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.notifyToggleEvent(!_co.showUserNavigation) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserNavigationComponent_2)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.daysBeforePasswordExpires; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.showUserNavigation; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_UserNavigationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserNavigationComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_UserNavigationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mgr-user-navigation", [], null, null, null, View_UserNavigationComponent_0, RenderType_UserNavigationComponent)), i1.ɵdid(1, 114688, null, 0, i12.UserNavigationComponent, [i7.Store, i11.PanelService, i13.ConfigurationService, i8.PopinService, i14.StorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserNavigationComponentNgFactory = i1.ɵccf("mgr-user-navigation", i12.UserNavigationComponent, View_UserNavigationComponent_Host_0, {}, { toggle: "toggle" }, []);
export { UserNavigationComponentNgFactory as UserNavigationComponentNgFactory };
