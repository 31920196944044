import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Notification, NotificationCategory } from '@notifications/shared/models/notification.model';
import { DocumentsService } from '@documents/shared/services/documents.service';
import { CONFIGURATION } from 'country-configuration/country-configuration';
import { PanelService } from '@app/shared/ui/panel/shared/panel.service';
import { UserManagementConst } from '@app/user-management/shared/user-management.const';
import { Store } from '@ngrx/store';
import { NotificationsState } from '@app/notifications/shared/state/notifications.reducer';
import * as NotificationsActions from '@notifications/shared/state/notifications.actions';

@Component({
  selector: 'mgr-user-notifications-panel',
  templateUrl: './user-notifications-panel.component.html',
  styleUrls: ['./user-notifications-panel.component.scss'],
})
export class UserNotificationsPanelComponent implements OnInit, OnDestroy {
  @Input() notifications: Notification[];

  public dateFormat: string;
  public readonly NotificationCategory = NotificationCategory;

  constructor(
    private documentsService: DocumentsService,
    private panelService: PanelService,
    private store: Store<NotificationsState>) {
  }

  ngOnInit() {
    this.dateFormat = CONFIGURATION.getCountry().defaultDateFormat;
  }

  ngOnDestroy() {
    this.store.dispatch(new NotificationsActions.GetManagerAlerts());
    this.panelService.close(UserManagementConst.windowCodename.userAlertsPanel);
  }

  getDocument(documentId) {
    this.documentsService.getDownloadUrl(documentId).subscribe();
  }
}
