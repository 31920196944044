import { Action } from '@ngrx/store';
import { Order } from '../../models/order.model';

export enum OrderActionTypes {
  GetOrderList = '[OrderList] Get',
  GetOrderListSuccess = '[OrderList] Get success',
  GetOrderListFail = '[OrderList] Get fail',
  GetCurrentOrder = '[OrderDetail] Get the current contract details',
  GetCurrentOrderSuccess = '[OrderDetail] Current Order Success',
  GetCurrentOrderFail = '[OrderDetail] Current Order Fail',
}

export class GetOrderList implements Action {
  readonly type = OrderActionTypes.GetOrderList;
}

export class GetOrderListSuccess implements Action {
  readonly type = OrderActionTypes.GetOrderListSuccess;

  constructor(public payload: Order[]) { }
}

export class GetOrderListFail implements Action {
  readonly type = OrderActionTypes.GetOrderListFail;
}

export class GetCurrentOrder implements Action {
  readonly type = OrderActionTypes.GetCurrentOrder;

  constructor(public payload: string) { }
}

export class GetCurrentOrderSuccess implements Action {
  readonly type = OrderActionTypes.GetCurrentOrderSuccess;

  constructor(public payload: Order) { }
}

export class GetCurrentOrderFail implements Action {
  readonly type = OrderActionTypes.GetCurrentOrderFail;
}

export type OrderActions =
  GetOrderList |
  GetOrderListSuccess |
  GetOrderListFail |
  GetCurrentOrder |
  GetCurrentOrderFail |
  GetCurrentOrderSuccess;
