import { Company } from '@shared/models/company.model';
import { InputChoice } from '@shared/models/input-choice.model';
import { CompanyLight } from './company-light.model';

export class UserRole {
  id: string;
  company: Company;
  countryCode: string;
  culture: string;
  role: string;
  workingScopes: UserWorkingScope[];
  contracts: UserContract[];
  disabled: boolean;
  backOfficeId: string;
  notificationByEmail?: boolean;
  notificationByPush?: boolean;
}

export class UserContract {
  id: string;
  plate: string;
  status: string;
}

export class UserWorkingScope {
  companyId: string;
  companyName: string;
  companyReference: string;
  parentId: string;
  isSelected: boolean;

  static toInputChoice(workingScopes: UserWorkingScope[]): InputChoice[] {
    return workingScopes.map((workingScope) => {
      return {
        label: `${workingScope.companyName} (${workingScope.companyReference})`,
        value: workingScope.companyId,
        name: workingScope.companyName,
        reference: workingScope.companyReference,
        parentId: workingScope.parentId,
      };
    });
  }

  static toCompanyLight(workingScopes: UserWorkingScope[]): CompanyLight[] {
    return workingScopes.map((workingScope) => {
      return {
        id: workingScope.companyId,
        reference: workingScope.companyReference,
        parentId: workingScope.parentId,
        name: workingScope.companyName,
      };
    });
  }
}
