import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

export function validatePhoneNumeric(control: AbstractControl): ValidationErrors | null {
  return (!control.value || /^[0-9,{}()+-_=\[\] ]+$/.test(control.value)) ? null : { phoneNumber: true };
}

@Directive({
  selector: '[phoneNumber]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PhoneNumberValidatorDirective, multi: true }],
})

export class PhoneNumberValidatorDirective implements Validator {
  validate: ValidatorFn = validatePhoneNumeric;
}
