import { Filter } from '@shared/ui/table/table-filters/shared/filter.interface';

export function userStateFilterFunction(option: Filter, row: any): boolean | null {
  return option.value.some(opt => opt === row.status);
}

export const UserStateFilter = {
  name: 'userState',
  label: 'global-filters-userstate',
  options: [
    {
      label: 'global-filters-active',
      value: 'Enabled',
    },
    {
      label: 'global-filters-inactive',
      value: 'Disabled',
    },
  ],
  predicateFn: userStateFilterFunction,
};
