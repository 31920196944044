import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SvgInlineService {

  private svgList = new Map<string, any>();

  constructor(private http: HttpClient) {}

  public getSvgByUrl(url: string): Observable<any> {
    if (this.isSvgExists(url)) {
      return of(this.svgList.get(url));
    }

    // @ts-ignore
    return this.http.get<string>(url, { responseType: 'text' });
  }

  public isSvgExists(url: string): boolean {
    return this.svgList.has(url);
  }
}
