import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromAuthentication from './authentication.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthenticationEffects } from './authentication.effects';
import { AuthenticationService } from './authentication.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('authentication', fromAuthentication.reducer),
    EffectsModule.forFeature([AuthenticationEffects]),
  ],
  providers: [
    AuthenticationService,
  ],
  declarations: [],
})
export class AuthenticationModule { }
