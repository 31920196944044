import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import { UserState } from '../../entity/user/state/user.reducer';
import { Observable } from 'rxjs';
import { getUserSelectedRole } from '../../entity/user/state';
import { APP_ROUTES } from '@app/app.routes';
import { UserRoles } from '@shared/entity/user/user.const';

@Injectable()
export class RoleGuardService implements CanActivate {

  constructor(
    private userStore: Store<UserState>,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.userStore.pipe(
      select(getUserSelectedRole),
      filter(r => r != null),
      map((currentUserRole) => {
        if (currentUserRole) {
          const isRoleMatch = route.data.roles.includes(currentUserRole.role);
          if (isRoleMatch) {
            return true;
          }
        }
        if (currentUserRole.role !== UserRoles.Supplier) {
          this.router.navigate([APP_ROUTES.NOT_FOUND]);
        } else {
          this.router.navigate([APP_ROUTES.ALDSAT]);
        }
        return false;
      }),
      take(1),
    );
  }
}
