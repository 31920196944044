import { environment } from '../../../../environments/environment';
import { DeliveryDateFilter } from '../filters/delivery-date.filter';
import { APP_ROUTES } from '@app/app.routes';
import { UserStatusFilter } from '../filters/user-status.filter';

export const OrdersConst = {
  api: {
    baseUrl: `${environment.apigatewayUrl}/apigateway/`,
    modules: {
      user: {
        url: 'usermanagement/api/',
        endpoints: {
          getDriver: 'UserManagement/GetUserByOrder/',
        },
      },
      order: {
        url: 'contract/api/',
        endpoints: {
          getCompany: 'OrderManagement/Company',
          getOrderList: 'OrderManagement/Orders',
          getOrderById: 'OrderManagement/Order',
          exportOrders: 'OrderManagement/Export',
        },
      },
    },
  },

  filters: {
    ordersList: [
      UserStatusFilter,
      DeliveryDateFilter,
    ],
  },

  breadcrumbs: {
    myFleet: {
      path: '',
      label: 'global-breadcrumbs-my_fleet_label',
    },
    orderList: {
      path: `/${APP_ROUTES.MY_FLEET}/${APP_ROUTES.ORDER_LIST}`,
      label: 'global-breadcrumbs-my_fleet_orders_label',
    },
    orderDetail: (id) => {
      return {
        path: `/${APP_ROUTES.MY_FLEET}/${APP_ROUTES.ORDER_LIST}/${id}/${APP_ROUTES.ORDER_DRIVER_INFO}`,
        label: id,
      };
    },
    orderInfo: {
      path: '',
      label: 'global-breadcrumbs-my_fleet_orders_details_label',
    },
    orderDriverInfo: {
      path: '',
      label: 'global-breadcrumbs-my_fleet_orders_driverinfo_label',
    },
  },

  windowCodename: {
    switchWorkingScopePanel: 'switchWorkingScopePanel',
  },

  displayedColumns: {
    ordersList: [
      'select',
      'user.name',
      'reference',
      'company.name',
      'vehicle.model',
      'vehicle.plate',
      'supplierName',
      'estimatedDeliveryDate',
      'actions' ,
    ],
  },
};
