import { NavigationActions, NavigationActionTypes } from './navigation.actions';
import { StorageConst } from '@shared/core/storage/storage.const';

export interface NavigationState {
  showNotifications: boolean;
  showUserNavigation: boolean;
  showFullNavigation: boolean;
  showLanguages: boolean;
}

export const initialState: NavigationState = {
  showNotifications: false,
  showUserNavigation: false,
  showFullNavigation: window.localStorage.getItem(StorageConst.fullNavigation) !== 'false',
  showLanguages: false,
};

export function reducer(state = initialState, action: NavigationActions): NavigationState {
  switch (action.type) {

    case NavigationActionTypes.ToggleNotifications:
      return {
        ...state,
        showNotifications: action.payload,
      };

    case NavigationActionTypes.ToggleUserNavigation:
      return {
        ...state,
        showUserNavigation: action.payload,
      };

    case NavigationActionTypes.ToggleFullNavigation:
      return {
        ...state,
        showFullNavigation: action.payload,
      };

    case NavigationActionTypes.ToggleLanguages:
      return {
        ...state,
        showLanguages: action.payload,
      };

    default:
      return state;
  }
}
