import { UserConst } from '@shared/entity/user/user.const';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TransferHttpService } from '@shared/core/transfer-http';
import { UserManagementConst } from '../user-management.const';
import { UserManagementResponse } from '@shared/core/transfer-http/user-management-response.model';
import { EditUserProfileRequest } from '../../user-list/shared/models/user-edit-profile.model';
import { UsersImportResponse } from '../user-import-response';
import { DocumentUser } from '@documents/shared/models/document-user.model';
import { UpdateUserProfileRequest } from '../../user-list/shared/models/user-update-profile.model';
import { EditUserPreferencesRequest } from '@app/user-management/user-list/shared/models/user-edit-preferences.model';

@Injectable()
export class UserManagementService {

  constructor(
    private transferHttpService: TransferHttpService) {
  }

  public getUsersByCompaniesAndRoles(companiesAndRoles): Observable<DocumentUser[]> {
    const url = UserManagementConst.api.baseUrl + UserManagementConst.api.modules.user.url +
      UserManagementConst.api.modules.user.endpoints.getUserList;
    return this.transferHttpService.post<DocumentUser[]>(url, companiesAndRoles);
  }

  public updateUserByContract(id: string, userProfile: UpdateUserProfileRequest): Observable<UserManagementResponse> {
    const endPoint = UserManagementConst.api.modules.user.endpoints.updateUserByContract;
    const url = `${UserManagementConst.api.baseUrl}${UserManagementConst.api.modules.user.url}${endPoint}${id}`;
    return this.transferHttpService.post<UserManagementResponse>(url, userProfile);
  }

  public updateUserByOrder(id: string, userProfile: UpdateUserProfileRequest): Observable<UserManagementResponse> {
    const endPoint = UserManagementConst.api.modules.user.endpoints.updateUserByOrder;
    const url = `${UserManagementConst.api.baseUrl}${UserManagementConst.api.modules.user.url}${endPoint}${id}`;
    return this.transferHttpService.post<UserManagementResponse>(url, userProfile);
  }

  public updateMyUserProfile(profile: EditUserProfileRequest) {
    const usersModule = UserConst.api.modules.users;
    const url = UserConst.api.baseUrl + usersModule.url + usersModule.endpoints.updateUser;
    return this.transferHttpService.post(url, profile);
  }

  public updateMyUserPreferences(preferences: EditUserPreferencesRequest) {
    const usersModule = UserConst.api.modules.users;
    const url = `${UserConst.api.baseUrl}${usersModule.url}${usersModule.endpoints.updatePreferences}`;
    return this.transferHttpService.post(url, preferences);
  }

  public uploadProspectsInBulk(companyId: string, file: FormData, ignoreErrors: boolean): Observable<UsersImportResponse> {
    let url = UserManagementConst.api.baseUrl + UserManagementConst.api.modules.user.url +
      UserManagementConst.api.modules.user.endpoints.uploadProspectInBulk;
    url += `${companyId}/${ignoreErrors}`;
    return this.transferHttpService.postFile<UsersImportResponse>(url, file);
  }
}
