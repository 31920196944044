import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserService } from './user.service';
import { UserEffects } from './state/user.effects';
import * as fromUser from './state/user.reducer';
import { UserManagementService } from '@user-management/shared/services/user-management.service';
import { LoadingModule } from '@shared/ui/loading/loading.module';
import { MaterialModule } from '@app/shared/ui/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { UserUtilsService } from '@user-management/shared/services/user-utils.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('users', fromUser.reducer),
    EffectsModule.forFeature([UserEffects]),
    LoadingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxMatSelectSearchModule,
  ],
  providers: [
    UserService,
    UserManagementService,
    UserUtilsService,
  ],
  declarations: [
  ],
  exports: [
  ],
})

export class UserModule { }
