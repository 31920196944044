import { createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../../app.state';
import * as fromPopin from './popin.reducer';

// Extends the app state to include the navigation feature.
export interface State extends fromRoot.State {
  popin: fromPopin.PopinState;
}

// Selector functions
export const getPopinFeatureState = createFeatureSelector<fromPopin.PopinState>('popin');
