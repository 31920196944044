import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { reducer } from './state/panel.reducer';
import { TranslateModule } from '@ngx-translate/core';
import { PanelComponent } from './panel.component';
import { CommonModule } from '@angular/common';
import { OpenPanelDirective } from './directive/open-panel.directive';
import { ClosePanelDirective } from './directive/close-panel.directive';
import { PanelService } from '@shared/ui/panel/shared/panel.service';
import { ClearPanelsDirective } from './directive/clear-panel.directive';

@NgModule({
  declarations: [
    PanelComponent,
    OpenPanelDirective,
    ClosePanelDirective,
    ClearPanelsDirective,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    StoreModule.forFeature('panel', reducer),
  ],
  exports: [
    PanelComponent,
    OpenPanelDirective,
    ClosePanelDirective,
    ClearPanelsDirective,
  ],
  providers: [
    PanelService,
  ],
})
export class PanelModule {
}
