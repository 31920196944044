import { NgModule } from '@angular/core';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';
import { DocumentRef } from '../../core/helpers/document.ref';
import { RestrictToDirective } from './restrict-to/restrict-to.directive';
import { AreFeaturesActivatedDirective } from '@shared/ui/directives/are-features-activated/are-features-activated.directive';
import { PasswordVisibilityDirective } from './password-visibility/password-visibility.directive';

@NgModule({
  declarations: [
    ClickOutsideDirective,
    RestrictToDirective,
    AreFeaturesActivatedDirective,
    PasswordVisibilityDirective,
  ],
  providers: [
    DocumentRef,
  ],
  exports: [
    ClickOutsideDirective,
    RestrictToDirective,
    AreFeaturesActivatedDirective,
    PasswordVisibilityDirective,
  ],
})
export class DirectivesModule {
}
