import { Link } from './link.model';

export interface Notification {
  id: string;
  date: string;
  title: string;
  text: string;
  link: Link;
  imageUrl: string;
  imagePath: string;
  companies: any[];
  roles: string[];
  users: any[];
  culture: string;
  documentId: string;
  category: NotificationCategory;
  read: boolean;
}

export enum NotificationCategory {
  shared_document = 'shared_document',
  mileage = 'mileage',
  custommessage = 'custommessage',
  glovie = 'glovie',
  carsection = 'carsection',
  help = 'help',
  helpticket = 'helpticket',
  manageprofile = 'manageprofile',
  onroadassistance = 'onroadassistance',
  upthewall = 'upthewall',
  vehicleupdates = 'vehicleupdates',
  wheelsfalloff = 'wheelsfalloff',
}
