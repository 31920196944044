<mgr-panel class='edit-profile' codename="editMyProfile" title="{{ 'global-edit_my_profile_panel-title' | translate }}">
  <ng-template>
    <form [formGroup]="form" (ngSubmit)="updateUserProfile()">
      <span class="mandatory-field-text">{{ 'global-generic_terms-mandatory_fields_text' | translate}}</span>

      <!-- Name -->
      <mat-form-field class="form-fullwidth">
        <input type="text" [placeholder]="'global-edit_my_profile_panel-name_label' | translate" formControlName="name" matInput
          required maxlength="60">
        <mat-hint *ngIf="form.controls.name.value">{{ form.controls.name.value.length }} / 60</mat-hint>
        <mat-error>{{ 'global-generic_terms-error_message_required' | translate }}</mat-error>
      </mat-form-field>

      <!-- Email -->
      <mat-form-field class="form-fullwidth">
        <input type="email" [placeholder]="'global-edit_my_profile_panel-email_label' | translate" formControlName="email" matInput>
      </mat-form-field>

      <div *restrictTo="[USER_ROLES.FleetManager]">
        <!-- Company name -->
        <mat-form-field class="form-fullwidth">
          <input type="text" [placeholder]="'global-edit_my_profile_panel-company_name_label' | translate" formControlName="companyName"
            matInput>
        </mat-form-field>

        <!-- Company location -->
        <mat-form-field class="form-fullwidth">
          <input type="text" [placeholder]="'global-edit_my_profile_panel-company_location_label' | translate"
            formControlName="companyLocation" matInput>
        </mat-form-field>

        <!-- Address -->
        <mat-form-field class="form-fullwidth">
          <input type="text" [placeholder]="'global-edit_my_profile_panel-address_label' | translate" formControlName="address" matInput
            required maxlength="90">
          <mat-hint *ngIf="form.controls.address.value">{{ form.controls.address.value.length }} / 90</mat-hint>
          <mat-error>{{ 'global-generic_terms-error_message_required' | translate }}</mat-error>
        </mat-form-field>

        <div class="row">
          <!-- Postal code -->
          <mat-form-field class="column">
            <input type="text" [placeholder]="'global-edit_my_profile_panel-postal_code_label' | translate" formControlName="postalcode"
              matInput required maxlength="10">
            <mat-hint *ngIf="form.controls.postalcode.value">{{ form.controls.postalcode.value.length }} / 10</mat-hint>
            <mat-error>{{ 'global-generic_terms-error_message_required' | translate }}</mat-error>
          </mat-form-field>

          <!-- City -->
          <mat-form-field class="column">
            <input type="text" [placeholder]="'global-edit_my_profile_panel-city_label' | translate" formControlName="city" matInput
              required maxlength="23">
            <mat-hint *ngIf="form.controls.city.value">{{ form.controls.city.value.length }} / 23</mat-hint>
            <mat-error>{{ 'global-generic_terms-error_message_required' | translate }}</mat-error>
          </mat-form-field>
        </div>

        <div class="row">
          <!-- Phone -->
          <mat-form-field class="column">
            <input [placeholder]="'global-edit_my_profile_panel-phone_label' | translate" formControlName="phone" matInput required
              maxlength="15">
            <mat-hint *ngIf="form.controls.phone.value">{{ form.controls.phone.value.length }} / 15</mat-hint>
            <mat-error>{{ 'global-generic_terms-error_message_required' | translate }}</mat-error>
          </mat-form-field>

          <!-- Company phone -->
          <mat-form-field class="column">
            <input [placeholder]="'global-edit_my_profile_panel-company_phone_label' | translate" formControlName="companyPhone" matInput
              required maxlength="15">
          </mat-form-field>
        </div>
      </div>

      <!-- Change password -->
      <div class="form-field align-left">
        <div class="field _has-value">
          <label class="field-label" for="change-pass">{{ 'global-change_my_password_panel-title' | translate}}</label>
          <input class="field-input" id="change-pass" name="change-pass" type="password" value="******" readonly>
          <button mat-button class="change-password-btn" type="button" mgrOpenPanel="changeUserPassword"><i class="icon-edit"></i></button>
        </div>
      </div>

      <div class="panel-footer align-center">
        <button mat-button (click)="cancelEdit()" type="button">{{'global-generic_terms-form_cancel'| translate}}</button>
        <button mat-raised-button [disabled]="!form.valid" type="submit" color="primary">{{'global-generic_terms-form_submit'| translate}}</button>
      </div>
    </form>
  </ng-template>
</mgr-panel>

<mgr-change-password></mgr-change-password>