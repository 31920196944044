import { Directive } from '@angular/core';
import { NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

export const PasswordRulesRegex = {
  minCharacters: /.{6,}/,
  minOneDigitCharacter: /(?=.*?[0-9])/,
  minOneSpecialCharacter: /(?=.*?[~!@#$%^&*_\-+=`|\(){}[\]\:;"'<>,.?\/])/,
  minOneUppercase: /(?=.*?[A-Z])/,
  minOneLowercase: /(?=.*?[a-z])/,
};

export function validateSecurePassword(control: AbstractControl): ValidationErrors | null {
  const validationErrors: ValidationErrors = {};
  for (const ruleName in PasswordRulesRegex) {
    if (!PasswordRulesRegex[ruleName].test(control.value)) {
      validationErrors[ruleName] = true;
    }
  }

  if (Object.values(validationErrors).some(v => v)) {
    return validationErrors;
  }

  return null;
}

@Directive({
  selector: '[securePassword]',
  providers: [{ provide: NG_VALIDATORS, useExisting: SecurePasswordDirective, multi: true }],
})
export class SecurePasswordDirective implements Validator {
  validate: ValidatorFn = validateSecurePassword;
}
