import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { GtmService } from '@shared/core/gtm/gtm.service';
import { GTM_EVENTS } from '@shared/core/gtm/gtm-events.const';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private readonly authenticationService: AuthenticationService,
              private gtmService: GtmService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 419) {
            return this.authenticationService.refreshToken()
              .pipe(
                switchMap((refreshResult) => {
                  if (refreshResult) {
                    const newRequest = request.clone();
                    return next.handle(newRequest);
                  }
                  return throwError(error);
                }),
              );
            // tslint:disable-next-line:no-else-after-return
          } else {
            this.gtmService.sendEvent(GTM_EVENTS.error500, error.message);
            return throwError(error);
          }
        }),
      );
  }
}
