import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducer } from './state/navigation.reducer';
import { TopNavigationComponent } from './top-navigation/top-navigation.component';
import { DirectivesModule } from '@shared/ui/directives/directives.module';
import { LeftNavigationComponent } from './left-navigation/left-navigation.component';
import { AppShellComponent } from './app-shell.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationItemComponent } from './left-navigation/navigation-item/navigation-item.component';
import { PopinModule } from '@shared/ui/popin/popin.module';
import { UserNavigationComponent } from './top-navigation/user-navigation/user-navigation.component';
import { MgrFormsModule } from '@shared/ui/forms/forms.module';
import { ErrorModule } from '@shared/core/error-handler/error.module';
import { PanelModule } from '@shared/ui/panel/panel.module';
import { MatBadgeModule } from '@angular/material/badge';
import { EditMyProfilePanelComponent } from './top-navigation/edit-my-profile/edit-my-profile-panel.component';
import { ChangePasswordComponent } from './top-navigation/edit-my-profile/change-password/change-password.component';
// tslint:disable-next-line:max-line-length
import { ChangePasswordFormComponent } from './top-navigation/edit-my-profile/change-password/change-password-form/change-password-form.component';
import { LoadingModule } from '@shared/ui/loading/loading.module';
import { EditWorkingScopeComponent } from './top-navigation/edit-working-scope/edit-working-scope.component';
import {
  MatInputModule,
  MatFormFieldModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatSelectModule,
  MatButtonModule,
} from '@angular/material';
import { EffectsModule } from '@ngrx/effects';
import { NavigationEffects } from './state/navigation.effects';
import { ReactiveFormsModule } from '@angular/forms';
import { LogoutPopinComponent } from '@start/logout-popin/logout-popin.component';
import { PipesModule } from '@shared/ui/pipes/pipes.module';
import { NotificationsService } from '@notifications/shared/services/notifications.service';
import { UserNotificationsPanelComponent } from '@shell/top-navigation/user-notifications-panel/user-notifications-panel.component';
import { EditPreferencesComponent } from './top-navigation/edit-preferences/edit-preferences.component';
import { GtmModule } from '@shared/core/gtm/gtm.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import * as fromNotifications from '@notifications/shared/state/notifications.reducer';
import { NotificationsEffects } from '@notifications/shared/state/notifications.effects';
import { AldCdnModule, AldMatButtonLoadingModule } from '@ald/ui';
import { PasswordExpirationPopinComponent } from '@app/start/password-expiration-popin/password-expiration-popin.component';
import { DidomiModule } from '@app/shared/entity/cookies/didomi.module';

@NgModule({
  declarations: [
    TopNavigationComponent,
    LeftNavigationComponent,
    AppShellComponent,
    NavigationItemComponent,
    UserNavigationComponent,
    EditWorkingScopeComponent,
    EditMyProfilePanelComponent,
    ChangePasswordComponent,
    ChangePasswordFormComponent,
    LogoutPopinComponent,
    UserNotificationsPanelComponent,
    EditPreferencesComponent,
    PasswordExpirationPopinComponent,
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    RouterModule,
    TranslateModule,
    StoreModule.forFeature('navigation', reducer),
    StoreModule.forFeature('notifications', fromNotifications.reducer),
    EffectsModule.forFeature([NavigationEffects]),
    EffectsModule.forFeature([NotificationsEffects]),
    PopinModule,
    MgrFormsModule,
    ErrorModule,
    PanelModule,
    MatBadgeModule,
    LoadingModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    PipesModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatSelectModule,
    GtmModule,
    NgxMatSelectSearchModule,
    MatButtonModule,
    AldMatButtonLoadingModule,
    AldCdnModule,
    DidomiModule,
  ],
  exports: [
    TopNavigationComponent,
    LeftNavigationComponent,
    AppShellComponent,
  ],
  providers: [
    NotificationsService,
  ],
})
export class ShellModule {
}
