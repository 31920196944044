import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationService } from './configuration.service';
import { StoreModule } from '@ngrx/store';
import * as fromConfiguration from './configuration.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ConfigurationEffects } from './configuration.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('configuration', fromConfiguration.reducer),
    EffectsModule.forFeature([ConfigurationEffects]),
  ],
  providers: [
    ConfigurationService,
  ],
  declarations: [],
})
export class ConfigurationModule { }
