<mat-form-field class="form-fullwidth multiselect">
  <mat-select #matSelectOptions disableRipple disableOptionCentering placeholder="{{ label | translate }}"
    [required]="hasValidator('required')" [formControl]="control" [multiple]="true" panelClass="myPanelClass">
    <!-- Selected options chips display-->
    <mat-select-trigger class="multiselect-chips">
      <mat-chip-list>
        <mat-basic-chip disableRipple *ngFor="let selected of control.value" [selectable]="false" [removable]="true"
          (removed)="removeOption(selected)">
          {{ selected.label }}
          <i class="icon-x" matChipRemove matRipple *ngIf="!control.disabled"></i>
        </mat-basic-chip>
      </mat-chip-list>
    </mat-select-trigger>

    <!-- All options -->
    <ng-container *ngIf="filteredOptions">
      <div class="multiselect-close">
        <ald-icon name="x" (click)="matSelectOptions.close()"></ald-icon>
      </div>
      <div matRipple class="multiselect-selectall mat-option" *ngIf="selectAll">
        <mat-checkbox color="primary" [(ngModel)]="isAllSelected" (change)="toggleSelection($event.checked)">
          {{ selectAllLabel }}
        </mat-checkbox>
      </div>
      <mat-option class="multiselect-option" [class.child]="option.parentId" [class.grandchild]="isGrandChild(option)"
        *ngFor="let option of filteredOptions" [value]="option">
        <ng-container *ngIf="option?.additionalInfo">
          <ald-icon name="info"  matTooltip="{{ optionTooltip | translate}}"
          matTooltipPosition="above"></ald-icon>
        </ng-container>
        {{ option.label }}
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
<span *ngIf="!control.disabled && clearOption && control.value && control.value.length > 0" (click)="clearSelection()"
  class="multiselect-clear-selection-old">{{ 'global-generic_terms-clear_selection' | translate }}</span>