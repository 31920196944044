import * as tslib_1 from "tslib";
import { NotificationsService } from '../services/notifications.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, map, tap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { NotificationsActionTypes } from './notifications.actions';
import * as actions from '../../../notifications/shared/state/notifications.actions';
import { PanelService } from '@shared/ui/panel/shared/panel.service';
import { AldSnackbarService } from '@ald/ui';
import { NotificationsConst } from '../notifications.const';
import { APP_ROUTES } from '@app/app.routes';
import { Router } from '@angular/router';
import { GtmService } from '@shared/core/gtm/gtm.service';
import { GTM_EVENTS } from '@shared/core/gtm/gtm-events.const';
import { TranslateService } from '@ngx-translate/core';
var NotificationsEffects = /** @class */ (function () {
    function NotificationsEffects(notificationService, actions$, panelService, gtmService, snackBarService, translateService, router) {
        var _this = this;
        this.notificationService = notificationService;
        this.actions$ = actions$;
        this.panelService = panelService;
        this.gtmService = gtmService;
        this.snackBarService = snackBarService;
        this.translateService = translateService;
        this.router = router;
        this.getNotifications$ = this.actions$.pipe(ofType(NotificationsActionTypes.GetNotifications), mergeMap(function (action) {
            return _this.notificationService.getNotifications().pipe(map(function (data) { return ({ type: NotificationsActionTypes.GetNotificationsSuccess, payload: data || [] }); }), catchError(function () { return of({ type: NotificationsActionTypes.GetNotificationsFail }); }));
        }));
        this.getManagerAlerts$ = this.actions$.pipe(ofType(NotificationsActionTypes.GetManagerAlerts), mergeMap(function (action) {
            return _this.notificationService.getAlerts().pipe(map(function (data) { return ({ type: NotificationsActionTypes.GetManagerAlertsSuccess, payload: data || [] }); }), catchError(function () { return of({ type: NotificationsActionTypes.GetManagerAlertsFail }); }));
        }));
        this.createNotification$ = this.actions$.pipe(ofType(NotificationsActionTypes.CreateNotification), mergeMap(function (action) {
            _this.snackBarService.loading(_this.translateService.instant('notifications-create_panel-snackbar_loading'));
            return _this.notificationService.createNotification('notification', action.payload).pipe(tap(function () {
                _this.snackBarService.success(_this.translateService.instant('notifications-create_panel-snackbar_success'));
                _this.panelService.close(NotificationsConst.windowCodename.createNotificationPanel);
                _this.router.navigate(["/" + APP_ROUTES.NOTIFICATIONS]);
            }), switchMap(function () {
                _this.gtmService.sendEvent(GTM_EVENTS.createNotification, '1');
                return [new actions.CreateNotificationSuccess(), new actions.GetNotifications()];
            }), catchError(function () {
                _this.snackBarService.error(_this.translateService.instant('notifications-create_panel-snackbar_fail'));
                return of(new actions.CreateNotificationFail());
            }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], NotificationsEffects.prototype, "getNotifications$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], NotificationsEffects.prototype, "getManagerAlerts$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], NotificationsEffects.prototype, "createNotification$", void 0);
    return NotificationsEffects;
}());
export { NotificationsEffects };
