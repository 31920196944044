import { Filter } from '@shared/ui/table/table-filters/shared/filter.interface';

export function deliveryDateFilterFunction(option: Filter, row: any): boolean | null {
  const today = new Date();
  const rowDate = new Date(row.estimatedDeliveryDate);

  let rowMatch = false;

  if (option.value.includes('today')) {
    rowMatch = rowDate.toDateString() === today.toDateString();
  }

  if (option.value.includes('ThisMonth')) {
    rowMatch = rowMatch || (rowDate.getMonth() === today.getMonth() && rowDate.getFullYear()  === today.getFullYear());
  }

  if (option.value.includes('NextMonth')) {
    const nextMonth = new Date(today);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    rowMatch = rowMatch || (rowDate.getMonth() === nextMonth.getMonth() && rowDate.getFullYear()  === nextMonth.getFullYear());
  }

  return rowMatch;
}

export const DeliveryDateFilter = {
  name: 'deliveryDate',
  label: 'global-filters-deliverydate',
  options: [
    {
      label: 'global-filters-today',
      value: 'today',
    },
    {
      label: 'global-filters-thismonth',
      value: 'ThisMonth',
    },
    {
      label: 'global-filters-nextmonth',
      value: 'NextMonth',
    },
  ],
  predicateFn: deliveryDateFilterFunction,
};
