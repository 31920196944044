import { UserProfileFilter, UserRoleFilter, MyaldAccessRoleFilter } from './filters/user-profile.filter';
import { SelectedOnlyFilter } from './filters/selected-only.filter';
import { UserStateFilter } from './filters/user-state.filter';
import { environment } from '../../../environments/environment';
import { APP_ROUTES } from '@app/app.routes';
import { ExpiredPasswordStateFilter } from '@user-management/shared/filters/expired-password-state.filter';

export const UserManagementConst = {
  api: {
    baseUrl: `${environment.apigatewayUrl}/apigateway/`,
    modules: {
      user: {
        url: 'usermanagement/api/',
        endpoints: {
          getRequestAccessList: 'Request/GetRequestAccessList',
          rejectAccessRequest: 'Request/DenyRequest',
          approveRequest: 'Request/ApproveRequest',
          getUserList: 'UserManagement/Users',
          resetPassword: 'UserManagement/ResetUsersPassword',
          deleteUserRequest: 'UserManagement/DeleteUsers',
          createUserRequest: 'UserManagement/CreateUser',
          createDriverRequest: 'UserManagement/CreateDriver',
          updateUserStatus: 'UserManagement/UpdateUsersStatus/',
          updateUserProfile: 'UserManagement/UpdateUser/',
          updateUserByContract: 'UserManagement/UpdateUserByContract/',
          updateUserByOrder: 'UserManagement/UpdateUserByOrder/',
          getAllCompanies: 'Company/GetAllCompanies',
          searchCompanies: 'Company/SearchCompany',
          getCompanyChildren: 'Company/GetChildrenCompanies/',
          getCompanyPlates: 'Company/GetCompanyPlates',
          setSelectedWorkingScope: 'User/SetSelectedWorkingScope',
          getProspectTemplate: 'UsersImport/Template/',
          uploadProspectInBulk: 'UsersImport/',
          assignCarPoliciesInBulk: 'UserManagement/UpdateCarPolicyInBulk/',
          getWhitelistedDomains: 'DomainManagement',
          deleteWhitelistedDomains: 'DomainManagement/Delete',
          giveAccess: 'UserManagement/GiveAccess',
          getUsersWithoutAccess: 'UserManagement/GiveAccessList',
          exportUsers : 'UserManagement/Export',
          exportMyAldAccess : 'UserManagement/ExportGiveAccessList',
        },
      },
      notification: {
        url: 'notification/api/',
        endpoints: {
          sendNotification: 'Messages/SendNotification',
        },
      },
      contract: {
        url: 'contract/api/',
        endpoints: {
          getUserContracts: 'ContractManagement/UserContracts',
        },
      },
      order: {
        url: 'contract/api/',
        endpoints: {
          getUserOrders: 'OrderManagement/UserOrders',
        },
      },
    },
  },
  windowCodename: {
    resetPasswordPopin: 'resetPasswordPopin',
    deleteUserPopin: 'deleteUserPopin',
    toggleUserStatePanel: 'toggleUserState',
    switchWorkingScopePanel: 'switchWorkingScopePanel',
    userAlertsPanel: 'userAlertsPanel',
    sendNotificationPanel: 'sendNotificationPanel',
    editProfilePanel: 'editUserProfile',
    editMyProfilePanel: 'editMyProfile',
    rejectAccessPopinCodename: 'rejectAccessPopinCodename',
    approveRequestFormCodename: 'approveRequestForm',
    createUserPanel: 'createUserPanel',
    registerDomainPanel: 'registerDomainPanel',
    editDomainPanel: 'editDomainPanel',
    deleteDomainsPopin: 'deleteDomainsPopin',
    giveMyaldAccessPanel: 'giveMyaldAccessPanel',
    editPreferencesPanel: 'editPreferencesPanel',
  },
  breadcrumbs: {
    userManagement: {
      path: '',
      label: 'global-breadcrumbs-user_management_label',
    },
    requestApproval: {
      path: `/${APP_ROUTES.USER_MANAGEMENT}/${APP_ROUTES.REQUEST_APPROVAL}`,
      label: 'global-breadcrumbs-request_approval_label',
    },
    userList:  {
      path: `/${APP_ROUTES.USER_MANAGEMENT}/${APP_ROUTES.USER_LIST}`,
      label: 'global-breadcrumbs-user_list_label',
    },
    whitelistDomains:  {
      path: `/${APP_ROUTES.USER_MANAGEMENT}/${APP_ROUTES.WHITELIST_DOMAINS}`,
      label: 'global-breadcrumbs-whitelist_domains_label',
    },
    importProspect:  {
      path: `/${APP_ROUTES.USER_MANAGEMENT}/${APP_ROUTES.IMPORT_PROSPECT}`,
      label: 'global-breadcrumbs-import_prospect_label',
    },
    giveAccess: {
      path: `/${APP_ROUTES.USER_MANAGEMENT}/${APP_ROUTES.GIVE_ACCESS}`,
      label: 'global-breadcrumbs-give_access_label',
    },
  },
  filters: {
    requestApproval: [
      UserProfileFilter,
      SelectedOnlyFilter,
    ],
    userList: [
      UserStateFilter,
      UserRoleFilter,
      SelectedOnlyFilter,
      ExpiredPasswordStateFilter,
    ],
    myaldAccessList: [
      MyaldAccessRoleFilter,
    ],
  },
  displayedColumns: {
    requestApproval: [
      'select',
      'name',
      'email',
      'plateNumber',
      'companyId',
      'company',
      'userProfile',
    ],
    userList: [
      'select',
      'passwordexpiration',
      'name',
      'email',
      'status',
      'roles',
      'company.name',
      'creationdate',
      'lastlogindate',
    ],
    whitelisting: [
      'select',
      'company.name',
      'company.reference',
      'url',
      'whiteListedDomains',
      'associatedCarPolicies.grade',
    ],
    myaldAccessList: [
      'select',
      'name',
      'email',
      'roles',
      'plate',
      'company.name',
    ],
  },
};
