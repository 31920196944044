import { UserRoles } from '@shared/entity/user/user.const';

export interface GtmPageViewProperties {
  event: 'dataLayerLoad';
  country: string;
  pageLanguage: string;
  userRole: UserRoles;
  userCompany: string;
  plateforme: 'manager' | string;
  pageSection: string;
  userId: string;
}

export interface GtmEventProperties {
  event: string;
  eventCategory: string;
  eventAction: string;
  plateforme?: 'manager' | string;
  eventLabel?: string;
  eventDetails?: string;
  userRole?: UserRoles;
  country?: string;
}

export enum GtmUserRoleProperty {
  role = 'role',
  countryCode = 'countryCode',
}
