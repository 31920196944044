import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[passwordVisibility]',
})

export class PasswordVisibilityDirective {
  private _isPasswordVisible = false;

  constructor(private el: ElementRef) {
    this.setup();
  }

  private togglePasswordVisibility(button: HTMLElement): void {
    this._isPasswordVisible = !this._isPasswordVisible;
    if (this._isPasswordVisible) {
      button.querySelector('i').classList.remove('icon-eye');
      button.querySelector('i').classList.add('icon-eye-off');
      this.el.nativeElement.setAttribute('type', 'text');
    } else {
      button.querySelector('i').classList.remove('icon-eye-off');
      button.querySelector('i').classList.add('icon-eye');
      this.el.nativeElement.setAttribute('type', 'password');
    }
  }

  private setup(): void {
    const button = document.createElement('button');
    button.type = 'button';
    button.classList.add('hide-password-button');
    button.addEventListener('click', (event) => {
      event.stopPropagation();
      event.preventDefault();
      this.togglePasswordVisibility(button);
    });
    const icon = document.createElement('i');
    icon.classList.add('icon-eye');
    button.appendChild(icon);
    const element = this.el.nativeElement as HTMLInputElement;
    element.style.paddingRight = '35px';
    element.parentElement.appendChild(button);
  }
}
