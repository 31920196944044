/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./country-access-error-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./country-access-error-page.component";
import * as i9 from "../../core/authentication/authentication.service";
import * as i10 from "../../core/helpers/window.ref";
import * as i11 from "../../core/storage/storage.service";
import * as i12 from "@angular/router";
var styles_CountryAccessErrorPageComponent = [i0.styles];
var RenderType_CountryAccessErrorPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CountryAccessErrorPageComponent, data: {} });
export { RenderType_CountryAccessErrorPageComponent as RenderType_CountryAccessErrorPageComponent };
export function View_CountryAccessErrorPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "country-access-error"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "country-access-error-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [["class", "country-access-error-home-button"], ["color", "accent"], ["mat-raised-button", ""]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.goToLogin() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatAnchor_0, i3.RenderType_MatAnchor)), i1.ɵdid(6, 180224, null, 0, i4.MatAnchor, [i5.Platform, i6.FocusMonitor, i1.ElementRef, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(7, 0, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 0, "iframe", [["height", "1"], ["id", "logout_ifm"], ["src", ""], ["width", "1"]], null, null, null, null, null))], function (_ck, _v) { var currVal_5 = "accent"; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("global-country_access_error_page-title")); _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 6).disabled ? (0 - 1) : (i1.ɵnov(_v, 6).tabIndex || 0)); var currVal_2 = (i1.ɵnov(_v, 6).disabled || null); var currVal_3 = i1.ɵnov(_v, 6).disabled.toString(); var currVal_4 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_6 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("global-country_access_error_page-button")); _ck(_v, 7, 0, currVal_6); }); }
export function View_CountryAccessErrorPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mgr-country-access-error-page", [], null, null, null, View_CountryAccessErrorPageComponent_0, RenderType_CountryAccessErrorPageComponent)), i1.ɵdid(1, 49152, null, 0, i8.CountryAccessErrorPageComponent, [i9.AuthenticationService, i10.WindowRef, i11.StorageService, i12.Router], null, null)], null, null); }
var CountryAccessErrorPageComponentNgFactory = i1.ɵccf("mgr-country-access-error-page", i8.CountryAccessErrorPageComponent, View_CountryAccessErrorPageComponent_Host_0, {}, {}, []);
export { CountryAccessErrorPageComponentNgFactory as CountryAccessErrorPageComponentNgFactory };
