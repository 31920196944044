import { Component, Input } from '@angular/core';

@Component({
  selector: 'mgr-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {

  @Input() theme: 'light' | 'grey' | 'main' = 'light';

  constructor() {
  }

}
