import { Filter } from '@shared/ui/table/table-filters/shared/filter.interface';
import { UserRoles } from '../../../shared/entity/user/user.const';

export function documentRoleFilterFunction(option: Filter, row: any): boolean | null {
  return option.value.some(opt => row.roles.includes(opt));
}

export const DocumentRoleFilter = {
  name: 'documentRole',
  label: 'global-filters-documentrole',
  options: [
    {
      label: 'global-filters-fleetmanager',
      value: UserRoles.FleetManager,
    },
    {
      label: 'global-filters-driver',
      value: UserRoles.Driver,
    },
    {
      label: 'global-filters-prospect',
      value: UserRoles.Prospect,
    },
  ],
  predicateFn: documentRoleFilterFunction,
};
