import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as navigationState from '../state';
import { takeWhile } from 'rxjs/operators';
import { ConfigurationService } from '@shared/core/configuration/configuration.service';
import { FeatureConst } from '@shared/core/feature/feature.consts';
import { combineLatest } from 'rxjs';
import { UserRoles } from '@shared/entity/user/user.const';
import { APP_ROUTES } from '@app/app.routes';
import { GTM_EVENTS } from '@shared/core/gtm/gtm-events.const';
import { FeatureService } from '@app/shared/core/feature/feature.service';
import { UserService } from '@app/shared/entity/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { AldSnackbarService } from '@ald/ui';
import { environment } from 'environments/environment';
import { StorageService } from '@shared/core/storage/storage.service';
import { StorageConst } from '@shared/core/storage/storage.const';
import { StorageAuthService } from '@shared/core/storage/storage.auth.service';
import * as userState from '../../shared/entity/user/state';
import { UpdateSelectedCulture } from '@app/shared/entity/user/state/user.actions';
import { LoadingPos } from '@app/administration/shared/state/pos-state/pos.actions';

@Component({
  selector: 'mgr-left-navigation',
  templateUrl: './left-navigation.component.html',
  styleUrls: ['./left-navigation.component.scss'],
})
export class LeftNavigationComponent implements OnInit, OnDestroy {
  public showSubNavigation = [];
  public showFullNavigation = true;
  private subNavigationTagName = 'FM-SUB-NAVIGATION';
  private componentActive = true;
  public reportUrl: string;
  public aldQuoteUrl: string;
  public carSelectorUrl: string;
  public smartCareUrl: string;
  public isQuoterActivated = false;
  public isTrafficFinesV1Activated = false;
  public isTrafficFinesV2Activated = false;
  public isManageSupplierActivated = false;
  public isPosManagementActivated = false;
  public countryCode: string;
  public culture: string;
  public aldQuoteV2Url: string;

  public readonly USER_ROLES = UserRoles;
  public readonly APP_ROUTES = APP_ROUTES;
  public readonly FEATURES = FeatureConst.features;
  public readonly GTM_EVENTS = GTM_EVENTS;
  public smartCareUrlForSafari: any;
  public smartCareUrlForNonSafri: any;

  constructor(
    private store: Store<navigationState.State | userState.State>,
    private configurationService: ConfigurationService,
    private userService: UserService,
    private featureService: FeatureService,
    private snackBarService: AldSnackbarService,
    private readonly translateService: TranslateService,
    private readonly storageService: StorageService,
    private storagrAuth: StorageAuthService,
  ) {
  }

  ngOnInit(): void {
    this.countryCode = this.storageService.get(StorageConst.selectedCountry);
    this.culture = this.storageService.get(StorageConst.translation.keys.lang);
    this.store.dispatch(new UpdateSelectedCulture(this.culture));
    this.store.pipe(
      select(navigationState.getShowFullNavigation),
      takeWhile(() => this.componentActive),
    ).subscribe((status) => {
      this.showFullNavigation = status;
    });
    combineLatest(
      this.configurationService.getItemFromConfiguration('SmartCareManagerUrl'),
      this.configurationService.getItemFromConfiguration('Quoter'),
      this.featureService.isFeatureActivated(FeatureConst.features.trafficFinesV1),
      this.featureService.isFeatureActivated(FeatureConst.features.trafficFinesV2),
      this.featureService.isFeatureActivated(FeatureConst.features.manageSupplier),
      this.featureService.isFeatureActivated(FeatureConst.features.smartCare),
      this.featureService.isFeatureActivated(FeatureConst.features.supplierWithToken),
    ).subscribe(([smartCareManagerUrl, isQuoterActivated, isTrafficFinesV1Activated,
      isTrafficFinesV2Activated, isSupplierActivated, isSmartCareActivated, isSupplierWithTokenActivated]) => {

      if (smartCareManagerUrl) {
        this.smartCareUrlForSafari = `${smartCareManagerUrl.value}wait?access_token=${this.storagrAuth.accessToken}`;
        this.smartCareUrl = this.smartCareUrlForSafari;
      }

      this.isTrafficFinesV1Activated = isTrafficFinesV1Activated;
      this.isTrafficFinesV2Activated = isTrafficFinesV2Activated;
      this.isPosManagementActivated = !isSmartCareActivated;
      this.isQuoterActivated = !!isQuoterActivated;
      if (isSupplierActivated || isSupplierWithTokenActivated) {
        this.isManageSupplierActivated = true;
      }
      if (this.isQuoterActivated) {
        this.setCarSelectorUrl();
        this.getAldQuoteUrl();
        this.setAldQuoteV2Url();
      }
    });

    this.setReportUrl();
    this.getUpdatedLocale();
  }

  private getUpdatedLocale() {
    this.storageService.$localeChanges.subscribe((locale) => {
      this.culture = locale.value;
      this.store.dispatch(new UpdateSelectedCulture(locale.value));
      this.store.dispatch(new LoadingPos(true));

    });
  }

  public getAldQuoteUrl(): void {
    this.configurationService.getItemFromConfiguration('AldQuoteManagerUrl')
      .subscribe((aldQuoteUrl) => {
        if (aldQuoteUrl) {
          this.aldQuoteUrl = aldQuoteUrl.value;
        }
      });
  }
  public setAldQuoteV2Url(): void {
    combineLatest([
      this.configurationService.getItemFromConfiguration('AldQuoteUrl'),
      this.featureService.isFeatureActivated(FeatureConst.features.aldQuoteV2),
    ]).pipe(takeWhile(() => this.componentActive))
      .subscribe(([aldQuoteV2Url, aldQuoteV2]) => {
        if (aldQuoteV2 && aldQuoteV2Url) {
          this.aldQuoteV2Url = `${aldQuoteV2Url.value}wait?access_token=${this.storagrAuth.accessToken}`;
        }
      });
  }

  public toggleSubNavigation(codename, event) {
    if (event.target.parentElement.parentElement.tagName !== this.subNavigationTagName
      && event.target.parentElement.tagName !== this.subNavigationTagName
      && event.target.tagName !== this.subNavigationTagName) {
      const status = this.showSubNavigation[codename];
      this.showSubNavigation = [];
      this.showSubNavigation[codename] = !status;
    }
  }

  public toggleFullNavigation() {
    this.store.dispatch(new navigationState.actions.ToggleFullNavigation(!this.showFullNavigation));
  }

  public openReportsWithToken() {
    const windowRef = window.open();
    this.snackBarService.loading(
      this.translateService.instant('global-generic_terms-snackbar_loading'),
    );
    this.userService.getExternalAppToken().pipe(takeWhile(() => this.componentActive))
      .subscribe(
        (externalJwtToken) => {
          windowRef.location.href = `${this.reportUrl}?mgr=1&token=${externalJwtToken}`;
          this.snackBarService.clear();
        },
        () => {
          this.snackBarService.error(
            this.translateService.instant('global-generic_terms-snackbar_generic_error'),
          );
        });
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  setAldQuoteUrl() {
    const windowRef = window.open();
    this.snackBarService.loading(
      this.translateService.instant('global-generic_terms-snackbar_loading'),
    );
    combineLatest([
      this.featureService.isFeatureActivated(FeatureConst.features.aldQuoteWithToken),
      this.featureService.isFeatureActivated(FeatureConst.features.aldQuoteWithoutToken),
      this.userService.getExternalAppToken(),
    ]).pipe(takeWhile(() => this.componentActive))
      .subscribe(([aldQuoteWithToken, aldQuoteWithoutToken, externalJwtToken]) => {
        if (this.aldQuoteUrl && aldQuoteWithToken && externalJwtToken) {
          windowRef.location.href = `${this.aldQuoteUrl}?token=${externalJwtToken}`;
        }
        if (this.aldQuoteUrl && aldQuoteWithoutToken) {
          windowRef.location.href = this.aldQuoteUrl;
        }
        this.snackBarService.clear();
      },         () => {
        this.snackBarService.error(
          this.translateService.instant('global-generic_terms-snackbar_generic_error'),
        );
      });
  }

  setReportUrl() {
    this.configurationService.getItemFromConfiguration('ReportManagerUrl').subscribe((reportUrl) => {
      if (reportUrl) {
        this.reportUrl = reportUrl.value;
      }
    });
  }
  public setCarSelectorUrl(): void {
    this.configurationService.getItemFromConfiguration('CarSelectorManagerUrl')
      .subscribe((carSelectorUrl) => {
        if (carSelectorUrl) {
          this.carSelectorUrl = carSelectorUrl.value;
        }
      });
  }
  openCarSelectorLink() {
    const windowRef = window.open();
    this.snackBarService.loading(
      this.translateService.instant('global-generic_terms-snackbar_loading'),
    );
    this.userService.getExternalAppToken().subscribe((externalJwtToken) => {
      if (externalJwtToken) {
        windowRef.location.href = `${this.carSelectorUrl}?token=${externalJwtToken}`;
        this.snackBarService.clear();
      }
    },                                               () => {
      this.snackBarService.error(
        this.translateService.instant('global-generic_terms-snackbar_generic_error'),
      );
    });
  }

  public getLinkToTermsOfUse(): string {
    return `${environment.publicDocumentsBaseUrl}/${this.countryCode}/disclaimer-${this.culture}.pdf`;
  }
  public getLinkToCookiesPolicy(): string {
    // tslint:disable-next-line: max-line-length
    return `${environment.publicDocumentsBaseUrl}/${this.countryCode}/cookies-charter-${this.culture}.pdf`;
  }
  private getBrowser() {
    if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return 'Chrome';
    }

    if (navigator.userAgent.indexOf('Safari') !== -1) {
      return 'Safari';
    }
  }
}
