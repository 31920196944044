import { environment } from '../../../../environments/environment';
import { ContractStateFilter } from '../filters/contract-state.filter';
import { APP_ROUTES } from '@app/app.routes';
import { ContractMileageCategoryFilter } from '../filters/contract-mileagecategory.filter';
import { UserStatusFilter } from '../filters/user-status.filter';
import { ContractMileageDeviationFilter } from '@my-fleet/shared/filters/contract-mileage-deviation.filter';

export const ContractsConst = {
  api: {
    baseUrl: `${environment.apigatewayUrl}/apigateway/`,
    modules: {
      user: {
        url: 'usermanagement/api/',
        endpoints: {
          getDriver: 'UserManagement/GetUserByContract/',
        },
      },
      contract: {
        url: 'contract/api/',
        endpoints: {
          getContractList: 'ContractManagement/Contracts',
          getContractDetail: 'ContractManagement/Contract',
          getMileageHistory: 'ContractManagement/MileageHistory',
          getCompany: 'ContractManagement/Company',
          updateMileage: 'ContractManagement/UpdateMileage',
          getVehicleInformation: 'ContractManagement/Vehicle',
          getMaintenanceHistory: 'ContractManagement/MaintenanceHistory',
          downloadMaintenanceHistory: 'ContractManagement/MaintenanceHistory/Export',
          getFuelCardHistory: 'ContractManagement/FuelCardHistory',
          exportFuelReport: 'ContractManagement/FuelCardHistory/Export',
          exportContract: 'ContractManagement/ExportContactList',
        },
      },
      assistance: {
        url: 'help/api/',
        endpoints: {
          sendAskPermissionForm: 'Assistance/PermissionToGoAbroad',
          sendOrderDamageRepairRequest: 'Assistance/OrderDamageRepair',
          sendOrderMaintenanceRequest: 'Assistance/OrderMaintenance',
        },
      },
      notifications: {
        url: 'feedmanagement/api/',
        endpoints: {
          sendNotification: 'FeedManagement/createfeeditembycontractids',
        },
      },
    },
  },

  filters: {
    contractList: [
      UserStatusFilter,
      ContractStateFilter,
      ContractMileageCategoryFilter,
      ContractMileageDeviationFilter,
    ],
  },

  breadcrumbs: {
    myFleet: {
      path: '',
      label: 'global-breadcrumbs-my_fleet_label',
    },
    contractList: {
      path: `/${APP_ROUTES.MY_FLEET}/${APP_ROUTES.CONTRACT_LIST}`,
      label: 'global-breadcrumbs-my_fleet_contracts_label',
    },
    contractDetail: (id) => {
      return {
        path: `/${APP_ROUTES.MY_FLEET}/${APP_ROUTES.CONTRACT_LIST}/${id}/${APP_ROUTES.CONTRACT_DRIVER_INFO}`,
        label: id,
      };
    },
    contractInfo: {
      path: '',
      label: 'global-breadcrumbs-my_fleet_contracts_details_label',
    },
    contractDriverInfo: {
      path: '',
      label: 'global-breadcrumbs-my_fleet_contracts_driverinfo_label',
    },
    contractVehicleInfo: {
      path: '',
      label: 'global-breadcrumbs-my_fleet_contracts_vehicleinfo_label',
    },
    assistanceForDriver: {
      path: '',
      label: 'global-breadcrumbs-my_fleet_contracts_assistance_label',
    },
    assistanceAskPermission: {
      path: '',
      label: 'global-breadcrumbs-my_fleet_contracts_assistance_ask_permission_label',
    },
    assistanceOrderDamage: {
      path: '',
      label: 'global-breadcrumbs-my_fleet_contracts_assistance_order_damage_label',
    },
    assistanceOrderMaintenance: {
      path: '',
      label: 'global-breadcrumbs-my_fleet_contracts_assistance_order_maintenance_label',
    },
  },

  windowCodename: {
    contractsSendNotificationPanel: 'contractsSendNotificationPanel',
    switchWorkingScopePanel: 'switchWorkingScopePanel',
  },

  displayedColumns: {
    contractList: [
      'select',
      'reference',
      'user.name',
      'company.name',
      'vehicle.plate',
      'status',
      'endDate',
    ],
    maintenanceHistory: [
      'date',
      'mileage',
      'serviceItem',
      'serviceProvider',
    ],
    fuelCardList: [
      'company.name',
      'vehicle.plate',
      'vehicle.model',
      'eventDate',
      'mileage',
      'oilCompany',
      'productName',
      'netTotal',
      'units',
    ],
  },

  crepCode : 'crepCode',
};

export enum UpdateMileageStatus {
  Success = 'Success',
  Invalid = 'InvalidData',
  Error = 'Error',
}
