import { Action } from '@ngrx/store';
import { UserListDataItem } from '../models/user-list-data-item.model';
import { CreateNewUserRequest } from '../models/user-create-new.model';
import { EditUserProfileRequest } from '@user-management/user-list/shared/models/user-edit-profile.model';
import { UserRoles } from '@shared/entity/user/user.const';

export enum UserListActionTypes {
  GetUserList = '[User List] Get',
  GetUserListSuccess = '[User List] Get success',
  GetUserListFail = '[User List] Get fail',
  RemoveUser = '[User List] Remove From List',
  SearchUser = '[User List] Search In List',
  UpdateUsers = '[User List] Update User In List',
  RemoveUserSelection = '[User List] Remove From Selection',
  AddUserSelection = '[User List] Add To Selection',
  ClearUserSelection = '[User List] Clear Selection',
  CreateUser = '[User List] Create User',
  CreateUserFail = '[User List] Create User Fail',
  SelectUserToEdit = '[User List] Select User',
  UpdateUserProfile = '[User List] Update user profile',
  ClearUserStore = '[User List] Clear User List',
  SelectUser = '[User List] Set selected user in searchbar',
  ExportUsersFail = '[User List] Export users fail',
  ExportUsersSuccess = '[User List] Export users success',
}
export class GetUserList implements Action {
  readonly type = UserListActionTypes.GetUserList;
}

export class GetUserListSuccess implements Action {
  readonly type = UserListActionTypes.GetUserListSuccess;

  constructor(public payload: UserListDataItem[]) { }
}

export class GetUserListFail implements Action {
  readonly type = UserListActionTypes.GetUserListFail;
}

export class SearchUser implements Action {
  readonly type = UserListActionTypes.SearchUser;

  constructor(public payload: string) { }
}

export class RemoveUser implements Action {
  readonly type = UserListActionTypes.RemoveUser;

  constructor(public payload: UserListDataItem[]) { }
}

export class RemoveUserSelection implements Action {
  readonly type = UserListActionTypes.RemoveUserSelection;

  constructor(public payload: UserListDataItem[]) { }
}

export class AddUserSelection implements Action {
  readonly type = UserListActionTypes.AddUserSelection;

  constructor(public payload: UserListDataItem[]) { }
}

export class UpdateUsers implements Action {
  readonly type = UserListActionTypes.UpdateUsers;

  constructor(public payload: UserListDataItem[]) { }
}

export class UpdateUserProfile implements Action {
  readonly type = UserListActionTypes.UpdateUserProfile;

  constructor(public payload: EditUserProfileRequest) { }
}

export class ClearUserSelection implements Action {
  readonly type = UserListActionTypes.ClearUserSelection;
}

export class CreateUser implements Action {
  readonly type = UserListActionTypes.CreateUser;

  constructor(public payload: CreateNewUserRequest) { }
}

export class CreateUserFail implements Action {
  readonly type = UserListActionTypes.CreateUserFail;
}

export class SelectUserToEdit implements Action {
  readonly type = UserListActionTypes.SelectUserToEdit;

  constructor(public payload: string) { }
}

export class ClearUserStore implements Action {
  readonly type = UserListActionTypes.ClearUserStore;
}

export class SelectUser implements Action {
  readonly type = UserListActionTypes.SelectUser;

  constructor(public payload: string) { }
}

export class ExportUsersFail implements Action {
  readonly type = UserListActionTypes.ExportUsersFail;
}

export class ExportUsersSuccess implements Action {
  readonly type = UserListActionTypes.ExportUsersSuccess;
}

export type UserListActions =
  | GetUserList
  | GetUserListSuccess
  | GetUserListFail
  | SearchUser
  | RemoveUser
  | RemoveUserSelection
  | AddUserSelection
  | ClearUserSelection
  | UpdateUsers
  | CreateUser
  | CreateUserFail
  | SelectUserToEdit
  | UpdateUserProfile
  | ClearUserStore
  | SelectUser
  | ExportUsersFail
  | ExportUsersSuccess;
