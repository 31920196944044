import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NavigationActionTypes, ToggleFullNavigation } from './navigation.actions';
import { StorageService } from '@shared/core/storage/storage.service';
import { StorageConst } from '@shared/core/storage/storage.const';

@Injectable()
export class NavigationEffects {
  constructor(
    private actions$: Actions,
    private storageService: StorageService,
  ) { }

  @Effect({ dispatch: false })
  toggleFullNavigation$: Observable<boolean> = this.actions$.pipe(
    ofType<ToggleFullNavigation>(NavigationActionTypes.ToggleFullNavigation),
    map(action => action.payload),
    tap((fullNavigation) => {
      this.storageService.set(StorageConst.fullNavigation, fullNavigation);
    },
    ),
  );
}
