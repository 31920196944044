import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NavigationActionTypes } from './navigation.actions';
import { StorageService } from '@shared/core/storage/storage.service';
import { StorageConst } from '@shared/core/storage/storage.const';
var NavigationEffects = /** @class */ (function () {
    function NavigationEffects(actions$, storageService) {
        var _this = this;
        this.actions$ = actions$;
        this.storageService = storageService;
        this.toggleFullNavigation$ = this.actions$.pipe(ofType(NavigationActionTypes.ToggleFullNavigation), map(function (action) { return action.payload; }), tap(function (fullNavigation) {
            _this.storageService.set(StorageConst.fullNavigation, fullNavigation);
        }));
    }
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Observable)
    ], NavigationEffects.prototype, "toggleFullNavigation$", void 0);
    return NavigationEffects;
}());
export { NavigationEffects };
