import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromFeature from './feature.reducer';
import { StoreModule } from '@ngrx/store';
import { FeatureEffects } from './feature.effects';
import { EffectsModule } from '@ngrx/effects';
import { FeatureService } from './feature.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('feature', fromFeature.reducer),
    EffectsModule.forFeature([FeatureEffects]),
  ],
  providers: [
    FeatureService,
  ],
  declarations: [],
})
export class FeatureModule { }
