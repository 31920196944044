import { Action } from '@ngrx/store';
import { KpiData } from '../models/dashboard-kpi.model';

export enum DashBoardKpiActionTypes {
  UpdateKpis = '[All Kpis] Update after working scope change',
  GetRequestAccessKpi = '[RequestAccessKpi] Get',
  GetRequestAccessKpiSuccess = '[RequestAccessKpi] Get success',
  GetRequestAccessKpiFail = '[RequestAccessKpi] Get fail',
  GetMileageDeviationKpi = '[MileageDeviationKpi] Get',
  GetMileageDeviationKpiSuccess = '[MileageDeviationKpi] Get success',
  GetMileageDeviationKpiFail = '[MileageDeviationKpi] Get fail',
  GetMileageInformationKpi = '[MileageInformationKpi] Get',
  GetMileageInformationKpiSuccess = '[MileageInformationKpi] Get success',
  GetMileageInformationKpiFail = '[MileageInformationKpi] Get fail',
  GetContractInformationKpi = '[ContractInformationKpi] Get',
  GetContractInformationKpiSuccess = '[ContractInformationKpi] Get success',
  GetContractInformationKpiFail = '[ContractInformationKpi] Get fail',
  GetDeliveryKpi = '[DeliveryKpi] Get',
  GetDeliveryKpiSuccess = '[DeliveryKpi] Get success',
  GetDeliveryKpiFail = '[DeliveryKpi] Get fail',
  GetSalesforceKpi = '[SalesforceKpi] Get',
  GetSalesforceKpiSuccess = '[SalesforceKpi] Get success',
  GetSalesforceKpiFail = '[SalesforceKpi] Get fail',
}

export class UpdateKpis implements Action {
  readonly type = DashBoardKpiActionTypes.UpdateKpis;
}
export class GetRequestAccessKpi implements Action {
  readonly type = DashBoardKpiActionTypes.GetRequestAccessKpi;
}

export class GetRequestAccessKpiSuccess implements Action {
  readonly type = DashBoardKpiActionTypes.GetRequestAccessKpiSuccess;

  constructor(public payload: KpiData[]) { }
}

export class GetRequestAccessKpiFail implements Action {
  readonly type = DashBoardKpiActionTypes.GetRequestAccessKpiFail;
}

export class GetMileageDeviationKpi implements Action {
  readonly type = DashBoardKpiActionTypes.GetMileageDeviationKpi;
}

export class GetMileageDeviationKpiSuccess implements Action {
  readonly type = DashBoardKpiActionTypes.GetMileageDeviationKpiSuccess;

  constructor(public payload: KpiData[]) { }
}

export class GetMileageDeviationKpiFail implements Action {
  readonly type = DashBoardKpiActionTypes.GetMileageDeviationKpiFail;
}

export class GetMileageInformationKpi implements Action {
  readonly type = DashBoardKpiActionTypes.GetMileageInformationKpi;
}

export class GetMileageInformationKpiSuccess implements Action {
  readonly type = DashBoardKpiActionTypes.GetMileageInformationKpiSuccess;

  constructor(public payload: KpiData[]) { }
}

export class GetMileageInformationKpiFail implements Action {
  readonly type = DashBoardKpiActionTypes.GetMileageInformationKpiFail;
}

export class GetContractInformationKpi implements Action {
  readonly type = DashBoardKpiActionTypes.GetContractInformationKpi;
}

export class GetContractInformationKpiSuccess implements Action {
  readonly type = DashBoardKpiActionTypes.GetContractInformationKpiSuccess;

  constructor(public payload: KpiData[]) { }
}

export class GetContractInformationKpiFail implements Action {
  readonly type = DashBoardKpiActionTypes.GetContractInformationKpiFail;
}

export class GetDeliveryKpi implements Action {
  readonly type = DashBoardKpiActionTypes.GetDeliveryKpi;
}

export class GetDeliveryKpiSuccess implements Action {
  readonly type = DashBoardKpiActionTypes.GetDeliveryKpiSuccess;

  constructor(public payload: KpiData[]) { }
}

export class GetDeliveryKpiFail implements Action {
  readonly type = DashBoardKpiActionTypes.GetDeliveryKpiFail;
}

export class GetSalesforceKpi implements Action {
  readonly type = DashBoardKpiActionTypes.GetSalesforceKpi;
}

export class GetSalesforceKpiSuccess implements Action {
  readonly type = DashBoardKpiActionTypes.GetSalesforceKpiSuccess;

  constructor(public payload: KpiData[]) { }
}

export class GetSalesforceKpiFail implements Action {
  readonly type = DashBoardKpiActionTypes.GetSalesforceKpiFail;
}

export type DashBoardKpiActions = UpdateKpis | GetRequestAccessKpi | GetRequestAccessKpiSuccess | GetRequestAccessKpiFail
  | GetMileageDeviationKpi | GetMileageDeviationKpiSuccess | GetMileageDeviationKpiFail
  | GetMileageInformationKpi | GetMileageInformationKpiSuccess | GetMileageInformationKpiFail
  | GetContractInformationKpi | GetContractInformationKpiSuccess | GetContractInformationKpiFail
  | GetDeliveryKpi | GetDeliveryKpiSuccess | GetDeliveryKpiFail
  | GetSalesforceKpi | GetSalesforceKpiSuccess | GetSalesforceKpiFail;
