import { Action } from '@ngrx/store';

export enum PopinActionTypes {
  OpenPopin = '[Popin] Open Popin',
  ClosePopin = '[Popin] Close Popin',
}

export class OpenPopin implements Action {
  readonly type = PopinActionTypes.OpenPopin;

  constructor(public payload: string) {
  }
}

export class ClosePopin implements Action {
  readonly type = PopinActionTypes.ClosePopin;

  constructor(public payload: string) {
  }
}

export type PopinActions = OpenPopin | ClosePopin;
