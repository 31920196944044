import { Filter } from '@shared/ui/table/table-filters/shared/filter.interface';
import { UserRoles } from 'app/shared/entity/user/user.const';

export function userProfileFilterFunction(option: Filter, row: any): boolean | null {
  return option.value.includes(row.role);
}

export function userRoleFilterFunction(option: Filter, row: any): boolean | null {
  return row.roles.some(value => option.value.includes(value));
}

export function myaldAccessRoleFilterFunction(option: Filter, row: any): boolean | null {
  return row.roles.some(value => option.value.includes(value));
}

export const UserProfileFilter = {
  name: 'profile',
  label: 'global-filters-user_profile',
  options: [
    {
      label: 'global-filters-fleetmanager',
      value: UserRoles.FleetManager,
    },
    {
      label: 'global-filters-driver',
      value: UserRoles.Driver,
    },
  ],
  predicateFn: userProfileFilterFunction,
};

export const UserRoleFilter = {
  name: 'role',
  label: 'global-filters-user_profile',
  options: [
    {
      label: 'global-filters-fleetmanager',
      value: UserRoles.FleetManager,
    },
    {
      label: 'global-filters-driver',
      value: UserRoles.Driver,
    },
    {
      label: 'global-filters-prospect',
      value: UserRoles.Prospect,
    },
  ],
  predicateFn: userRoleFilterFunction,
};

export const MyaldAccessRoleFilter = {
  name: 'myAldaccessrole',
  label: 'global-filters-user_profile',
  options: [
    {
      label: 'global-filters-fleetmanager',
      value: UserRoles.FleetManager,
    },
    {
      label: 'global-filters-driver',
      value: UserRoles.Driver,
    },
  ],
  predicateFn: myaldAccessRoleFilterFunction,
};
