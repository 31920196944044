import { OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as navigationState from '../state';
import { takeWhile } from 'rxjs/operators';
import { ConfigurationService } from '@shared/core/configuration/configuration.service';
import { FeatureConst } from '@shared/core/feature/feature.consts';
import { combineLatest } from 'rxjs';
import { UserRoles } from '@shared/entity/user/user.const';
import { APP_ROUTES } from '@app/app.routes';
import { GTM_EVENTS } from '@shared/core/gtm/gtm-events.const';
import { FeatureService } from '@app/shared/core/feature/feature.service';
import { UserService } from '@app/shared/entity/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { AldSnackbarService } from '@ald/ui';
import { environment } from 'environments/environment';
import { StorageService } from '@shared/core/storage/storage.service';
import { StorageConst } from '@shared/core/storage/storage.const';
import { StorageAuthService } from '@shared/core/storage/storage.auth.service';
import { UpdateSelectedCulture } from '@app/shared/entity/user/state/user.actions';
import { LoadingPos } from '@app/administration/shared/state/pos-state/pos.actions';
var LeftNavigationComponent = /** @class */ (function () {
    function LeftNavigationComponent(store, configurationService, userService, featureService, snackBarService, translateService, storageService, storagrAuth) {
        this.store = store;
        this.configurationService = configurationService;
        this.userService = userService;
        this.featureService = featureService;
        this.snackBarService = snackBarService;
        this.translateService = translateService;
        this.storageService = storageService;
        this.storagrAuth = storagrAuth;
        this.showSubNavigation = [];
        this.showFullNavigation = true;
        this.subNavigationTagName = 'FM-SUB-NAVIGATION';
        this.componentActive = true;
        this.isQuoterActivated = false;
        this.isTrafficFinesV1Activated = false;
        this.isTrafficFinesV2Activated = false;
        this.isManageSupplierActivated = false;
        this.isPosManagementActivated = false;
        this.USER_ROLES = UserRoles;
        this.APP_ROUTES = APP_ROUTES;
        this.FEATURES = FeatureConst.features;
        this.GTM_EVENTS = GTM_EVENTS;
    }
    LeftNavigationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.countryCode = this.storageService.get(StorageConst.selectedCountry);
        this.culture = this.storageService.get(StorageConst.translation.keys.lang);
        this.store.dispatch(new UpdateSelectedCulture(this.culture));
        this.store.pipe(select(navigationState.getShowFullNavigation), takeWhile(function () { return _this.componentActive; })).subscribe(function (status) {
            _this.showFullNavigation = status;
        });
        combineLatest(this.configurationService.getItemFromConfiguration('SmartCareManagerUrl'), this.configurationService.getItemFromConfiguration('Quoter'), this.featureService.isFeatureActivated(FeatureConst.features.trafficFinesV1), this.featureService.isFeatureActivated(FeatureConst.features.trafficFinesV2), this.featureService.isFeatureActivated(FeatureConst.features.manageSupplier), this.featureService.isFeatureActivated(FeatureConst.features.smartCare), this.featureService.isFeatureActivated(FeatureConst.features.supplierWithToken)).subscribe(function (_a) {
            var smartCareManagerUrl = _a[0], isQuoterActivated = _a[1], isTrafficFinesV1Activated = _a[2], isTrafficFinesV2Activated = _a[3], isSupplierActivated = _a[4], isSmartCareActivated = _a[5], isSupplierWithTokenActivated = _a[6];
            if (smartCareManagerUrl) {
                _this.smartCareUrlForSafari = smartCareManagerUrl.value + "wait?access_token=" + _this.storagrAuth.accessToken;
                _this.smartCareUrl = _this.smartCareUrlForSafari;
            }
            _this.isTrafficFinesV1Activated = isTrafficFinesV1Activated;
            _this.isTrafficFinesV2Activated = isTrafficFinesV2Activated;
            _this.isPosManagementActivated = !isSmartCareActivated;
            _this.isQuoterActivated = !!isQuoterActivated;
            if (isSupplierActivated || isSupplierWithTokenActivated) {
                _this.isManageSupplierActivated = true;
            }
            if (_this.isQuoterActivated) {
                _this.setCarSelectorUrl();
                _this.getAldQuoteUrl();
                _this.setAldQuoteV2Url();
            }
        });
        this.setReportUrl();
        this.getUpdatedLocale();
    };
    LeftNavigationComponent.prototype.getUpdatedLocale = function () {
        var _this = this;
        this.storageService.$localeChanges.subscribe(function (locale) {
            _this.culture = locale.value;
            _this.store.dispatch(new UpdateSelectedCulture(locale.value));
            _this.store.dispatch(new LoadingPos(true));
        });
    };
    LeftNavigationComponent.prototype.getAldQuoteUrl = function () {
        var _this = this;
        this.configurationService.getItemFromConfiguration('AldQuoteManagerUrl')
            .subscribe(function (aldQuoteUrl) {
            if (aldQuoteUrl) {
                _this.aldQuoteUrl = aldQuoteUrl.value;
            }
        });
    };
    LeftNavigationComponent.prototype.setAldQuoteV2Url = function () {
        var _this = this;
        combineLatest([
            this.configurationService.getItemFromConfiguration('AldQuoteUrl'),
            this.featureService.isFeatureActivated(FeatureConst.features.aldQuoteV2),
        ]).pipe(takeWhile(function () { return _this.componentActive; }))
            .subscribe(function (_a) {
            var aldQuoteV2Url = _a[0], aldQuoteV2 = _a[1];
            if (aldQuoteV2 && aldQuoteV2Url) {
                _this.aldQuoteV2Url = aldQuoteV2Url.value + "wait?access_token=" + _this.storagrAuth.accessToken;
            }
        });
    };
    LeftNavigationComponent.prototype.toggleSubNavigation = function (codename, event) {
        if (event.target.parentElement.parentElement.tagName !== this.subNavigationTagName
            && event.target.parentElement.tagName !== this.subNavigationTagName
            && event.target.tagName !== this.subNavigationTagName) {
            var status_1 = this.showSubNavigation[codename];
            this.showSubNavigation = [];
            this.showSubNavigation[codename] = !status_1;
        }
    };
    LeftNavigationComponent.prototype.toggleFullNavigation = function () {
        this.store.dispatch(new navigationState.actions.ToggleFullNavigation(!this.showFullNavigation));
    };
    LeftNavigationComponent.prototype.openReportsWithToken = function () {
        var _this = this;
        var windowRef = window.open();
        this.snackBarService.loading(this.translateService.instant('global-generic_terms-snackbar_loading'));
        this.userService.getExternalAppToken().pipe(takeWhile(function () { return _this.componentActive; }))
            .subscribe(function (externalJwtToken) {
            windowRef.location.href = _this.reportUrl + "?mgr=1&token=" + externalJwtToken;
            _this.snackBarService.clear();
        }, function () {
            _this.snackBarService.error(_this.translateService.instant('global-generic_terms-snackbar_generic_error'));
        });
    };
    LeftNavigationComponent.prototype.ngOnDestroy = function () {
        this.componentActive = false;
    };
    LeftNavigationComponent.prototype.setAldQuoteUrl = function () {
        var _this = this;
        var windowRef = window.open();
        this.snackBarService.loading(this.translateService.instant('global-generic_terms-snackbar_loading'));
        combineLatest([
            this.featureService.isFeatureActivated(FeatureConst.features.aldQuoteWithToken),
            this.featureService.isFeatureActivated(FeatureConst.features.aldQuoteWithoutToken),
            this.userService.getExternalAppToken(),
        ]).pipe(takeWhile(function () { return _this.componentActive; }))
            .subscribe(function (_a) {
            var aldQuoteWithToken = _a[0], aldQuoteWithoutToken = _a[1], externalJwtToken = _a[2];
            if (_this.aldQuoteUrl && aldQuoteWithToken && externalJwtToken) {
                windowRef.location.href = _this.aldQuoteUrl + "?token=" + externalJwtToken;
            }
            if (_this.aldQuoteUrl && aldQuoteWithoutToken) {
                windowRef.location.href = _this.aldQuoteUrl;
            }
            _this.snackBarService.clear();
        }, function () {
            _this.snackBarService.error(_this.translateService.instant('global-generic_terms-snackbar_generic_error'));
        });
    };
    LeftNavigationComponent.prototype.setReportUrl = function () {
        var _this = this;
        this.configurationService.getItemFromConfiguration('ReportManagerUrl').subscribe(function (reportUrl) {
            if (reportUrl) {
                _this.reportUrl = reportUrl.value;
            }
        });
    };
    LeftNavigationComponent.prototype.setCarSelectorUrl = function () {
        var _this = this;
        this.configurationService.getItemFromConfiguration('CarSelectorManagerUrl')
            .subscribe(function (carSelectorUrl) {
            if (carSelectorUrl) {
                _this.carSelectorUrl = carSelectorUrl.value;
            }
        });
    };
    LeftNavigationComponent.prototype.openCarSelectorLink = function () {
        var _this = this;
        var windowRef = window.open();
        this.snackBarService.loading(this.translateService.instant('global-generic_terms-snackbar_loading'));
        this.userService.getExternalAppToken().subscribe(function (externalJwtToken) {
            if (externalJwtToken) {
                windowRef.location.href = _this.carSelectorUrl + "?token=" + externalJwtToken;
                _this.snackBarService.clear();
            }
        }, function () {
            _this.snackBarService.error(_this.translateService.instant('global-generic_terms-snackbar_generic_error'));
        });
    };
    LeftNavigationComponent.prototype.getLinkToTermsOfUse = function () {
        return environment.publicDocumentsBaseUrl + "/" + this.countryCode + "/disclaimer-" + this.culture + ".pdf";
    };
    LeftNavigationComponent.prototype.getLinkToCookiesPolicy = function () {
        // tslint:disable-next-line: max-line-length
        return environment.publicDocumentsBaseUrl + "/" + this.countryCode + "/cookies-charter-" + this.culture + ".pdf";
    };
    LeftNavigationComponent.prototype.getBrowser = function () {
        if (navigator.userAgent.indexOf('Chrome') !== -1) {
            return 'Chrome';
        }
        if (navigator.userAgent.indexOf('Safari') !== -1) {
            return 'Safari';
        }
    };
    return LeftNavigationComponent;
}());
export { LeftNavigationComponent };
