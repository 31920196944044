import { EditUserProfileRequest } from '@user-management/user-list/shared/models/user-edit-profile.model';
import { UserManagementConst } from '@user-management/shared/user-management.const';
import { MgrValidators } from '@shared/ui/forms/validators/custom.validator';
import { StorageConst } from '@shared/core/storage/storage.const';
import { catchError, filter, takeWhile, tap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, throwError } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserState } from '@shared/entity/user/state/user.reducer';
import { StorageService } from '@shared/core/storage/storage.service';
import { UserHelper } from '@shared/entity/user/user.helper';
import { UserListDataItem } from '@user-management/user-list/shared/models/user-list-data-item.model';
import { PanelService } from '@shared/ui/panel/shared/panel.service';
import { UserRoles } from '@shared/entity/user/user.const';
import { getUserFeatureState } from '@shared/entity/user/state';
import { UpdateMyUserProfile } from '@shared/entity/user/state/user.actions';
import { UserProfile } from '@shared/models/user-profile.model';
import { FeatureService } from '@shared/core/feature/feature.service';
import { FeatureConst } from '@shared/core/feature/feature.consts';

@Component({
  selector: 'mgr-edit-my-user-profile',
  templateUrl: './edit-my-profile-panel.component.html',
  styleUrls: ['./edit-my-profile-panel.component.scss'],
})
export class EditMyProfilePanelComponent implements OnInit, OnDestroy {
  @Input() selection: Observable<UserListDataItem[]>;

  public form: FormGroup;
  public userProfile: UserProfile;
  public currentCountry = null;
  public readonly USER_ROLES = UserRoles;
  public canEditOwnName: boolean;
  public canEditOwnProfile: boolean;
  public componentActive = true;
  constructor(
    private userStore: Store<UserState>,
    private storage: StorageService,
    private featureService: FeatureService,
    private panelService: PanelService) {
  }

  ngOnInit() {
    // TODO OD update this part of code to use the stored SelectedUserRole
    this.currentCountry = this.storage.get(StorageConst.selectedCountry).toLowerCase();
    this.initForm();

    this.userStore.pipe(
      select(getUserFeatureState),
      filter(state => state.user !== null),
    ).subscribe((state) => {
      const user = state.user;
      if (user) {
        this.userProfile = user;
        this.fillForm();
      }
    });
  }

  private initForm() {
    this.form = new FormGroup({
      name: new FormControl('', [MgrValidators.required]),
      email: new FormControl('', [MgrValidators.required, MgrValidators.customEmail]),
      companyName: new FormControl('', MgrValidators.required),
      companyLocation: new FormControl('', MgrValidators.required),
      address: new FormControl('', []),
      postalcode: new FormControl('', []),
      city: new FormControl('', []),
      phone: new FormControl('', []),
      companyPhone: new FormControl('', []),
    });

    if (this.form) {
      this.form.reset();

      combineLatest([
        this.featureService.isFeatureActivated(FeatureConst.features.canEditOwnNameActivated),
        this.featureService.isFeatureActivated(FeatureConst.features.canEditOwnProfileActivated),
      ]).pipe(
        takeWhile(() => this.componentActive),
        catchError((error) => {
          return throwError(error);
        }),
        tap(([canEditOwnName, canEditOwnProfile]) => {
          this.canEditOwnName = canEditOwnName;
          this.canEditOwnProfile = canEditOwnProfile;
          this.form.get('email').disable();
          if (!this.canEditOwnName) {
            this.form.get('name').disable();
          }
          if (!this.canEditOwnProfile) {
            this.form.get('name').disable();
            this.form.get('companyName').disable();
            this.form.get('companyLocation').disable();
            this.form.get('address').disable();
            this.form.get('postalcode').disable();
            this.form.get('city').disable();
            this.form.get('phone').disable();
            this.form.get('companyPhone').disable();
          }
          this.userProfile = null;
        })).subscribe();
    }
  }

  private fillForm() {
    if (this.userProfile) {
      this.form.get('name').setValue(this.userProfile.name);
      this.form.get('email').setValue(this.userProfile.email);
      const userCompany = UserHelper.getUserCompany(this.userProfile, this.currentCountry);

      if (userCompany) {
        this.form.get('companyName').setValue(userCompany.name);
        this.form.get('companyLocation').setValue(userCompany.address);
        this.form.get('companyPhone').setValue(userCompany.phone);
      }

      if (this.userProfile.address) {
        this.form.get('address').setValue(this.userProfile.address);
      }

      if (this.userProfile.postalCode) {
        this.form.get('postalcode').setValue(this.userProfile.postalCode);
      }

      if (this.userProfile.city) {
        this.form.get('city').setValue(this.userProfile.city);
      }

      if (this.userProfile.phone) {
        this.form.get('phone').setValue(this.userProfile.phone);
      }
    }
  }

  public cancelEdit(): void {
    this.panelService.close(UserManagementConst.windowCodename.editMyProfilePanel);
  }

  public updateUserProfile() {
    if (this.form.valid) {

      const userRequest: EditUserProfileRequest = {
        name: this.form.get('name').value,
        address: this.form.get('address').value,
        postalCode: this.form.get('postalcode').value,
        city: this.form.get('city').value,
        phone: this.form.get('phone').value,
      };

      this.userStore.dispatch(new UpdateMyUserProfile(userRequest));
      this.panelService.close(UserManagementConst.windowCodename.editMyProfilePanel);
    }
  }
  ngOnDestroy() {
    this.componentActive = false;
  }
}
