import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { PopinModule } from '../../ui/popin/popin.module';
import { MgrFormsModule } from '../../ui/forms/forms.module';
import { PipesModule } from '../../ui/pipes/pipes.module';
import { ErrorService } from './error.service';

@NgModule({
  imports: [
    PipesModule,
    MgrFormsModule,
    PopinModule,
    TranslateModule,
    CommonModule,
  ],
  providers: [
    ErrorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
})
export class ErrorModule { }
