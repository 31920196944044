import { PopinActions, PopinActionTypes } from './popin.actions';

export interface PopinState {
  showPopins: string[];
}

export const initialState: PopinState = {
  showPopins: [],
};

export function reducer(state = initialState, action: PopinActions): PopinState {
  switch (action.type) {

    case PopinActionTypes.OpenPopin:
      return {
        ...state,
        showPopins: [...state.showPopins, action.payload],
      };

    case PopinActionTypes.ClosePopin:
      return {
        ...state,
        showPopins: state.showPopins.filter((popin) => {
          return popin !== action.payload;
        }),
      };

    default:
      return state;
  }
}
