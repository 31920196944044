import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as popinState from '../state';
import * as actions from '../state/popin.actions';

@Injectable()
export class PopinService {

  constructor(private store: Store<popinState.State>) {
  }

  public open(codename: string): void {
    this.store.dispatch(new actions.OpenPopin(codename));
  }

  public close(codename: string): void {
    this.store.dispatch(new actions.ClosePopin(codename));
  }

}
