import { Action } from '@ngrx/store';

export enum StartActionTypes {
  UpdateRecaptchaAuth = '[Start] Value to know about recaptcha',
}

export class UpdateRecaptchaAuth implements Action {
  readonly type = StartActionTypes.UpdateRecaptchaAuth;
  constructor(public payload: string) {
  }
}

export type StartActions = UpdateRecaptchaAuth;
