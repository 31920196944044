import { environment } from '../../../environments/environment';

export const StartConst = {
  api: {
    baseUrl: `${environment.apigatewayUrl}/apigateway/`,
    modules: {
      start: {
        url: 'usermanagement/api/',
        endpoints: {
          requestAccess: 'Request/RequestAccess',
          checkToken: 'User/CheckToken',
          resetPassword: 'User/ResetPassword',
          forgotPassword: 'User/ForgotPassword',
        },
      },
    },
  },
  windowCodename: {
    passwordExpirationPopin: 'passwordExpirationPopin',
  },
};
