import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NotificationsConst } from '../notifications.const';
import { Notification } from '../models/notification.model';
import { TransferHttpService } from '@shared/core/transfer-http';

@Injectable()
export class NotificationsService {

  constructor(private httpClient: TransferHttpService) {
  }

  public getNotifications(): Observable<Notification[]> {
    const notificationsModule = NotificationsConst.api.modules.notifications;
    const url = NotificationsConst.api.baseUrl + notificationsModule.url + notificationsModule.endpoints.GetNotifications;
    return this.httpClient.get(url);
  }

  public deleteNotifications(notifications: Notification[]): Observable<any[]> {
    if (!notifications || notifications.length === 0) {
      return of([]);
    }
    const notificationsModule = NotificationsConst.api.modules.notifications;
    const url = NotificationsConst.api.baseUrl + notificationsModule.url + notificationsModule.endpoints.DeleteNotifications;
    const body: string[] = notifications.map((notification) => {
      return notification.id;
    });
    return this.httpClient.post(url, body);
  }

  public createNotification(from: string, notification: FormData) {
    const notificationsModule = NotificationsConst.api.modules.notifications;
    let url = NotificationsConst.api.baseUrl + notificationsModule.url;
    switch (from) {
      case 'notification':
        url = url + notificationsModule.endpoints.CreateNotification;
        break;
      case 'contract':
        url = url + notificationsModule.endpoints.CreateContractsNotification;
        break;
      case 'document':
        url = url + notificationsModule.endpoints.CreateDocumentsNotification;
        break;
      case 'user':
        url = url + notificationsModule.endpoints.CreateUsersNotification;
        break;
    }
    return this.httpClient.postFile(url, notification);
  }

  public getAlerts(): Observable<Notification[]> {
    const notificationsModule = NotificationsConst.api.modules.notifications;
    const url = NotificationsConst.api.baseUrl + notificationsModule.url + notificationsModule.endpoints.GetAlerts;
    return this.httpClient.get(url);
  }

  public updateSeenAlertTime() {
    const notificationsModule = NotificationsConst.api.modules.notifications;
    const url = NotificationsConst.api.baseUrl + notificationsModule.url + notificationsModule.endpoints.UpdateReadDate;
    return this.httpClient.put(url, {});
  }
}
