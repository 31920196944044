import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as navigationState from '../state';
import { takeWhile } from 'rxjs/operators';
import { fadeAnimation } from '@shared/ui/animations/fade.animation';
import { TranslationService } from '@shared/core/translation/translation.service';
import { PanelService } from '@shared/ui/panel/shared/panel.service';
import { UserManagementConst } from '@user-management/shared/user-management.const';
import { UserState } from '@shared/entity/user/state/user.reducer';
import { getUserSelectedWorkingScope, getUserSelectedRole } from '@shared/entity/user/state';
import { APP_ROUTES } from '@app/app.routes';
import { UserRoles } from '@shared/entity/user/user.const';
import { NotificationsService } from '@notifications/shared/services/notifications.service';
import { Notification, NotificationCategory } from '@notifications/shared/models/notification.model';
import { Observable } from 'rxjs';
import { EditUserPreferencesRequest } from '@user-management/user-list/shared/models/user-edit-preferences.model';
import { UpdateMyPreferences } from '@app/shared/entity/user/state/user.actions';
import { CONFIGURATION } from 'country-configuration/country-configuration';
import { NotificationsState } from '@app/notifications/shared/state/notifications.reducer';
import { getManagerAlerts, getManagerAlertsLoading, getUnreadNotificationsNumber } from '@app/notifications/shared/state';
import * as NotificationsActions from '@notifications/shared/state/notifications.actions';
import { StorageService } from '@app/shared/core/storage/storage.service';

@Component({
  selector: 'mgr-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss'],
  animations: [fadeAnimation],
})
export class TopNavigationComponent implements OnInit, OnDestroy {
  public showNotifications: boolean;
  public showLanguages: boolean;
  public availableCultures: string[];
  private componentActive = true;
  public selectedWorkingScopesSize = 0;
  public notifications$: Observable<Notification[]>;
  public notificationsLoading$: Observable<boolean>;
  public unreadNotifications$: Observable<number>;
  public readonly APP_ROUTES = APP_ROUTES;
  public readonly USER_ROLES = UserRoles;
  public country: string;
  public countryLogo: string;
  public countryName: string;
  public nffleetCounty = 'Nffleet';
  constructor(
    private translationService: TranslationService,
    private store: Store<navigationState.State | UserState | NotificationsState>,
    private notificationService: NotificationsService,
    private panelService: PanelService,
    private readonly storageService: StorageService,
    ) {
  }

  ngOnInit(): void {
    this.availableCultures = CONFIGURATION.getCountry().cultures;
    this.country = CONFIGURATION.getCountry();
    this.countryLogo = CONFIGURATION.getCountry().company_logo;
    this.countryName = CONFIGURATION.getCountry().countryName;
    this.store.pipe(
      select(navigationState.getShowLanguages),
      takeWhile(() => this.componentActive),
    ).subscribe((status) => {
      this.showLanguages = status;
    });

    this.store.pipe(
      select(navigationState.getShowNotifications),
      takeWhile(() => this.componentActive),
    ).subscribe((status) => {
      this.showNotifications = status;
    });

    this.store.pipe(select(getUserSelectedRole)).pipe(
      takeWhile(() => this.componentActive),
    ).subscribe((user) => {
      if (user && user.role && user.role.includes(UserRoles.FleetManager)) {
        this.store.dispatch(new NotificationsActions.GetManagerAlerts());

        this.notifications$ = this.store.pipe(
          select(getManagerAlerts),
          takeWhile(() => this.componentActive),
        );

        this.notificationsLoading$ = this.store.pipe(
          select(getManagerAlertsLoading),
          takeWhile(() => this.componentActive),
        );

        this.unreadNotifications$ = this.store.pipe(
          select(getUnreadNotificationsNumber),
          takeWhile(() => this.componentActive),
        );
      }
    });

    this.initSelectedWorkingScopeSize();

  }

  get currentLanguage(): string {
    return this.availableCultures ? this.availableCultures.find(l => l === this.translationService.language) : '';
  }

  public toggleUserNavigation(status): void {
    this.store.dispatch(new navigationState.actions.ToggleUserNavigation(status));
  }

  public toggleLanguage(): void {
    this.store.dispatch(new navigationState.actions.ToggleLanguages(!this.showLanguages));
  }

  public openSwitchWorkingScopePanel(): void {
    this.panelService.open(UserManagementConst.windowCodename.switchWorkingScopePanel);
  }

  public switchLanguage(selectedLanguage: string): void {
    this.toggleLanguage();
    const preferences: EditUserPreferencesRequest = { culture: selectedLanguage };
    this.store.dispatch(new UpdateMyPreferences(preferences));
  }

  private initSelectedWorkingScopeSize(): void {
    this.store.pipe(
      select(getUserSelectedWorkingScope),
      takeWhile(() => this.componentActive),
    ).subscribe((selectedWorkingScope) => {
      if (selectedWorkingScope) {
        this.selectedWorkingScopesSize = selectedWorkingScope.length;
      }
    });
  }

  public openNotificationPanel(): void {
    this.panelService.open(UserManagementConst.windowCodename.userAlertsPanel);
    this.notificationService.updateSeenAlertTime().subscribe();
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }
}
