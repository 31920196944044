import * as tslib_1 from "tslib";
import { FeatureActionTypes } from './feature.actions';
export var initialState = {
    loading: false,
    isLoaded: true,
    items: null,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var loading;
    var items;
    switch (action.type) {
        case FeatureActionTypes.GetFeature:
            loading = true;
            return tslib_1.__assign({}, state, { loading: loading });
        case FeatureActionTypes.GetFeatureSuccess:
            loading = false;
            items = action.payload;
            return tslib_1.__assign({}, state, { loading: loading, items: items, isLoaded: true });
        case FeatureActionTypes.GetFeatureFail:
            loading = false;
            return tslib_1.__assign({}, state, { loading: loading, isLoaded: true });
        default:
            return state;
    }
}
