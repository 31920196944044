import { EditUserProfileRequest } from '@user-management/user-list/shared/models/user-edit-profile.model';
import { Action } from '@ngrx/store';
import { UserRole, UserWorkingScope } from '@shared/models/user-role.model';
import { UserProfile } from '@shared/models/user-profile.model';
import { EditUserPreferencesRequest } from '@app/user-management/user-list/shared/models/user-edit-preferences.model';

export enum UserActionTypes {
  GetUser = '[User] Get',
  GetUserSuccess = '[User] Get success',
  GetUserFail = '[User] Get fail',
  SwitchRole = '[User] Switch Role',
  UpdateWorkingScope = '[User] Update selected working scope',
  UpdateWorkingScopeSuccess = '[User] Update selected working scope success',
  UpdateWorkingScopeFail = '[User] Update selected working scope fail',
  UpdateMyUserProfile = '[User] Update my user profile',
  UpdateMyUserProfileSuccess = '[User] Update my user profile success',
  UpdateMyUserProfileFail = '[User] Update my user profile fail',
  ClearUserStore = '[User] Clear user slice in store when loging out',
  UpdateMyPreferences = '[User] Update preferences',
  UpdateMyPreferencesSuccess = '[User] Update preferences success',
  UpdateMyPreferencesFail = '[User] Update preferences fail',
  UpdateLastLoginDate = '[User] Update last login date',
  UpdateLastLoginDateSuccess = '[User] Update last login date success',
  UpdateLastLoginDateFail = '[User] Update last login date fail',
  UpdateSelectedCulture = '[User] Update selected culture',
}

export class GetUser implements Action {
  readonly type = UserActionTypes.GetUser;
}

export class GetUserSuccess implements Action {
  readonly type = UserActionTypes.GetUserSuccess;

  constructor(public payload: UserProfile) { }
}

export class GetUserFail implements Action {
  readonly type = UserActionTypes.GetUserFail;
}

export class SwitchRole implements Action {
  readonly type = UserActionTypes.SwitchRole;

  constructor(public payload: UserRole) { }
}

export class UpdateWorkingScope implements Action {
  readonly type = UserActionTypes.UpdateWorkingScope;

  constructor(public payload: UserWorkingScope[]) { }
}

export class UpdateWorkingScopeSuccess implements Action {
  readonly type = UserActionTypes.UpdateWorkingScopeSuccess;

  constructor(public payload: UserWorkingScope[]) { }
}

export class UpdateWorkingScopeFail implements Action {
  readonly type = UserActionTypes.UpdateWorkingScopeFail;
}

export class UpdateMyUserProfile implements Action {
  readonly type = UserActionTypes.UpdateMyUserProfile;

  constructor(public payload: EditUserProfileRequest) { }
}

export class UpdateMyUserProfileSuccess implements Action {
  readonly type = UserActionTypes.UpdateMyUserProfileSuccess;
}

export class UpdateMyUserProfileFail implements Action {
  readonly type = UserActionTypes.UpdateMyUserProfileFail;
}

export class ClearUserStore implements Action {
  readonly type = UserActionTypes.ClearUserStore;
}

export class UpdateMyPreferences implements Action {
  readonly type = UserActionTypes.UpdateMyPreferences;

  constructor(public payload: EditUserPreferencesRequest, public showToaster = true) { }
}

export class UpdateMyPreferencesSuccess implements Action {
  readonly type = UserActionTypes.UpdateMyPreferencesSuccess;

  constructor(public payload: EditUserPreferencesRequest) { }
}

export class UpdateMyPreferencesFail implements Action {
  readonly type = UserActionTypes.UpdateMyPreferencesFail;
}

export class UpdateLastLoginDate implements Action {
  readonly type = UserActionTypes.UpdateLastLoginDate;
}

export class UpdateLastLoginDateSuccess implements Action {
  readonly type = UserActionTypes.UpdateLastLoginDateSuccess;
}

export class UpdateLastLoginDateFail implements Action {
  readonly type = UserActionTypes.UpdateLastLoginDateFail;
}

export class UpdateSelectedCulture implements Action {
  readonly type = UserActionTypes.UpdateSelectedCulture;
  constructor(public payload: string) { }
}

export type UserActions = GetUser | GetUserSuccess | GetUserFail | SwitchRole |
  UpdateWorkingScope | UpdateWorkingScopeSuccess | UpdateWorkingScopeFail |
  UpdateMyUserProfile | UpdateMyUserProfileSuccess | UpdateMyUserProfileFail | ClearUserStore |
  UpdateMyPreferences | UpdateMyPreferencesSuccess | UpdateMyPreferencesFail |
  UpdateLastLoginDate | UpdateLastLoginDateSuccess | UpdateLastLoginDateFail | UpdateSelectedCulture;
