import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as navigationState from './state';
import { takeWhile } from 'rxjs/operators';
import { UserManagementConst } from '@user-management/shared/user-management.const';
import { UserState } from '@shared/entity/user/state/user.reducer';
import { PanelService } from '@shared/ui/panel/shared/panel.service';
import { UserRoles } from '@shared/entity/user/user.const';
import { getUserSelectedRole } from '../shared/entity/user/state';

@Component({
  selector: 'mgr-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
})
export class AppShellComponent implements OnInit, OnDestroy {
  public showFullNavigation: boolean;
  private componentActive = true;

  constructor(
    private store: Store<navigationState.State>,
    private userStore: Store<UserState>,
    private panelService: PanelService,
  ) {
  }

  ngOnInit(): void {
    this.store.pipe(select(navigationState.getShowFullNavigation), takeWhile(() => this.componentActive),
    ).subscribe((status) => {
      this.showFullNavigation = status;
    });

    this.userStore.pipe(
      select(getUserSelectedRole),
      takeWhile(() => this.componentActive),
    ).subscribe((selectedUserRole) => {
      if (selectedUserRole && selectedUserRole.workingScopes.length === 0 && selectedUserRole.role !== UserRoles.Supplier) {
        this.panelService.open(UserManagementConst.windowCodename.switchWorkingScopePanel);
      } else {
        this.panelService.close(UserManagementConst.windowCodename.switchWorkingScopePanel);
      }
    });
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }
}
