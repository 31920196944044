import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../../../app.state';
import * as fromPanel from './panel.reducer';

export interface State extends fromRoot.State {
  panel: fromPanel.PanelState;
}

export const getPanelFeatureState = createFeatureSelector<fromPanel.PanelState>('panel');

export const getPanel = createSelector(
  getPanelFeatureState,
  (panels, codename) => panels.showPanels.filter(p => p === codename),
);
