import { NotificationsActions, NotificationsActionTypes, UpdateNotification } from './notifications.actions';
import { Notification } from '../models/notification.model';

export interface NotificationsState {
  loading: boolean;
  notifications: Notification[];
  selectedNotificationId: string;
  managerAlerts: Notification[];
  managerAlertsLoading: boolean;
}

export const initialState: NotificationsState = {
  loading: false,
  notifications: [],
  selectedNotificationId: null,
  managerAlerts: [],
  managerAlertsLoading: false,
};

export function reducer(state = initialState, action: NotificationsActions): NotificationsState {

  switch (action.type) {
    case NotificationsActionTypes.GetNotifications:
      return { ...state, loading: true };

    case NotificationsActionTypes.GetNotificationsSuccess:
      return { ...state, loading: false, notifications: action.payload };

    case NotificationsActionTypes.GetNotificationsFail:
      return { ...state, loading: false, notifications: [] };

    case NotificationsActionTypes.DeleteNotifications:
      return {
        ...state,
        notifications: state.notifications.filter((notifcation) => {
          return !action.payload.includes(notifcation);
        }),
      };

    case NotificationsActionTypes.CreateNotification:
      return { ...state, loading: true };

    case NotificationsActionTypes.CreateNotificationSuccess:
      return { ...state, loading: false };

    case NotificationsActionTypes.CreateNotificationFail:
      return { ...state, loading: false };

    case NotificationsActionTypes.SelectNotificationToEdit:
      return { ...state, loading: false, selectedNotificationId: action.payload };

    case NotificationsActionTypes.UpdateNotification:
      const updatedNotification = action.payload;
      const indexToUpdate = state.notifications.findIndex(notification => notification.id === updatedNotification.id);
      state.notifications[indexToUpdate] = updatedNotification;
      return { ...state, notifications: [...state.notifications] };

    case NotificationsActionTypes.RemoveNotificationFromSelection:
      return { ...state, selectedNotificationId: null };

    case NotificationsActionTypes.GetManagerAlerts:
      return { ...state, managerAlertsLoading: true };

    case NotificationsActionTypes.GetManagerAlertsSuccess:
      return { ...state, managerAlertsLoading: false, managerAlerts: action.payload };

    case NotificationsActionTypes.GetManagerAlertsFail:
      return { ...state, managerAlertsLoading: false, managerAlerts: [] };

    default:
      return state;
  }
}
