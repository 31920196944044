export const APP_ROUTES = {

  HOME: 'dashboard',
  NOT_FOUND: 'not-found',
  USER_ERROR: 'user_error',
  COUNTRY_ACCESS_ERROR: 'coutry-access-error',

  // ## Anonymous Routes
  START: 'start',
  COUNTRY_SELECTOR: 'country-selector',
  LOGIN: 'login',
  SIGN_UP: 'sign-up',
  SIGN_UP_SUCCESS: 'success',
  SIGN_UP_ERROR: 'error',
  SET_PASSWORD: 'set-password',
  FORGOT_PASSWORD: 'forgot-password',
  ONLOGIN: 'onlogin',

  // ## UserManagement Routes
  USER_MANAGEMENT: 'user-management',
  REQUEST_APPROVAL: 'request-approval',
  REQUEST_APPROVAL_APPROVE: 'approve-request',
  USER_LIST: 'user-list',
  USER_LIST_CREATE_USER: 'create-user',
  USER_LIST_SEND_NOTIFICATION: 'send-notification',
  USER_LIST_TOGGLE_STATE: 'toggle-state',
  USER_LIST_EDIT: 'edit-profile',
  USER_LIST_ASSIGN_POLICIES: 'assign-car-policies',
  IMPORT_PROSPECT: 'import-prospect',
  WHITELIST_DOMAINS: 'whitelist-domain',
  WHITELIST_DOMAINS_ADD: 'register',
  WHITELIST_DOMAINS_EDIT: 'edit',
  GIVE_ACCESS: 'myald-access',
  GIVE_ACCESS_APPROVE: 'give-access',

  // ## MyFleet Routes
  MY_FLEET: 'my-fleet',
  CONTRACT_LIST: 'contracts',
  CONTRACT_DRIVER_INFO: 'driver-information',
  CONTRACT_DETAILS: 'contract-details',
  CONTRACT_VEHICLE_INFORMATION: 'vehicle-information',
  CONTRACT_FUEL_CARDS: 'fuel-cards',
  CONTRACT_SEND_NOTIFICATION: 'send-notification',
  CONTRACT_DRIVER_ASSISTANCE: 'assistance',
  CONTRACT_ASK_PERMISSION: 'ask-permission',
  CONTRACT_ORDER_DAMAGE_REPAIR: 'order-damage-repair',
  CONTRACT_ORDER_MAINTENANCE: 'order-maintenance',
  CONTRACT_ONLINE_BOOKING: 'online-booking',
  ORDER_LIST: 'orders',
  ORDER_DETAIL: 'tab-order-information',
  ORDER_DRIVER_INFO: 'driver-information',
  MAINTENANCE_HISTORY: 'maintenance-history',
  FUEL_CARD_HISTORY: 'fuel-card-history',
  SALESFORCE_TICKETS: 'salesforce-tickets',
  SALESFORCE_MANAGE_TICKETS: 'manage',

  // ## Documents Routes
  DOCUMENTS: 'documents',
  DOCUMENT_LIST: 'document-list',
  DOCUMENT_LIST_SHARED_BY_DRIVER: 'document-list-shared-by-driver',
  DOCUMENT_UPLOAD: 'upload-document',
  DOCUMENT_EDIT: 'edit-document',
  DOCUMENT_SEND_NOTIFICATION: 'send-notification',

  // ## MyReports Routes
  REPORTS: 'my-reports',
  IFRS_REPORTS: 'ifrs-reports',

  // ## Notifications Routes
  NOTIFICATIONS: 'notifications',
  NOTIFICATION_CREATE: 'create-notification',
  NOTIFICATION_EDIT: 'edit-notification',

  // ## Administration Routes
  ADMINISTRATION: 'administration',
  ADMINISTRATOR_LIST: 'administrator-list',
  CREATE_ADMINISTRATOR: 'create-administrator',
  EDIT_ADMINISTRATOR: 'edit-administrator',
  ENABLE_DISABLE_ADMINISTRATOR: 'enable-disable-administrator',
  ENABLE_DISABLE_SUPPLIER: 'enable-disable-supplier',
  CREATE_SUPPLIER: 'create-supplier',
  EDIT_SUPPLIER: 'edit-supplier',
  SUPPLIER_LIST: 'supplier-list',
  POS_LISTING: 'pos-listing',
  EDIT_POS: 'edit-pos',
  CREATE_POS: 'create-pos',
  TRANSLATION_LANDING_PAGE: 'translation-management',
  ADD_TRANSLATION_PANEL: 'add-translation',
  FEATURES_LANDING_PAGE: 'feature-management',
  MANAGE_FEATURE_FOR_COMPANY_PANEL: 'manage-feature-for-company',
  MANAGE_SHARE_WITH_FM: 'shareDocumentToFM',
  MANAGE_QUOTER: 'quoterSelector',

  // ## Services Routes
  SERVICES: 'services',
  TRAFFIC_FINES: 'traffic-fines',

  // ## ALD sat Routes
  ALDSAT: 'ald-sat',
};
