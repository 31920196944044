import { UserActions, UserActionTypes } from './user.actions';
import { UserRole } from '@shared/models/user-role.model';
import { UserProfile } from '@shared/models/user-profile.model';

export interface UserState {
  loading: boolean;
  isLoaded: boolean;
  user: UserProfile;
  selectedUserRole: UserRole;
  workingScope: string[];
  language: string;
}

export const initialState: UserState = {
  loading: false,
  isLoaded: false,
  user: null,
  selectedUserRole: null,
  workingScope: [],
  language: null,
};

export function reducer(state = initialState, action: UserActions): UserState {
  let loading: boolean;

  switch (action.type) {
    case UserActionTypes.GetUser:
      loading = true;
      return { ...state, loading };

    case UserActionTypes.GetUserSuccess:
      loading = false;
      const user = action.payload;
      return { ...state, loading, user, isLoaded: true };

    case UserActionTypes.GetUserFail:
      loading = false;
      return { ...state, loading, isLoaded : true };

    case UserActionTypes.UpdateWorkingScope:
      loading = true;
      return { ...state, loading };

    case UserActionTypes.UpdateWorkingScopeSuccess:
      const newUser = Object.assign({}, state.user);
      newUser.userRoles.find(r => r.id === state.selectedUserRole.id).workingScopes = action.payload;
      const newRole = state.selectedUserRole;
      newRole.workingScopes = action.payload;
      loading = false;
      return { ...state, loading, user: newUser, selectedUserRole: newRole };

    case UserActionTypes.UpdateWorkingScopeFail:
      loading = false;
      return { ...state, loading };

    case UserActionTypes.SwitchRole:
      return { ...state, selectedUserRole: action.payload };

    case UserActionTypes.UpdateMyUserProfile:
      loading = true;
      return { ...state, loading };

    case UserActionTypes.UpdateMyUserProfileSuccess:
      loading = false;
      return { ...state, loading, user };

    case UserActionTypes.UpdateMyUserProfileFail:
      loading = false;
      return { ...state, loading };

    case UserActionTypes.ClearUserStore:
      loading = false;
      return { ...initialState, loading };

    case UserActionTypes.UpdateMyPreferences:
      loading = true;
      return { ...state, loading };

    case UserActionTypes.UpdateSelectedCulture:
      return { ...state, language: action.payload };

    case UserActionTypes.UpdateMyPreferencesSuccess:
      loading = false;
      const selectedUserRole = state.selectedUserRole;
      selectedUserRole.culture = action.payload.culture;
      selectedUserRole.notificationByEmail = action.payload.notificationByEmail;
      selectedUserRole.notificationByPush = action.payload.notificationByPush;
      return { ...state, loading };

    case UserActionTypes.UpdateMyPreferencesFail:
      loading = false;
      return { ...state, loading };

    default:
      return state;
  }
}
