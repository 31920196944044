import { environment } from '../../../environments/environment';
import { APP_ROUTES } from '@app/app.routes';
import { UserRoleFilter } from '../../user-management/shared/filters/user-profile.filter';
import { DateFilter } from './filters/date.filter';

export const NotificationsConst = {
  api: {
    baseUrl: `${environment.apigatewayUrl}/apigateway/`,
    modules: {
      notifications: {
        url: 'feedmanagement/api/',
        endpoints: {
          GetNotifications: 'FeedManagement/items',
          CreateNotification: 'FeedManagement/item',
          DeleteNotifications: 'FeedManagement/Delete',
          GetAlerts: 'feed/items',
          UpdateReadDate: 'Feed/ReadingDate',
          CreateContractsNotification: 'FeedManagement/createfeeditembycontractids',
          CreateUsersNotification: 'FeedManagement/createFeedItemByUserIds',
          CreateDocumentsNotification: 'FeedManagement/createFeedItemByDocumentId',
          EditNotification: 'FeedManagement/editFeedItem',
        },
      },
    },
  },
  breadcrumbs: {
    notificationsHistory:  {
      path: `/${APP_ROUTES.NOTIFICATIONS}`,
      label: 'global-breadcrumbs-notifications_label',
    },
  },
  windowCodename: {
    createNotificationPanel: 'createNotificationPanel',
    deleteNotificationPopin: 'deleteNotificationPopin',
  },
  filters: {
    notificationList: [
      UserRoleFilter,
      DateFilter,
    ],
  },
  displayedColumns: {
    notificationList: [
      'select',
      'preview',
      'title',
      'date',
      'description',
      'companies',
      'roles',
      'users',
      'language',
    ],
  },
  allowedImageTypes: [
    'image/x-windows-bmp',
    'image/bmp',
    'image/png',
    'image/jpeg',
    'image/pjpeg',
    'image/gif',
  ],
  allLanguages : 'All Languages',
};
