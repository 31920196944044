import * as tslib_1 from "tslib";
import { FeatureService } from './feature.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FeatureActionTypes, GetFeatureFail, GetFeatureSuccess } from './feature.actions';
import { Observable, of } from 'rxjs';
var FeatureEffects = /** @class */ (function () {
    function FeatureEffects(featureService, actions$) {
        var _this = this;
        this.featureService = featureService;
        this.actions$ = actions$;
        this.getFeature$ = this.actions$.pipe(ofType(FeatureActionTypes.GetFeature), switchMap(function () {
            return _this.featureService.get()
                .pipe(map(function (data) { return new GetFeatureSuccess(data); }), catchError(function () { return of(new GetFeatureFail()); }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], FeatureEffects.prototype, "getFeature$", void 0);
    return FeatureEffects;
}());
export { FeatureEffects };
