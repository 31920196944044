import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AuthenticationActionTypes } from './authentication.actions';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { UpdateLastLoginDate } from '@app/shared/entity/user/state/user.actions';

@Injectable()
export class AuthenticationEffects {

  @Effect()
  effect$ = this.actions$.pipe(ofType(AuthenticationActionTypes.AuthenticationRefreshTokens));

  @Effect()
  authenticate$: Observable<UpdateLastLoginDate> = this.actions$.pipe(
    ofType(AuthenticationActionTypes.AuthenticationAuthenticate),
    mergeMap(() => of(new UpdateLastLoginDate())),
  );

  constructor(private actions$: Actions) {}
}
