/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./shared/ui/popin/popin.component.ngfactory";
import * as i2 from "./shared/ui/popin/popin.component";
import * as i3 from "@ngrx/store";
import * as i4 from "./shared/ui/popin/shared/popin.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "./shared/ui/popin/directive/close-popin.directive";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "@angular/router";
import * as i13 from "./app.component";
import * as i14 from "./shared/core/gtm/gtm.service";
import * as i15 from "./shared/ui/app-loader/app-loader.service";
import * as i16 from "./shared/core/translation/translation.service";
import * as i17 from "./shared/core/storage/storage.auth.service";
import * as i18 from "./shared/core/storage/storage.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "mgr-popin", [["codename", "unsupportedBrowserPopin"], ["size", "sm"]], null, null, null, i1.View_PopinComponent_0, i1.RenderType_PopinComponent)), i0.ɵdid(1, 245760, null, 0, i2.PopinComponent, [i3.Store, i4.PopinService], { codename: [0, "codename"], size: [1, "size"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 3, "div", [["class", "popin-header no-border text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "h2", [["class", "popin-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(6, 0, null, 0, 3, "div", [["class", "popin-content text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(10, 0, null, 0, 5, "div", [["class", "popin-footer text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 4, "button", [["color", "primary"], ["mat-raised-button", ""], ["mgrClosePopin", "unsupportedBrowserPopin"], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i0.ɵdid(12, 81920, null, 0, i7.ClosePopinDirective, [i3.Store, i4.PopinService], { mgrClosePopin: [0, "mgrClosePopin"] }, null), i0.ɵdid(13, 180224, null, 0, i8.MatButton, [i0.ElementRef, i9.Platform, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵted(14, 0, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(16, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(17, 212992, null, 0, i12.RouterOutlet, [i12.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_0 = "unsupportedBrowserPopin"; var currVal_1 = "sm"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_6 = "unsupportedBrowserPopin"; _ck(_v, 12, 0, currVal_6); var currVal_7 = "primary"; _ck(_v, 13, 0, currVal_7); _ck(_v, 17, 0); }, function (_ck, _v) { var currVal_2 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("global-browser_version_popin-title")); _ck(_v, 4, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform("global-browser_version_popin-text")); _ck(_v, 8, 0, currVal_3); var currVal_4 = (i0.ɵnov(_v, 13).disabled || null); var currVal_5 = (i0.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_4, currVal_5); var currVal_8 = i0.ɵunv(_v, 14, 0, i0.ɵnov(_v, 15).transform("global-browser_version_popin-close")); _ck(_v, 14, 0, currVal_8); }); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mgr-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 245760, null, 0, i13.AppComponent, [i14.GtmService, i12.Router, i3.Store, i15.AppLoaderService, i16.TranslationService, i17.StorageAuthService, i4.PopinService, i18.StorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("mgr-root", i13.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
