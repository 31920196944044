import { AldIconModule } from '@ald/ui';
import { NgModule } from '@angular/core';
import { InputErrorsComponent } from './input-errors/input-errors.component';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoadingModule } from '../loading/loading.module';
import { RadioComponent } from './radio/radio.component';
import { SelectComponent } from './select/select.component';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { InputLicensePlateComponent } from './input-license-plate/input-license-plate.component';
import { SvgInlineModule } from '../svg-inline/svg-inline.module';
import { ManagerValidatorsModule } from './validators/manager-validators.module';
import { FormErrorComponent } from './form-error/form-error.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { MultiSelectBrandComponent } from './multiselect-brand/multiselect-brand.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  MatCheckboxModule,
  MatChipsModule,
  MatFormFieldModule,
  MatRippleModule,
  MatSelectModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
} from '@angular/material';
import { SelectSearchComponent } from './select-search/select-search.component';
import { MultiselectAutocompleteComponent } from '@shared/ui/forms/multiselect-autocomplete/multiselect-autocomplete.component';
import { SelectAutocompleteComponent } from '@shared/ui/forms/select-autocomplete/select-autocomplete.component';
import { PasswordValidationComponent } from './password-validation/password-validation.component';
import { ApplyFilterwithButtonComponent } from '@shared/ui/forms/apply-filter-with-button/apply-filter-with-button';

@NgModule({
  declarations: [
    InputErrorsComponent,
    RadioComponent,
    InputLicensePlateComponent,
    SelectComponent,
    FormErrorComponent,
    MultiselectComponent,
    MultiSelectBrandComponent,
    SelectSearchComponent,
    MultiselectAutocompleteComponent,
    SelectAutocompleteComponent,
    PasswordValidationComponent,
    ApplyFilterwithButtonComponent,
  ],
  exports: [
    InputErrorsComponent,
    RadioComponent,
    InputLicensePlateComponent,
    SelectComponent,
    FormErrorComponent,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    MultiselectComponent,
    MultiSelectBrandComponent,
    SelectSearchComponent,
    MultiselectAutocompleteComponent,
    SelectAutocompleteComponent,
    PasswordValidationComponent,
    ApplyFilterwithButtonComponent,
  ],
  imports: [
    CommonModule,
    LoadingModule,
    TranslateModule,
    SvgIconModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    PipesModule,
    SvgInlineModule,
    ManagerValidatorsModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatChipsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatRippleModule,
    MatInputModule,
    MatProgressSpinnerModule,
    AldIconModule,
  ],
})
export class MgrFormsModule {
}
