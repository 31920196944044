import { Action } from '@ngrx/store';
import { BreadcrumbItem } from '../breadcrumb';

export enum BreadcrumbActionsTypes {
  SetBreadcrumb = '[Breadcrumb] Set new breadcrumbs',
  AddBreadcrumb = '[Breadcrumb] Add new breadcrumb item(s)',
  RemoveBreadcrumb = '[Breadcrumb] Remove breadcrumb item(s)',
}

export class SetBreadcrumb implements Action {
  readonly type = BreadcrumbActionsTypes.SetBreadcrumb;

  constructor(public payload: BreadcrumbItem[]) {
  }
}

export class AddBreadcrumb implements Action {
  readonly type = BreadcrumbActionsTypes.AddBreadcrumb;

  constructor(public payload: BreadcrumbItem[]) {
  }
}

export class RemoveBreadcrumb implements Action {
  readonly type = BreadcrumbActionsTypes.RemoveBreadcrumb;

  constructor(public payload: BreadcrumbItem[]) {
  }
}

export type BreadcrumbActions = SetBreadcrumb | AddBreadcrumb | RemoveBreadcrumb;
