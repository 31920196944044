import { ManagerTranslateLoader } from './translation-loader';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { TranslateLoader } from '@ngx-translate/core';
import { StorageService } from '../storage/storage.service';

export const translationFactory = (http: HttpClient, storage: StorageService) => {
  return new ManagerTranslateLoader(http, storage, `${environment.apigatewayUrl}${environment.translationLoadPath}`);
};

export const TRANSLATION_CONFIG = {
  loader: {
    provide: TranslateLoader,
    useFactory: translationFactory,
    deps: [HttpClient, StorageService],
  },
};
