import { UserConst } from '@shared/entity/user/user.const';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TransferHttpService } from '@shared/core/transfer-http';
import { UserManagementConst } from '../user-management.const';
import { UserManagementResponse } from '@shared/core/transfer-http/user-management-response.model';
import { UserCarPolicyAssociation } from '../../user-list/assign-car-policies/assign-car-policies-form/user-car-policy-association';
import { CompanyLight } from '@shared/models/company-light.model';
import { PartnerLight } from '@shared/models/partner-light.model';
import { CarPolicy } from '@shared/models/car-policy.model';
import { map } from 'rxjs/operators';
import { ContractLight } from '../../../my-fleet/shared/models/contract-light.model';
import { Order } from '../../../my-fleet/shared/models/order.model';

@Injectable()
export class UserUtilsService {

  constructor(private transferHttpService: TransferHttpService) {
  }

  public getAllCompanies(): Observable<CompanyLight[]> {
    const url = UserManagementConst.api.baseUrl + UserManagementConst.api.modules.user.url
      + UserManagementConst.api.modules.user.endpoints.getAllCompanies;
    return this.transferHttpService.get<CompanyLight[]>(url);
  }

  public searchCompanies(query: string): Observable<CompanyLight[]> {
    const searchParameter = `?keyword=${query}`;
    const url = UserManagementConst.api.baseUrl + UserManagementConst.api.modules.user.url
      + UserManagementConst.api.modules.user.endpoints.searchCompanies + searchParameter;
    return this.transferHttpService.get<CompanyLight[]>(url);
  }

  public getCompanyChildren(companyId: string): Observable<CompanyLight[]> {
    const url = UserManagementConst.api.baseUrl + UserManagementConst.api.modules.user.url
      + UserManagementConst.api.modules.user.endpoints.getCompanyChildren + companyId;
    return this.transferHttpService.get<CompanyLight[]>(url);
  }

  public getPartners(): Observable<PartnerLight[]> {
    const usersModule = UserConst.api.modules.users;
    const url = UserConst.api.baseUrl + usersModule.url + usersModule.endpoints.getPartners;
    return this.transferHttpService.get(url);
  }

  public getPlatesByCompany(selectedCompany): Observable<string[]> {
    // tslint:disable-next-line:max-line-length
    const url = `${UserManagementConst.api.baseUrl}${UserManagementConst.api.modules.user.url}${UserManagementConst.api.modules.user.endpoints.getCompanyPlates}/${selectedCompany}`;
    return this.transferHttpService.get<string[]>(url);
  }

  public updateSelectedWorkingScopes(selectedWorkingScopes: string[]): Observable<UserManagementResponse[]> {
    const url = `${UserManagementConst.api.baseUrl}${UserManagementConst.api.modules.user.url}` +
      `${UserManagementConst.api.modules.user.endpoints.setSelectedWorkingScope}`;
    return this.transferHttpService.post<UserManagementResponse[]>(url, selectedWorkingScopes);
  }

  public getCarPolicies(companyId: string): Observable<CarPolicy[]> {
    const userModule = UserConst.api.modules.users;
    // tslint:disable-next-line: max-line-length
    const url = `${UserConst.api.baseUrl}${UserManagementConst.api.modules.user.url}${userModule.endpoints.getCarPolicies}/${companyId}`;
    return this.transferHttpService.get<CarPolicy[]>(url);
  }

  public getPlatesByCompanyAndEmail(selectedCompany, userEmail): Observable<string[]> {
    const url = `${UserManagementConst.api.baseUrl}${UserManagementConst.api.modules.contract.url
    + UserManagementConst.api.modules.contract.endpoints.getUserContracts}/${selectedCompany}/${userEmail}`;
    return this.transferHttpService.get<any[]>(url).pipe(
      map((contracts) => {
        if (contracts) {
          return contracts.map(contract => contract.vehicle ? contract.vehicle.plate : contract);
        }
      },
      ));
  }

  public getContractDetailsByCompanyAndEmail(selectedCompany, userEmail): Observable<ContractLight[]> {
    const url = `${UserManagementConst.api.baseUrl}${UserManagementConst.api.modules.contract.url
    + UserManagementConst.api.modules.contract.endpoints.getUserContracts}/${selectedCompany}/${userEmail}`;
    return this.transferHttpService.get<any[]>(url);
  }

  public getOrderDetailsByCompanyAndEmail(selectedCompany, userEmail): Observable<Order[]> {
    const url = `${UserManagementConst.api.baseUrl}${UserManagementConst.api.modules.order.url
    + UserManagementConst.api.modules.order.endpoints.getUserOrders}/${selectedCompany}/${userEmail}`;
    return this.transferHttpService.get<any[]>(url);
  }

  public getProspectTemplateByCompany(companyId: string): Observable<Blob> {
    let url = UserManagementConst.api.baseUrl + UserManagementConst.api.modules.user.url +
      UserManagementConst.api.modules.user.endpoints.getProspectTemplate;
    url += `${companyId}`;
    return this.transferHttpService.getBlob(url);
  }

  public assignCarPoliciesInBulk(carPoliciesAssociation: UserCarPolicyAssociation): Observable<any> {
    const url = UserManagementConst.api.baseUrl + UserManagementConst.api.modules.user.url +
      UserManagementConst.api.modules.user.endpoints.assignCarPoliciesInBulk;
    return this.transferHttpService.put<any>(url, carPoliciesAssociation);
  }
}
