import { FeatureActions, FeatureActionTypes } from './feature.actions';

export interface FeatureState {
  loading: boolean;
  items: string[];
  isLoaded: boolean;
}

export const initialState: FeatureState = {
  loading: false,
  isLoaded: true,
  items: null,
};

export function reducer(state = initialState, action: FeatureActions): FeatureState {
  let loading: boolean;
  let items: string[];

  switch (action.type) {
    case FeatureActionTypes.GetFeature:
      loading = true;
      return { ...state, loading };

    case FeatureActionTypes.GetFeatureSuccess:
      loading = false;
      items = action.payload;
      return { ...state, loading, items, isLoaded: true };

    case FeatureActionTypes.GetFeatureFail:
      loading = false;
      return { ...state, loading, isLoaded: true };

    default:
      return state;
  }
}
