import { TranslationService } from './../../../core/translation/translation.service';
import { Injectable } from '@angular/core';
import { UserService } from '../user.service';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  GetUser,
  GetUserSuccess,
  SwitchRole,
  UpdateMyPreferences,
  UpdateMyPreferencesSuccess,
  UpdateMyUserProfile,
  UpdateMyUserProfileSuccess,
  UpdateWorkingScope,
  UpdateWorkingScopeSuccess,
  UserActionTypes,
  UpdateLastLoginDateSuccess,
  UpdateLastLoginDateFail,
} from './user.actions';
import { Observable, of } from 'rxjs';
import { UserManagementService } from '@user-management/shared/services/user-management.service';
import { StorageService } from '@shared/core/storage/storage.service';
import { StorageConst } from '@shared/core/storage/storage.const';
import { AldSnackbarService } from '@ald/ui';
import { PanelService } from '@shared/ui/panel/shared/panel.service';
import { UserManagementConst } from '@user-management/shared/user-management.const';
import { UserRole } from '@shared/models/user-role.model';
import { UserUtilsService } from '@user-management/shared/services/user-utils.service';
import { Router } from '@angular/router';
import { APP_ROUTES } from '@app/app.routes';
import { GetContractList } from '@my-fleet/shared/state/contract-state/contract.actions';
import { GetDocuments } from '@documents/shared/state/documents.actions';
import { GetOrderList } from '@my-fleet/shared/state/order-state/order.actions';
import { GetMyaldAccessUsers } from '@user-management/myald-access/shared/state/myald-access.actions';
import { GetUserList } from '@user-management/user-list/shared/state/user-list.actions';
import { GtmService } from '@shared/core/gtm/gtm.service';
import { GTM_EVENTS } from '@shared/core/gtm/gtm-events.const';
import { GtmUserRoleProperty } from '@shared/core/gtm/gtm-properties.model';
import { UpdateKpis } from '@app/dashboard/shared/state/dashboard-kpi.actions';
import { TranslateService } from '@ngx-translate/core';
import { LoadingPos } from '@app/administration/shared/state/pos-state/pos.actions';

@Injectable()
export class UserEffects {
  constructor(
    private userService: UserService,
    private actions$: Actions,
    private storageService: StorageService,
    private panelService: PanelService,
    private userManagementService: UserManagementService,
    private translationService: TranslationService,
    private userUtilsService: UserUtilsService,
    private snackBarService: AldSnackbarService,
    private gtmService: GtmService,
    private router: Router,
    private readonly translateService: TranslateService,
  ) { }

  @Effect()
  getUser$: Observable<Action> = this.actions$.pipe(
    ofType(UserActionTypes.GetUser),
    mergeMap(() =>
      this.userService.getUser().pipe(
        switchMap((user) => {
          if (user && user.userRoles) {
            const selectedRole = this.userService.getUserDefaultRole(user);
            return [new GetUserSuccess(user), new SwitchRole(selectedRole)];
          }
        }),
        catchError((error) => {
          const redirectRoute = error.status === 401 ? [APP_ROUTES.COUNTRY_ACCESS_ERROR] : [APP_ROUTES.USER_ERROR];
          this.router.navigate(redirectRoute);
          return of({ type: UserActionTypes.GetUserFail });
        }),
      ),
    ),
  );

  @Effect({ dispatch: false })
  switchRole$: Observable<UserRole> = this.actions$.pipe(
    ofType<SwitchRole>(UserActionTypes.SwitchRole),
    map(action => action.payload),
    tap((userRole) => {
      this.storageService.set(StorageConst.selectedRoleId, userRole.id);
      this.storageService.set(StorageConst.selectedRoleLabel, userRole.role);
      this.storageService.set(StorageConst.selectedCountry, userRole.countryCode);
    },
    ),
  );

  @Effect()
  updateWorkingScope$: Observable<Action> = this.actions$.pipe(
    ofType(UserActionTypes.UpdateWorkingScope),
    mergeMap((action: UpdateWorkingScope) => {
      this.snackBarService.loading(this.translateService.instant('global-working_scope_panel-snackbar_loading'));
      return this.userUtilsService.updateSelectedWorkingScopes(action.payload.filter(ws => ws.isSelected).map(ws => ws.companyId)).pipe(
        tap(() => {
          this.snackBarService.success(this.translateService.instant('global-working_scope_panel-snackbar_success'));
          this.panelService.close(UserManagementConst.windowCodename.switchWorkingScopePanel);
        }),
        switchMap(() => {
          this.gtmService.sendEvent(
            GTM_EVENTS.switchWorkingScope,
            action.payload.length.toString(),
            GtmUserRoleProperty.role,
            true,
          );
          return [new UpdateWorkingScopeSuccess(action.payload), ...this.getCurrentRouteRefreshAction()];
        }),
        catchError(() => {
          this.snackBarService.error(this.translateService.instant('global-working_scope_panel-snackbar_error'));
          return of({ type: UserActionTypes.UpdateWorkingScopeFail });
        }),
      );
    }),
  );

  @Effect()
  updateMyUserProfile$: Observable<Action> = this.actions$.pipe(
    ofType(UserActionTypes.UpdateMyUserProfile),
    mergeMap((action: UpdateMyUserProfile) => {
      this.snackBarService.loading(this.translateService.instant('global-edit_my_profile_panel-snackbar_loading'));
      return this.userManagementService.updateMyUserProfile(action.payload).pipe(
        switchMap(() => {
          this.snackBarService.success(this.translateService.instant('global-edit_my_profile_panel-snackbar_success'));
          this.gtmService.sendEvent(
            GTM_EVENTS.editUserProfile,
            GtmUserRoleProperty.role,
            GtmUserRoleProperty.countryCode,
            true,
          );
          return [new UpdateMyUserProfileSuccess(), new GetUser()];
        }),
        catchError(() => {
          this.snackBarService.error(this.translateService.instant('global-generic_terms-snackbar_generic_error'));
          return of({ type: UserActionTypes.UpdateMyUserProfileFail });
        }),
      );
    }),
  );

  @Effect()
  updateMyPreferences$: Observable<Action> = this.actions$.pipe(
    ofType(UserActionTypes.UpdateMyPreferences),
    mergeMap((action: UpdateMyPreferences) => {
      if (action.showToaster) {
        this.snackBarService.loading(this.translateService.instant('global-edit_preferences_panel-snackbar_loading'));
      }
      return this.userManagementService.updateMyUserPreferences(action.payload).pipe(
        tap(() => {
          if (action.showToaster) {
            this.snackBarService.success(this.translateService.instant('global-edit_preferences_panel-snackbar_success'));
          }
          this.panelService.close(UserManagementConst.windowCodename.editPreferencesPanel);
        }),
        switchMap(() => {
          if (action.payload.culture) {
            this.translationService.language = action.payload.culture;
          }
          return [new UpdateMyPreferencesSuccess(action.payload)];
        }),
        catchError(() => {
          if (action.showToaster) {
            this.snackBarService.error(this.translateService.instant('global-edit_preferences_panel-snackbar_error'));
          }
          return of({ type: UserActionTypes.UpdateMyPreferencesFail });
        }),
      );
    }),
  );

  @Effect()
  updateLastLoginDate$: Observable<Action> = this.actions$.pipe(
    ofType(UserActionTypes.UpdateLastLoginDate),
    mergeMap(() =>
      this.userService.updateLastLoginDate().pipe(
        switchMap(() => of(new UpdateLastLoginDateSuccess())),
        catchError(() => of(new UpdateLastLoginDateFail())),
      ),
    ),
  );

  @Effect({ dispatch: false })
  updateLastLoginDateFail$: Observable<UpdateLastLoginDateFail> = this.actions$.pipe(
    ofType<UpdateLastLoginDateFail>(UserActionTypes.UpdateLastLoginDateFail),
    tap(() => {
      this.snackBarService.error(this.translateService.instant('global-generic_terms-snackbar_generic_error'));
    },
    ),
  );

  private getCurrentRouteRefreshAction() {
    const actionsList = {
      [APP_ROUTES.DOCUMENTS]: new GetDocuments(),
      [APP_ROUTES.CONTRACT_LIST]: new GetContractList(),
      [APP_ROUTES.ORDER_LIST]: new GetOrderList(),
      [APP_ROUTES.GIVE_ACCESS]: new GetMyaldAccessUsers(),
      [APP_ROUTES.USER_LIST]: new GetUserList(),
      [APP_ROUTES.HOME]: new UpdateKpis(),
    };
    const routes = Object.keys(actionsList);
    const routeToReload = routes.find(t => this.router.url.includes(t));
    return routeToReload ? [actionsList[routeToReload]] : [];
  }
}
