import { Filter } from '@shared/ui/table/table-filters/shared/filter.interface';
import { PasswordExpiration } from '@shared/entity/user/user.const';
import { UserListDataItem } from '@user-management/user-list/shared/models/user-list-data-item.model';

export function expiredStateFilterFunction(option: Filter, row: UserListDataItem): boolean | null {
  return option.value.includes(row.passwordExpirationState);
}

export const ExpiredPasswordStateFilter  = {
  name: 'expiration-state',
  label: 'global-filters-expiration_state_title',
  options: [
    {
      label: 'global-filters-expiration_state_expired_label',
      value: PasswordExpiration.Expired,
    },
    {
      label: 'global-filters-expiration_state_expire_soon_label',
      value: PasswordExpiration.WillExpireSoon,
    },
  ],
  predicateFn: expiredStateFilterFunction,
};
