import { select, Store } from '@ngrx/store';
import { filter, take, takeWhile } from 'rxjs/operators';
import { getUserFeatureState } from '../../entity/user/state';
import { GtmRef } from '@shared/core/gtm/gtm.ref';
import { combineLatest } from 'rxjs';
import { getPageSectionByUrl } from '@shared/core/gtm/gtm.helper';
import { AuthStatus } from '@shared/core/authentication/authentication.reducer';
import { GtmUserRoleProperty } from '@shared/core/gtm/gtm-properties.model';
import { getAuthFeatureState } from '@shared/core/authentication';
import { StorageService } from '@shared/core/storage/storage.service';
import { StorageConst } from '@shared/core/storage/storage.const';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../storage/storage.service";
import * as i3 from "./gtm.ref";
var GtmService = /** @class */ (function () {
    function GtmService(store, storageService, gtm) {
        this.store = store;
        this.storageService = storageService;
        this.gtm = gtm;
        this.userData$ = combineLatest([
            this.store.pipe(select(getUserFeatureState)),
            this.store.pipe(select(getAuthFeatureState)),
        ]).pipe(filter(function (_a) {
            var userState = _a[0], authState = _a[1];
            return userState &&
                authState.status === AuthStatus.IN &&
                userState.isLoaded &&
                userState.user != null &&
                userState.selectedUserRole != null;
        }), take(1));
    }
    GtmService.prototype.sendPageView = function (url) {
        var _this = this;
        var isPageViewFired = false;
        this.userData$.pipe(takeWhile(function () { return !isPageViewFired; })).subscribe(function (_a) {
            var userState = _a[0];
            var currentCulture = _this.storageService.get(StorageConst.translation.keys.lang) || userState.selectedUserRole.culture;
            var gtmProperties = {
                event: 'dataLayerLoad',
                country: environment.production ? userState.selectedUserRole.countryCode : 'TEST',
                pageLanguage: currentCulture,
                userRole: userState.selectedUserRole.role,
                userCompany: userState.selectedUserRole.company ? userState.selectedUserRole.company.name : null,
                plateforme: 'manager',
                pageSection: getPageSectionByUrl(url),
                userId: userState.user.userId,
            };
            _this.gtm.nativeDatalayer.push(gtmProperties);
            isPageViewFired = true;
        });
    };
    GtmService.prototype.sendEvent = function (event, label, details, isFromUserRole) {
        var _this = this;
        var isEventFired = false;
        this.userData$.pipe(takeWhile(function () { return !isEventFired; })).subscribe(function (_a) {
            var userState = _a[0];
            // Set eventLabel with existing user role data or take hardcoded label or take default
            if (Object.prototype.hasOwnProperty.call(event, 'eventLabel')) {
                event.eventLabel = label in GtmUserRoleProperty && isFromUserRole ?
                    userState.selectedUserRole[label] :
                    label || event.eventLabel;
            }
            // Set eventDetail with existing user role data or take hardcoded details or take default
            if (Object.prototype.hasOwnProperty.call(event, 'eventDetails')) {
                event.eventDetails = details in GtmUserRoleProperty && isFromUserRole ?
                    userState.selectedUserRole[details] :
                    details || event.eventDetails;
            }
            _this.checkEventRequiredProperty(event);
            _this.gtm.nativeDatalayer.push(event);
            isEventFired = true;
        });
    };
    GtmService.prototype.sendAnonymousEvent = function (event, label, details) {
        event.eventDetails = details ? details : event['eventDetails'];
        event.eventLabel = label ? label : event['eventLabel'];
        this.checkEventRequiredProperty(event);
        this.gtm.nativeDatalayer.push(event);
    };
    GtmService.prototype.checkEventRequiredProperty = function (event) {
        for (var property in event) {
            if (property && Object.prototype.hasOwnProperty.call(event, property) && /<.*>/.test(event[property])) {
                console.error('GTM - Missing property in event : ', event.event, property, event[property]);
                return;
            }
        }
    };
    GtmService.ngInjectableDef = i0.defineInjectable({ factory: function GtmService_Factory() { return new GtmService(i0.inject(i1.Store), i0.inject(i2.StorageService), i0.inject(i3.GtmRef)); }, token: GtmService, providedIn: "root" });
    return GtmService;
}());
export { GtmService };
