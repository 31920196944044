import { Directive, HostListener, Input, OnInit } from '@angular/core';
import * as popinState from '../state';
import { select, Store } from '@ngrx/store';
import { PopinService } from '@shared/ui/popin/shared/popin.service';

@Directive({
  selector: '[mgrOpenPopin]',
})
export class OpenPopinDirective implements OnInit {
  @Input() mgrOpenPopin: string;

  private isPopinActive = true;

  constructor(
    private store: Store<popinState.State>,
    private popinService: PopinService,
  ) {
  }

  ngOnInit(): void {
    this.store.pipe(select(popinState.getPopinFeatureState)).subscribe((state) => {
      this.isPopinActive = (state.showPopins.includes(this.mgrOpenPopin));
    },
    );
  }

  @HostListener('click', ['$event'])
  private onClick(event): void {
    event.stopPropagation();
    if (!this.isPopinActive) {
      this.popinService.open(this.mgrOpenPopin);
    }
  }
}
