import { Injectable } from '@angular/core';
import { WindowRef } from '@shared/core/helpers/window.ref';

@Injectable()
export class GtmRef {

  constructor(private windowRef: WindowRef) {
  }

  get nativeDatalayer(): any {
    return this.windowRef.nativeWindow.dataLayer || [];
  }
}
