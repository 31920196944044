import { InputChoice } from './input-choice.model';

export class CompanyLight {
  id: string;
  reference?: string;
  parentId?: string;
  name: string;

  static toInputChoice(companies: CompanyLight[]): InputChoice[] {
    if (!companies) {
      return [];
    }
    return companies.map((company) => {
      return {
        label: `${company.name} (${company.reference})`,
        value: company.id,
        id: company.id,
        name: company.name,
        reference: company.reference,
        parentId: company.parentId,
      };
    });
  }

  // ## Return the tree (children & grandchildren) from all companies
  static toInputChoiceTree(companies: CompanyLight[]): InputChoice[] {
    if (!companies) {
      return [];
    }
    let tree = [];
    const parentCompanies = companies.filter(c => !c.parentId);
    if (parentCompanies.length > 0) {
      parentCompanies.forEach((rootCompany) => {
        tree = [...tree, ...this.getCompanyTree(companies, rootCompany)];
      });
    }
    const orphanCompanies = companies.filter(c => !!c.parentId && !tree.includes(c));
    tree = [...tree, ...orphanCompanies];
    return tree.map((company) => {
      return {
        label: `${company.name} (${company.reference})`,
        value: company.id,
        id: company.id,
        name: company.name,
        reference: company.reference,
        parentId: company.parentId,
      };
    });
  }

  // ## Return an unique input choice from a company
  static toInputChoiceSingle(company: CompanyLight): InputChoice {
    return {
      label: `${company.name} (${company.reference})`,
      value: company.id,
      id: company.id,
      name: company.name,
      reference: company.reference,
      parentId: company.parentId,
    };
  }

  static getCompanyTree(companies: CompanyLight[], rootCompany: CompanyLight): CompanyLight[] {
    const childrenCompanies = companies.filter(c =>  c.parentId === rootCompany.id);
    const currentCompany = companies.filter(c => c.id === rootCompany.id);

    // Stop recursion if no children
    if (childrenCompanies.length === 0) {
      return currentCompany;
    }

    // Get recursively all children companies
    let filteredCompanies = currentCompany;
    childrenCompanies.forEach((childCompany) => {
      filteredCompanies = [...filteredCompanies, ...this.getCompanyTree(companies, childCompany)];
    });
    return filteredCompanies.sort((a, b) => +b.parentId - +b.parentId);
  }
}
