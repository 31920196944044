import { Injectable } from '@angular/core';

const MIN_LOADING_SCREEN_TIME = 1500;

@Injectable()
export class HostPageService {

  private originalConfig;
  private loadingScreen;

  constructor() {

    // Get config from widow/global
    this.originalConfig = window['HOST_PAGE_CONFIG'];
    this.loadingScreen = document.getElementById('loading-screen');

  }

  public get config() {
    return this.originalConfig;
  }

  hideLoadingScreen(): number {

    const elapsedTime = Date.now() - this.originalConfig.bootTime;

    // Create function to hide
    const hide = () => {
      this.loadingScreen.classList.add('hide');
    };

    if (elapsedTime < MIN_LOADING_SCREEN_TIME) {
      setTimeout(hide, MIN_LOADING_SCREEN_TIME - elapsedTime);
    } else {
      hide();
    }

    // return remaining time (or 0 if it's already passed)
    return Math.max(0, MIN_LOADING_SCREEN_TIME - elapsedTime);

  }

}
