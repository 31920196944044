import { Filter } from '@shared/ui/table/table-filters/shared/filter.interface';

export function contractMileageCategoryFilterFunction(option: Filter, row: any): boolean | null {
  return option.value.some(opt => row.mileageInformationCategories && row.mileageInformationCategories.includes(opt));
}
export const ContractMileageCategoryFilter = {
  name: 'contractMileageCategory',
  label: 'global-filters-contractmileagecategory',
  options: [
    {
      label: 'global-filters-mileagereached',
      value: 'ContractMileageReached',
    },
    {
      label: 'global-filters-mileagemissing',
      value: 'MileageMissing',
    },
    {
      label: 'global-filters-mileageupdated',
      value: 'MileageNotUpdated',
    },
  ],
  predicateFn: contractMileageCategoryFilterFunction,
};
