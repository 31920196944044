import { NgModule } from '@angular/core';
import { PosStorageService } from './pos/pos-storage.service';
import { StorageService } from './storage.service';

@NgModule({
  providers: [
    StorageService,
    PosStorageService,
  ],
})
export class StorageModule {
}
