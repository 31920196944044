import * as tslib_1 from "tslib";
import { NavigationActionTypes } from './navigation.actions';
import { StorageConst } from '@shared/core/storage/storage.const';
export var initialState = {
    showNotifications: false,
    showUserNavigation: false,
    showFullNavigation: window.localStorage.getItem(StorageConst.fullNavigation) !== 'false',
    showLanguages: false,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case NavigationActionTypes.ToggleNotifications:
            return tslib_1.__assign({}, state, { showNotifications: action.payload });
        case NavigationActionTypes.ToggleUserNavigation:
            return tslib_1.__assign({}, state, { showUserNavigation: action.payload });
        case NavigationActionTypes.ToggleFullNavigation:
            return tslib_1.__assign({}, state, { showFullNavigation: action.payload });
        case NavigationActionTypes.ToggleLanguages:
            return tslib_1.__assign({}, state, { showLanguages: action.payload });
        default:
            return state;
    }
}
