import { Action } from '@ngrx/store';
import { CreatePosModel, EditPosModel, PosManagementModel, AllCategoryGroup, Brands } from '../../models/pos.model';

export enum PosActionTypes {
  GetPos = '[Pos] Get',
  GetPosSuccess = '[Pos] Get Success',
  GetPosFail = '[Pos] Get Fail',
  DeletePos = '[Pos] Delete',

  CreatePos = '[Pos] Create',
  CreatePosSuccess = '[Pos] Create Success',
  CreatePosFail = '[Pos] Create Fail',

  EditPos = '[Pos] Edit',
  EditPosSuccess = '[Pos] Edit Success',
  EditPosFail = '[Pos] Edit Fail',

  AddPosSelection = '[Pos] Add pos selection',
  RemovePosSelection = '[Pos] Remove pos selection',
  ClearPosSelection = '[Pos] Clear pos selection',

  GetAllCategories = '[Pos] Get All Categories',
  GetAllCategoriesSuccess = '[Pos] Get All Categories Success',
  GetAllCategoriesFail = '[Pos] Get All Categories Fail',

  GetAllBrands = '[Pos] Get All Brands',
  GetAllBrandsSuccess = '[Pos] Get All Brands Success',
  GetAllBrandsFail = '[Pos] Get All Brandsv Fail',

  SearchPos = '[Pos] search on pos listing',
  CurrentLanguage = '[Pos] current application language',

  ClearPosStore = '[Pos] clear pos store',
  LoadingPos = '[Pos] loading',

  ExportPOS = '[User List] Export POS',
  ExportPOSFail = '[User List] Export POS fail',
  ExportPOSSuccess = '[User List] Export POS success',
}

export class GetPos implements Action {
  readonly type = PosActionTypes.GetPos;
  constructor() {}
}
export class CurrentLanguage implements Action {
  readonly type = PosActionTypes.CurrentLanguage;
  constructor(public payload: string) {}
}
export class GetPosSuccess implements Action {
  readonly type = PosActionTypes.GetPosSuccess;
  constructor(public payload: {data: PosManagementModel[],
    currentLanguage: string,
    responseFromAPI: boolean}) {
  }
}

export class GetPosFail implements Action {
  readonly type = PosActionTypes.GetPosFail;
}

export class DeletePos implements Action {
  readonly type = PosActionTypes.DeletePos;
  constructor(public payload: PosManagementModel[]) {
  }
}

export class GetAllCategories implements Action {
  readonly type = PosActionTypes.GetAllCategories;
}

export class GetAllCategoriesSuccess implements Action {
  readonly type = PosActionTypes.GetAllCategoriesSuccess;
  constructor(public payload: AllCategoryGroup[]) {}
}

export class GetAllCategoriesFail implements Action {
  readonly type = PosActionTypes.GetAllCategoriesFail;
}

export class GetAllBrands implements Action {
  readonly type = PosActionTypes.GetAllBrands;
}

export class GetAllBrandsSuccess implements Action {
  readonly type = PosActionTypes.GetAllBrandsSuccess;
  constructor(public payload: Brands[]) {}
}

export class GetAllBrandsFail implements Action {
  readonly type = PosActionTypes.GetAllBrandsFail;
}

export class CreatePos implements Action {
  readonly type = PosActionTypes.CreatePos;
  constructor(public payload: CreatePosModel) {
  }
}

export class CreatePosSuccess implements Action {
  readonly type = PosActionTypes.CreatePosSuccess;
}

export class CreatePosFail implements Action {
  readonly type = PosActionTypes.CreatePosFail;
}

export class EditPos implements Action {
  readonly type = PosActionTypes.EditPos;
  constructor(public payload: EditPosModel) {
  }
}

export class EditPosSuccess implements Action {
  readonly type = PosActionTypes.EditPosSuccess;
  constructor(public payload: EditPosModel) {
  }
}

export class EditPosFail implements Action {
  readonly type = PosActionTypes.EditPosFail;
}

export class AddPosSelection implements Action {
  readonly type = PosActionTypes.AddPosSelection;
  constructor(public payload: PosManagementModel[]) { }
}

export class RemovePosSelection implements Action {
  readonly type = PosActionTypes.RemovePosSelection;
  constructor(public payload: PosManagementModel[]) { }
}

export class ClearPosSelection implements Action {
  readonly type = PosActionTypes.ClearPosSelection;
  constructor() { }
}
export class SearchPos implements Action {
  readonly type = PosActionTypes.SearchPos;
  constructor(public payload: string) { }
}

export class ClearPosStore implements Action {
  readonly type = PosActionTypes.ClearPosStore;
}

export class LoadingPos implements Action {
  readonly type = PosActionTypes.LoadingPos;
  constructor(public payload: boolean) { }
}

export class ExportPOS implements Action {
  readonly type = PosActionTypes.ExportPOS;
}

export class ExportPOSFail implements Action {
  readonly type = PosActionTypes.ExportPOSFail;
}

export class ExportPOSSuccess implements Action {
  readonly type = PosActionTypes.ExportPOSSuccess;
}

export type PosActions = CurrentLanguage
  | GetPos
  | GetPosSuccess
  | GetPos
  | GetPosFail
  | GetAllBrands
  | GetAllBrandsSuccess
  | GetAllBrandsFail
  | GetAllCategories
  | GetAllCategoriesSuccess
  | GetAllCategoriesFail
  | DeletePos
  | CreatePos
  | CreatePosSuccess
  | CreatePosFail
  | EditPos
  | EditPosSuccess
  | EditPosFail
  | AddPosSelection
  | RemovePosSelection
  | ClearPosSelection
  | SearchPos
  | ClearPosStore
  | LoadingPos
  | ExportPOS
  | ExportPOSFail
  | ExportPOSSuccess;
