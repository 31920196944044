import { ChangeDetectorRef, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { of } from 'rxjs';
var ApplyFilterwithButtonComponent = /** @class */ (function () {
    function ApplyFilterwithButtonComponent(changeDetector) {
        this.changeDetector = changeDetector;
        this.clearOption = false;
        this.maxItems = null;
        this.isNoResultsState = false;
        this.searchOptionField = new FormControl();
    }
    Object.defineProperty(ApplyFilterwithButtonComponent.prototype, "options", {
        get: function () {
            return this._options;
        },
        set: function (options) {
            this._options = options;
        },
        enumerable: true,
        configurable: true
    });
    ApplyFilterwithButtonComponent.prototype.ngOnInit = function () {
        if (this.control.value) {
            this.options = this.control.value;
        }
    };
    ApplyFilterwithButtonComponent.prototype.applyFilter = function (searchQuery) {
        var _this = this;
        if (searchQuery && searchQuery.length > 1 && searchQuery !== '') {
            this.isOptionsLoading = true;
            this.optionsSearchCallback(searchQuery).subscribe(function (newOptions) {
                _this.isOptionsLoading = false;
                _this.isNoResultsState = !(newOptions && newOptions.length);
                // We must concat with the selected values ​​otherwise they disappear in the select trigger
                if (newOptions.length > 0) {
                    _this.options = _this.control.value ? newOptions.concat(_this.control.value) : newOptions;
                    _this.changeDetector.detectChanges();
                }
            });
        }
        else {
            this.options = this.control.value;
            this.isOptionsLoading = false;
            this.isNoResultsState = false;
            of([]);
        }
    };
    ApplyFilterwithButtonComponent.prototype.removeOption = function (optionToRemove) {
        var index = this.control.value.findIndex(function (o) { return o === optionToRemove; });
        if (index !== -1) {
            this.control.value.splice(index, 1);
            this.control.setValue(this.control.value);
            this.options = this.control.value;
        }
    };
    ApplyFilterwithButtonComponent.prototype.hasValidator = function (validator) {
        if (!(this.control.validator instanceof Function)) {
            return false;
        }
        if (!this.control.validator('')) {
            return false;
        }
        return !!this.control
            .validator('')
            .hasOwnProperty(validator);
    };
    ApplyFilterwithButtonComponent.prototype.isOptionSelected = function (option) {
        return (this.control.value &&
            this.control.value.find(function (o) { return option && o.value === option.value; }));
    };
    ApplyFilterwithButtonComponent.prototype.isGrandChild = function (option) {
        var father = this.options.find(function (o) { return o.value === option.parentId; });
        return option.parentId && father && father.parentId;
    };
    ApplyFilterwithButtonComponent.prototype.clearSelection = function () {
        this.control.setValue([]);
        this.options = null;
    };
    return ApplyFilterwithButtonComponent;
}());
export { ApplyFilterwithButtonComponent };
