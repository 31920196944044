/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popin.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../directives/click-outside/click-outside.directive";
import * as i3 from "../../core/helpers/document.ref";
import * as i4 from "@angular/common";
import * as i5 from "./popin.component";
import * as i6 from "@ngrx/store";
import * as i7 from "./shared/popin.service";
var styles_PopinComponent = [i0.styles];
var RenderType_PopinComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopinComponent, data: { "animation": [{ type: 7, name: "Fade", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 0, name: "*", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: { type: 4, styles: null, timings: "500ms ease-out" }, options: null }, { type: 1, expr: ":leave", animation: { type: 4, styles: null, timings: "300ms ease-in" }, options: null }], options: {} }] } });
export { RenderType_PopinComponent as RenderType_PopinComponent };
function View_PopinComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "popin"], ["id", "popin"]], [[24, "@Fade", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["mgrClickOutside", ""]], [[8, "className", 0]], [[null, "clickOut"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClickDocument($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("clickOut" === en)) {
        var pd_1 = (_co.closePopin() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 81920, null, 0, i2.ClickOutsideDirective, [i1.ElementRef, i3.DocumentRef], null, { clickOut: "clickOut" }), i1.ɵncd(null, 0)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "popin-container ", _co.size, ""); _ck(_v, 1, 0, currVal_1); }); }
export function View_PopinComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopinComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showPopin; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PopinComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mgr-popin", [], null, null, null, View_PopinComponent_0, RenderType_PopinComponent)), i1.ɵdid(1, 245760, null, 0, i5.PopinComponent, [i6.Store, i7.PopinService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopinComponentNgFactory = i1.ɵccf("mgr-popin", i5.PopinComponent, View_PopinComponent_Host_0, { codename: "codename", size: "size" }, {}, ["*"]);
export { PopinComponentNgFactory as PopinComponentNgFactory };
