/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-found.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./not-found.component";
var styles_NotFoundComponent = [i0.styles];
var RenderType_NotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "not-found"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "not-found-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "a", [["class", "not-found-home-button"], ["color", "accent"], ["mat-raised-button", ""], ["routerLink", "/dashboard"]], [[1, "target", 0], [8, "href", 4], [1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7)._haltDisabledEvents($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatAnchor_0, i3.RenderType_MatAnchor)), i1.ɵdid(6, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(7, 180224, null, 0, i6.MatAnchor, [i7.Platform, i8.FocusMonitor, i1.ElementRef, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(8, 0, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_7 = "/dashboard"; _ck(_v, 6, 0, currVal_7); var currVal_8 = "accent"; _ck(_v, 7, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("global-page_not_found-title")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).target; var currVal_2 = i1.ɵnov(_v, 6).href; var currVal_3 = (i1.ɵnov(_v, 7).disabled ? (0 - 1) : (i1.ɵnov(_v, 7).tabIndex || 0)); var currVal_4 = (i1.ɵnov(_v, 7).disabled || null); var currVal_5 = i1.ɵnov(_v, 7).disabled.toString(); var currVal_6 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("global-page_not_found-button")); _ck(_v, 8, 0, currVal_9); }); }
export function View_NotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mgr-not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i1.ɵdid(1, 49152, null, 0, i10.NotFoundComponent, [], null, null)], null, null); }
var NotFoundComponentNgFactory = i1.ɵccf("mgr-not-found", i10.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
