export const GTM_EVENTS = {
  loginSuccess: {
    event: 'connection-loginSucceed',
    eventCategory: 'Connection',
    eventAction: 'Login succeed',
    eventLabel: '<user-role>',
    eventDetails: '<country>',
    plateforme: 'manager',
  },
  logout: {
    event: 'connection-logout',
    eventCategory: 'Connection',
    eventAction: 'Logout',
    eventLabel: '<user-role>',
    eventDetails: '<country>',
    plateforme: 'manager',
  },
  editOwnProfile: {
    event: 'connection-editOwnProfile',
    eventCategory: 'User account management',
    eventAction: 'Edit own profile',
    eventLabel: '<user-role>',
    eventDetails: '<country>',
    plateforme: 'manager',
  },
  switchWorkingScope: {
    event: 'workingScope-workingScopeSwitch',
    eventCategory: 'Working scope',
    eventAction: 'Working scope switch',
    eventLabel: '<number-of-working-scope-selected>',
    eventDetails: '<user-role>',
    plateforme: 'manager',
  },
  changePassword: {
    event: 'userAccountManagement-passwordChange',
    eventCategory: 'User account management',
    eventAction: 'Password change',
    plateforme: 'manager',
  },
  clickNavigation: {
    event: 'navigation-outboundLink',
    eventCategory: 'Navigation',
    eventAction: 'Outbound link',
    eventLabel: '<click-text>',
    plateforme: 'manager',
  },
  useFilter: {
    event: 'search-filterUse',
    eventCategory: 'Search',
    eventAction: 'Filter use',
    eventLabel: '<filter-name>',
    plateforme: 'manager',
  },
  seeDashboardTile: {
    event: 'navigation-impression',
    eventCategory: 'Navigation',
    eventAction: 'Impression',
    eventLabel: '<tile-name>',
    plateforme: 'manager',
  },
  approveRequest: {
    event: 'accessRequests-approve',
    eventCategory: 'Access requests',
    eventAction: 'Approve',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },
  rejectRequest: {
    event: 'accessRequests-reject',
    eventCategory: 'Access requests',
    eventAction: 'Reject',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },
  createUser: {
    event: 'usersList-createUser',
    eventCategory: 'Users list',
    eventAction: 'Create user',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },
  editUserProfile: {
    event: 'usersList-editUserProfile',
    eventCategory: 'Users list',
    eventAction: 'Edit user profile',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },
  resetUserPassword: {
    event: 'usersList-resetUserPassword',
    eventCategory: 'Users list',
    eventAction: 'Reset user password',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },
  enableDisableUser: {
    event: 'usersList-disable/EnableUserAccount',
    eventCategory: 'Users list',
    eventAction: 'Disable/enable user account',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },
  deleteUser: {
    event: 'usersList-deleteUser',
    eventCategory: 'Users list',
    eventAction: 'Delete user',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },
  assignCarPolicy: {
    event: 'usersList-assignCarPolicy',
    eventCategory: 'Users list',
    eventAction: 'Assign car policy',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },

  importProspect: {
    event: 'prospectsBulkCreation-prospectsImport',
    eventCategory: 'Prospects bulk creation',
    eventAction: 'Prospects import',
    eventLabel: '<number-of-prospects-added>',
    plateforme: 'manager',
  },
  viewWhitelistDetails: {
    event: 'domainsWhitelisting-viewDetails',
    eventCategory: 'domains whitelisting',
    eventAction: 'View details',
    plateforme: 'manager',
  },
  registerDomain: {
    event: 'domainsWhitelisting-registerNewDomain',
    eventCategory: 'domains whitelisting',
    eventAction: 'Register new domain',
    eventLabel: '<number-of-domains-selected>',
    plateforme: 'manager',
  },
  editDomain: {
    event: 'domainsWhitelisting-editDomain',
    eventCategory: 'domains whitelisting',
    eventAction: 'Edit domain',
    eventLabel: '<number-of-domains-selected>',
    plateforme: 'manager',
  },
  deleteDomain: {
    event: 'domainsWhitelisting-deleteDomain',
    eventCategory: 'domains whitelisting',
    eventAction: 'Delete domain',
    eventLabel: '<number-of-domains-selected>',
    plateforme: 'manager',
  },
  giveAccess: {
    event: 'accessToMyald-giveAccess',
    eventCategory: 'Access to MyALD',
    eventAction: 'Give access',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },
  notifyDriverFromContract: {
    event: 'manageContracts-notifyDriver',
    eventCategory: 'Manage contracts',
    eventAction: 'Notify driver',
    eventLabel: '<number-of-contracts-selected>',
    plateforme: 'manager',
  },
  updateContractMileage: {
    event: 'manageContracts-updateContractMileage',
    eventCategory: 'Manage contracts',
    eventAction: 'Update contract mileage',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },
  permissionToTakeAbroad: {
    event: 'manageContracts-updateContractMileage',
    eventCategory: 'Manage contracts',
    eventAction: 'Driver assistance - Update contract mileage',
    plateforme: 'manager',
  },
  orderDamageRepair: {
    event: 'manageContracts-orderDamageRepair',
    eventCategory: 'Manage contracts',
    eventAction: 'Driver assistance - Order damage repair',
    plateforme: 'manager',
  },
  orderMaintainance: {
    event: 'manageContracts-updateContractMileage',
    eventCategory: 'Manage contracts',
    eventAction: 'Driver assistance - Update contract mileage',
    plateforme: 'manager',
  },
  updateDriverInformation: {
    event: 'myFleet-updateDriver',
    eventCategory: 'Manage orders/contracts',
    eventAction: 'Update driver',
    eventLabel: '<number-of-orders-selected>',
    plateforme: 'manager',
  },
  downloadPdfDocument: {
    event: 'download-pdf',
    eventCategory: 'Download',
    eventAction: 'PDF',
    eventLabel: '<document-name>',
    plateforme: 'manager',
  },
  downloadExcelDocument: {
    event: 'download-excel',
    eventCategory: 'Download',
    eventAction: '<document-name>',
    plateforme: 'manager',
  },
  uploadDocument: {
    event: 'manageDocuments-uploadDocument',
    eventCategory: 'Manage documents',
    eventAction: 'Upload document',
    eventLabel: '<number-of-documents-selected>',
    plateforme: 'manager',
  },
  editDocument: {
    event: 'manageDocuments-editDocument',
    eventCategory: 'Manage documents',
    eventAction: 'Edit document',
    eventLabel: '<number-of-documents-selected>',
    plateforme: 'manager',
  },
  downloadDocument: {
    event: 'manageDocuments-downloadDocument',
    eventCategory: 'Manage documents',
    eventAction: 'Download document',
    eventLabel: '<number-of-documents-selected>',
    plateforme: 'manager',
  },
  deleteDocument: {
    event: 'manageDocuments-deleteDocument',
    eventCategory: 'Manage documents',
    eventAction: 'Delete document',
    eventLabel: '<number-of-documents-selected>',
    plateforme: 'manager',
  },
  documentNotification: {
    event: 'manageDocuments-documentNotification',
    eventCategory: 'Manage documents',
    eventAction: 'Document notification',
    eventLabel: '<number-of-documents-selected>',
    plateforme: 'manager',
  },
  ifrsReports: {
    event: 'ifrsReports-requestReports',
    eventCategory: 'IFRS reports',
    eventAction: 'Request reports',
    plateforme: 'manager',
  },
  createNotification: {
    event: 'manageNotifications-createNotification',
    eventCategory: 'Manage notifications',
    eventAction: 'Create notification',
    eventLabel: '<number-of-notifications-selected>',
    plateforme: 'manager',
  },
  deleteNotification: {
    event: 'manageNotifications-deleteNotification',
    eventCategory: 'Manage notifications',
    eventAction: 'Delete notification',
    eventLabel: '<number-of-notifications-selected>',
    plateforme: 'manager',
  },
  createAdmin: {
    event: 'administration-createAnAdministrator',
    eventCategory: 'Administration',
    eventAction: 'Create an administrator',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },
  editAdmin: {
    event: 'administration-editAnAdministrator',
    eventCategory: 'Administration',
    eventAction: 'Edit an administrator',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },
  enableDisableAdmin: {
    event: 'administration-enable/Disable',
    eventCategory: 'Administration',
    eventAction: 'Enable/disable admin',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },
  resetAdminPassword: {
    event: 'administration-resetAdminPassword',
    eventCategory: 'Administration',
    eventAction: 'Reset admin password',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },
  createSupplier: {
    event: 'administration-createSupplier',
    eventCategory: 'Administration',
    eventAction: 'Create a supplier',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },
  editSupplier: {
    event: 'administration-editSupplier',
    eventCategory: 'Administration',
    eventAction: 'Edit a supplier',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },
  deleteSupplier: {
    event: 'administration-deleteSupplier',
    eventCategory: 'Administration',
    eventAction: 'Delete supplier',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },

  enableDisableSupplier: {
    event: 'administration-enable/Disable',
    eventCategory: 'Administration',
    eventAction: 'Enable/disable supplier',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },

  createPos : {
    event: 'administration-create-pos',
    eventCategory: 'Administration',
    eventAction: 'Create POS',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },
  editPos: {
    event: 'administration-edit-pos',
    eventCategory: 'Administration',
    eventAction: 'Edit POS',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },
  deletePos : {
    event: 'administration-delete-pos',
    eventCategory: 'Administration',
    eventAction: 'Delete POS',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },
  resetSupplierPassword: {
    event: 'administration-resetSupplierPassword',
    eventCategory: 'Administration',
    eventAction: 'Reset supllier password',
    eventLabel: '<number-of-users-selected>',
    plateforme: 'manager',
  },
  manageFeatures: {
    event: 'administration-manageFeature/activateOrDeactivateFeature',
    eventCategory: 'Administration',
    eventAction: 'Activate or deactivate the feature',
    plateforme: 'manager',
  },
  error500: {
    event: 'error-error500',
    eventCategory: 'Error',
    eventAction: 'Error 500',
    eventLabel: '<error-detail>',
    plateforme: 'manager',
  },
};
