<div class="user-error">
  <h1 class="user-error-title">
    {{ 'global-user_error_page-title' | translate }}
  </h1>

  <div>
    <a (click)="goToLogin()" mat-raised-button color="accent" class="user-error-home-button">{{ 'global-user_error_page-button' | translate }}</a>
  </div>
</div>

<iframe id="logout_ifm" width="1" height="1" src=""></iframe>
