<div class="notification" *ngIf="data && data.notification" size="sm">
  <div class="notification-info">
    <div class="notification-info-icon"><i class="icon-feed"></i></div>
    <div class="notification-info-text">
      <h3 class="notification-info-text-title">{{data.notification.title}}</h3>
      <p class="notification-info-text-para">{{data.notification.text}}</p>
      <a class="notification-info-text-link" *ngIf="data.notification.link?.label" [href]="data.notification.link.url" target="_blank">
        {{data.notification.link.label}}
        <i class="notification-info-text-link-icon icon-arrow-right"></i>
      </a>
      <span class="notification-info-text-wrapper" *ngIf="data.image">
        <div class="notification-info-text-wrapper-img" [style.background-image]="'url(' + data.image +')'"></div>
      </span>
      <a (click)="getDocument(data.notification.documentId)" class="notification-info-text-link" *ngIf="data.notification.documentId">
        {{ 'notifications-notification_list_page-download_file' | translate }}
        <i class="notification-info-text-link-icon icon-arrow-right"></i>
      </a>
    </div>
  </div>

  <div class="panel-footer notification-validation align-center" *ngIf="!data.isDisplayMode">
    <button mat-button class="notification-validation-action-button _transparent-main" type="button" (click)="onClose(false)">
      {{ 'global-generic_terms-form_cancel' | translate }}
    </button>
    <button mat-raised-button color="primary" class="notification-validation-action-button" (click)="onClose(true)">
      {{ 'global-generic_terms-form_submit' | translate }}
    </button>
  </div>

</div>
