import { Action } from '@ngrx/store';

export enum FeatureActionTypes {
  GetFeature = '[Feature] Get',
  GetFeatureSuccess = '[Feature] Get success',
  GetFeatureFail = '[Feature] Get fail',
}

export class GetFeature implements Action {
  readonly type = FeatureActionTypes.GetFeature;
}

export class GetFeatureSuccess implements Action {
  readonly type = FeatureActionTypes.GetFeatureSuccess;

  constructor(public payload: string[]) { }
}

export class GetFeatureFail implements Action {
  readonly type = FeatureActionTypes.GetFeatureFail;
}

export type FeatureActions = GetFeature | GetFeatureSuccess | GetFeatureFail;
