import { Action } from '@ngrx/store';

export enum AuthenticationActionTypes {
  AuthenticationAuthenticate = '[Authentication] Authenticate',
  AuthenticationDisconnect = '[Authentication] Disconnect',
  AuthenticationRefreshTokens = '[Authentication] Refresh Tokens',
  AuthenticationRefreshTokensSuccess = '[Authentication] Refresh Tokens Success',
  AuthenticationRefreshTokensFail = '[Authentication] Refresh Tokens Fail',
}

export class Authenticate implements Action {
  readonly type = AuthenticationActionTypes.AuthenticationAuthenticate;
}

export class Disconnect implements Action {
  readonly type = AuthenticationActionTypes.AuthenticationDisconnect;
}

export class RefreshTokens implements Action {
  readonly type = AuthenticationActionTypes.AuthenticationRefreshTokens;
}

export class RefreshTokensSuccess implements Action {
  readonly type = AuthenticationActionTypes.AuthenticationRefreshTokensSuccess;
}

export class RefreshTokensFail implements Action {
  readonly type = AuthenticationActionTypes.AuthenticationRefreshTokensFail;
}

export type AuthenticationActions = Authenticate | Disconnect | RefreshTokens | RefreshTokensSuccess | RefreshTokensFail;
