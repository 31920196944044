import { Action } from '@ngrx/store';
import { UserWithoutAccount } from '../models/user-without-account.model';

export enum MyaldAccessUsersActionTypes {
  GetMyaldAccessUsers = '[MyaldAccess] Get users',
  GetMyaldAccessUsersSuccess = '[MyaldAccess] Get users success',
  GetMyaldAccessUsersFail = '[MyaldAccess] Get users fail',
  SelectMyaldAccessUsers = '[MyaldAccess] Select users',
}

export class GetMyaldAccessUsers implements Action {
  readonly type = MyaldAccessUsersActionTypes.GetMyaldAccessUsers;
}

export class GetMyaldAccessUsersSuccess implements Action {
  readonly type = MyaldAccessUsersActionTypes.GetMyaldAccessUsersSuccess;

  constructor(public payload: UserWithoutAccount[]) { }
}

export class GetMyaldAccessUsersFail implements Action {
  readonly type = MyaldAccessUsersActionTypes.GetMyaldAccessUsersFail;
}

export class SelectMyaldAccessUsers implements Action {
  readonly type = MyaldAccessUsersActionTypes.SelectMyaldAccessUsers;

  constructor(public payload: UserWithoutAccount[]) { }
}

export type MyaldAccessUsersActions = GetMyaldAccessUsers
  | GetMyaldAccessUsersSuccess
  | GetMyaldAccessUsersFail
  | SelectMyaldAccessUsers;
