import { ChangeDetectorRef, Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as panelState from './state';
import { takeWhile } from 'rxjs/operators';
import { fadeAnimation } from '../animations/fade.animation';
import { PanelService } from '@shared/ui/panel/shared/panel.service';
import { slideLeftAnimation } from '../animations/slide-left.animation';

@Component({
  selector: 'mgr-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  animations: [fadeAnimation, slideLeftAnimation],
})
export class PanelComponent implements OnInit, OnDestroy {
  @Input() codename: string;
  @Input() title: string;
  @Input() initialOpened: boolean;

  @Output() clickBackLink: EventEmitter<void> = new EventEmitter<void>();

  @ContentChild(TemplateRef) contentRef;

  private componentActive = true;
  public showPanel: boolean;
  public isFirstPanel: boolean;

  constructor(private readonly store: Store<panelState.State>,
              private readonly panelService: PanelService,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.checkRequiredCodename();
    if (this.initialOpened) {
      this.panelService.open(this.codename);
    }
    this.store.pipe(select(panelState.getPanelFeatureState),
                    takeWhile(() => this.componentActive),
    ).subscribe((state) => {
      this.showPanel = state.showPanels.includes(this.codename);
      this.isFirstPanel = state.showPanels.indexOf(this.codename) === 0;
      this.ref.detectChanges();
    },
    );
  }

  private checkRequiredCodename(): void {
    if (!this.codename) {
      throw Error('Panel codename is required');
    }
  }

  public closePanel(): void {
    if (this.showPanel) {
      this.panelService.close(this.codename);
      this.clickBackLink.emit();
    }
  }

  public notifyClickBackLink(event): void {
    event.stopPropagation();
    if (this.clickBackLink.observers.length > 0) {
      this.clickBackLink.emit();
    } else {
      this.closePanel();
    }
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }
}
