var MIN_LOADING_SCREEN_TIME = 1500;
var HostPageService = /** @class */ (function () {
    function HostPageService() {
        // Get config from widow/global
        this.originalConfig = window['HOST_PAGE_CONFIG'];
        this.loadingScreen = document.getElementById('loading-screen');
    }
    Object.defineProperty(HostPageService.prototype, "config", {
        get: function () {
            return this.originalConfig;
        },
        enumerable: true,
        configurable: true
    });
    HostPageService.prototype.hideLoadingScreen = function () {
        var _this = this;
        var elapsedTime = Date.now() - this.originalConfig.bootTime;
        // Create function to hide
        var hide = function () {
            _this.loadingScreen.classList.add('hide');
        };
        if (elapsedTime < MIN_LOADING_SCREEN_TIME) {
            setTimeout(hide, MIN_LOADING_SCREEN_TIME - elapsedTime);
        }
        else {
            hide();
        }
        // return remaining time (or 0 if it's already passed)
        return Math.max(0, MIN_LOADING_SCREEN_TIME - elapsedTime);
    };
    return HostPageService;
}());
export { HostPageService };
