import { CurrencyPosition } from './app-currencies';

export const AppCountries = {
  IN: {
    countryName: 'India',
    defaultCulture: 'en-us',
    cultures: ['en-us'],
    defaultDateFormat: 'dd/MM/yyyy',
    currencyPosition: CurrencyPosition.BEFORE,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  GR: {
    countryName: 'Greece',
    defaultCulture: 'en-us',
    cultures: ['en-us', 'el-gr'],
    defaultDateFormat: 'dd/MM/yyyy',
    currencyPosition: CurrencyPosition.BEFORE,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  BG: {
    countryName: 'Bulgaria',
    defaultCulture: 'en-us',
    cultures: ['en-us', 'bg-bg'],
    defaultDateFormat: 'dd/MM/yyyy',
    currencyPosition: CurrencyPosition.AFTER,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  AT: {
    countryName: 'Austria',
    defaultCulture: 'en-us',
    cultures: ['en-us', 'de-de'],
    defaultDateFormat: 'dd/MM/yyyy',
    currencyPosition: CurrencyPosition.AFTER,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  CH: {
    countryName: 'Switzerland',
    defaultCulture: 'en-us',
    cultures: ['en-us', 'de-de', 'fr-fr'],
    defaultDateFormat: 'dd/MM/yyyy',
    currencyPosition: CurrencyPosition.BEFORE,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  UA: {
    countryName: 'Ukraine',
    defaultCulture: 'en-us',
    cultures: ['en-us', 'uk-ua'],
    defaultDateFormat: 'dd/MM/yyyy',
    currencyPosition: CurrencyPosition.BEFORE,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  TR: {
    countryName: 'Turkey',
    defaultCulture: 'en-us',
    cultures: ['en-us', 'tr-tr'],
    defaultDateFormat: 'dd/MM/yyyy',
    currencyPosition: CurrencyPosition.BEFORE,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  HU: {
    countryName: 'Hungary',
    defaultCulture: 'en-us',
    cultures: ['en-us', 'hu-hu'],
    defaultDateFormat: 'dd/MM/yyyy',
    currencyPosition: CurrencyPosition.BEFORE,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  RS: {
    countryName: 'Serbia',
    defaultCulture: 'en-us',
    cultures: ['en-us', 'sr-rs'],
    defaultDateFormat: 'dd/MM/yyyy',
    currencyPosition: CurrencyPosition.BEFORE,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  RO: {
    countryName: 'Romania',
    defaultCulture: 'en-us',
    cultures: ['en-us', 'ro-ro'],
    defaultDateFormat: 'dd.MM.yyyy',
    currencyPosition: CurrencyPosition.AFTER,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  DZ: {
    countryName: 'Algeria',
    defaultCulture: 'en-us',
    cultures: ['en-us', 'fr-fr'],
    defaultDateFormat: 'dd/MM/yyyy',
    currencyPosition: CurrencyPosition.BEFORE,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  MA: {
    countryName: 'Morocco',
    defaultCulture: 'en-us',
    cultures: ['en-us', 'fr-fr'],
    defaultDateFormat: 'dd/MM/yyyy',
    currencyPosition: CurrencyPosition.AFTER,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  PT: {
    countryName: 'Portugal',
    defaultCulture: 'pt-pt',
    cultures: ['en-us', 'pt-pt'],
    defaultDateFormat: 'dd/MM/yyyy',
    currencyPosition: CurrencyPosition.AFTER,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  PL: {
    countryName: 'Poland',
    defaultCulture: 'en-us',
    cultures: ['en-us', 'pl-pl'],
    defaultDateFormat: 'dd/MM/yyyy',
    currencyPosition: CurrencyPosition.AFTER,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  HR: {
    countryName: 'Croatia',
    defaultCulture: 'en-us',
    cultures: ['en-us', 'hr-hr'],
    defaultDateFormat: 'dd/MM/yyyy',
    currencyPosition: CurrencyPosition.BEFORE,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  SI: {
    countryName: 'Slovenia',
    defaultCulture: 'en-us',
    cultures: ['en-us', 'sl-si'],
    defaultDateFormat: 'dd/MM/yyyy',
    currencyPosition: CurrencyPosition.BEFORE,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  FI: {
    countryName: 'Finland',
    defaultCulture: 'en-us',
    cultures: ['en-us'],
    defaultDateFormat: 'dd/MM/yyyy',
    currencyPosition: CurrencyPosition.AFTER,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  NFFLEET: {
    countryName: 'Nffleet',
    defaultCulture: 'en-us',
    cultures: ['en-us'],
    defaultDateFormat: 'dd/MM/yyyy',
    currencyPosition: CurrencyPosition.AFTER,
    seo: {
      title: 'My Nordea Fleet',
    },
    company_logo : 'logo-nffleet-white.png',
  },
  EE: {
    countryName: 'Estonia',
    defaultCulture: 'en-us',
    cultures: ['en-us', 'et-ee'],
    defaultDateFormat: 'dd.MM.yyyy',
    currencyPosition: CurrencyPosition.AFTER,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  LV: {
    countryName: 'Latvia',
    defaultCulture: 'en-us',
    cultures: ['en-us', 'lv-lv'],
    defaultDateFormat: 'dd.MM.yyyy',
    currencyPosition: CurrencyPosition.AFTER,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  LT: {
    countryName: 'Lithuania',
    defaultCulture: 'en-us',
    cultures: ['en-us', 'lt-lt'],
    defaultDateFormat: 'yyyy-MM-dd',
    currencyPosition: CurrencyPosition.AFTER,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'brand-logo.svg',
  },
  MY: {
    countryName: 'Malaysia',
    defaultCulture: 'en-us',
    cultures: ['en-us'],
    defaultDateFormat: 'dd/MM/yyyy',
    currencyPosition: CurrencyPosition.BEFORE,
    seo: {
      title: 'My ALD Manager',
    },
    company_logo : 'logo-ALD-mitsubishi-2021_logos white.png',
  },
};
