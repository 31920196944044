import { Action } from '@ngrx/store';

export enum PanelActionTypes {
  OpenPanel = '[Panel] Open Panel',
  ClosePanel = '[Panel] Close Panel',
  ClearPanels = '[Panel] Close All Panel',
}

export class OpenPanel implements Action {
  readonly type = PanelActionTypes.OpenPanel;

  constructor(public payload: string) {
  }
}

export class ClosePanel implements Action {
  readonly type = PanelActionTypes.ClosePanel;

  constructor(public payload: string) {
  }
}

export class ClearPanels implements Action {
  readonly type = PanelActionTypes.ClearPanels;

  constructor() {}
}

export type PanelActions = OpenPanel | ClosePanel | ClearPanels;
