import { Filter } from '@shared/ui/table/table-filters/shared/filter.interface';

export function selectedOnlyFilterFunction(option: Filter, row: any): boolean | null {
  return option.value ? option.value.includes(row.highlighted) : false;
}

export const SelectedOnlyFilter: Filter = {
  name: 'selected-only',
  label: 'global-filters-selectedonly_title',
  options: [
    {
      label: 'global-filters-selectedonly_label',
      value: true,
    },
  ],
  predicateFn: selectedOnlyFilterFunction,
};
