import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { StorageAuthService } from '../storage/storage.auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { select, Store } from '@ngrx/store';
import { AuthenticationState, AuthStatus } from './authentication.reducer';
import { Observable, of, throwError } from 'rxjs';
import { filter, map, take, catchError, switchMap } from 'rxjs/operators';
import * as AuthenticationActions from './authentication.actions';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { APP_ROUTES } from '@app/app.routes';
import { StorageService } from '../storage/storage.service';
import { TranslationService } from '../translation/translation.service';
import { getAuthStatus } from './index';

@Injectable()
export class AuthenticationService implements CanActivate {
  private helper = new JwtHelperService();

  constructor(
    private storageAuth: StorageAuthService,
    private storage: StorageService,
    private translationService: TranslationService,
    private store: Store<AuthenticationState>,
    private router: Router,
    private httpClient: HttpClient,
  ) {
  }

  canActivate() {
    return this.isAuthenticated();
  }

  public isAuthenticated(): Observable<boolean> {
    return this.store.pipe(
      filter(state => state['authentication'] !== undefined),
      select(getAuthStatus),
      switchMap((status) => {
        if (status === AuthStatus.IN) {
          return of(true);
        }
        if (status === AuthStatus.OUT) {
          if (this.storageAuth.accessToken && !this.helper.isTokenExpired(this.storageAuth.accessToken)) {
            this.store.dispatch(new AuthenticationActions.Authenticate());
            return of(true);
          }
          this.redirectToLoginPage();
          this.storage.clearStorage();
          return of(false);
        }
      }),
      take(1),
    );
  }

  public redirectToLoginPage(): void {
    this.router.navigate([`/${APP_ROUTES.START}/${APP_ROUTES.LOGIN}`]);
  }

  public clearAuthentication(): void {
    this.storageAuth.removeAuth();
    this.storage.clearStorage();
    this.store.dispatch(new AuthenticationActions.Disconnect());
  }

  public refreshToken(): Observable<boolean> {
    const adfsUrl = new URL(environment.adfs.loginUrl);
    const headers = new HttpHeaders({ 'Content-type': 'application/x-www-form-urlencoded' });
    const options = { headers };

    const params = new HttpParams()
      .append('grant_type', 'refresh_token')
      .append('refresh_token', this.storageAuth.refreshToken as string)
      .append('client_id', adfsUrl['searchParams'].get('client_id'))
      .append('redirect_uri', adfsUrl['searchParams'].get('redirect_Uri'));

    return this.httpClient.post<any>(
      `${adfsUrl.origin}/adfs/oauth2/token`,
      params,
      options,
    ).pipe(
      map((response) => {
        if (response && response.access_token) {
          const previousAuth = this.storageAuth.auth;
          previousAuth.access_token = response.access_token;
          this.storageAuth.setAuth(previousAuth);
          this.store.dispatch(new AuthenticationActions.Authenticate());
          return true;
        }
        this.clearAuthentication();
        return false;
      }),
      catchError((error) => {
        this.clearAuthentication();
        return throwError(error);
      }),
    );
  }
}
