export const StorageConst = {
  auth: {
    keys: {
      session: 'auth',
      rememberAuth: 'remember_auth',
      accessToken: 'access_token',
      refreshToken: 'refresh_token',
    },
  },
  translation: {
    keys: {
      lang: 'lang',
    },
  },
  selectedCountry: 'country_selected',
  selectedRoleId: 'selectedRoleId',
  selectedRoleLabel: 'selectedRoleLabel',
  fullNavigation: 'fullNavigation',
};
