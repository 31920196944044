<mgr-panel codename="userAlertsPanel" title="{{ 'global-user_notifications_panel-title' | translate }}"
  (clickBackLink)="ngOnDestroy()">

  <ng-template>

    <div class="notification-container">

      <div *ngFor="let notification of notifications" class="notification-item"
        [ngClass]="{'notification-item-unread': !notification.read}">

        <!-- Unread indicator -->
        <div *ngIf="!notification.read" class="notification-new">{{ 'global-user_notifications_panel-unread_indicator' | translate }}</div>

        <!-- Text -->
        <h3 *ngIf="notification.category === NotificationCategory.shared_document" class="notification-title">
          {{ 'global-user_notifications_panel-driver_shared_document' | translate:{ driver:notification.createdByName } }}
        </h3>
        <h3 *ngIf="notification.category != NotificationCategory.shared_document" class="notification-title">
          {{ notification.title }}</h3>

        <p class="notification-text">{{ notification.text }}</p>

        <!-- date -->
        <time class="notification-time">{{ notification.date | date: dateFormat }}</time>

        <!-- Image -->
        <div class="notification-illustration" *ngIf="notification.imageUrl"
          [style.background-image]="'url(' + notification.imageUrl +')'"></div>

        <!-- document -->
        <a (click)="getDocument(notification.documentId)" class="notification-document-link"
          *ngIf="notification.documentId">{{ 'global-user_notifications_panel-download_file' | translate }}</a>

        <!-- Link -->
        <a [href]="notification.link.url" target="_blank" class="notification-link"
          *ngIf="notification.link?.label && notification.link?.url">{{ notification.link.label }}</a>

      </div>

    </div>

  </ng-template>

</mgr-panel>
