import { Action } from '@ngrx/store';
import { ContractLight } from '../../models/contract-light.model';
import { ContractNotificationRecipient } from '../../models/contract-notification-recipient.model';
import { ContractInformation } from '@my-fleet/shared/models/contract-information.model';
import { ContractAskPermission,
  ContractOrderDamageRepair,
  ContractOrderMaintenance,
} from '@my-fleet/shared/models/contract-assistance.model';

export enum ContractActionTypes {
  GetContractList = '[ContractList] Get',
  GetContractListSuccess = '[ContractList] Get success',
  GetContractListFail = '[ContractList] Get fail',
  SelectContracts = '[ContractList] Set selected contracts ids and user ids',
  GetCurrentContract = '[ContractDetail] Get the current contract details',
  GetCurrentContractSuccess = '[ContractDetail] Current Contract Success',
  GetCurrentContractFail = '[ContractDetail] Current Contract Fail',
  SendAskPermissionForm = '[AskPermission] Create',
  SendAskPermissionFormSuccess = '[AskPermission] Create Success',
  SendAskPermissionFormFail = '[AskPermission] Create Fail',
  ClearAskPermissionForm = '[AskPermission] Clear Form',
  SaveAskPermissionForm = '[AskPermission] Save Form',
  SendOrderDamageRepairRequest = '[OrderDamageRepair] Create',
  SendOrderDamageRepairRequestSuccess = '[OrderDamageRepair] Create Success',
  SendOrderDamageRepairRequestFail = '[OrderDamageRepair] Create Fail',
  ClearOrderDamageRepairRequest = '[OrderDamageRepair] Clear Request',
  SaveOrderDamageRepairRequest = '[OrderDamageRepair] Save Request',
  SendOrderMaintenanceRequest = '[OrderMaintenance] Create',
  SendOrderMaintenanceRequestSuccess = '[OrderMaintenance] Create Success',
  SendOrderMaintenanceRequestFail = '[OrderMaintenance] Create Fail',
  ClearOrderMaintenanceRequest = '[OrderMaintenance] Clear Form',
  SaveOrderMaintenanceRequest = '[OrderMaintenance] Save Form',
  ClearFormsStatus = '[All Forms] Clear Forms Status Error or Success',
  ClearAssistanceForDriverForms = '[All Forms] Clear all assistance for drivers forms',
  ExportFuelReport = '[User List] Export fuel report',
  ExportFuelReportFail = '[User List] Export fuel report fail',
  ExportFuelReportSuccess = '[User List] Export fuel report success',
}

export class GetContractList implements Action {
  readonly type = ContractActionTypes.GetContractList;
}

export class GetContractListSuccess implements Action {
  readonly type = ContractActionTypes.GetContractListSuccess;

  constructor(public payload: ContractLight[]) { }
}

export class GetContractListFail implements Action {
  readonly type = ContractActionTypes.GetContractListFail;
}

export class SelectContracts implements Action {
  readonly type = ContractActionTypes.SelectContracts;

  constructor(public payload: ContractNotificationRecipient[]) { }
}

export class GetCurrentContract implements Action {
  readonly type = ContractActionTypes.GetCurrentContract;

  constructor(public payload: string) { }
}

export class GetCurrentContractSuccess implements Action {
  readonly type = ContractActionTypes.GetCurrentContractSuccess;

  constructor(public payload: ContractInformation) { }
}

export class GetCurrentContractFail implements Action {
  readonly type = ContractActionTypes.GetCurrentContractFail;
}

export class SendAskPermissionForm implements Action {
  readonly type = ContractActionTypes.SendAskPermissionForm;
  constructor(public payload: any) {
  }
}

export class SendAskPermissionFormSuccess implements Action {
  readonly type = ContractActionTypes.SendAskPermissionFormSuccess;
  constructor() {
  }
}

export class SendAskPermissionFormFail implements Action {
  readonly type = ContractActionTypes.SendAskPermissionFormFail;
}

export class ClearAskPermissionForm implements Action {
  readonly type = ContractActionTypes.ClearAskPermissionForm;
}

export class SaveAskPermissionForm  implements Action {
  readonly type = ContractActionTypes.SaveAskPermissionForm ;

  constructor(public payload: ContractAskPermission) { }
}

export class SendOrderDamageRepairRequest implements Action {
  readonly type = ContractActionTypes.SendOrderDamageRepairRequest;
  constructor(public payload: any) {
  }
}

export class SendOrderDamageRepairRequestSuccess implements Action {
  readonly type = ContractActionTypes.SendOrderDamageRepairRequestSuccess;
  constructor() {
  }
}

export class SendOrderDamageRepairRequestFail implements Action {
  readonly type = ContractActionTypes.SendOrderDamageRepairRequestFail;
}

export class ClearOrderDamageRepairRequest implements Action {
  readonly type = ContractActionTypes.ClearOrderDamageRepairRequest;
}

export class SaveOrderDamageRepairRequest  implements Action {
  readonly type = ContractActionTypes.SaveOrderDamageRepairRequest ;

  constructor(public payload: ContractOrderDamageRepair) { }
}

export class SendOrderMaintenanceRequest implements Action {
  readonly type = ContractActionTypes.SendOrderMaintenanceRequest;
  constructor(public payload: ContractOrderMaintenance) {
  }
}

export class SendOrderMaintenanceRequestSuccess implements Action {
  readonly type = ContractActionTypes.SendOrderMaintenanceRequestSuccess;
}

export class SendOrderMaintenanceRequestFail implements Action {
  readonly type = ContractActionTypes.SendOrderMaintenanceRequestFail;
}

export class ClearOrderMaintenanceRequest implements Action {
  readonly type = ContractActionTypes.ClearOrderMaintenanceRequest;
}

export class SaveOrderMaintenanceRequest  implements Action {
  readonly type = ContractActionTypes.SaveOrderMaintenanceRequest ;

  constructor(public payload: ContractOrderMaintenance) { }
}

export class ClearFormsStatus implements Action {
  readonly type = ContractActionTypes.ClearFormsStatus;
}

export class ClearAssistanceForDriverForms implements Action {
  readonly type = ContractActionTypes.ClearAssistanceForDriverForms;
}

export class ExportFuelReport implements Action {
  readonly type = ContractActionTypes.ExportFuelReport;

  constructor(public payload: string) { }
}

export class ExportFuelReportFail implements Action {
  readonly type = ContractActionTypes.ExportFuelReportFail;
}

export class ExportFuelReportSuccess implements Action {
  readonly type = ContractActionTypes.ExportFuelReportSuccess;
}

export type ContractActions =
  GetContractList |
  GetContractListSuccess |
  GetContractListFail |
  SelectContracts |
  GetCurrentContract |
  GetCurrentContractFail |
  GetCurrentContractSuccess |
  SendAskPermissionForm |
  SendAskPermissionFormSuccess |
  SendAskPermissionFormFail |
  ClearAskPermissionForm |
  SaveAskPermissionForm |
  SendOrderDamageRepairRequest |
  SendOrderDamageRepairRequestSuccess |
  SendOrderDamageRepairRequestFail |
  ClearOrderDamageRepairRequest |
  ClearFormsStatus |
  SaveOrderDamageRepairRequest |
  SaveOrderDamageRepairRequest |
  SendOrderMaintenanceRequest |
  SendOrderMaintenanceRequestSuccess |
  SendOrderMaintenanceRequestFail |
  ClearOrderMaintenanceRequest |
  ClearAssistanceForDriverForms |
  SaveOrderMaintenanceRequest |
  ExportFuelReport |
  ExportFuelReportFail |
  ExportFuelReportSuccess;
