import { WindowRef } from '@shared/core/helpers/window.ref';
var GtmRef = /** @class */ (function () {
    function GtmRef(windowRef) {
        this.windowRef = windowRef;
    }
    Object.defineProperty(GtmRef.prototype, "nativeDatalayer", {
        get: function () {
            return this.windowRef.nativeWindow.dataLayer || [];
        },
        enumerable: true,
        configurable: true
    });
    return GtmRef;
}());
export { GtmRef };
