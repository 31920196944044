import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AuthenticationActionTypes } from './authentication.actions';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { UpdateLastLoginDate } from '@app/shared/entity/user/state/user.actions';
var AuthenticationEffects = /** @class */ (function () {
    function AuthenticationEffects(actions$) {
        this.actions$ = actions$;
        this.effect$ = this.actions$.pipe(ofType(AuthenticationActionTypes.AuthenticationRefreshTokens));
        this.authenticate$ = this.actions$.pipe(ofType(AuthenticationActionTypes.AuthenticationAuthenticate), mergeMap(function () { return of(new UpdateLastLoginDate()); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthenticationEffects.prototype, "effect$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AuthenticationEffects.prototype, "authenticate$", void 0);
    return AuthenticationEffects;
}());
export { AuthenticationEffects };
