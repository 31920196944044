import { Filter } from '@shared/ui/table/table-filters/shared/filter.interface';

export function dateFilter(option: Filter, row: any): boolean | null {
  const today = new Date();
  const rowDate = new Date(row.date);
  let showRow = false;

  if (option.value.includes('today')) {
    showRow = rowDate.toDateString() === today.toDateString();
  }

  if (option.value.includes('thisMonth')) {
    showRow = showRow || (rowDate.getMonth() === today.getMonth() && rowDate.getFullYear()  === today.getFullYear());
  }
  return showRow;
}

export const DateFilter = {
  name: 'date',
  label: 'global-filters-date',
  options: [
    {
      label: 'global-filters-today',
      value: 'today',
    },
    {
      label: 'global-filters-thismonth',
      value: 'thisMonth',
    },
  ],
  predicateFn: dateFilter,
};
