/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-shell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./top-navigation/top-navigation.component.ngfactory";
import * as i3 from "./top-navigation/top-navigation.component";
import * as i4 from "../shared/core/translation/translation.service";
import * as i5 from "@ngrx/store";
import * as i6 from "../notifications/shared/services/notifications.service";
import * as i7 from "../shared/ui/panel/shared/panel.service";
import * as i8 from "../shared/core/storage/storage.service";
import * as i9 from "./left-navigation/left-navigation.component.ngfactory";
import * as i10 from "./left-navigation/left-navigation.component";
import * as i11 from "../shared/core/configuration/configuration.service";
import * as i12 from "../shared/entity/user/user.service";
import * as i13 from "../shared/core/feature/feature.service";
import * as i14 from "@ald/ui";
import * as i15 from "@ngx-translate/core";
import * as i16 from "../shared/core/storage/storage.auth.service";
import * as i17 from "@angular/common";
import * as i18 from "@angular/router";
import * as i19 from "../shared/entity/cookies/didomi-cookie-consent/didomi-cookie-consent.component.ngfactory";
import * as i20 from "../shared/entity/cookies/didomi-cookie-consent/didomi-cookie-consent.component";
import * as i21 from "../shared/core/helpers/window.ref";
import * as i22 from "../shared/entity/cookies/services/didomi-cookie-consent.service";
import * as i23 from "./app-shell.component";
var styles_AppShellComponent = [i0.styles];
var RenderType_AppShellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppShellComponent, data: {} });
export { RenderType_AppShellComponent as RenderType_AppShellComponent };
export function View_AppShellComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mgr-top-navigation", [], null, null, null, i2.View_TopNavigationComponent_0, i2.RenderType_TopNavigationComponent)), i1.ɵdid(1, 245760, null, 0, i3.TopNavigationComponent, [i4.TranslationService, i5.Store, i6.NotificationsService, i7.PanelService, i8.StorageService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mgr-left-navigation", [], null, null, null, i9.View_LeftNavigationComponent_0, i9.RenderType_LeftNavigationComponent)), i1.ɵdid(3, 245760, null, 0, i10.LeftNavigationComponent, [i5.Store, i11.ConfigurationService, i12.UserService, i13.FeatureService, i14.AldSnackbarService, i15.TranslateService, i8.StorageService, i16.StorageAuthService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "main", [["class", "main-container nav-fullwidth"]], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i17.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "nav-fullwidth": 0 }), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i18.RouterOutlet, [i18.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "mgr-smart-didomi-cookie-consent", [], null, null, null, i19.View_DidomiCookieConsentComponent_0, i19.RenderType_DidomiCookieConsentComponent)), i1.ɵdid(10, 114688, null, 0, i20.DidomiCookieConsentComponent, [i21.WindowRef, i5.Store, i22.SmartDidomiCookieConsentService], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 3, 0); var currVal_0 = "main-container nav-fullwidth"; var currVal_1 = _ck(_v, 6, 0, _co.showFullNavigation); _ck(_v, 5, 0, currVal_0, currVal_1); _ck(_v, 8, 0); _ck(_v, 10, 0); }, null); }
export function View_AppShellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mgr-shell", [], null, null, null, View_AppShellComponent_0, RenderType_AppShellComponent)), i1.ɵdid(1, 245760, null, 0, i23.AppShellComponent, [i5.Store, i5.Store, i7.PanelService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppShellComponentNgFactory = i1.ɵccf("mgr-shell", i23.AppShellComponent, View_AppShellComponent_Host_0, {}, {}, []);
export { AppShellComponentNgFactory as AppShellComponentNgFactory };
