<form [formGroup]="form" (ngSubmit)="submitForm()" class="align-left" *ngIf="form">

  <mgr-password-validation [passwordControl]="form.get('password')"></mgr-password-validation>

  <!-- Current password -->
  <mat-form-field class="form-fullwidth change-password-form-field">
    <div>
      <input type="password" id="currentPassword" passwordVisibility [placeholder]="'global-change_my_password_panel-password_label' | translate"
        formControlName="currentPassword" matInput required>
    </div>
    <mat-error *ngIf="form.get('currentPassword')?.hasError('required')">
      {{ 'global-generic_terms-error_message_required' | translate }}
    </mat-error>
  </mat-form-field>

  <!-- New password -->
  <mat-form-field class="form-fullwidth change-password-form-field">
    <div>
      <input type="password" id="password" passwordVisibility  (keyup)="resetConfirmationField()"
        [placeholder]="'global-change_my_password_panel-new_password_label' | translate" formControlName="password"
        matInput required>
    </div>
    <mat-error *ngIf="form.get('password')?.hasError('required')">
      {{ 'global-generic_terms-error_message_required' | translate }}
    </mat-error>
  </mat-form-field>

  <!-- New password confirmation -->
  <mat-form-field class="form-fullwidth change-password-form-field">
    <div>
      <input type="password" id="confirm-password" passwordVisibility #confirmPassword
        [placeholder]="'global-generic_terms-confirm_new_password_label' | translate" formControlName="confirmPassword"
        matInput required>
    </div>
    <mat-error *ngIf="form.get('confirmPassword')?.hasError('required')">
      {{ 'global-generic_terms-error_message_required' | translate }}
    </mat-error>
    <mat-error *ngIf="form.get('confirmPassword')?.hasError('confirmedPassword')">{{ 'global-generic_terms-error_password_mismatch' | translate }}</mat-error>
  </mat-form-field>

  <div class="panel-footer align-center">
    <button mat-button mgrClosePanel="changeUserPassword" type="button">{{'global-generic_terms-form_cancel'| translate}}</button>
    <button mat-raised-button [disabled]="!form.valid" type="submit" color="primary">{{'global-generic_terms-form_submit'| translate}}</button>
  </div>
</form>