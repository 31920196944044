import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { environment } from './environments/environment';

const mapScript = document.createElement('script');
mapScript.src = `https://maps.googleapis.com/maps/api/js?key=${environment.gmapApiKey}&libraries=places`;
document.body.appendChild(mapScript);

const gtmNoScript = (gtmID: string): string  =>
  `<iframe src='https://www.googletagmanager.com/ns.html?id=${gtmID}'
          height='0'
          width=0'
          style='display:none;visibility:hidden'>
  </iframe>`;

const noscript = document.createElement('noscript');

noscript.innerHTML = gtmNoScript(environment.gtmKey);
document.body.appendChild(noscript);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
