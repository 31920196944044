import { Injectable } from '@angular/core';
import { StorageService } from '@app/shared/core/storage/storage.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { DidomiCookieConsentViewModel } from '../model/didomi-cookie-consent.view-model';
import { StorageConst } from '@shared/core/storage/storage.const';

@Injectable({
  providedIn: 'root',
})
export class SmartDidomiCookieConsentService {
  private countryCode: string;
  private culture: string;

  constructor(private storageService: StorageService) {
  }

  public loadConfig(configDetails: DidomiCookieConsentViewModel): Observable<any> {
    return new Observable((observer) => {
      this.countryCode = this.storageService.get(StorageConst.selectedCountry);
      this.culture = this.storageService.get(StorageConst.translation.keys.lang);
      configDetails.gtmKey = `${environment.gtmKey}`;
      configDetails.app.apiKey = `${environment.didomiApiKey}`;
      configDetails.app.privacyPolicyURL = `${environment.publicDocumentsBaseUrl}/${this.countryCode}/cookies-charter-${this.culture}.pdf`;
      observer.next(configDetails);
      observer.complete();
    });
  }

  public getDomain(hostname): string {
    return hostname === 'localhost' ? hostname :
      `.${hostname.split('.')[hostname.split('.').length - 2]}` +
      `.${hostname.split('.')[hostname.split('.').length - 1]}`;
  }

  public deleteCookies(name: string, domain: string) {
    const d: Date = new Date();
    d.setTime(d.getTime() + (-1 * 24 * 60 * 60 * 1000));
    const cName = `${name}= `;
    const expires = `expires=${d.toUTCString()}`;
    const cPath = `path=/`;
    const cDomain = `domain=${domain}`;
    document.cookie = `${cName};${cDomain};${expires};${cPath}`;
  }

  public getAllCookie() {
    const cookieArray = [];
    document.cookie.split('; ').forEach((cookie) => {
      cookieArray.push(cookie.split('=')[0]);
    });
    return cookieArray;
  }

}
