import { OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as navigationState from '../state';
import { takeWhile } from 'rxjs/operators';
import { TranslationService } from '@shared/core/translation/translation.service';
import { PanelService } from '@shared/ui/panel/shared/panel.service';
import { UserManagementConst } from '@user-management/shared/user-management.const';
import { getUserSelectedWorkingScope, getUserSelectedRole } from '@shared/entity/user/state';
import { APP_ROUTES } from '@app/app.routes';
import { UserRoles } from '@shared/entity/user/user.const';
import { NotificationsService } from '@notifications/shared/services/notifications.service';
import { Notification } from '@notifications/shared/models/notification.model';
import { UpdateMyPreferences } from '@app/shared/entity/user/state/user.actions';
import { CONFIGURATION } from 'country-configuration/country-configuration';
import { getManagerAlerts, getManagerAlertsLoading, getUnreadNotificationsNumber } from '@app/notifications/shared/state';
import * as NotificationsActions from '@notifications/shared/state/notifications.actions';
import { StorageService } from '@app/shared/core/storage/storage.service';
var TopNavigationComponent = /** @class */ (function () {
    function TopNavigationComponent(translationService, store, notificationService, panelService, storageService) {
        this.translationService = translationService;
        this.store = store;
        this.notificationService = notificationService;
        this.panelService = panelService;
        this.storageService = storageService;
        this.componentActive = true;
        this.selectedWorkingScopesSize = 0;
        this.APP_ROUTES = APP_ROUTES;
        this.USER_ROLES = UserRoles;
        this.nffleetCounty = 'Nffleet';
    }
    TopNavigationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.availableCultures = CONFIGURATION.getCountry().cultures;
        this.country = CONFIGURATION.getCountry();
        this.countryLogo = CONFIGURATION.getCountry().company_logo;
        this.countryName = CONFIGURATION.getCountry().countryName;
        this.store.pipe(select(navigationState.getShowLanguages), takeWhile(function () { return _this.componentActive; })).subscribe(function (status) {
            _this.showLanguages = status;
        });
        this.store.pipe(select(navigationState.getShowNotifications), takeWhile(function () { return _this.componentActive; })).subscribe(function (status) {
            _this.showNotifications = status;
        });
        this.store.pipe(select(getUserSelectedRole)).pipe(takeWhile(function () { return _this.componentActive; })).subscribe(function (user) {
            if (user && user.role && user.role.includes(UserRoles.FleetManager)) {
                _this.store.dispatch(new NotificationsActions.GetManagerAlerts());
                _this.notifications$ = _this.store.pipe(select(getManagerAlerts), takeWhile(function () { return _this.componentActive; }));
                _this.notificationsLoading$ = _this.store.pipe(select(getManagerAlertsLoading), takeWhile(function () { return _this.componentActive; }));
                _this.unreadNotifications$ = _this.store.pipe(select(getUnreadNotificationsNumber), takeWhile(function () { return _this.componentActive; }));
            }
        });
        this.initSelectedWorkingScopeSize();
    };
    Object.defineProperty(TopNavigationComponent.prototype, "currentLanguage", {
        get: function () {
            var _this = this;
            return this.availableCultures ? this.availableCultures.find(function (l) { return l === _this.translationService.language; }) : '';
        },
        enumerable: true,
        configurable: true
    });
    TopNavigationComponent.prototype.toggleUserNavigation = function (status) {
        this.store.dispatch(new navigationState.actions.ToggleUserNavigation(status));
    };
    TopNavigationComponent.prototype.toggleLanguage = function () {
        this.store.dispatch(new navigationState.actions.ToggleLanguages(!this.showLanguages));
    };
    TopNavigationComponent.prototype.openSwitchWorkingScopePanel = function () {
        this.panelService.open(UserManagementConst.windowCodename.switchWorkingScopePanel);
    };
    TopNavigationComponent.prototype.switchLanguage = function (selectedLanguage) {
        this.toggleLanguage();
        var preferences = { culture: selectedLanguage };
        this.store.dispatch(new UpdateMyPreferences(preferences));
    };
    TopNavigationComponent.prototype.initSelectedWorkingScopeSize = function () {
        var _this = this;
        this.store.pipe(select(getUserSelectedWorkingScope), takeWhile(function () { return _this.componentActive; })).subscribe(function (selectedWorkingScope) {
            if (selectedWorkingScope) {
                _this.selectedWorkingScopesSize = selectedWorkingScope.length;
            }
        });
    };
    TopNavigationComponent.prototype.openNotificationPanel = function () {
        this.panelService.open(UserManagementConst.windowCodename.userAlertsPanel);
        this.notificationService.updateSeenAlertTime().subscribe();
    };
    TopNavigationComponent.prototype.ngOnDestroy = function () {
        this.componentActive = false;
    };
    return TopNavigationComponent;
}());
export { TopNavigationComponent };
