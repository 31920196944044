/* tslint:disable */

export const environment = {
  production: true,
  host: 'http://localhost:4000',
  adfs: {
    loginUrl: 'https://authenticationportal.com/adfs/oauth2/authorize?response_type=code&client_id=738ae2e1-a486-49d5-8645-1dbdc5bb2ce2&resource=https://manager.aldautomotive.com&redirect_Uri=https://manager.aldautomotive.com/start/onlogin',
    logoutUrl: 'https://authenticationportal.com/adfs/ls?wa=wsignout1.0&wreply=https://manager.aldautomotive.com',
    clientId: '738ae2e1-a486-49d5-8645-1dbdc5bb2ce2',
    loginRedirectUri: 'https://manager.aldautomotive.com/start/onlogin',
    baseUrl: 'https://authenticationportal.com/adfs/oauth2/token'
  },
  countryRedirects: {
    IN: '',
    BG: '',
    GR: '',
    AT: '',
    CH: '',
    RO: '',
    TR: '',
    UA: '',
    HU: '',
    RS: '',
    SI: '',
    FI: '',
    PL: '',
    DZ: '',
    MA: '',
    HR: '',
    PT: '',
    EE: 'https://my.ayvens.com/ee-fleet',
    LV: 'https://my.ayvens.com/lv-fleet',
    LT: 'https://my.ayvens.com/lt-fleet',
    NFFLEET:'',
    MY: ''
  },
  apigatewayUrl: 'https://apimanager.aldautomotive.com',
  jwtWhiteList: ['apimanager.aldautomotive.com'],
  translationLoadPath: '/apigateway/Translation/api/Translations/myaldmanager/',
  appInsightsInstrumentationKey: '12010eb2-048b-4d95-94e4-6a551ddc4942',
  googleAnalyticsKey: 'UA-102140739-7',
  gtmKey: 'GTM-56DPNWF',
  gmapApiKey: 'AIzaSyAlsx9464OymM0GR6cG2meclh-zfBh9Q5A',
  assetsPath: '/assets/',
  siteKey: '6LewocUUAAAAAN_YqWsWltjfui8dpg_uHRLjndzc',
  publicDocumentsBaseUrl: 'https://myalddocuments.blob.core.windows.net/public',
  didomiApiKey: 'cf2c3525-195b-489b-9bea-b733850b94b6'
};
