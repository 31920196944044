import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lowerUnderscore',
})
export class LowerUnderscorePipe implements PipeTransform {

  constructor() {}

  transform(value: string, args?: any): any {
    if (!value) {
      return null;
    }

    return value.toLowerCase().trim()
      .replace(/[^A-Za-z0-9\- ]/, '')
      .replace(/ /g, '_')
      .replace(/-/, '_');
  }
}
