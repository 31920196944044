import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Pipe({
  name: 'assetPath',
})
export class AssetPathPipe implements PipeTransform {

  constructor() {

  }

  transform(value: any, args?: any): any {
    return environment.assetsPath + value;
  }
}
