import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

export function validateNotEmpty(control: AbstractControl): ValidationErrors | null {
  return control.value && control.value.length && control.value.length !== 0 ? null : { notEmpty: true };
}

@Directive({
  selector: '[notEmpty]',
  providers: [{ provide: NG_VALIDATORS, useExisting: NotEmptyValidatorDirective, multi: true }],
})
export class NotEmptyValidatorDirective implements Validator {
  validate: ValidatorFn = validateNotEmpty;
}
