export const AppCultures = [
  'en-us',
  'el-gr',
  'bg-bg',
  'fr-fr' ,
  'de-de',
  'uk-ua',
  'tr-tr',
  'hu-hu',
  'ro-ro',
  'sr-rs',
  'pt-pt',
  'pl-pl',
  'sl-si',
  'hr-hr',
  'et-ee',
  'lt-lt',
  'lv-lv',
];
