import { Filter } from '@shared/ui/table/table-filters/shared/filter.interface';

export function contractStateFilterFunction(option: Filter, row: any): boolean | null {
  return option.value.some(opt => opt === row.status);
}

export const ContractStateFilter = {
  name: 'contractState',
  label: 'global-filters-contractstate',
  options: [
    {
      label: 'global-filters-onroad',
      value: 'VehicleOnRoad',
    },
    {
      label: 'global-filters-terminating',
      value: 'ContractTerminating',
    },
    {
      label: 'global-filters-terminated',
      value: 'ContractTerminated',
    },
  ],
  predicateFn: contractStateFilterFunction,
};
