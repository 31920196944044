var _a;
import { APP_ROUTES } from '@app/app.routes';
var PageSection;
(function (PageSection) {
    PageSection["Homepage"] = "Homepage";
    PageSection["UsersManagement"] = "Users management";
    PageSection["MyFleet"] = "My fleet";
    PageSection["Documents"] = "Documents";
    PageSection["MyReports"] = "My reports";
    PageSection["Notifications"] = "Notifications";
    PageSection["Administration"] = "Administration";
    PageSection["Others"] = "Others";
    PageSection["ErrorPage"] = "Error page";
})(PageSection || (PageSection = {}));
var routeMapper = (_a = {},
    _a[APP_ROUTES.HOME] = PageSection.Homepage,
    _a[APP_ROUTES.USER_MANAGEMENT] = PageSection.UsersManagement,
    _a[APP_ROUTES.MY_FLEET] = PageSection.MyFleet,
    _a[APP_ROUTES.DOCUMENTS] = PageSection.Documents,
    _a[APP_ROUTES.REPORTS] = PageSection.MyReports,
    _a[APP_ROUTES.NOTIFICATIONS] = PageSection.Notifications,
    _a[APP_ROUTES.ADMINISTRATION] = PageSection.Administration,
    _a[APP_ROUTES.NOT_FOUND] = PageSection.ErrorPage,
    _a);
export var getPageSectionByUrl = function (url) {
    for (var route in routeMapper) {
        if (route && Object.prototype.hasOwnProperty.call(routeMapper, route)) {
            if (url.includes(route)) {
                return routeMapper[route];
            }
        }
    }
    return PageSection.Others;
};
