import * as tslib_1 from "tslib";
import { ConfigurationActionTypes } from './configuration.actions';
export var initialState = {
    loading: false,
    isLoaded: false,
    items: null,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var loading;
    var items;
    switch (action.type) {
        case ConfigurationActionTypes.GetConfiguration:
            loading = true;
            return tslib_1.__assign({}, state, { loading: loading });
        case ConfigurationActionTypes.GetConfigurationSuccess:
            loading = false;
            items = action.payload;
            return tslib_1.__assign({}, state, { loading: loading, items: items, isLoaded: true });
        case ConfigurationActionTypes.GetConfigurationFail:
            loading = false;
            return tslib_1.__assign({}, state, { loading: loading, isLoaded: true });
        default:
            return state;
    }
}
