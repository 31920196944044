import { Directive, HostListener, Input, OnInit } from '@angular/core';
import * as panelState from '../state';
import { select, Store } from '@ngrx/store';
import { PanelService } from '@shared/ui/panel/shared/panel.service';

@Directive({
  selector: '[mgrClosePanel]',
})
export class ClosePanelDirective implements OnInit {
  @Input() mgrClosePanel: string;

  private isPanelActive = true;

  constructor(
    private readonly store: Store<panelState.State>,
    private readonly panelService: PanelService,
  ) {
  }

  ngOnInit(): void {
    this.store.pipe(select(panelState.getPanelFeatureState)).subscribe((state) => {
      this.isPanelActive = state.showPanels.includes(this.mgrClosePanel);
    },
    );
  }

  @HostListener('click', ['$event'])
  private onClick(event): void {
    event.stopPropagation();
    if (this.isPanelActive) {
      this.panelService.close(this.mgrClosePanel);
    }
  }

}
