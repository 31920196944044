import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material';
import 'hammerjs';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule()
export class MaterialLocaleModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: MaterialLocaleModule,
      providers: [
        MatNativeDateModule,
        MatMomentDateModule,
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
        {
          provide: DateAdapter,
          useClass: MomentDateAdapter,
          deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
      ],
    };
  }
}
