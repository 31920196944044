import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@shared/shared.module';
import { AppRouting } from './app.routing';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './app.reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { UserModule } from '@shared/entity/user/user.module';
import { environment } from '../environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TRANSLATION_CONFIG } from '@shared/core/translation/translation-config';
import { JwtInterceptor } from '@shared/core/jwt-interceptor/jwt.interceptor';
import { ErrorModule } from '@shared/core/error-handler/error.module';
import { AppShellComponent } from '@shell/app-shell.component';
import { GtmModule } from '@shared/core/gtm/gtm.module';
import { MaterialLocaleModule } from '@app/shared/ui/material-locale.module';
import { MatButtonModule } from '@angular/material';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    AppRouting,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    MaterialLocaleModule.forRoot(),
    GtmModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([AppEffects]),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    TranslateModule.forRoot(TRANSLATION_CONFIG),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    UserModule,
    ErrorModule,
    MatButtonModule,
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    FormBuilder,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    Title,
  ],
  bootstrap: [AppComponent],
  entryComponents: [AppShellComponent],
})

export class AppModule { }
