/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./panel.component";
import * as i5 from "@ngrx/store";
import * as i6 from "./shared/panel.service";
var styles_PanelComponent = [i0.styles];
var RenderType_PanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PanelComponent, data: { "animation": [{ type: 7, name: "Fade", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 0, name: "*", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: { type: 4, styles: null, timings: "500ms ease-out" }, options: null }, { type: 1, expr: ":leave", animation: { type: 4, styles: null, timings: "300ms ease-in" }, options: null }], options: {} }, { type: 7, name: "SlideLeft", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { transform: "translateX(100%)" }, offset: null }, options: undefined }, { type: 0, name: "*", styles: { type: 6, styles: { transform: "translateX(0)" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: { type: 4, styles: null, timings: "400ms ease" }, options: null }, { type: 1, expr: ":leave", animation: { type: 4, styles: null, timings: "300ms ease" }, options: null }], options: {} }] } });
export { RenderType_PanelComponent as RenderType_PanelComponent };
function View_PanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "panel-overlay"]], [[2, "_transparent", null], [24, "@Fade", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePanel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isFirstPanel; var currVal_1 = undefined; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_PanelComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "panel-header-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.title)); _ck(_v, 1, 0, currVal_0); }); }
function View_PanelComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_PanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "panel-container"]], [[24, "@SlideLeft", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "panel-header"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.notifyClickBackLink($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "panel-header-back icon-arrow-left"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PanelComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "panel-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PanelComponent_4)), i1.ɵdid(7, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.contentRef; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_PanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "panel"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PanelComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PanelComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showPanel; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.showPanel; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "panel-", _co.codename, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_PanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mgr-panel", [], null, null, null, View_PanelComponent_0, RenderType_PanelComponent)), i1.ɵdid(1, 245760, null, 1, i4.PanelComponent, [i5.Store, i6.PanelService, i1.ChangeDetectorRef], null, null), i1.ɵqud(335544320, 1, { contentRef: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PanelComponentNgFactory = i1.ɵccf("mgr-panel", i4.PanelComponent, View_PanelComponent_Host_0, { codename: "codename", title: "title", initialOpened: "initialOpened" }, { clickBackLink: "clickBackLink" }, []);
export { PanelComponentNgFactory as PanelComponentNgFactory };
